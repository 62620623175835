import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Tabs, Input, Row, Col} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import MemberShipsList from './memberships_list';
import Box from '../../../components/box';
import {getMembership} from "../../../lib/api_membership";

const Memberships = () => {
    const [list, setList] = useState([]);
    const [filter_key, setFilter_key] = useState("");

    // 获取学生列表
    const loadMembers = () => {
        getMembership()
            .then((result) => {
                if (result.code === 0) {
                    setList(result.data);
                    // this.setState({
                    //     list: result.data
                    // })
                } else {

                }
            })
    };

    const handlerSearch = (value) => {
        setFilter_key(value);
    };

    const handlerIncludes = (a, b) => {
        return a && a.length > 0 && a.includes(b);
    };

    const handlerFilter = (item, is_file) => {
        return item.status === is_file &&
            (
                filter_key
                    ? (handlerIncludes(item.name, filter_key)
                        || handlerIncludes(item.mobile_number, filter_key)
                        || handlerIncludes(item.remark, filter_key)
                        || handlerIncludes(item.license_plate, filter_key)
                        // || handlerIncludes(item.other_name, filter_key)
                        || handlerIncludes(item.py, filter_key)
                        || handlerIncludes(item.remark, filter_key)
                        // || handlerIncludes(item.login_name, filter_key)
                    )
                    : true
            )
    }
    useEffect(() => {
        loadMembers();
        // if (this.props.history.location.state && this.props.history.location.state.type === "select") {
        //     this.setState({
        //         is_select: true
        //     })
        // }
    }, [])

    return (
        <PageContent>
            <Box>
                <Row>
                    <Col flex={"auto"}>
                        <Input.Search
                            placeholder="输入姓名、联系电话、车牌、备注、拼音等信息进行搜索"
                            onSearch={handlerSearch}
                            style={{width: 500}}
                            autoFocus
                        />
                    </Col>
                    <Col>
                        <Link to={'/member/keshi_batch'}>
                            <Button type='primary' icon={<PlusOutlined/>}
                                    style={{marginRight: 10}}>手动消课</Button>
                        </Link>
                        <Link to={'/member/member_ship/add'}>
                            <Button type='primary' icon={<PlusOutlined/>}>添加会籍</Button>
                        </Link>
                    </Col>
                </Row>
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab={`有效(${list.filter(item => handlerFilter(item, 0)).length})`} key="1">
                        <MemberShipsList list={list.filter(item => handlerFilter(item, 0))}/>

                    </Tabs.TabPane>
                    <Tabs.TabPane tab={`失效(${list.filter(item => handlerFilter(item, 1)).length})`} key="2">
                        <MemberShipsList list={list.filter(item => handlerFilter(item, 1))}/>

                    </Tabs.TabPane>
                </Tabs>
            </Box>
        </PageContent>
    );
}

export default Memberships;




