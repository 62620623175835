import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {DatePicker, Table, Space, Statistic, Row, Col, Card, Modal} from 'antd';
import moment from 'moment';
import {getStatShouRuDetail} from "../../../lib/api_stat";
import PageContent from '../../../components/page-content/page-content';
import CardDetailCiKaModal from '../../members/members/card-detail-cika-modal';

const {Column} = Table;

// 次卡消费
const CKXFModal = ({data, onCancel}) => {
    const [cikadetail, setcikadetail] = useState(null);
    const list = data.users_cards;
    return (
        <Modal
            title="次卡收入"
            visible={!!data}
            footer={null}
            width={800}
            onOk={() => {

            }}
            onCancel={() => {
                onCancel && onCancel()
            }}
        >
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size={"small"}
            >

                <Column
                    title="失效时间"
                    dataIndex="shixiao_time"
                    render={(shixiao_time) => moment(shixiao_time).format("YYYY-MM-DD HH:mm")}
                />
                <Column
                    title="会员"
                    dataIndex="user_id"
                    render={(user_id) => (<Link to={`/member/members/${user_id}`}>{user_id}</Link>)}
                />
                <Column
                    title="卡片"
                    dataIndex="name"
                    render={(name, record) => <a onClick={() => setcikadetail(record.id)}>{name}</a>}
                />
                <Column
                    title="开始时间"
                    dataIndex="begin_time"
                    render={(begin_time) => moment(begin_time).format("YYYY-MM-DD")}
                />
                <Column
                    title="结束时间"
                    dataIndex="end_time"
                    render={(end_time) => moment(end_time).format("YYYY-MM-DD")}
                />
                <Column
                    title="支付金额"
                    dataIndex="payments"
                />
            </Table>
            {cikadetail &&
            <CardDetailCiKaModal
                onOK={() => {
                    // loadData();
                    setcikadetail(null);
                }}
                onClose={() => {
                    // loadData();
                    setcikadetail(null);
                }}
                id={cikadetail}
            />
            }
        </Modal>
    )
};

// 年卡消费
const NKXFModal = ({data, onCancel}) => {
    const list = data.memberships_dates;
    return (
        <Modal
            title="年卡年费收入"
            visible={!!data}
            footer={null}
            width={800}
            onOk={() => {

            }}
            onCancel={() => {
                onCancel && onCancel()
            }}
        >
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="名称"
                    dataIndex="name"
                />
                <Column
                    title="开始时间"
                    dataIndex="begin_time"
                    render={(begin_time) => moment(begin_time).format("YYYY-MM-DD")}
                />
                <Column
                    title="结束时间"
                    dataIndex="end_time"
                    render={(end_time) => moment(end_time).format("YYYY-MM-DD")}
                />
                <Column
                    title="支付金额"
                    dataIndex="payments"
                />
            </Table>
        </Modal>
    )
};

const StatMonthDetail = ({year, month}) => {

    const [data, setdata] = useState({});
    const [ckje, setckje] = useState(null);
    const [ckczje, setckczje] = useState(null);
    const [ckzscs, setckzscs] = useState(null);
    const [ckzsczcs, setckzsczcs] = useState(null);
    const [nkjlf, setnkjlf] = useState(null);
    const [nkczjlf, setnkczjlf] = useState(null);
    const [nkzsjlf, setnkzsjlf] = useState(null);
    const [nkzsczjlf, setnkzsczjlf] = useState(null);

    const fetchData = () => {
        (async () => {
            let res = await getStatShouRuDetail(year, month);
            if (res.code === 0) {
                setdata(res.data);
            }
        })().catch()
    };

    useEffect(fetchData, []);

    return (
        <PageContent title={`${year}-${month}统计详情`}>
            {data && <div>
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Card title="次卡">
                        <Row>
                            <Col span={8}>
                                <Statistic title="次卡收入(元)" value={data.ckje}
                                           formatter={(value) => (<a onClick={() => {
                                               setckje(JSON.parse(data.orders))
                                           }}>
                                               {value}
                                           </a>)}
                                />
                            </Col>
                            <Col span={8}>
                                <Statistic title="累计收入(元)" value={data.ckhj}/>
                            </Col>
                        </Row>
                    </Card>
                    <Card title="年卡年费">
                        <Row>

                            <Col span={8}>
                                <Statistic title="收入(元)" value={data.nknf}
                                           formatter={(value) => (<a onClick={() => {
                                               setckczje(JSON.parse(data.orders))
                                           }}>
                                               {value}
                                           </a>)}
                                />
                            </Col>
                            <Col span={8}>
                                <Statistic title="累计收入(元)" value={data.nkhj}/>
                            </Col>
                        </Row>
                    </Card>
                    <Card title="合计">
                        <Row>
                            <Col span={8}>
                                <Statistic title="本期合计收入(元)" value={data.hjje}/>
                            </Col>
                            <Col span={8}>
                                <Statistic title="累计收入(元)" value={data.hjjehj}/>
                            </Col>
                        </Row>
                    </Card>
                </Space>
                {ckje && <CKXFModal data={ckje} onCancel={() => setckje(null)}/>}
                {ckczje && <NKXFModal data={ckczje} onCancel={() => setckczje(null)}/>}

            </div>}

        </PageContent>
    )
};


export default StatMonthDetail;