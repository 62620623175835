import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {postCard} from "../../../lib/api_cards";
import {Modal, Form} from 'antd';
import CardForm from './cards-form';

const CardsAddModal = ({visible, onClose, onOK}) => {
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log(values)
                values.holiday_use = values.holiday_use ? 1 : 0;
                values.weekdays_use = values.weekdays_use ? 1 : 0;
                values.special_use = values.special_use ? 1 : 0;
                values.expired_date = values.expired_date ? values.expired_date.format("YYYY-MM-DD") : null;
                values.card_type = 0;
                postCard(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        handleOK();
                    })
            })
    };
    const  handleOK = () => {
        onOK && onOK();
    };

    const handleClose = () => {
        onClose && onClose();
    };
    return (
        <Modal
            title="添加次卡"
            visible={visible}
            onOk={handleSubmit}
            onCancel={handleClose}
            destroyOnClose={true}
        >
            <CardForm form={form} initialValues={{
                keshi: 1,
                retail_price: 0,
                time_type: 0,
                holiday_use: true,
                weekdays_use: true,
                special_use: true,
            }}/>

        </Modal>
    )
}

CardsAddModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};

export default CardsAddModal;
