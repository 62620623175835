const SET_STAFF = 'staff/SET_STAFF';

const initialState = [];

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_STAFF:
            // console.log("SET_STAFF", {...action.staff});
            return [...action.staff];
        default:
            return state;
    }
};

const setStaff = function (staff) {
    return {
        type: SET_STAFF,
        staff: staff
    }
};


export {
    reducer as default,
    initialState as initialStaffState,
    setStaff,
}