import PropTypes from 'prop-types';

const YuyueTypeName = ({id}) => {
    switch (id) {
        case 0:
            return "散客";
        case 1:
            return "会员";
        case 2:
            return "潜在";
        case 3:
            return "小组";
        default:
            return ""
    }
};
YuyueTypeName.propTypes = {
    id: PropTypes.number,
};
export default YuyueTypeName;

