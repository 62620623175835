import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PageContent from '../../components/page-content/page-content';
import {Button, Table, DatePicker} from 'antd';
import {getStat1} from '../../lib/api_stat'
import Box from '../../components/box';
import moment from 'moment';
import excelUtil from '../../lib/excelUtil.js'
import JiaoAn2 from './jiaolian2'

const {Column} = Table;


// @hasAuth
class Staff extends Component {
    state = {
        date: moment(),
        list: [],

        detailItem: null
    };

    componentDidMount() {
        // console.log(this);
        // this.hasRole();
        this.loadData()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        let begin_time = this.state.date.format("YYYY-MM-01");
        let end_time = moment(this.state.date).endOf('month').format("YYYY-MM-DD");
        getStat1(begin_time, end_time)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data
                    })
                    // this.props.setStaff(result.data);
                } else {

                }
            })
    };
    onChange = (date, dateString) => {
        console.log(date, dateString);
        this.setState({
            date: date
        }, () => {
            this.loadData()
        })
    };

    columns = [{
        title: '教练',
        dataIndex: '教练',
        key: '教练',
    }, {
        title: '15分钟',
        dataIndex: '15分钟',
        key: '15分钟',
    }, {
        title: '30分钟',
        dataIndex: '30分钟',
        key: '30分钟',
    }, {
        title: '45分钟',
        dataIndex: '45分钟',
        key: '45分钟',
    }, {
        title: '处罚',
        dataIndex: '处罚',
        key: '处罚',
    }, {
        title: '合计',
        dataIndex: '合计',
        key: '合计',
    }, {
        title: '主教',
        dataIndex: '主教',
        key: '主教',
    }, {
        title: '助教',
        dataIndex: '助教',
        key: '助教',
    }, {
        title: '小组处罚',
        dataIndex: '小组处罚',
        key: '小组处罚',
    }, {
        title: '小组合计',
        dataIndex: '小组合计',
        key: '小组合计',
    }, {
        title: '散客15分钟',
        dataIndex: '散客15分钟',
        key: '散客15分钟',
    }, {
        title: '散客30分钟',
        dataIndex: '散客30分钟',
        key: '散客30分钟',
    }, {
        title: '散客45分钟',
        dataIndex: '散客45分钟',
        key: '散客45分钟',
    }, {
        title: '会员',
        dataIndex: '会员',
        key: '会员',
    }, {
        title: '潜在',
        dataIndex: '潜在',
        key: '潜在',
    }, {
        title: '合计',
        dataIndex: '合计2',
        key: '合计2',
    }];

    exportXlsx = () => {
        excelUtil.exportExcel(this.columns, this.state.list, `${this.state.date.format("YYYY年M月")}课时统计.xlsx`)
    };


    render() {
        let {list,detailItem} = this.state;
        let begin_time = this.state.date.format("YYYY-MM-01");
        let end_time = moment(this.state.date).endOf('month').format("YYYY-MM-DD");
        return (

                <Box>
                    <DatePicker
                        allowClear={false}
                        onChange={this.onChange}
                        value={this.state.date}
                        picker="month"/>
                    <Button onClick={this.exportXlsx}>导出</Button>
                    <Table
                        dataSource={list}
                        rowKey={"教练"}
                        pagination={false}
                        size={"small"}
                    >
                        <Column
                            title="教练"
                            dataIndex="教练"
                            key="教练"
                            render={(_, record)=>{
                                return <a onClick={()=>this.setState({detailItem: record})}>{record["教练"]}</a>
                            }}
                        />
                        <Column
                            title="15分钟"
                            dataIndex="15分钟"
                            key="15分钟"
                            width={70}
                            align="center"
                        />
                        <Column
                            title="30分钟"
                            dataIndex="30分钟"
                            key="30分钟"
                            width={70}
                            align="center"
                        />
                        <Column
                            title="45分钟"
                            dataIndex="45分钟"
                            key="45分钟"
                            width={70}
                            align="center"
                        />
                        <Column
                            title="处罚"
                            dataIndex="处罚"
                            key="处罚"
                            width={70}
                            align="center"
                        />
                        <Column
                            title="合计"
                            dataIndex="合计"
                            key="合计"
                            width={70}
                            align="center"
                        />

                        <Column
                            title="主教"
                            dataIndex="主教"
                            key="主教"
                            width={70}
                            align="center"
                        />
                        <Column
                            title="助教"
                            dataIndex="助教"
                            key="助教"
                            width={70}
                            align="center"
                        />
                        <Column
                            title="小组处罚"
                            dataIndex="小组处罚"
                            key="小组处罚"
                            width={90}
                            align="center"
                        />
                        <Column
                            title="小组合计"
                            dataIndex="小组合计"
                            key="小组合计"
                            width={90}
                            align="center"
                        />
                        <Column
                            title="散客15分钟"
                            dataIndex="散客15分钟"
                            key="散客15分钟"
                            width={70}
                            align="center"
                        />
                        <Column
                            title="散客30分钟"
                            dataIndex="散客30分钟"
                            key="散客30分钟"
                            width={70}
                            align="center"
                        />
                        <Column
                            title="散客45分钟"
                            dataIndex="散客45分钟"
                            key="散客45分钟"
                            width={70}
                            align="center"
                        />
                        <Column
                            title="会员"
                            dataIndex="会员"
                            key="会员"
                            width={70}
                            align="center"
                        />
                        <Column
                            title="潜在"
                            dataIndex="潜在"
                            key="潜在"
                            width={70}
                            align="center"
                        />
                        <Column
                            title="合计"
                            dataIndex="合计2"
                            key="合计2"
                            width={70}
                            align="center"
                        />

                    </Table>
                    <div style={{color:"red"}}>
                    备注：以上数据包含赠券课时
                    </div>

                    <Table
                        dataSource={list}
                        rowKey={"教练"}
                        pagination={false}
                        size={"small"}
                    >
                        <Column
                            title="教练"
                            dataIndex="教练"
                            key="教练"
                            render={(_, record)=>{
                                return <a onClick={()=>this.setState({detailItem: record})}>{record["教练"]}</a>
                            }}
                        />
                        <Column
                            title="免费邀约"
                            dataIndex="免费邀约"
                            key="免费邀约"
                            width={140}
                            align="center"
                        />
                    </Table>
                    {detailItem &&
                    <JiaoAn2
                        visible={true}
                        nick_name={detailItem["教练"]}
                        begin_time={begin_time}
                        end_time={end_time}
                        onClose={()=>{
                            this.setState({detailItem: null})
                        }
                        }
                    />
                    }
                </Box>


        );
    }
}


export default withRouter(Staff);



