import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import {getMembershipType} from "../../../lib/api_membership_type";
import styles from './memberships_type_select.module.css';

/**
 * 年卡会籍选择
 * @param value
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
const MembershipsTypeSelect = ({value, onChange}) => {
    const [list, setList] = useState([]);

    /**
     * 获取会籍类型数据
     */
    const fetchData = () => {
        getMembershipType()
            .then(res => {
                if (res.code === 0) {
                    setList(res.data.filter(m => m.id !== 0 && m.status === 0))
                }
            })
    }

    useEffect(fetchData, []);

    const handleChange = (value) => {
        onChange && onChange(value, list.find(m => m.id === value))
    };

    return (<Select
        style={{
            width: '100%',
        }}
        placeholder="选择会籍类型"
        // defaultValue={['china']}
        defaultValue={value}
        onChange={handleChange}
        optionLabelProp="label"
    >
        {list.map(m => (
            <Select.Option value={m.id} label={`${m.name} / ￥${m.price} / ${m.member_count}人 / ${m.limit_days}天`}
                           key={m.id}>
                <div className={styles.option}>
                    <span style={{flex: 1}}>{m.name}</span>
                    <span style={{width: 100}}>￥{m.price}</span>
                    <span style={{width: 50}}>{m.member_count}人</span>
                    <span style={{width: 70}}>{m.limit_days}天</span>
                </div>
            </Select.Option>
        ))}

    </Select>)
}

export default MembershipsTypeSelect;