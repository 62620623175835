import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {putStaff2} from "../../../lib/api_staff";
import '@ant-design/compatible/assets/index.css';
import {Modal, Input, Form, InputNumber, Divider, Radio, DatePicker} from 'antd';
import moment from 'moment';
import Extattr from './extattr-editor';
import FormDataCheck from '../../../components/data/form-data-check';

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};

class StaffEditModal extends Component {
    formRef = React.createRef();

    state = {
        is_coach: 0
    };

    componentWillReceiveProps(props) {
        if (props.user_info && this.state.is_coach !== props.user_info.is_coach) {
            this.setState({
                is_coach: props.user_info.is_coach
            })
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    handleSubmit = e => {
        e.preventDefault();
        this.formRef.current.validateFields()
            .then(values => {
                console.log('Received values of form: ', values);
                values.id = this.props.user_info.id;
                // values.birthday = values.birthday ? values.birthday.format("YYYY-MM-DD") : null;
                putStaff2(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            this.handleOK();
                        }
                    })
            });
    };

    handleRolesChange = (values) => {
        // console.log(values)
        this.setState({
            is_coach: (values.includes(1) || values.includes(4)) ? 1 : 0
        })
    };

    handleIdChange = (e) => {
        console.log(e.target.value);
        let id = e.target.value;
        if (id.length === 18 || id.length === 15) {
            let birthYearSpan = id.length === 18 ? 4 : 2;
            let year = (birthYearSpan === 2 ? '19' : '') + id.substr(6, birthYearSpan);
            let month = id.substr(6 + birthYearSpan, 2);
            let day = id.substr(8 + birthYearSpan, 2);
            let birthday = year + '-' + month + '-' + day;
            console.log(birthday);
            this.formRef.current.setFieldsValue({
                birthday: moment(birthday)
            })
        }
    }

    render() {
        const {is_coach} = this.state;
        const {user_info} = this.props;


        if (user_info) {
            return (

                <Modal
                    title="修改员工信息"
                    visible={!!this.props.user_info}
                    onOk={this.handleSubmit}
                    onCancel={this.handleClose}
                    destroyOnClose={true}
                    width={600}
                >
                    <Form {...formItemLayout}
                        // onSubmit={this.handleSubmit}
                          initialValues={user_info}
                          ref={this.formRef}
                    >
                        <Form.Item
                            label="账号"
                            // name="userid"
                            // extra="成员唯一标识，设置以后不能修改！"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: '请输入账号',
                            //     },
                            // ]}
                        >
                            <span className="ant-form-text">{user_info.userid}</span>
                            {/*<Input/>*/}
                        </Form.Item>
                        <Form.Item
                            label="姓名"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入姓名',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="工号"
                            name="gh"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="别名"
                            name="alias"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="性别"
                            name="gender"
                        >
                            <Radio.Group>
                                <Radio value={1}>男</Radio>
                                <Radio value={2}>女</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="手机号码"
                            name="mobile"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入手机号码',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="邮箱"
                            name="email"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="职务"
                            name="position"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="角色"
                            name="roles"
                        >
                            <FormDataCheck table="roles" labelkey="name" valuekey="id"/>
                        </Form.Item>

                        <Form.Item
                            label="备注"
                            name="remark"
                        >
                            <Input.TextArea/>
                        </Form.Item>
                        <Form.Item
                            label="职级"
                            name="staff_order_by"
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            // label="其他信息"
                            name="extattr"
                            wrapperCol={{
                                span: 24
                            }}
                        >
                            <Extattr/>
                        </Form.Item>
                    </Form>
                </Modal>
            )
        } else {
            return null;
        }
    }
}

StaffEditModal.propTypes = {
    onClose: PropTypes.func,
    onOK: PropTypes.func,
    user_info: PropTypes.object
};

export default StaffEditModal;
