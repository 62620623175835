import React, {Component} from 'react';
import {Switch, Route, withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import {Layout, Menu, Dropdown} from 'antd';
import './App.css';
import Login from './containers/login/login';
import './assets/react-contextmenu.css';
import AuthComponent from './components/auth_component';
// import {getFirstMenu} from "./components/sider-menu/menu.config";
import SiderBar, {getFirstMenu} from './components/sider-menu/sidebar';
import Settings from './containers/settings/Router';
import Members from './containers/members/Router';
import Calendar from './containers/calendar/calendar3';
import PaiBan from './containers/paiban/paiban';
import Stat from './containers/stat/Router';
import QueRen from './containers/calendar/queren';
import ChangePassword from './containers/change-password';
import Messages from './containers/message/messages';
import Manage from './containers/manage/Router';
import MaFang from './containers/mafang/Router';
import DuiZhang from './containers/duizhang/Router';
import './wssocket';
import Drkb from "./containers/manage/drkb/drkb";
import {signOut} from "./reducers/account";
import {showPasswordModal} from "./reducers/password-modal";
import {
    CalendarOutlined,
    CrownOutlined,
    UserOutlined,
    BookOutlined,
    CreditCardOutlined,
    ClockCircleOutlined,
    BarChartOutlined,
    SettingOutlined,
    LogoutOutlined,
    LockOutlined,
    MessageOutlined,
} from '@ant-design/icons';
import HeaderBar from "./header_bar";
import StaffDetailDrawer from  "./containers/manage/staff/staff_detail_drawer";
import Mafang_weisheng from "./containers/paiban/mafang_weisheng";


const menu = ({signOut, showPasswordModal}) => {
    return (
        <Menu
            items={[
                {
                    label: (
                        <a onClick={showPasswordModal}>
                            修改密码
                        </a>
                    ),
                    icon: <LockOutlined/>,
                }, {
                    type: 'divider',
                }, {
                    label: (
                        <a onClick={signOut}>
                            退出登录
                        </a>
                    ),
                    icon: <LogoutOutlined/>,
                },
            ]}
        />
    )
};


const mapStateToProps1 = state => ({});

const mapDispatchToProps1 = dispatch => ({
    signOut: () => dispatch(signOut()),
    showPasswordModal: () => dispatch(showPasswordModal()),
});


const Menuconn = connect(
    mapStateToProps1,
    mapDispatchToProps1
)(menu);


@AuthComponent
class App extends Component {
    state = {
        path: "/",
        siderWidth: 206
    };

    routeChange = (route) => {
        console.log(route); // 这个route里面有当前路由的各个参数信息
        this.setState({
            path: route.pathname
        }, () => {
            // 获取菜单情况
            console.log(this.state.path);
            if (this.props.menus) {
                let menu = getFirstMenu(this.state.path, this.props.menus);
                console.log(menu);
                if (menu && menu.children && menu.children.length === 0) {
                    this.setState({
                        siderWidth: 80
                    })
                } else {
                    this.setState({
                        siderWidth: 206
                    })
                }
            }

        })
    };

    componentDidMount() {
        // 路由监听
        this.historyinstance = this.props.history.listen(this.routeChange);
        // setTimeout(() => {
        //     this.routeChange(this.props.history.location)
        // }, 2000)
        // this.routeChange(this.props.history.location)
    }

    componentWillUnmount() {
        // 取消监听
        this.historyinstance && this.historyinstance();
    }

    componentWillReceiveProps(newProps) {
        this.routeChange(this.props.history.location);
    }


    render() {
        let {account, menus} = this.props;
        if (!account) {
            return (<Login/>)
        }
        console.log(menus);
        return (
            <Layout style={{minHeight: '100vh'}}>
                {/*侧边导航栏*/}
                <SiderBar path={this.state.path} menus={menus}/>
                <Layout style={{marginLeft: this.state.siderWidth}}>
                    {/*<HeaderBar/>*/}
                    <Layout.Content>
                        <Switch>
                            <Route path="/settings"
                                   component={Settings}/>
                            <Route path="/manage"
                                   component={Manage}/>
                            <Route path="/mafang"
                                   component={MaFang}/>
                            <Route path="/drkb"
                                   component={Drkb}/>
                            <Route path="/member"
                                   component={Members}/>
                            <Route exact path="/paiban"
                                   component={PaiBan}/>
                            <Route path="/stat"
                                   component={Stat}/>
                            <Route path="/queren"
                                   component={QueRen}/>
                            <Route path="/messages"
                                   component={Messages}/>

                            <Route path="/duizhang"
                                   component={DuiZhang}/>
                            <Route path="/"
                                   component={Calendar}/>
                        </Switch>
                    </Layout.Content>
                </Layout>
                <ChangePassword/>
                <StaffDetailDrawer/>
            </Layout>
        );

    }
}


const mapStateToProps = state => ({
    // school: state.school,
    account: state.account,
    menus: state.menus
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App));
