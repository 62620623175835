import React, {useState, useEffect} from 'react';
import {getGrowup} from "../../lib/api_growup";
import {Table, Button} from 'antd';
import TeachingDetail from "./teaching-detail";
import {getLuomaListByYuyueId} from "../../lib/api_luoma";
import moment from "moment";
import LuomaAddModal from "./luoma-add-modal";
import {Link} from "react-router-dom";

const {Column} = Table;

const LuomaList = ({yuyue_id}) => {
    const [list, setlist] = useState([]);
    const [visible, setVisible] = useState(false);

    const fetchData = () => {
        getLuomaListByYuyueId(yuyue_id)
            .then(res => {
                if (res.code === 0) {
                    setlist(res.data);
                }
            })

        // if (menberstr) {
        //
        //     let members = JSON.parse(menberstr);
        //     if (members && members.length > 0) {
        //         setusers(members);
        //     }else{
        //         setusers([]);
        //     }
        // }


    };

    useEffect(fetchData, []);

    if (list.length > 0) {
        return (<div>
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="报告时间"
                    dataIndex="createdAt"
                    key="报告时间"
                    render={(time,record) => <Link to={`/member/luoma/${record.id}`}>{
                        moment(time).format("YYYY-MM-DD HH:mm:ss")
                    }</Link>}
                />
                <Column
                    title="会员"
                    dataIndex="user_name"
                    key="会员"
                />
                <Column
                    title="是否落马"
                    dataIndex="isdown"
                    key="是否落马"
                    render={(isdown) => isdown === 1 ? "是" : "否"}
                />
                <Column
                    title="是否受伤"
                    dataIndex="ishurt"
                    key="是否受伤"
                    render={(isdown) => isdown === 1 ? "是" : "否"}
                />
                <Column
                    title="落马场地"
                    dataIndex="luoma_place"
                    key="落马场地"
                />
                <Column
                    title="当前处理人"
                    dataIndex="staff_name"
                    key="当前处理人"
                />
                <Column
                    title="状态"
                    dataIndex="status"
                    key="状态"
                    render={(status) => {
                        if (status === 0) {
                            return "待责任教练反馈";
                        } else if (status === 1) {
                            return "待总教练反馈";
                        } else if (status === 2) {
                            return "待客户经理反馈";
                        } else if (status === 3) {
                            return "待管理员反馈";
                        } else if (status === 4) {
                            return "结束";
                        }
                    }}
                />

            </Table>
        </div>)
    } else {
        return (<div>
            无报告
            <Button onClick={() => setVisible(true)}>发起落马报告</Button>
            <LuomaAddModal
                visible={visible}
                yuyue_id={yuyue_id}
                onOk={() => {
                    setVisible(false);
                    fetchData();
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            />
        </div>)
    }
}

export default LuomaList;