/**
 * @name: 数据字典类型
 * @description:
 * @author: Wood
 * @date: 2021/5/23
 * @update:
 */
import React, {useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import {Card, Button, Space, Divider, Typography} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import DataTable from '../../../components/data/data-table2';
import {useTable} from 'table-render';
import {PlusOutlined} from '@ant-design/icons';
import {refreshApprovals} from "../../../lib/api_approvals";

const Approvals = () => {
    const [visible, setVisible] = useState(false);
    const [editdata, setEditData] = useState(null);
    const table = useRef();
    // const {refresh} = useTable();

    const refresh = async (sp_no) => {
        const res = await refreshApprovals(sp_no);
        if (res.code === 0) {
            table.current.refresh();
        }
    }


    const config = {
        table: table,
        title: "二次授权",
        tableName: "approvals",
        pagination: false,
        size: "small",
        columns: [{
            title: '编号',
            dataIndex: 'id',
            sorter: true,
            sortDirections: ['descend'],
        }, {
            title: '授权类型',
            dataIndex: 'sqlx',
        }, {
            title: '审批编号',
            dataIndex: 'sp_no',
        }, {
            title: '审批状态',
            dataIndex: 'sp_status',
            enum: {
                0: '非内置',
                1: '审批中',
                2: '已通过',
                3: '已驳回',
                4: '已撤销',
                6: '通过后撤销',
                7: '已删除',
                10: '已支付',
            },
        }, {
            title: '提交时间',
            dataIndex: 'apply_time',
        }, {
            title: '操作',
            render: (record) => {
                return (<Typography.Link onClick={() => refresh(record.sp_no)}>刷新</Typography.Link>);
            }
        }],
    };

    return (
        <PageContent>
            <Card bordered={false}>
                <DataTable {...config} />
            </Card>
        </PageContent>
    )
};


export default Approvals;


