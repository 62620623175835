import { io } from "socket.io-client";
import React from 'react';
import {notification} from 'antd';
import store from './reducers';
import {flushMessageCount} from "./reducers/message_count";
import {flashCalendar} from "./reducers/calendar_reload";

const url = window.location.host.indexOf('localhost') > -1 ? "http://localhost:6003":`${window.location.protocol}//${window.location.host}`;
var md = require('markdown-it')({
    html: true,
});

const socket = io(url);
console.log("123");

socket.on("connect", () => {
    console.log("123");
    if (socket.connected) {
        console.log("connected");
        socket.emit("hello", "world");
        // socket.emit( /* ... */ );
    } else {
        // ...
    }
});
socket.on("yuyue-new", (data) => {
    console.log("yuyue-new");
    console.log(data);
    notification.success({
        message: '新的会员预约',
        description:  <div dangerouslySetInnerHTML={{__html: md.render(data || '')}} />,
        duration: 0,
    });
    (async () => {
        store.dispatch(await flushMessageCount());
        store.dispatch(flashCalendar());
    })().catch()
});


socket.on("yuyue-quxiao", (data) => {
    console.log("yuyue-quxiao");
    console.log(data);
    // let keys = Object.keys(data);
    // keys.map(key=> (<div>{key}：{data[key]}</div>))
    notification.error({
        message: '会员取消预约',
        description:  <div dangerouslySetInnerHTML={{__html: md.render(data || '')}} />,
        duration: 0,
    });

    (async () => {
        store.dispatch(await flushMessageCount());
        store.dispatch(flashCalendar());
    })().catch()
});


socket.on("disconnect", () => {
    console.log(socket.connected); // false
});

export default socket;