import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Select} from 'antd';
import MemberCardName from './member-card-name';

const MemberCardSelect = ({value, onChange, user_id, students, memberships, users_cards,useCard = true, useFee = true}) => {
    const [list, setlist] = useState([]);
    const [value1, setvalue1] = useState(value);

    useEffect(() => {
        console.log("change")
        // 获得用户所有卡片信息
        let student = students.find(_ => _.id === user_id);
        if (!student) return;


        let list = [];

        let membership = memberships.find(_ => _.id === student.membership_id);
        if(membership && membership.status===0){
            if(membership.xiuka_status === 0) {
                let fee = parseFloat(membership.fee_remaining) + parseFloat(membership.fee_free_remaining);
                if (useFee && fee > 0) {
                    list.push({
                        value: `membership_${membership.id}`,
                        text: `${membership.name} (余${fee.toFixed(2)})`
                    })
                }
            }
            if(useCard){
                let cards = users_cards.filter(_ => _.membership_id === student.membership_id);
                for (let i in cards) {
                    let card = cards[i];
                    if (card.status === 0) {
                        list.push({
                            value: `card_${card.id}`,
                            text: `${card.name} (余${card.keshi_remaining + card.keshi_free_remaining})`
                        })
                    }
                }
            }
        }

        console.log(list)
        setlist(list);
    }, []);


    useEffect(() => {
        console.log(value);
        setvalue1(value)
    }, [value]);

    const handleCardChange = (e) => {
        console.log(e)
        onChange && onChange(e);
    };

    return (
        <Select
            value={value1}
            onChange={handleCardChange}
        >
            {list.map(card =>
                <Select.Option value={card.value}>
                    {/*<MemberCardName card_id={card.value}/>*/}
                    {card.text}
                </Select.Option>
            )}
        </Select>
    )
};


MemberCardSelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    user_id: PropTypes.number,
};

const mapStateToProps = state => ({
    students: state.students,
    users_cards: state.users_cards,
    memberships: state.memberships,
});

const mapDispatchToProps = dispatch => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberCardSelect);


