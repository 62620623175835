import React, {} from 'react';
import {Modal, Form} from 'antd';
import HorsesSelect from './horses_select';
import {updateHorse} from "../../../lib/api_horses";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};


const HorsesLinkModal = ({id, onCancel, onOk}) => {
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                updateHorse({
                    id: values.horse_id,
                    stable_id: id,
                })
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk();
                        }
                    })
            })
    };

    const handleCancel = () => {
        onCancel && onCancel();
    };
    return (
        <Modal
            title="关联马匹"
            visible={true}
            onOk={handleSubmit}
            onCancel={handleCancel}
            destroyOnClose={true}
        >
            <Form
                form={form}
                {...formItemLayout}
                initialValues={{}}
            >
                <Form.Item
                    label="选择马匹"
                    name="horse_id"
                    rules={[
                        {
                            required: true,
                            message: '请选择马匹',
                        }
                    ]}
                >
                    <HorsesSelect/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default HorsesLinkModal;
