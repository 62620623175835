/**
 * @name: 退会功能
 * @description:
 * @author: Wood
 * @date: 2022/4/3
 * @update:
 */
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {Modal, Form, InputNumber, Input, DatePicker, message} from 'antd';
import {postMembershipTuiFei} from "../../../lib/api_membership";
import Decimal from 'decimal.js'

const MembershipTuiHuiModal = ({data, visible, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [payments_remaining, setpayments_remaining] = useState(0);
    const [fee_remaining, setfee_remaining] = useState(0);


    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                postMembershipTuiFei(data.id, values.remark)
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk(res.data);
                        }
                    })
            })
    };


    useEffect(() => {
        console.log(data);
        setfee_remaining(data.fee_remaining);
        // 计算退还教练费
        let payments_remaining = new Decimal(0);
        for (let i in data.memberships_dates) {
            payments_remaining = payments_remaining.add(data.memberships_dates[i].payments_remaining);
        }
        setpayments_remaining(payments_remaining.toString());
        // console.log(payments_remaining.toString())
    }, []);

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 18},
        },
    };

    return (
        <Modal
            title="退会"
            visible={true}
            onOk={handleSubmit}
            onCancel={onCancel}
            destroyOnClose={true}
            width={800}
        >
            <Form {...formItemLayout}
                  form={form}
                  initialValues={{
                      // begin_time: begindate,
                      // begin_time: moment(data.end_time).add(1, "d")
                  }}
            >
                <Form.Item label="退还教练费">
                    <div>
                        ¥{fee_remaining}
                    </div>
                </Form.Item>
                <Form.Item label="退还年费">
                    <div>
                        ¥{payments_remaining}
                    </div>
                </Form.Item>
                <Form.Item
                    label="备注"
                    name="remark"
                >
                    <Input.TextArea rows={4}/>
                </Form.Item>
            </Form>
        </Modal>
    )
};

MembershipTuiHuiModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};

export default MembershipTuiHuiModal;
