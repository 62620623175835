import axios from './rest-axios';

const getCWZCYSJiDuAll = (year) => axios.get('/manage/cw_zcys_jidu/', {year});
const getCWZCYSJiDuById = (id) => axios.get(`/manage/cw_zcys_jidu/${id}`);


const createOrUpdateCWZCYSJiDu = (data) => axios.post('/manage/cw_zcys_jidu/', data);

export {
    getCWZCYSJiDuAll,
    getCWZCYSJiDuById,
    createOrUpdateCWZCYSJiDu
};
