import React, {useEffect, useState} from 'react'
import {Button, Form, Input, InputNumber, Modal} from "antd";
import {getYuYueById, putFKXX} from "../../lib/api_yuyue";

const formItemLayout = {
    labelCol: {
        span: 6
    },
    wrapperCol: {
        span: 18
    },
};


const FuKuanModal = ({id, visible, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [data, setData] = useState(null);

    const fetchData = () => {
        getYuYueById(id).then(res => {
            setData(res);
            // if (res.code === 0) {
            //     setData(res.data);
            // }
        });
    }

    useEffect(fetchData, []);
    const handleOk = () => {
        form.validateFields().then(values => {
            putFKXX({
                yuyue_id: id,
                fkje: values.fkje,
                ddh: values.ddh,
            }).then(res => {
                if (res.code === 0) {
                    onOk && onOk();
                }
            })
        })
    }
    return (
        <Modal
            title="付款信息"
            open={visible}
            onOk={handleOk}
            onCancel={onCancel}
        >
            {data ? (
                <Form {...formItemLayout}
                      form={form}
                      initialValues={{
                          fkje: data.fkje,
                          ddh: data.ddh,
                      }}
                >
                    <Form.Item name="fkje" label="付款金额">
                        <InputNumber precision={2}/>
                    </Form.Item>
                    <Form.Item name="ddh" label="订单号">
                        <Input/>
                    </Form.Item>
                </Form>

            ) : (
                <div>
                    <div>加载中</div>
                </div>
            )}

        </Modal>
    )
}

export default FuKuanModal;