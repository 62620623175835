import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Card, Button, Space, Divider, Popconfirm, Table, Badge} from 'antd';
import PageContent from '../../components/page-content/page-content';
import {getMessages} from "../../lib/api_messages";
import moment from 'moment';
import Box from '../../components/box';
import MessageDetailModal from './message_detail_modal';

const Messages = ({match}) => {
    const [list, setList] = useState([]);
    const [detail, setdetail] = useState(null);

    const loadData = () => {
        getMessages()
            .then(res => {
                if (res.code === 0) {
                    setList(res.data);
                }
            })
    };
    useEffect(loadData, []);

    return (
        <PageContent title={"消息"}>
            <Box bordered={false}>
                <Table
                    // title="课程设置"
                    size="small"
                    pagination={false}
                    dataSource={list}
                    rowKey={"id"}
                    columns={[{
                        title: '消息内容',
                        dataIndex: 'content',
                        key: "消息内容",
                        ellipsis: true,
                        render: (content, record) => {
                            return <a onClick={() => setdetail(record)}>{record.status === 0 && <Badge status="success" />} {content}</a>;
                        }
                    }, {
                        title: '时间',
                        dataIndex: 'createdAt',
                        key: "时间",
                        width: 150,
                        render: (time) => moment(time).format("YYYY-MM-DD HH:mm")
                    }, {
                        title: '消息类型',
                        dataIndex: 'type',
                        key: "消息类型",
                        width: 150,
                        render: (type) => {
                            switch (type) {
                                case "yuyue-new":
                                    return "新的预约";
                                case "yuyue-quxiao":
                                    return "取消预约";
                                default:
                                    return "其他";
                            }
                        }
                    }]}/>
            </Box>
            {detail && <MessageDetailModal
                data={detail}
                onOk={() => {
                    setdetail(null);
                    loadData();
                }}
                onCancel={() => {
                    setdetail(null);
                    loadData();
                }}
            />}

        </PageContent>
    )
};


export default Messages;


