import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const StaffName = ({id, staff}) => {
    let model = staff.find(item => item.id == id);
    if (model) {
        return model.name
    } else {
        return "";
    }
};
StaffName.propTypes = {
    id: PropTypes.number,
};


const mapStateToProps = state => ({
    staff: state.staff,
});

const mapDispatchToProps = dispatch => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StaffName);

