/**
 * @name: 修改字典类型
 * @description:
 * @author: Wood
 * @date: 2021/5/23
 * @update:
 */
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Modal} from 'antd';
import Form, {useForm} from 'form-render';
import FormDataCheck from '../../../components/data/form-data-check';
import {putData} from "../../../lib/api_data";

const schema = {
    "type": "object",
    "properties": {
        "key": {
            "title": "key",
            "required": true,
            "disabled": false,
            "readOnly": false,
            "hidden": false,
            "props": {
                "allowClear": false
            },
            "type": "string"
        },
        "value": {
            "title": "value",
            "required": true,
            "disabled": false,
            "readOnly": false,
            "hidden": false,
            "props": {
                "allowClear": false
            },
            "type": "string"
        },
        "desc": {
            "title": "描述",
            "required": false,
            "disabled": false,
            "readOnly": false,
            "hidden": false,
            "props": {
                "allowClear": false
            },
            "type": "string"
        },
        "is_lock": {
            "title": "是否内置",
            "default": 0,
            "required": true,
            "disabled": false,
            "readOnly": false,
            "hidden": false,
            "enum": [
                0,
                1
            ],
            "enumNames": [
                "非内置",
                "内置"
            ],
            "type": "number",
            "widget": "radio"
        },
        "status": {
            "title": "状态",
            "default": 0,
            "required": true,
            "disabled": false,
            "readOnly": false,
            "hidden": false,
            "enum": [
                0,
                1
            ],
            "enumNames": [
                "正常",
                "停用"
            ],
            "type": "number",
            "widget": "radio"
        }
    },
    "column": 1,
    "labelWidth": 150,
    "displayType": "row",
    "showDescIcon": true
};

const SysDictTypeEditModal = ({visible, data, onOk, onCancel}) => {
    const form = useForm();

    const onFinish = (formData, errors) => {
        if (errors.length > 0) {
        } else {
            console.log(formData);
            putData("sys_dict_type", formData)
                .then(res => {
                    if (res.code === 0) {
                        onOk && onOk(res.data);
                    }
                })
        }
    };

    useEffect(() => {
        form.setValues(data)
    }, []);

    return (
        <Modal
            title="修改字典类型"
            visible={visible}
            onOk={form.submit}
            onCancel={onCancel}
            width={800}
            destroyOnClose={true}
        >
            <Form
                form={form}
                schema={schema}
                widgets={{FormDataCheck: FormDataCheck}}
                onFinish={onFinish}
                // initialValues={data}
            />
        </Modal>
    )
};


SysDictTypeEditModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    data: PropTypes.object,
};


export default SysDictTypeEditModal;
