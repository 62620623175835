import axios from './rest-axios';

const getMenus = () => axios.get(`/manage/menu`);
const getMenu = (id) => axios.get(`/manage/menu/${id}`);
const postMenu = (data) => axios.post(`/manage/menu`, data);
const putMenu = (id, data) => axios.put(`/manage/menu/${id}`, data);
const deleteMenu = (id) => axios.delete(`/manage/menu/${id}`);

export {
    getMenus,
    getMenu,
    postMenu,
    putMenu,
    deleteMenu
};