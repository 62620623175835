/**
 * @name: 班级详情
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update: 2019/2/24
 */
import React, {useState, useEffect} from 'react';
import PageContent from '../../../components/page-content/page-content';
import {
    Button,
    Descriptions,
    Tag,
    Row,
    Col,
    Empty,
    Spin,
    Space,
} from 'antd';
import Box from '../../../components/box';
import {getMember2, postMemberLevels} from "../../../lib/api_members";
import TrainTypeName from '../../../components/config/train_type_name';
import MemberYuyue from './member_yuyue';
import MemberKeShi from './member_keshis';
import StaffName from '../../../components/staff_name';
import MembershipInfo from '../membership/membership-info';
import MembersEditModal from './members-edit-modal';
import LevelName from "../level-name";
import MemberLevels from "./member_levels";

const MemberDetail = ({id}) => {
    const [student_info, setStudentInfo] = useState(null);
    const [editModal, setEditModal] = useState(false);
    const [hiskey, setHiskey] = useState(0);


    // 获取学生信息
    const loadStudentInfo = () => {
        getMember2(id)
            .then((result) => {
                if (result.code === 0) {
                    setStudentInfo(result.data);
                }
            })
    };
    useEffect(loadStudentInfo, []);

    return (
        <div>
            {student_info
                ? <PageContent>
                    <Box title="会员信息" extra={<Button key="2"
                                        onClick={() => setEditModal(true)}>
                        修改
                    </Button>}>
                        <Descriptions bordered size={"small"}>
                            <Descriptions.Item label="会员姓名">{student_info.nick_name}{student_info.is_file === 1 ?
                                <Tag>已失效</Tag> :
                                <Tag color="success">正常</Tag>}</Descriptions.Item>
                            <Descriptions.Item label="昵称">{student_info.other_name}</Descriptions.Item>
                            <Descriptions.Item
                                label="性别">{student_info.sex === 1 && "男"}{student_info.sex === 2 && "女"}</Descriptions.Item>
                            <Descriptions.Item label="出生年月">{student_info.birthday}</Descriptions.Item>
                            <Descriptions.Item label="专属教练"><StaffName
                                id={student_info.teacher}/></Descriptions.Item>
                            <Descriptions.Item label="课程顾问"><StaffName
                                id={student_info.consultant}/></Descriptions.Item>
                            <Descriptions.Item label="训练类型"><TrainTypeName
                                value={student_info.train_type}/></Descriptions.Item>
                            <Descriptions.Item label="标记1">{student_info.tag1}</Descriptions.Item>
                            <Descriptions.Item label="标记2">{student_info.tag2}</Descriptions.Item>
                            <Descriptions.Item label="级别"><LevelName value={student_info.level}/></Descriptions.Item>
                            <Descriptions.Item label="备注" span={3}>{student_info.remark}</Descriptions.Item>
                        </Descriptions>
                    </Box>
                    <Box title="教学进度" style={{marginTop: 10}}>
                        <MemberLevels id={id}/>
                    </Box>
                    <Box title={"VIP会籍"} style={{marginTop: 10}}>
                        {student_info && student_info.membership_id > 0
                            ? <MembershipInfo id={student_info.membership_id} key={hiskey}/>
                            : <Empty/>
                        }
                    </Box>

                    <Row gutter={[12, 12]} style={{marginTop: 10}}>
                        <Col span={12}>
                            <MemberYuyue id={id}/>
                        </Col>
                        <Col span={12}>
                            <MemberKeShi id={id}/>
                        </Col>
                    </Row>

                    {editModal &&
                        <MembersEditModal
                            visible={editModal}
                            onOk={() => {
                                setEditModal(false);
                                loadStudentInfo();
                                setHiskey(hiskey + 1);
                            }}
                            onCancel={() => {
                                setEditModal(false);
                            }}
                            id={id}
                        />
                    }

                </PageContent>
                : <Spin/>
            }
        </div>
    )
};

export default MemberDetail;