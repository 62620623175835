import axios from './rest-axios';

const get_yuyue_signing = (yuyue_id) => axios.get(`/manage/signing/yuyue/${yuyue_id}`);
const get_membership_signing = (membership_id) => axios.get(`/manage/signing/membership/${membership_id}`);
const post_yuyue_signing = (yuyue_id) => axios.post(`/manage/signing/yuyue/`,{yuyue_id});
const post_membership_signing = (membership_id) => axios.post(`/manage/signing/membership/`,{membership_id});
const post_send = (id) => axios.post(`/manage/signing/send`,{id});
const get_current = () => axios.get(`/manage/signing/current`);
const post_signing =(yuyue_id, signing)=> axios.post(`/manage/signing/yuyue/signing/`,{yuyue_id, signing});
const post_signing_membership =(membership_id, signing)=> axios.post(`/manage/signing/membership/signing/`,{membership_id, signing});
export {
    get_yuyue_signing,
    get_membership_signing,
    post_yuyue_signing,
    post_membership_signing,
    post_send,
    get_current,
    post_signing,
    post_signing_membership,
};
