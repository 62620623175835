/**
 * @name: 学生相关接口
 * @description:
 * @author: Wood
 * @date: 2019/3/26
 * @update:
 */
import axios from './rest-axios';

const getMembers = (type) => axios.get(`/manage/members/?type=${type}`);
const getMemberCards = (id) => axios.get(`/manage/members/${id}/cards`);
const getMemberYuyues = (id) => axios.get(`/manage/members/${id}/yuyues`);
const getMemberKeshis = (id) => axios.get(`/manage/members/${id}/keshis`);
const getMember2 = (id) => axios.get(`/manage/members/${id}`);
/**
 * 修改会员信息
 * @param model
 */
const putMember = (model) => axios.put(`/manage/members/${model.id}`, model);
/**
 * 新建会员
 * @param model
 */
const postMembers = (model) => axios.post(`/manage/members/`, model);
/**
 * 重置密码
 * @param student_id
 * @param password
 */
const resetPassword = (student_id, password) => axios.post(`/manage/members/${student_id}/resetpassword`, {password});
const refrushMember = (student_id) => axios.post(`/manage/members/${student_id}/refrushMember`, {});

const postXuKa = (student_id, card_id) => axios.post(`/manage/members/${student_id}/xuka`, {card_id});

const putCard = (model) => axios.put(`/manage/members/${model.user_id}/card`, model);

const getMembersV2 = (ids) => axios.post(`/manage/members/list`, {ids});

const postMemberFile = (id, remark) => axios.post(`/manage/members/${id}/file`, {remark});
const postMemberUnFile = (id, remark) => axios.post(`/manage/members/${id}/unfile`, {remark});
const putMemberMembershipId = (id, membership_id) => axios.put(`/manage/members/${id}/membership_id`, {membership_id});

const postMemberLevels = (id) => axios.post(`/manage/members/${id}/levels`);

const getMemberLevelPlan = (id) => axios.get(`/manage/members/${id}/level_plan`);


export {
    getMembers,
    getMember2,
    getMemberCards,
    getMemberYuyues,
    getMemberKeshis,
    putMember,
    postMembers,
    resetPassword,
    postXuKa,
    putCard,
    getMembersV2,
    postMemberFile,
    postMemberUnFile,
    refrushMember,
    putMemberMembershipId,
    postMemberLevels,
    getMemberLevelPlan,
};
