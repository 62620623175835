import React, {useEffect, useState} from 'react'
import {Modal, Radio, message} from 'antd';
import {getHorses} from "../../lib/api_horses";
import {getYuYueById, postYuYueShangma} from "../../lib/api_yuyue";

const HorseSelectModal = ({visible, yuyue_id, onOk, onCancel}) => {
    const [horseId, setHorseId] = useState(0);
    const [horseList, setHorseList] = useState([]);
    const [users, setUsers] = useState([]);

    // 获取所有的马匹
    const fetchData = () => {
        if (yuyue_id === 0) {
            setHorseId(0);
            setHorseList([]);
            return;
        }
        // 获得预约详情
        getYuYueById(yuyue_id)
            .then(res => {
                    if (res.yuyue_type === 1 || res.yuyue_type === 3) {
                        setUsers(res.yuyue_users.map(user=>{
                            return {
                                user_id: user.user_id,
                                nick_name: user.user.nick_name
                            }
                        }))
                    }else{
                        setUsers([{
                            user_id: 0,
                            nick_name: res.nick_name
                        }])
                    }
            });
        getHorses()
            .then(res => {
                if (res.code === 0) {
                    setHorseList(res.data);
                    setHorseId(0);
                }
            });
    }
    useEffect(fetchData, [yuyue_id]);

    // 处理提交逻辑
    const handleSubmit = () => {
        // 检查用户数据里面的马匹id是不是空的
        for (let i = 0; i < users.length; i++) {
            if (!users[i].horse_id) {
                message.error("请选择马匹");
                return;
            }
        }
        // 提交上马
        postYuYueShangma(yuyue_id, users)
            .then(res => {
                if (res.code === 0) {
                    onOk && onOk();
                } else {
                    message.error(res.message);
                }
            })
    }
    // 处理取消逻辑
    const handleCancel = () => {
        // 执行取消逻辑
        onCancel && onCancel();
    }
    // 处理单选框变化
    const onChange = (user, horse_id) => {
        user.horse_id=horse_id;
        // 获得马匹名称
        let horse = horseList.find(horse => horse.id === horse_id);
        user.horse_name = horse.name;
        setUsers([...users]);
    }
    return (
        <Modal
            title="选择马匹"
            visible={visible}
            onOk={handleSubmit}
            onCancel={handleCancel}
            destroyOnClose={true}
            width={600}
        >
            {users.map(user =><div>
                <div>
                    {user.nick_name}
                </div>
                <Radio.Group onChange={(e)=>onChange(user,e.target.value)} value={user.horse_id}>
                    {horseList.map(horse =>
                        <Radio key={horse.id} value={horse.id}>{horse.name}</Radio>
                    )}
                </Radio.Group>
            </div>)}

        </Modal>
    )
}
export default HorseSelectModal