import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {Checkbox, Row, Col} from 'antd';
import {getCoursesFee} from "../../../lib/api_courses_fee";
import DictName from '../../../components/dict/dict-name';

const CoursesTypeCheck = ({value, onChange}) => {
    const [list, setlist] = useState([]);
    const [tvalue, settvalue] = useState([]);

    useEffect(() => {
        if(value){
            settvalue(value.split(',').map(_=>parseInt(_)))
        }

        getCoursesFee()
            .then(res => {
                if (res.code === 0) {
                    setlist(res.data);

                }
            })
    }, []);

    const handleChange = (e) => {
        console.log("e", e);
        settvalue(e);
        onChange && onChange(e.join(','));
    };

    return (
        <Checkbox.Group value={tvalue} onChange={handleChange}>
            <Row>
                {list.map(item =>
                    <Col span={24} key={item.id}>
                        <Checkbox value={item.id}>
                            <DictName type_value={"course_type"} value={item.course_type}/>-
                            <DictName type_value={"coach_level"} value={item.coach_level}/>-
                            <DictName type_value={"teaching_method"} value={item.teaching_method}/>-
                            ¥{item.fee}</Checkbox>
                    </Col>)}
            </Row>
        </Checkbox.Group>
    )
};


CoursesTypeCheck.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default CoursesTypeCheck;