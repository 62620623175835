import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {DatePicker, Table, Space, Statistic, Row, Col, Card, Modal} from 'antd';
import moment from 'moment';
import {getStatDateDetail} from "../../../lib/api_stat";
import PageContent from '../../../components/page-content/page-content';

const {Column} = Table;

// 次卡消费
const CKXFModal = ({data, onCancel}) => {
    const list = data.keshilist.filter(m => m.keshi <= 0 && m.is_gift === 0);
    return (
        <Modal
            title="次卡消费"
            visible={!!data}
            footer={null}
            width={800}
            onOk={() => {

            }}
            onCancel={() => {
                onCancel && onCancel()
            }}
        >
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size={"small"}
            >

                <Column
                    title="时间"
                    dataIndex="createdAt"
                    render={(createdAt) => moment(createdAt).format("YYYY-MM-DD HH:mm")}
                />
                <Column
                    title="会员"
                    dataIndex="user_id"
                    render={(user_id) => (<Link to={`/member/members/${user_id}`}>{user_id}</Link>)}
                />
                <Column
                    title="课时"
                    dataIndex="keshi"
                />
                <Column
                    title="备注"
                    dataIndex="remark"
                />
                <Column
                    title="操作人"
                    dataIndex="create_by"
                />
                <Column
                    title="会员卡"
                    dataIndex="card_id"
                />
                <Column
                    title="预约订单"
                    dataIndex="order_id"
                />
                <Column
                    title="消费金额"
                    dataIndex="price"
                    align="right"
                    render={(price) => parseFloat(price).toFixed(2)}
                />
            </Table>
        </Modal>
    )
};
// 次卡充值
const CKXCZodal = ({data, onCancel}) => {
    const list = data.keshilist.filter(m => m.keshi > 0 && m.is_gift === 0);
    return (
        <Modal
            title="次卡充值"
            visible={!!data}
            footer={null}
            width={800}
            onOk={() => {

            }}
            onCancel={() => {
                onCancel && onCancel()
            }}
        >
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size={"small"}
            >

                <Column
                    title="时间"
                    dataIndex="createdAt"
                    render={(createdAt) => moment(createdAt).format("YYYY-MM-DD HH:mm")}
                />
                <Column
                    title="会员"
                    dataIndex="user_id"
                    render={(user_id) => (<Link to={`/member/members/${user_id}`}>{user_id}</Link>)}
                />
                <Column
                    title="课时"
                    dataIndex="keshi"
                />
                <Column
                    title="备注"
                    dataIndex="remark"
                />
                <Column
                    title="操作人"
                    dataIndex="create_by"
                />
                <Column
                    title="会员卡"
                    dataIndex="card_id"
                />
                <Column
                    title="充值金额"
                    dataIndex="price"
                    align="right"
                    render={(price) => parseFloat(price).toFixed(2)}
                />
            </Table>
        </Modal>
    )
};
// 赠送次卡消费
const CKZSXFModal = ({data, onCancel}) => {
    const list = data.keshilist.filter(m => m.keshi <= 0 && m.is_gift === 1);
    return (
        <Modal
            title="赠送次卡消费"
            visible={!!data}
            footer={null}
            width={800}
            onOk={() => {

            }}
            onCancel={() => {
                onCancel && onCancel()
            }}
        >
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size={"small"}
            >

                <Column
                    title="时间"
                    dataIndex="createdAt"
                    render={(createdAt) => moment(createdAt).format("YYYY-MM-DD HH:mm")}
                />
                <Column
                    title="会员"
                    dataIndex="user_id"
                    render={(user_id) => (<Link to={`/member/members/${user_id}`}>{user_id}</Link>)}
                />
                <Column
                    title="课时"
                    dataIndex="keshi"
                />
                <Column
                    title="备注"
                    dataIndex="remark"
                />
                <Column
                    title="操作人"
                    dataIndex="create_by"
                />
                <Column
                    title="会员卡"
                    dataIndex="card_id"
                />
                <Column
                    title="预约订单"
                    dataIndex="order_id"
                />
                <Column
                    title="消费金额"
                    dataIndex="price"
                    align="right"
                    render={(price) => parseFloat(price).toFixed(2)}
                />
            </Table>
        </Modal>
    )
};
// 赠送次卡充值
const CKZSCZModal = ({data, onCancel}) => {
    const list = data.keshilist.filter(m => m.keshi > 0 && m.is_gift === 1);
    return (
        <Modal
            title="赠送次卡充值"
            visible={!!data}
            footer={null}
            width={800}
            onOk={() => {

            }}
            onCancel={() => {
                onCancel && onCancel()
            }}
        >
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size={"small"}
            >

                <Column
                    title="时间"
                    dataIndex="createdAt"
                    render={(createdAt) => moment(createdAt).format("YYYY-MM-DD HH:mm")}
                />
                <Column
                    title="会员"
                    dataIndex="user_id"
                    render={(user_id) => (<Link to={`/member/members/${user_id}`}>{user_id}</Link>)}
                />
                <Column
                    title="课时"
                    dataIndex="keshi"
                />
                <Column
                    title="备注"
                    dataIndex="remark"
                />
                <Column
                    title="操作人"
                    dataIndex="create_by"
                />
                <Column
                    title="会员卡"
                    dataIndex="card_id"
                />
                <Column
                    title="充值金额"
                    dataIndex="price"
                    align="right"
                    render={(price) => parseFloat(price).toFixed(2)}
                />
            </Table>
        </Modal>
    )
};

// 年卡消费
const NKXFModal = ({data, onCancel}) => {
    const list = data.memberships_fee_list.filter(m => m.fee <= 0 && m.is_gift === 0);
    return (
        <Modal
            title="年卡消费"
            visible={!!data}
            footer={null}
            width={800}
            onOk={() => {

            }}
            onCancel={() => {
                onCancel && onCancel()
            }}
        >
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size={"small"}
            >

                <Column
                    title="时间"
                    dataIndex="createdAt"
                    render={(createdAt) => moment(createdAt).format("YYYY-MM-DD HH:mm")}
                />
                <Column
                    title="会员"
                    dataIndex="user_id"
                    render={(user_id) => (<Link to={`/member/members/${user_id}`}>{user_id}</Link>)}
                />

                <Column
                    title="消费金额"
                    dataIndex="fee"
                    align="right"
                    render={(fee) => parseFloat(fee).toFixed(2)}
                />
                <Column
                    title="备注"
                    dataIndex="remark"
                />
                <Column
                    title="操作人"
                    dataIndex="create_by"
                />
                <Column
                    title="会员卡"
                    dataIndex="membership_id"
                />
                <Column
                    title="预约订单"
                    dataIndex="order_id"
                />
            </Table>
        </Modal>
    )
};
// 年卡充值教练费
const NKCZJLFModal = ({data, onCancel}) => {
    const list = data.memberships_fee_list.filter(m => m.fee > 0 && m.is_gift === 0);
    return (
        <Modal
            title="年卡充值教练费"
            visible={!!data}
            footer={null}
            width={800}
            onOk={() => {

            }}
            onCancel={() => {
                onCancel && onCancel()
            }}
        >
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size={"small"}
            >

                <Column
                    title="时间"
                    dataIndex="createdAt"
                    render={(createdAt) => moment(createdAt).format("YYYY-MM-DD HH:mm")}
                />

                <Column
                    title="会员卡"
                    dataIndex="membership_id"
                    render={id => <Link to={`/member/member_ship/${id}`}>{id}</Link>}
                />

                <Column
                    title="充值金额"
                    dataIndex="fee"
                    align="right"
                    render={(fee) => parseFloat(fee).toFixed(2)}
                />
                <Column
                    title="备注"
                    dataIndex="remark"
                />
                <Column
                    title="操作人"
                    dataIndex="create_by"
                />
            </Table>
        </Modal>
    )
};
// 年卡消费赠送教练费
const NKZSJLFXFModal = ({data, onCancel}) => {
    const list = data.memberships_fee_list.filter(m => m.fee <= 0 && m.is_gift === 1);
    return (
        <Modal
            title="年卡消费赠送教练费"
            visible={!!data}
            footer={null}
            width={800}
            onOk={() => {

            }}
            onCancel={() => {
                onCancel && onCancel()
            }}
        >
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size={"small"}
            >

                <Column
                    title="时间"
                    dataIndex="createdAt"
                    render={(createdAt) => moment(createdAt).format("YYYY-MM-DD HH:mm")}
                />
                <Column
                    title="会员"
                    dataIndex="user_id"
                    render={(user_id) => (<Link to={`/member/members/${user_id}`}>{user_id}</Link>)}
                />

                <Column
                    title="消费金额"
                    dataIndex="fee"
                    align="right"
                    render={(fee) => parseFloat(fee).toFixed(2)}
                />
                <Column
                    title="备注"
                    dataIndex="remark"
                />
                <Column
                    title="操作人"
                    dataIndex="create_by"
                />
                <Column
                    title="会员卡"
                    dataIndex="membership_id"
                />
                <Column
                    title="预约订单"
                    dataIndex="order_id"
                />
            </Table>
        </Modal>
    )
};
// 年卡赠送教练费
const NKZSJLFModal = ({data, onCancel}) => {
    const list = data.memberships_fee_list.filter(m => m.fee > 0 && m.is_gift === 1);
    return (
        <Modal
            title="年卡赠送教练费"
            visible={!!data}
            footer={null}
            width={800}
            onOk={() => {

            }}
            onCancel={() => {
                onCancel && onCancel()
            }}
        >
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size={"small"}
            >

                <Column
                    title="时间"
                    dataIndex="createdAt"
                    render={(createdAt) => moment(createdAt).format("YYYY-MM-DD HH:mm")}
                />

                <Column
                    title="会员卡"
                    dataIndex="membership_id"
                    render={id => <Link to={`/member/member_ship/${id}`}>{id}</Link>}
                />

                <Column
                    title="赠送金额"
                    dataIndex="fee"
                    align="right"
                    render={(fee) => parseFloat(fee).toFixed(2)}
                />
                <Column
                    title="备注"
                    dataIndex="remark"
                />
                <Column
                    title="操作人"
                    dataIndex="create_by"
                />
            </Table>
        </Modal>
    )
};

const StatDayDetail = ({date}) => {

    const [data, setdata] = useState({});
    const [ckje, setckje] = useState(null);
    const [ckczje, setckczje] = useState(null);
    const [ckzscs, setckzscs] = useState(null);
    const [ckzsczcs, setckzsczcs] = useState(null);
    const [nkjlf, setnkjlf] = useState(null);
    const [nkczjlf, setnkczjlf] = useState(null);
    const [nkzsjlf, setnkzsjlf] = useState(null);
    const [nkzsczjlf, setnkzsczjlf] = useState(null);

    const fetchData = () => {
        (async () => {
            let res = await getStatDateDetail(date);
            if (res.code === 0) {
                setdata(res.data);
            }
        })().catch()
    };

    useEffect(fetchData, [date]);

//     CREATE TABLE `stat_date` (
//         `date` date NOT NULL COMMENT '日期',
//         `ckcs` int(11) NOT NULL DEFAULT '0' COMMENT '次卡消费次数',
//         `ckczcs` int(11) NOT NULL DEFAULT '0' COMMENT '次卡充值次数',
//         `cksycs` int(11) NOT NULL DEFAULT '0' COMMENT '剩余次卡次数',
//         `ckje` decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '次卡消费金额',
//         `ckczje` decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '次卡充值金额',
//         `cksyje` decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '剩余次卡金额',
//         `ckzscs` int(11) NOT NULL DEFAULT '0' COMMENT '次卡消费赠送次数',
//         `ckzsczcs` int(11) NOT NULL DEFAULT '0' COMMENT '次卡赠送充值次数',
//         `ckzssycs` int(11) NOT NULL DEFAULT '0' COMMENT '次卡剩余赠送次数',
//         `nkas` int(11) NOT NULL DEFAULT '0' COMMENT '年卡消费鞍时',
//         `nkjlf` decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '年卡消费教练费',
//         `nkczjlf` decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '年卡充值教练费',
//         `nksyjlf` decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '年卡剩余教练费',
//         `nknf` decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '年卡扣除年费',
//         `nkcznf` decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '年卡充值年费',
//         `nksynf` decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '年卡剩余年费',
//         `nkzsczjlf` decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '年卡赠送教练费',
//         `nkzsjlf` decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '年卡消费赠送教练费',
//         `nkzssyjlf` decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '年卡剩余赠送教练费',
//         `hjje` decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '合计金额',
//         `hjsyje` decimal(10,2) NOT NULL DEFAULT '0.00' COMMENT '合计剩余金额',
//         `orders` longtext COMMENT '订单详情',
//         `deletedAt` datetime DEFAULT NULL,
//         `createdAt` datetime DEFAULT NULL,
//         `updatedAt` datetime DEFAULT NULL,
//         PRIMARY KEY (`date`)
// ) ENGINE=InnoDB DEFAULT CHARSET=utf8 COMMENT='日统计表';

    return (
        <PageContent title={`${date}统计详情`}>
            {data && <div>
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Card title="次卡课时费">
                        <Row>
                            <Col span={8}>
                                <Statistic title="收入(元)" value={data.ckczje}
                                           formatter={(value) => (<a onClick={() => {
                                               setckczje(JSON.parse(data.orders))
                                           }}>
                                               {value}
                                           </a>)}
                                />
                            </Col>
                            <Col span={8}>
                                <Statistic title="消耗(元)" value={data.ckje}
                                           formatter={(value) => (<a onClick={() => {
                                               setckje(JSON.parse(data.orders))
                                           }}>
                                               {value}
                                           </a>)}
                                />
                            </Col>

                            <Col span={8}>
                                <Statistic title="剩余(元)" value={data.cksyje}/>
                            </Col>
                            {/*<Col span={8}>*/}
                                {/*<Statistic title="赠送次卡消耗(课时)" value={data.ckzscs}*/}
                                           {/*formatter={(value) => (<a onClick={() => {*/}
                                               {/*setckzscs(JSON.parse(data.orders))*/}
                                           {/*}}>*/}
                                               {/*{value}*/}
                                           {/*</a>)}*/}
                                {/*/>*/}
                            {/*</Col>*/}
                            {/*<Col span={8}>*/}
                                {/*<Statistic title="赠送次卡充值(课时)" value={data.ckzsczcs}*/}
                                           {/*formatter={(value) => (<a onClick={() => {*/}
                                               {/*setckzsczcs(JSON.parse(data.orders))*/}
                                           {/*}}>*/}
                                               {/*{value}*/}
                                           {/*</a>)}*/}
                                {/*/>*/}
                            {/*</Col>*/}
                            {/*<Col span={8}>*/}
                                {/*<Statistic title="赠送次卡剩余(课时)" value={data.ckzssycs}/>*/}
                            {/*</Col>*/}
                        </Row>
                    </Card>
                    <Card title="年卡年费">
                        <Row>

                            <Col span={8}>
                                <Statistic title="收入(元)" value={data.nkcznf}/>
                            </Col>
                            <Col span={8}>
                                <Statistic title="消耗(元)" value={data.nknf}/>
                            </Col>
                            <Col span={8}>
                                <Statistic title="剩余(元)" value={data.nksynf}/>
                            </Col>

                        </Row>
                    </Card>
                    <Card title="年卡教练费">
                        <Row>
                            <Col span={8}>
                                <Statistic title="收入(元)" value={data.nkczjlf}
                                           formatter={(value) => (<a onClick={() => {
                                               setnkczjlf(JSON.parse(data.orders))
                                           }}>
                                               {value}
                                           </a>)}
                                />
                            </Col>
                            <Col span={8}>
                                <Statistic title="消耗(元)" value={data.nkjlf}
                                           formatter={(value) => (<a onClick={() => {
                                               setnkjlf(JSON.parse(data.orders))
                                           }}>
                                               {value}
                                           </a>)}
                                />
                            </Col>
                            <Col span={8}>
                                <Statistic title="剩余(元)" value={data.nksyjlf}/>
                            </Col>
                        </Row>
                    </Card>
                    <Card title="合计">
                        <Row>
                            <Col span={8}>
                                <Statistic title="本期合计消费金额" value={data.hjje}/>
                            </Col>
                            <Col span={8}>
                                <Statistic title="本期剩余金额" value={data.hjsyje}/>
                            </Col>
                        </Row>
                    </Card>
                </Space>
                {ckje && <CKXFModal data={ckje} onCancel={() => setckje(null)}/>}
                {ckczje && <CKXCZodal data={ckczje} onCancel={() => setckczje(null)}/>}
                {ckzscs && <CKZSXFModal data={ckzscs} onCancel={() => setckzscs(null)}/>}
                {ckzsczcs && <CKZSCZModal data={ckzsczcs} onCancel={() => setckzsczcs(null)}/>}
                {nkjlf && <NKXFModal data={nkjlf} onCancel={() => setnkjlf(null)}/>}
                {nkczjlf && <NKCZJLFModal data={nkczjlf} onCancel={() => setnkczjlf(null)}/>}
                {nkzsjlf && <NKZSJLFXFModal data={nkzsjlf} onCancel={() => setnkzsjlf(null)}/>}
                {nkzsczjlf && <NKZSJLFModal data={nkzsczjlf} onCancel={() => setnkzsczjlf(null)}/>}

            </div>}
        </PageContent>
    )
};


export default StatDayDetail;