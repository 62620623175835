import PropTypes from 'prop-types';
import React from 'react';
import {Modal, Form, InputNumber, Input} from 'antd';
import DictRadioGroup from '../../../components/dict/dict-radio-group';
import {putCoursesFee} from "../../../lib/api_courses_fee";


const SysDictTypeAddModal = ({data, visible, onOk, onCancel}) => {
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log(values);
                values.id = data.id;
                putCoursesFee(values)
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk(res.data);
                        }
                    })
            })
    };

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 18},
        },
    };

    return (
        <Modal
            title="修改教练费设置"
            visible={visible}
            // onOk={form.submit}
            onOk={handleSubmit}
            onCancel={onCancel}
            destroyOnClose={true}
        >
            <Form {...formItemLayout}
                  form={form}
                // onSubmit={this.handleSubmit}
                  initialValues={data}
                // ref={this.formRef}
            >
                <Form.Item
                    label="显示名称"
                    name="display_name"
                    rules={[
                        {
                            required: true,
                            message: '请输入显示名称',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="课程类型"
                    name="course_type"
                    rules={[
                        {
                            required: true,
                            message: '请选择',
                        },
                    ]}
                >
                    <DictRadioGroup type_value={"course_type"}/>
                </Form.Item>
                <Form.Item
                    label="教师级别"
                    name="coach_level"
                    rules={[
                        {
                            required: true,
                            message: '请选择',
                        },
                    ]}
                >
                    <DictRadioGroup type_value={"coach_level"}/>
                </Form.Item>
                <Form.Item
                    label="授课方式"
                    name="teaching_method"
                    rules={[
                        {
                            required: true,
                            message: '请选择',
                        },
                    ]}
                >
                    <DictRadioGroup type_value={"teaching_method"}/>
                </Form.Item>
                <Form.Item
                    label="教练费"
                    name="fee"
                    rules={[
                        {
                            required: true,
                            message: '请输入',
                        },
                    ]}
                >
                    <InputNumber min={0}/>
                </Form.Item>
            </Form>
        </Modal>
    )
};

SysDictTypeAddModal.propTypes = {
    data: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};

export default SysDictTypeAddModal;
