import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Table, Typography} from 'antd';
import moment from 'moment';
import TrainTypeShortName from '../../components/config/train_type_short_name';
import {train_type} from '../../config';
import styles from './members-list.module.css';
import DictName from '../../components/dict/dict-name';
import {VIP} from '../../components/icons';
import {ManOutlined, WomanOutlined} from '@ant-design/icons';
import MemberEndSpan from './members/member_end_span';

const {Column} = Table;


class MembersList extends Component {


    componentDidMount() {
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
    };


    render() {
        let {list, is_select} = this.props;
        let filterteachernames = [...new Set(list.map(item => item.teacher_name))].filter(item => item).map(item => {
            return {text: item, value: item}
        });
        let filterconsultant_names = [...new Set(list.map(item => item.consultant_name))].filter(item => item).map(item => {
            return {text: item, value: item}
        });

        return (
            <div>
                <Table
                    size={"small"}
                    dataSource={list}
                    rowKey={"id"}
                    pagination={false}
                    rowClassName={styles.row}
                    onRow={record => {
                        return {
                            onClick: event => {
                                if (is_select) {
                                    this.props.history.push(`/`, {user_id: record.id})
                                } else {
                                    this.props.history.push(`/member/members/${record.id}`)
                                }
                            },
                        };
                    }}
                    onChange={this.handleChange}
                    scroll={{x: 1300}}
                >
                    <Column
                        title="会员编号"
                        dataIndex="member_number"
                        key="会员编号"
                        width={140}
                        sorter={(a, b) => a.member_number < b.member_number ? 1 : -1}
                        render={(member_number, record) => {
                            return member_number ? member_number.member_number : null;
                        }}
                        fixed={"left"}
                    />
                    <Column
                        title="姓名"
                        dataIndex="nick_name"
                        key="nick_name"
                        width={100}
                        sorter={(a, b) => a.login_name < b.login_name ? 1 : -1}
                        render={(nick_name, record) => (

                            <span>

                                {record.sex === 1 && <ManOutlined style={{color: "blue"}}/>}
                                {record.sex === 2 && <WomanOutlined style={{color: "red"}}/>}
                                <span style={{color: "#1890ff"}}>
                                    {nick_name}
                                </span>
                                {record.membership_id > 0 && <VIP/>}
                            </span>
                        )}
                        fixed={"left"}
                    />
                    <Column
                        title="昵称"
                        dataIndex="other_name"
                        key="other_name"
                    />

                    <Column
                        title="年龄"
                        dataIndex="birthday"
                        key="birthday"
                        width={70}
                        render={(birthday, record) => (
                            <span>
                                {birthday ? <>{moment().diff(moment(birthday), 'years') + 1}</> : ""}
                            </span>
                        )}
                        sorter={(a, b) => a.birthday < b.birthday ? 1 : -1}
                    />
                    <Column
                        title="联系电话"
                        dataIndex="mobile_number"
                        key="mobile_number"
                    />
                    {/*<Column*/}
                    {/*title="车牌"*/}
                    {/*dataIndex="license_plate"*/}
                    {/*key="license_plate"*/}
                    {/*/>*/}
                    {/*<Column*/}
                    {/*title="剩余课时"*/}
                    {/*dataIndex="keshi_pay"*/}
                    {/*key="keshi_pay"*/}
                    {/*render={(keshi_pay, recored) => (*/}
                    {/*<span>*/}
                    {/*{recored.keshi_pay}+{recored.keshi_gift}*/}
                    {/*</span>*/}
                    {/*)}*/}
                    {/*/>*/}
                    {/*<Column*/}
                    {/*title="有效期"*/}
                    {/*dataIndex="member_end"*/}
                    {/*key="member_end"*/}
                    {/*sorter={(a, b) => a.member_end < b.member_end ? 1 : -1}*/}
                    {/*width={120}*/}
                    {/*/>*/}
                    {/*<Column*/}
                    {/*title="卡种"*/}
                    {/*dataIndex="license_plate"*/}
                    {/*key="license_plate1"*/}
                    {/*/>*/}
                    <Column
                        title="教练"
                        dataIndex="teacher_name"
                        key="teacher_name"
                        width={70}
                        filters={filterteachernames}
                        // filteredValue={"" || null}
                        onFilter={(value, record) => record.teacher_name && record.teacher_name.includes(value)}

                    />
                    <Column
                        title="顾问"
                        dataIndex="consultant_name"
                        key="consultant_name"
                        width={70}
                        filters={filterconsultant_names}
                        onFilter={(value, record) => record.consultant_name && record.consultant_name.includes(value)}
                    />
                    <Column
                        title="训练类型"
                        dataIndex="train_type"
                        key="train_type"
                        width={100}
                        render={(train_type) => <DictName type_value={"train_type_s"} value={train_type}/>}

                        // render={(train_type) => <TrainTypeShortName value={train_type}/>}
                        filters={train_type.map(item => {
                            return {text: item.name, value: item.id}
                        })}
                        onFilter={(value, record) => record.train_type === value}
                    />
                    <Column
                        title="标1"
                        dataIndex="tag1"
                        key="tag1"
                        width={70}
                        filters={[{text: "*", value: "*"}, {text: "**", value: "**"}]}
                        onFilter={(value, record) => record.tag1 === value}
                    />
                    <Column
                        title="标2"
                        dataIndex="tag2"
                        key="tag2"
                        width={70}
                        filters={[{text: "^", value: "^"}, {text: "^^", value: "^^"}]}
                        onFilter={(value, record) => record.tag2 === value}
                    />
                    <Column
                        title="首次办卡"
                        dataIndex="member_begin"
                        key="首次办卡日期"
                        width={110}
                        sorter={(a, b) => {
                            if (!a.member_begin) {
                                return -1;
                            }
                            if (!b.member_begin) {
                                return 1;
                            }
                            return moment(a.member_begin).isBefore(b.member_begin) ? -1 : 1
                        }}
                        // render={(nick_name, record) => (
                        //     <span style={{color: "#1890ff"}}>{nick_name}</span>
                        // )}
                    />
                    <Column
                        title="有效期"
                        dataIndex="member_end"
                        key="有效期"
                        width={110}
                        filters={[{text: "有效", value: 0}, {text: "临期", value: 1}, {text: "过期", value: 2}]}
                        onFilter={(value, record) => {
                            let member_end2 = moment(record.member_end);
                            let now = moment();
                            let days = member_end2.diff(now, 'days');

                            switch (value) {
                                case 0:
                                    return days > 0;
                                case 1:
                                    return days >= 0 && days < 90;
                                case 2:
                                    return days < 0;
                                default:
                                    return false;
                            }
                        }}
                        render={(member_end) => <MemberEndSpan date={member_end}/>}
                    />
                    <Column
                        title="备注"
                        dataIndex="remark"
                        key="remark"
                        render={(remark) => (
                            <Typography.Text ellipsis style={{"width": 100}} title={remark}>{remark}</Typography.Text>
                        )}
                    />
                </Table>

            </div>
        )
    }
}

MembersList.propTypes = {
    list: PropTypes.array,
    is_select: PropTypes.bool,
    onChange: PropTypes.func
};

export default withRouter(MembersList);


