/**
 * @name: 时间处理
 * @description:
 * @author: Wood
 * @date: 2020/2/11
 * @update:
 */

import moment from 'moment';

const rangeIntersect =(begin_time1, end_time1, begin_time2, end_time2) => {
    return (
        moment(begin_time1).isBefore(moment(end_time2))
        &&
        moment(end_time1).isAfter(moment(begin_time2))
    )
};

/**
 * 判断一段时间范围是否在另一段时间范围内
 * @param {moment} begin_time1 - 开始时间.
 * @param {moment} end_time1 - 结束时间.
 * @param {moment} begin_time2 - 范围开始时间.
 * @param {moment} end_time2 - 范围结束时间.
 * @return {boolean} 是否在范围内.
 */
const rangeInRange = (begin_time1, end_time1, begin_time2, end_time2) => {
    return (
        moment(begin_time1).isBetween(moment(begin_time2), moment(end_time2), null, '[]')
        &&
        moment(end_time1).isBetween(moment(begin_time2), moment(end_time2), null, '[]')
    )
};

/**
 * 判断一个时间是否在某个时间段内
 * @param {String} time - 时间
 * @param {String} begin_time - 开始时间
 * @param {String} end_time - 结束时间
 * @param {String} inc - 匹配模式
 * @return {boolean} 是否在时间范围内
 */
const timeInRange = (time, begin_time, end_time, inc = "[)") => {
    return moment(time).isBetween(begin_time, end_time, null, inc);
};

/**
 * 合并时间范围
 * @param {Array} timeRange - 时间范围
 * @return {Array} 合并后的时间范围
 */
const mergeRange = (timeRange) => {
    // 先进行排序
    timeRange.sort((a, b) => {
        if (moment(a.begin_time).isBefore(b.begin_time)) {
            return -1;
        } else {
            return 1;
        }
    });
    // console.log("timeRange", timeRange);

    let newRange = [];


    for (let i in timeRange) {
        // 遍历旧的时间范围
        let tr = timeRange[i];
        let deals = newRange.filter(item =>
            timeInRange(tr.begin_time, item.begin_time, item.end_time, "[]")
            || timeInRange(tr.end_time, item.begin_time, item.end_time, "[]")
        );
        // console.log("deals", deals);
        if (deals.length === 0) {
            // 没有重叠时间，直接插入
            newRange.push({
                begin_time: tr.begin_time,
                end_time: tr.end_time,
            });
            continue;
        }
        // 对重叠时间进行处理，只要取出最小时间点和最大时间点
        let begin_time = tr.begin_time;
        let end_time = tr.end_time;
        // 存在重叠时间，先移除原先数据
        deals.forEach(deal => {
            let index = newRange.findIndex(item => item === deal);
            // console.log(index);
            newRange.splice(index, 1);
            if (moment(deal.begin_time).isBefore(begin_time)) {
                begin_time = deal.begin_time;
            }
            if (moment(deal.end_time).isAfter(end_time)) {
                end_time = deal.end_time;
            }
        });

        newRange.push({
            begin_time,
            end_time
        });


        // console.log(newRange);

    }
    return newRange;
};

/**
 * 单个时间范围相减
 * @param {Object} timeRange1 - 被减的时间范围
 * @param {Object} timeRange2 - 用于减的时间范围
 * @return {Array} - 新的范围，可能有0-2个
 */
const subtractRange = (timeRange1, timeRange2) => {
    if (moment(timeRange2.begin_time).isSameOrBefore(timeRange1.begin_time)
        && moment(timeRange2.end_time).isSameOrAfter(timeRange1.end_time)
    ) {
        return [];
    } else if (moment(timeRange2.begin_time).isSameOrBefore(timeRange1.begin_time)
        && moment(timeRange2.end_time).isBetween(timeRange1.begin_time,timeRange1.end_time,null, "()")
    ) {
        return [{begin_time: timeRange2.end_time, end_time: timeRange1.end_time}]
    } else if (moment(timeRange2.begin_time).isBetween(timeRange1.begin_time,timeRange1.end_time, null, "()")
        && moment(timeRange2.end_time).isSameOrAfter(timeRange1.end_time)) {
        return [{begin_time: timeRange1.begin_time, end_time: timeRange2.begin_time}]
    } else if (moment(timeRange2.begin_time).isAfter(timeRange1.begin_time)
        && moment(timeRange2.end_time).isBefore(timeRange1.end_time)) {
        return [
            {begin_time: timeRange1.begin_time, end_time: timeRange2.begin_time},
            {begin_time: timeRange2.end_time, end_time: timeRange1.end_time},
        ]
    } else {
        return [{begin_time: timeRange1.begin_time, end_time: timeRange1.end_time}]
    }
};

/**
 * 时间范围相减
 * @param {Array} timeRange1 - 被减的时间范围列表
 * @param {Array} timeRange2 - 用于减的时间范围列表
 * @return {Array}
 */
const subtractRanges = (timeRange1, timeRange2) => {
    let newRange = [];
    // 预先合并一下
    timeRange1 = mergeRange(timeRange1); // 新时间范围
    timeRange2 = JSON.parse(JSON.stringify(timeRange2));
    timeRange2.forEach(item=> item.end_time = moment(item.end_time).add(10, "m").format("YYYY-MM-DD HH:mm:ss"));
    timeRange2 = mergeRange(timeRange2);

    console.log(timeRange1, timeRange2)
    // for(let i in newRange){
    //     let nr = newRange[i];
    //
    // }
    console.log(timeRange1, timeRange2);

    for (let i in timeRange2) {
        let tr2 = timeRange2[i];
        // 每一个范围都剪掉他，然后组合成新的范围
        newRange=[];
        for (let j in timeRange1) {
            let tr1 = timeRange1[j];
            newRange.push(...subtractRange(tr1, tr2))
        }
        timeRange1 = [...newRange];
    }

    console.log(timeRange1, mergeRange(timeRange1));

    return mergeRange(timeRange1);
};
export {
    rangeInRange,
    mergeRange,
    subtractRanges,
    timeInRange,
    rangeIntersect
}