import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Card, Button, Space, Divider, Popconfirm, Table} from 'antd';
import PageContent from '../../../components/page-content/page-content';
// import MembershipAddModal from './membership_add_modal';
import {PlusOutlined} from '@ant-design/icons';
import {getMembershipGuoQi} from "../../../lib/api_membership";
import moment from 'moment';
import Box from '../../../components/box';
import MemberEndSpan from '../members/member_end_span';
import UsersCardsStatus from '../users_cards/users_cards_status';
import UsersCardsShiXiaoModal from '../nianka/membership_date_shixiao_modal';

const Membership = ({match}) => {
    const [list, setList] = useState([]);
    const [shixiao, setshixiao] = useState(null);

    const loadData = () => {
        getMembershipGuoQi()
            .then(res => {
                if (res.code === 0) {
                    setList(res.data);
                }
            })
    };
    useEffect(loadData, []);

    return (
        <PageContent>
            <Box bordered={false}>
                <Table
                    // title="课程设置"
                    size="small"
                    pagination={{
                        position: ["topRight", "bottomRight"],
                        defaultPageSize: 20
                    }}
                    dataSource={list}
                    rowKey={"id"}
                    columns={[{
                        title: '会籍名称',
                        dataIndex: 'membership.name',
                        key: "会籍名称",
                        render: (name, record) => {
                            return (
                                <Link to={`/member/member_ship/${record.membership.id}`}>{record.membership.name}</Link>
                            )
                        }
                    }, {
                        title: '开始日期',
                        dataIndex: 'begin_time',
                        key: "开始日期",
                        render: (begin_time) => moment(begin_time).format("YYYY-MM-DD")
                    }, {
                        title: '结束日期',
                        dataIndex: 'end_time',
                        key: "结束日期",
                        // render: (end_time)=> moment(end_time).format("YYYY-MM-DD")
                        render: (end_time) => <MemberEndSpan date={end_time}/>

                    }, {
                        title: '成员',
                        key: "成员",
                        render: (record)=> {
                            return record.membership.users.map(_=><Link to={`/member/members/${_.id}`} style={{marginRight:10}}>{_.nick_name}</Link>)
                        }
                    }, {
                        title: '剩余天数',
                        dataIndex: 'limit_days_remaining',
                        key: "剩余天数",
                        align: "right"
                    }, {
                        title: '实际支付年费',
                        dataIndex: 'payments',
                        key: "实际支付年费",
                        align: "right"
                    }, {
                        title: '剩余年费',
                        dataIndex: 'payments_remaining',
                        key: "剩余年费",
                        align: "right"
                    }, {
                        title: '状态',
                        dataIndex: 'status',
                        key: "状态",
                        filters: [{text: "正常", value: 0}, {text: "失效", value: 1}],
                        onFilter: (value, record) => record.status === value,
                        render: (status => <UsersCardsStatus status={status}/>)
                    }, {
                        title: '操作',
                        key: "操作",
                        fixed: 'right',
                        render: (record) => {
                            return <Button type="danger" ghost onClick={() => setshixiao(record)}
                                           disabled={record.status === 1}>失效</Button>;
                        }
                    }
                    ]}/>

            </Box>
            {/*<MembershipAddModal*/}
            {/*visible={visible}*/}
            {/*onOk={() => {*/}
            {/*setVisible(false);*/}
            {/*loadData()*/}
            {/*}}*/}
            {/*onCancel={() => {*/}
            {/*setVisible(false)*/}
            {/*}}*/}
            {/*{...match.params}*/}
            {/*/>*/}
            {shixiao &&
            <UsersCardsShiXiaoModal
                data={shixiao}
                onCancel={() => {
                    setshixiao(null);
                }}
                onOk={() => {
                    setshixiao(null);
                    loadData()
                }}
            />
            }
        </PageContent>
    )
};


export default Membership;


