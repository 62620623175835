import React, {useEffect, useState} from 'react';
import {Form, Input} from 'antd';


const Editor = ({value, onChange}) => {
    const [data, setData] = useState({
        attrs: []
    });

    useEffect(() => {
        console.log(value);
        // 处理value
        if (value) {
            setData(JSON.parse(value))
        } else {
            setData({
                attrs: []
            })
        }
    }, [value]);

    const setValue = (name, value) => {
        console.log(name, value);
        let item = data.attrs.find(_ => _.name === name);
        if (item) {
            item.text.value = value;
            onChange && onChange(JSON.stringify(data));
        }
    };

    const renderItem = (item) => {
        if (item.type === 0) {
            return (
                <Form.Item
                    label={item.name}
                    // name={item.name}
                    labelCol={{
                        span: 6
                    }}
                >
                    <Input value={item.text.value} onChange={e => setValue(item.name, e.target.value)}/>
                </Form.Item>
            )
        } else if (item.type === 0) {
            return (
                <Form.Item
                    label={item.name}
                >
                    <Form.Item
                        label={item.name}
                        name={item.name}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={item.name}
                        name={item.name}
                    >
                        <Input/>
                    </Form.Item>
                </Form.Item>
            )
        } else {
            return null;
        }
    };

    return (<div>
        {data.attrs.map(item => renderItem(item))}
    </div>)
};


export default Editor;