import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';

let getDisplayName = component => {
    return component.displayName || component.name || 'Component'
};
const mapStateToProps = state => ({
    account: state.account
});

export default ChildComponent =>
    connect(
        mapStateToProps,
    )(class HocInheritance extends ChildComponent {
        static displayName = `HocInheritance(${getDisplayName(ChildComponent)})`

        hasRole = (id) => {
            const {account} = this.props;
            // if(!account || !account.roles) return false;
            // let roles = JSON.parse(account.roles);
            let roles = account.roles;
            if(!roles) return false;
            // console.log(typeof id );
            if(typeof id === "number"){
                return roles.includes(id);
            }else{
                return roles.some(item=> id.includes(item));
            }
            // console.log(this.props.account)
        }
    })

// class AuthComponent extends Component {
//     hasRole = (id)=>{
//         console.log(this.props.account)
//     }
// }
//
//
// const mapStateToProps = state => ({
//     account: state.account
// });
//
// export default connect(
//     mapStateToProps,
// )(AuthComponent);


// export let wrapAuth = ComposedComponent => {
//     class WrapComponent extends Component {
//         // 构造
//         constructor(props) {
//             super(props);
//         }
//
//         static propTypes = {
//             role: PropTypes.number.isRequired,
//         };
//
//         render() {
//             if (tool.getAuth(this.props.auth)) {
//                 return <ComposedComponent  {...this.props} />;
//             } else {
//                 return null;
//             }
//         }
//     }
// }