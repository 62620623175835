import PropTypes from 'prop-types';
import classnames from 'classnames'
import React from 'react';
import styles from './box.module.css';

const Box = ({children, className, bodyClassName, bodyStyle, title, extra, ...props}) => (
    <div className={classnames(styles.wrapper, className)} {...props}>
        <div className={styles.head}>
            <div className={styles.headwrapper}>
                {title ? <div className={styles.title}>{title}</div> : null}
                {extra ? <div className={styles.extra}>{extra}</div> : null}
            </div>
        </div>
        <div className={classnames(styles.body, bodyClassName)} style={bodyStyle}>
            {children}
        </div>
    </div>
);
Box.propTypes = {
    className: PropTypes.string,
    bodyClassName: PropTypes.string,
    bodyStyle: PropTypes.object,
};

export default Box;