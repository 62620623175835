import React, {useEffect, useState, useRef} from 'react';
import {get_yuyue_signing,post_signing} from "../../lib/api_signing";
import {Button, message} from 'antd';
import SignatureCanvas from 'react-signature-canvas'
import Markdown from 'markdown-to-jsx'

const Signing = ({id}) => {
    const sigCanvas = useRef({});
    const [data, setData] = useState(null);

    // 获取马房数据
    const fetchData = () => {
        get_yuyue_signing(id).then(res => {
            if (res.code === 0) {
                setData(res.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(fetchData, []);

    const clearCanvas = () => {
        sigCanvas.current.clear();
    };

    const getCanvasImage = () => {
        const base64Img = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        console.log(base64Img); // This is the base64 string representation of the signature

        post_signing(id, base64Img).then(res => {
            if (res.code === 0) {
                message.success('签字成功');
                fetchData();
            }
        }).catch(err => {
            console.log(err);
        })
    };
    let str = `# JEC郡域马术·骑乘须知

欢迎您来到郡安里·JEC郡域马术中心（以下简称“JEC郡域马术”）！全体教练和工作人员将努力为您营造一个愉悦、轻松、专业的马术骑乘和休闲体验环境。在您签署本骑乘须知前，请您详细阅读以下条款。

1. 骑乘者的年龄须介于**<u>3周岁</u>**至**<u>60周岁</u>**之间，体重不超过**<u>85公斤</u>**。

2. 鉴于马术是一项具有危险性的运动，JEC郡域马术已通过保险公司办理了公众责任险，若您在骑乘时出现意外事故，将由保险公司按规定进行赔付，具体赔付标准为：
   每次事故赔偿限额 ：人民币200万元；
   其中：每人每次事故赔偿限额人民币50万元；
               每人每次医疗费用赔偿限额人民币5万元。
   在上述保险理赔之外，我公司不再承担赔偿责任。为此，我方提示您：为防止意外损失，可在上述保险之外，自行为本人及所带来的客户、朋友另行投保商业保险。

3. 因违规或不听教练指导，造成马匹受伤、护具损坏、财产损失或他人伤害，您应当承担赔偿责任。

4. 如果出现以下情形，骑乘者在JEC郡域马术骑乘马匹所造成的一切后果，均由骑乘者本人承担：

   1)骑乘者未如实告知本人的年龄、体重的；

   2)骑乘者本人不适宜骑马，患有严重疾病（如精神病、癫痫、心脏病、高血压等），未事先向JEC郡域马术报备真实个人情况的；

   3)骑乘者不遵从JEC郡域马术教练员的要求佩戴骑马防护用具（头盔、护甲、护腿等）；

   4)骑乘者未经JEC郡域马术教练员许可私自骑乘；

   5)骑乘者不听从教练员指导，执意要求教练员放开缰绳自行骑乘；

   6)骑乘者酒后或精神恍惚、神志不清，经教练员劝告或制止后仍强行骑乘；

   7)骑乘者未经JEC郡域马术教练员的许可，骑乘马匹超出JEC郡域马术营业范围或规定路线，经教练员制止后仍未回归营业范围或规定路线内；

   8)由于骑乘者本人不听从JEC郡域马术教练员或工作人员要求所造成的其他人身安全事故。

   9)马场内**<u>禁止使用闪光灯拍摄；禁止大声喧哗、追逐打闹，禁止做出惊吓马匹的行为动作；</u>**未经教练员允许**<u>禁止私自进入训练场地</u>**。

5. 在前台完成付款手续后，因骑乘者个人原因不能顺利骑乘的（包括恐惧、心理障碍、行程有变等），JEC郡域马术收取**<u>服务费60元</u>**。

   本人已经认真阅读本须知，充分理解且同意本须知所列各项条款，并无其他异议。`;

    if (!data)
        return <div>loading...</div>;
    return (
        <div style={{backgroundColor: "#ffffff", padding: "20px"}}>
            <div>
                <Markdown options={{ forceBlock: true }}>{str}</Markdown>
            </div>
            {data.status===0?(
                <div>骑乘者（或监护人）签字
                    <div style={{border: "1px solid #ccc", backgroundColor: "#ffffff"}}>
                        <SignatureCanvas penColor='black'
                                         canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                                         ref={sigCanvas}
                        />
                    </div>
                    <div>
                        <Button onClick={clearCanvas}>重写</Button>
                    </div>
                    <div style={{marginTop:30}}>
                        <Button onClick={getCanvasImage} type="primary" block size="large">提交</Button>
                    </div>
                </div>
            ):(
                <div><img src={data.qianming}/></div>
            )}
        </div>
    );
}

export default Signing;