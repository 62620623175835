import axios from './rest-axios';

const postfromqywx = (begin, end) => axios.post(`/manage/cw_zcdj/getfromqywx`, {begin, end});
const getAll = (data) => axios.get(`/manage/cw_zcdj`, data);
const getwfl = (month) => axios.get(`/manage/cw_zcdj/wfl`, {month});
const getyfl = (month, md) => axios.get(`/manage/cw_zcdj/yfl`, {month, md});

const postadd = (ids, coa_id, md) => axios.post(`/manage/cw_zcdj/add`, {ids, coa_id, md});
const postremove = (ids) => axios.post(`/manage/cw_zcdj/remove`, {ids});
const getcwzcdjstat = (month, coa_id) => axios.get(`/manage/cw_zcdj/stat`, {month, coa_id});
const getcwzcdjstatyue = (year, coa_id) => axios.get(`/manage/cw_zcdj/statyue`, {year, coa_id});
const postmuti = (list) => axios.post(`/manage/cw_zcdj/postmuti`, {list});
const updatefkrq = (ids, fkrq) => axios.post(`/manage/cw_zcdj/updatefkrq`, {ids, fkrq});

export {
    postfromqywx,
    getwfl,
    getyfl,
    postadd,
    postremove,
    getcwzcdjstat,
    getcwzcdjstatyue,
    postmuti,
    getAll,
    updatefkrq,
};
