import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {DatePicker, Table, Space, Card,Select} from 'antd';
import moment from 'moment';
import {getStatShouRu} from "../../../lib/api_stat";
import PageContent from '../../../components/page-content/page-content';

const {Column} = Table;
const {Option} = Select;

const StatDay = () => {

    const [list, setlist] = useState([]);
    const [year, setyear] = useState(moment().year());
    const [l1, setl1] = useState("总收入");
    const [l2, setl2] = useState("全部");

    const fetchData = () => {
        (async () => {
            let res = await getStatShouRu(year);
            if (res.code === 0) {
                setlist(res.data);
            }
        })().catch()
    };

    useEffect(fetchData, [year]);

    const render总收入 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="月份"
                    width={130}
                    render={(record) => `${record.year}-${record.month}`}
                />
                <Column
                    title="当月收入"
                    align="right"
                    dataIndex="hjje"
                />
                <Column
                    title="累计收入"
                    align="right"
                    dataIndex="hjjehj"
                />
                <Column
                    title="操作"
                    align="right"
                    render={(result) => (
                        <Space>
                            <Link to={`/stat/shouru/${result.year}/${result.month}`}>详情</Link>
                        </Space>
                    )}
                />
            </Table>
        )
    };

    const render次卡会籍 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="月份"
                    width={130}
                    render={(record) => `${record.year}-${record.month}`}
                />
                <Column
                    title="当月收入"
                    align="right"
                    dataIndex="ckje"
                />
                <Column
                    title="累计收入"
                    align="right"
                    dataIndex="ckhj"
                />

                <Column
                    title="操作"
                    align="right"
                    render={(result) => (
                        <Space>
                            <Link to={`/stat/shouru/${result.year}/${result.month}`}>详情</Link>
                        </Space>
                    )}
                />
            </Table>
        )
    };

    const render年卡会籍 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="月份"
                    width={130}
                    render={(record) => `${record.year}-${record.month}`}
                />
                <Column
                    title="当月收入"
                    align="right"
                    dataIndex="nknf"
                />
                <Column
                    title="累计收入"
                    align="right"
                    dataIndex="nkhj"
                />

                <Column
                    title="操作"
                    align="right"
                    render={(result) => (
                        <Space>
                            <Link to={`/stat/shouru/${result.year}/${result.month}`}>详情</Link>
                        </Space>
                    )}
                />
            </Table>
        )
    };

    return (
        <PageContent title="收入统计（财务口径）">
            <Space style={{marginBottom: 12}}>
                <DatePicker
                    allowClear={false}
                    onChange={(value) => {
                        // console.log(value.format("YYYY-MM"))
                        setyear(value.year())
                    }}
                    value={moment(year + "", "YYYY")}
                    format="YYYY"
                    picker="year"/>
                <Select style={{width: 120}} value={l1} onChange={(v) => {
                    setl1(v);
                    if (v === "总收入") {
                        setl2("全部");
                    }
                }}>
                    <Option key={"总收入"}>总收入</Option>
                </Select>
                {l1 === "总收入" &&
                <Select style={{width: 120}} value={l2} onChange={(v) => {
                    setl2(v);
                }}>
                    <Option key={"全部"}>全部</Option>
                    <Option key={"次卡会籍"}>次卡会籍</Option>
                    <Option key={"年卡会籍"}>年卡会籍</Option>
                </Select>
                }

            </Space>
            {/*{!!data ?*/}
            {/*<Card>*/}
            {/*<Chart padding={[10, 20, 80, 80]} autoFit height={300} data={data}>*/}
            {/*<LineAdvance*/}
            {/*shape="smooth"*/}
            {/*point*/}
            {/*// area*/}
            {/*position="month*jee"*/}
            {/*color="name"*/}
            {/*/>*/}

            {/*</Chart>*/}
            {/*</Card>*/}
            {/*: null*/}
            {/*}*/}

            {l1 === "总收入" && l2 === "全部" && render总收入()}
            {l1 === "总收入" && l2 === "次卡会籍" && render次卡会籍()}
            {l1 === "总收入" && l2 === "年卡会籍" && render年卡会籍()}


            {/*{ufh && <UserFileHistoryModal data={ufh} type={0} onCancel={() => setufh(null)}/>}*/}
            {/*{ufh2 && <UserFileHistoryModal data={ufh2} type={1} onCancel={() => setufh2(null)}/>}*/}

        </PageContent>
    )
};


export default StatDay;