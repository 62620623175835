import React, {useState, useEffect} from 'react';
import {getGrowup} from "../../lib/api_growup";
import {Button, Descriptions, Tree} from 'antd';
import Box from '../../components/box/index';
import styles from './teaching-detail.module.css';
import {Link} from 'react-router-dom';
import {getMemberLevelPlan, postMemberLevels} from "../../lib/api_members";

const TeachingPlanDetail = ({yuyue_id, user_id}) => {
    const [data, setdata] = useState(null);
    const [expandedKeys, setexpandedKeys] = useState([]);
    const [tree, setTree] = useState([]);

    const fetchData = () => {
        getMemberLevelPlan(user_id)
            .then(res => {
                if (res.code === 0) {
                    console.log(res.data);
                    dealTree(res.data.levels);
                    setdata(res.data);
                    let keys = [];
                    for (let i = 0; i < res.data.levels.length; i++) {
                        keys.push(res.data.levels[i].id);
                        for (let j = 0; j < res.data.levels[i].children.length; j++) {
                            keys.push(res.data.levels[i].children[j].id);
                        }
                    }
                    setexpandedKeys(keys)
                    // if (res.data) {
                    //     setdata(res.data);
                    //     console.log(JSON.parse(res.data.plan));
                    //     if (res.data.plan) {
                    //         fetchLevels();
                    //     }
                    // }

                    // if (res.data.levels) {
                    //     dealTree(res.data.levels);
                    //     let keys = [];
                    //     for (let i = 0; i < res.data.levels.length; i++) {
                    //         keys.push(res.data.levels[i].id);
                    //         for (let j = 0; j < res.data.levels[i].children.length; j++) {
                    //             keys.push(res.data.levels[i].children[j].id);
                    //         }
                    //     }
                    //     setexpandedKeys(keys)
                    // }
                }
            })
    };

    const fetchLevels = () => {
        postMemberLevels(user_id)
            .then(res => {
                if (res.code === 0) {
                    console.log(res.data);
                    dealTree(res.data);
                }
            })
    }

    useEffect(fetchData, []);

    // 处理树结构
    const dealTree = (list) => {
        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            let titles = [];
            if (item.code) titles.push(item.code);
            if (item.name) titles.push(item.name);
            // if (item.stars) {
            //     let starstr = '';
            //     for (let j = 0; j < item.stars; j++) {
            //         starstr += '★';
            //     }
            //     titles.push(starstr);
            // }
            item.title = titles.join(' ');
            dealTree(item.children);
        }
    }

    const onExpand = (expandedKeysValue) => {
        console.log('onExpand', expandedKeysValue);
        // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.
        setexpandedKeys(expandedKeysValue);
    };

    if (data) {
        return (<div>
            <Box title={"教学计划"} extra={[<span>{data.anshi}鞍时</span>]}>
                <Tree
                    onExpand={onExpand}
                    treeData={data.levels}
                    fieldNames={{
                        title: "title",
                        key: "id"
                    }}
                    expandedKeys={expandedKeys}
                />
            </Box>

            <Link to={`/member/members/${user_id}`} style={{marginTop: 12}}>
                <Button block type="primary" size="large">查看学员详情</Button>
            </Link>
        </div>)
    } else {
        return (<div>无教学计划</div>)
    }

}

export default TeachingPlanDetail;