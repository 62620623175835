import React, {Component} from 'react';
import '@ant-design/compatible/assets/index.css';
import {Modal, Input, Form, InputNumber, Radio} from 'antd';
import {putLevel} from "../../../lib/api_levels";


const LevelEditModal = ({visible, onOk, onCancel, data}) => {
    const [form] = Form.useForm();

    const handleOk = (e) => {
        e.preventDefault();

        form.validateFields()
            .then(values => {
                console.log(values);
                data.name = values.name;
                data.code = values.code;
                data.order_index = values.order_index;
                data.time_weight = values.time_weight;
                data.sld_total = values.sld_total;
                putLevel(data)
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk();
                        }
                    });
            });

    };
    const handleCancel = () => {
        onCancel && onCancel();
    };
    return (
        <Modal
            title="修改"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose={true}>
            <Form form={form} labelCol={{span: 6}} initialValues={data}>
                <Form.Item
                    label="编号"
                    name="code"
                    rules={[
                        {
                            required: true,
                            message: '请输入编号',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="名称"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: '请输入名称',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="时间权重"
                    name="time_weight"
                    rules={[
                        {
                            required: true,
                            message: '请输入时间权重',
                        },
                    ]}
                >
                    <InputNumber/>
                </Form.Item>

                <Form.Item
                    label="掌握熟练度"
                    name="sld_total"
                    rules={[
                        {
                            required: true,
                            message: '请输入掌握熟练度',
                        },
                    ]}
                >
                    <InputNumber/>
                </Form.Item>
                <Form.Item
                    label="排序"
                    name="order_index"
                    rules={[
                        {
                            required: true,
                            message: '请输入排序',
                        },
                    ]}
                >
                    <InputNumber/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default LevelEditModal;
