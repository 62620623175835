import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Modal, Input, Radio, Checkbox, DatePicker, InputNumber, Form,message} from 'antd';
import {postPaiBan} from "../../lib/api_paiban";
import moment from 'moment';
import AuthComponent from '../../components/auth_component';

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
    },
};

@AuthComponent
class ChuqinModal extends Component {

    formRef = React.createRef();

    state = {
        disabled: false
    };

    componentDidMount(){
        if (this.props.data) {
            if (moment(this.props.data.begin_time).isBefore(moment(), "d")) {
                this.setState({disabled: true})
            }else{
                this.setState({disabled: false})
            }
        }
    }

    componentWillReceiveProps(props) {
        if (props.data) {
            if (moment(props.data.begin_time).isBefore(moment(), "d")) {
                this.setState({disabled: true})
            }else{
                this.setState({disabled: false})
            }
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    handleSubmit = e => {
        e.preventDefault();
        // if(!(this.hasRole(3) || this.hasRole(4))){
        //     message.error("无权修改");
        //     return;
        // }
        this.formRef.current.validateFields()
            .then(values => {
                console.log('Received values of form: ', values);
                values.begin_time = moment(values.begin_time).format("YYYY-MM-DD HH:mm");
                values.user_id = this.props.data.user_id;
                values.end_time = moment(values.end_time).format("YYYY-MM-DD HH:mm");
                postPaiBan(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        this.handleOK();
                    })
            });
    };

    handleValuesChange = (changedValues, allValues) => {
        console.log(changedValues, allValues);
        if (moment(allValues.begin_time).isBefore(moment(), "d")) {
            this.setState({disabled: true})
        }else{
            this.setState({disabled: false})
        }
    };

    render() {
        const {data} = this.props;
        const {disabled} = this.state;

        if (!data) {
            return null;
        }
        // data = data ||{};

        // let disabled = false;
        // if (this.formRef.current) {
        //     console.log(this.formRef.current.getFieldValue("begin_time"));
        // }
        // if (data && moment(data.begin_time).isBefore(moment(), "d")) {
        //     disabled = true;
        // }

        // this.formRef.current.getFieldValue("begin_time")


        return (
            <Modal
                title="设置出勤"
                visible={!!data}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                destroyOnClose={true}
                okButtonProps={{disabled: disabled}}
                // width={800}
            >
                <Form
                    {...formItemLayout}
                    onSubmit={this.handleSubmit}
                    ref={this.formRef}
                    initialValues={{
                        begin_time: moment(data.begin_time),
                        end_time: moment(data.end_time),
                    }}
                    onValuesChange={this.handleValuesChange}
                >
                    <Form.Item
                        label="教练">
                        {data.teacher_name}
                    </Form.Item>
                    <Form.Item
                        label="开始时间"
                        name="begin_time"
                        rules={[{
                            required: true,
                            message: '请选择开始时间',
                        }]}
                    >
                        <DatePicker
                            showTime
                            placeholder="Select Time"
                            format="YYYY-MM-DD HH:mm"
                        />
                    </Form.Item>
                    <Form.Item
                        label="结束时间"
                        name="end_time"
                        rules={[{
                            required: true,
                            message: '请选择结束时间',
                        }]}
                    >
                        <DatePicker
                            showTime
                            placeholder="Select Time"
                            format="YYYY-MM-DD HH:mm"
                        />
                    </Form.Item>
                </Form>

            </Modal>
        )
    }
}

ChuqinModal.propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};

export default ChuqinModal;
