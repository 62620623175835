import React, {useState} from 'react';
import {Table, Typography, Space, Divider} from 'antd';
import moment from 'moment';
import MembershipDatesHistory from './membership_dates_history';
import MemberEndSpan from '../members/member_end_span';
import {DeleteOutlined, FileTextOutlined, FieldTimeOutlined} from '@ant-design/icons';
import UsersCardsStatus from "../users_cards/users_cards_status";

const {Column} = Table;

const MembershipDatesList = ({list, onShiXiao, onXiuMian}) => {
    const [history, setHistory] = useState(0);

    const handleShiXiao = (data) => {
        onShiXiao && onShiXiao(data);
    }
    const handleXiuMian = (data) => {
        onXiuMian && onXiuMian(data);
    }
    return (
        <div>
            <Table
                size={"small"}
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                scroll={{
                    x: 1300,
                }}
            >
                <Column
                    title="状态"
                    dataIndex="status"
                    key="状态"
                    render={(status) => <UsersCardsStatus status={status}/>}
                    width={50}
                    fixed="left"
                />
                <Column
                    title="类型"
                    dataIndex="name"
                    key="类型"
                    fixed="left"
                    width={100}
                />
                <Column
                    title="开始日期"
                    dataIndex="begin_time"
                    key="开始时间"
                    width={120}
                />
                <Column
                    title="结束日期"
                    dataIndex="end_time"
                    key="结束时间"
                    width={120}
                    render={(end_time) => <MemberEndSpan date={end_time}/>}
                />
                <Column
                    title="指导价格"
                    dataIndex="price"
                    key="指导价格"
                    width={100}
                    align={"right"}
                />
                <Column
                    title="实际支付年费"
                    dataIndex="payments"
                    key="实际支付年费"
                    width={100}
                    align={"right"}
                />
                <Column
                    title="剩余年费"
                    dataIndex="payments_remaining"
                    key="剩余年费"
                    width={100}
                    align={"right"}
                />
                <Column
                    title="每日扣除"
                    dataIndex="fee_avg"
                    key="每日扣除"
                    width={100}
                    align={"right"}
                />
                <Column
                    title="剩余天数"
                    dataIndex="limit_days_remaining"
                    key="剩余天数"
                    width={100}
                    align={"right"}
                />

                <Column
                    title="创建时间"
                    dataIndex="createdAt"
                    key="创建时间"
                    render={(createdAt) => moment(createdAt).format("YYYY-MM-DD HH:mm")}
                    width={150}
                />


                <Column
                    title="操作"
                    key="操作"
                    fixed="right"
                    align="right"
                    width={220}
                    render={(record) => {
                        return (
                            <Space split={<Divider type="vertical"/>}
                                   size={0}>
                                <Typography.Link
                                    onClick={() => setHistory(record.id)}><FileTextOutlined/> 年费记录</Typography.Link>
                                <Typography.Link onClick={() => handleXiuMian(record)}
                                                 disabled={record.status === 1}><FieldTimeOutlined/> 休眠</Typography.Link>
                                <Typography.Link type="danger" onClick={() => handleShiXiao(record)}
                                                 disabled={record.status === 1}><DeleteOutlined/> 失效</Typography.Link>
                            </Space>
                        )
                    }}
                />
            </Table>
            {history > 0 &&
                <MembershipDatesHistory
                    id={history}
                    visible={!!history}
                    onCancel={() => {
                        setHistory(0)
                    }}
                />
            }

        </div>
    )
};
export default MembershipDatesList;


