import React, {useState, useEffect} from 'react';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Table,
    Tabs,
    Descriptions,
    Modal,
} from 'antd';
import moment from 'moment';
import StaffName from '../../../components/staff_name';
import {getUserCardInfo} from "../../../lib/api_users_cards";
import CardAddPaymentsModal from '../card-add-payments-modal'
import MemberEndSpan from './member_end_span';
import UsersCardsStatus from '../users_cards/users_cards_status';

const {Column} = Table;


const CardDetailCiKaModal = ({id, onOK, onClose}) => {
    const [data, setdata] = useState(null);
    const [addpayments, setaddpayments] = useState(null);

    const fetch = () => {
        getUserCardInfo(id)
            .then((result) => {
                if (result.code === 0) {
                    setdata(result.data);
                }
            })
    };

    useEffect(fetch, []);

    const handleClose = () => {
        onClose && onClose();
    };

    const handleOK = () => {
        onOK && onOK();
    };


    return (
        <Modal
            title="卡片详情"
            visible={true}
            // onOk={this.handleSubmit}
            onCancel={handleClose}
            destroyOnClose={true}
            footer={false}
            width={800}
        >
            {data && <>
                <Descriptions bordered size={"small"}>
                    <Descriptions.Item label="名称">{data.name}</Descriptions.Item>
                    <Descriptions.Item
                        label="开始时间">{moment(data.begin_time).format("YYYY-MM-DD")}</Descriptions.Item>
                    <Descriptions.Item label="结束时间">
                        <MemberEndSpan date={data.end_time}/>
                    </Descriptions.Item>
                    <Descriptions.Item label="正课课时">{data.keshi_original}</Descriptions.Item>
                    <Descriptions.Item label="剩余正课课时">{data.keshi_remaining}</Descriptions.Item>
                    <Descriptions.Item label="赠送课时">{data.keshi_free_original}</Descriptions.Item>
                    <Descriptions.Item label="剩余赠送课时">{data.keshi_free_remaining}</Descriptions.Item>
                    <Descriptions.Item label="支付金额">{data.payments.toFixed(2)}
                        {data.payments === 0 &&
                            <Button type="primary" onClick={() => setaddpayments(data)}>设置</Button>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="单节费用">{data.payments_avg.toFixed(2)}</Descriptions.Item>
                    <Descriptions.Item label="剩余金额">{data.payments_remaining.toFixed(2)}</Descriptions.Item>
                    <Descriptions.Item label="状态">
                        <UsersCardsStatus status={data.status}/>
                    </Descriptions.Item>
                </Descriptions>
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab={`课时记录`} key="1">
                        <Table
                            dataSource={data.keshis}
                            rowKey={"id"}
                            size="small"
                            pagination={false}>
                            <Column
                                title="时间"
                                dataIndex="createdAt"
                                key="时间"
                                render={(createdAt) => moment(createdAt).format("YYYY-MM-DD HH:mm")}
                            />
                            <Column
                                title="说明"
                                dataIndex="remark"
                                key="remark"
                            />
                            <Column
                                title="课时"
                                dataIndex="keshi"
                                key="keshi"
                            />
                            <Column
                                title="费用"
                                dataIndex="price"
                                key="price"
                                align="right"
                                render={(price) => parseFloat(price).toFixed(2)}
                            />
                            <Column
                                title="赠课"
                                dataIndex="is_gift"
                                key="is_gift"
                                render={(is_gift) => is_gift === 1 ? "是" : ""}
                            />

                            <Column
                                title="处理人"
                                dataIndex="create_by"
                                key="处理人"
                                render={(create_by) => {
                                    return <StaffName id={create_by}/>
                                }}
                            />
                        </Table>
                    </Tabs.TabPane>
                </Tabs>
            </>}

            {addpayments &&
                <CardAddPaymentsModal
                    data={addpayments}
                    onClose={() => {
                        setaddpayments(null);
                    }}
                    onOK={() => {
                        setaddpayments(null);
                        handleOK()
                    }}
                />
            }

        </Modal>
    )
};


export default CardDetailCiKaModal;