import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PageContent from '../../../components/page-content/page-content';
import Box from '../../../components/box/index';
import {getMembershipInfo, postMembershipRefrush} from "../../../lib/api_membership";
import {Descriptions, Button, Row, Col, Space, message} from 'antd';
import moment from 'moment';
import MembersList from '../memberships/members-list';
import MembershipHistory from './membership_history';
import MembershipFee from './membership_fee';
import MembershipChongZhiModal from './membership-chongzhi-modal';
import MembershipZengsongModal from './membership-zengsong-modal';
import MembershipXufeiModal from './membership_xufei_modal';
import MembershipDatesList from './membership_dates_list';
import MembershipXiuMianModal from './membership_xiumian_modal';
import MembershipTuiHuiModal from './membership_tuihui_modal';
import MembershipMemberEditModal from './membership_member_edit_modal';
import MemberEndSpan from '../members/member_end_span';
import UsersCardsStatus from '../users_cards/users_cards_status';
import MembershipYanChangModal from './membership_yanchang_modal';
import MemberCards from "../members/member_cards";
import MembershipsEditModal from '../memberships/memberships_edit_modal';
import MembershipsResetModal from "../memberships/memberships_reset_modal";
import UserCardAddModal from "./user_card_add_modal";
import MembershipsDatesAddModal from "../memberships/memberships_dates_add_modal";
import {
    PlusOutlined,
    EditOutlined,
    UnlockOutlined,
    GiftOutlined,
    PayCircleOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import MemberAddModal from '../members/member_add_modal';
import {postMemberFile, postMemberUnFile} from "../../../lib/api_members";
import MemberMoveModal from '../members/member_move_modal';
import UsersCardsShiXiaoModal from "../nianka/membership_date_shixiao_modal";
import ResidenceName from "../../../components/residence_name";
import MembershipTuiJiaoLianFeiModal from './membership_tuijiaolianfei_modal';
import SigningModal from './signing_modal';


const MembershipDetail = ({id}) => {
    const [data, setData] = useState({
        users: []
    });
    const [chongzhi, setchongzhi] = useState(false);
    const [zengsong, setzengsong] = useState(false);
    const [hiskey, sethiskey] = useState(0);
    const [xufei, setxufei] = useState(false);
    const [xiumian, setxiumian] = useState(false);
    const [tuihui, settuihui] = useState(false);
    const [zhuanrang, setzhuanrang] = useState(false);
    const [yanchang, setyanchang] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [resetModal, setresetModal] = useState(false);
    const [cikaAddModal, setCikaAddModal] = useState(false);
    const [memberAddModal, setMemberAddModal] = useState(false);
    const [memberMoveModal, setMemberMoveModal] = useState(0);
    const [niankashixiao, setniankashixiao] = useState(null);
    const [tuijiaolianfei, settuijiaolianfei] = useState(false);
    const [showSigningModal, setShowSigningModal] = useState(0);


    const fetchData = () => {
        getMembershipInfo(id)
            .then(res => {
                if (res.code === 0) {
                    setData(res.data);
                }
            })
    };

    useEffect(fetchData, []);

    const handleFile = (id) => {
        postMemberFile(id, null)
            .then(res => {
                if (res.code === 0) {
                    message.success("已禁用");
                    fetchData();
                }
            })
    }

    const handleUnFile = (id) => {
        postMemberUnFile(id, null)
            .then(res => {
                if (res.code === 0) {
                    message.success("已启用");
                    fetchData();
                }
            })
    }
    const handleMove = (id) => {
        setMemberMoveModal(id);
    }

    const handleRefrush = () => {
        postMembershipRefrush(id)
            .then(res => {
                if (res.code === 0) {
                    message.success("刷新成功");
                    fetchData();
                }
            })
    }

    return (
        <PageContent>
            <div style={{textAlign: "right", marginBottom: 12}}>
                <Space>
                    <Button onClick={() => setShowSigningModal(id)}>签约</Button>
                    <Button onClick={handleRefrush} icon={<EditOutlined/>}>刷新状态</Button>
                    <Button onClick={() => setEditModal(true)} icon={<EditOutlined/>}>修改资料</Button>
                    <Button onClick={() => setresetModal(true)} icon={<UnlockOutlined/>}>重置密码</Button>
                </Space>
            </div>
            <Box title={"会籍信息"}>
                <Descriptions bordered size={"small"} labelStyle={{width: 150}}>
                    <Descriptions.Item label="会籍名称">{data.name}</Descriptions.Item>
                    <Descriptions.Item label="会籍类型">{data.huiji}</Descriptions.Item>
                    <Descriptions.Item label="联系电话">{data.mobile_number}</Descriptions.Item>
                    <Descriptions.Item label="车牌号码">{data.license_plate}</Descriptions.Item>
                    <Descriptions.Item label="会籍编号">{data.member_number}</Descriptions.Item>
                    <Descriptions.Item
                        label="开始日期">{data.begin_time ? moment(data.begin_time).format("YYYY-MM-DD") : ""}
                    </Descriptions.Item>
                    <Descriptions.Item label="成员数量">{data.member_count}</Descriptions.Item>
                    <Descriptions.Item
                        label="结束日期">
                        <MemberEndSpan date={data.end_time}/>
                    </Descriptions.Item>
                    <Descriptions.Item label="状态">
                        <UsersCardsStatus status={data.status}/>
                        {data.xiuka_status===1?"(休卡中)":""}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="剩余天数">{moment(data.end_time).diff(moment(), "d") > 0 ? moment(data.end_time).diff(moment(), "d") : 0}</Descriptions.Item>
                    <Descriptions.Item label="居住地"><ResidenceName data={data.residence}/></Descriptions.Item>
                    <Descriptions.Item label="备注">{data.remark}</Descriptions.Item>
                </Descriptions>
            </Box>
            <Box title={"教练费"} style={{marginTop: 10}}
                 extra={[
                     <Space>
                         <Button onClick={() => settuijiaolianfei(true)} icon={<DeleteOutlined/>} danger
                                 type="dashed">退还教练费</Button>
                         <Button onClick={() => setzengsong(true)} icon={<GiftOutlined/>}>赠送教练费</Button>
                         <Button type={"primary"} onClick={() => setchongzhi(true)}
                                 icon={<PayCircleOutlined/>}>充值教练费</Button>
                     </Space>
                 ]}
            >
                <Descriptions bordered size={"small"} labelStyle={{width: 150}}>
                    <Descriptions.Item label="剩余教练费">{data.fee_remaining}</Descriptions.Item>
                    <Descriptions.Item label="剩余赠送教练费">{data.fee_free_remaining}</Descriptions.Item>
                </Descriptions>
            </Box>
            <Box title={"会员"} style={{marginTop: 10}}
                 extra={[
                     <Space>
                         {data.member_count > data.users.length &&
                             <Button type="primary" onClick={() => setMemberAddModal(true)}
                                     icon={<PlusOutlined/>}>增加会员</Button>
                         }
                     </Space>
                 ]}>

                <MembersList list={data.users}
                             onFile={handleFile}
                             onUnFile={handleUnFile}
                             onMove={handleMove}
                />

            </Box>
            <Box title={"年卡"} style={{marginTop: 10}}
                 extra={[
                     <Space>
                         <Button onClick={() => setyanchang(true)}>延长有效期</Button>
                         <Button type="primary" icon={<PlusOutlined/>} onClick={() => setxufei(true)}>添加年卡</Button>
                     </Space>
                 ]}>
                <MembershipDatesList
                    list={data.memberships_dates}
                    key={hiskey}
                    onShiXiao={(data) => setniankashixiao(data)}
                    onXiuMian={(data) => setxiumian(data)}
                />

            </Box>

            <Box title={"次卡"} style={{marginTop: 10}} extra={[
                <Space>
                    <Button type="primary" icon={<PlusOutlined/>}
                            onClick={() => setCikaAddModal(true)}>添加次卡</Button>
                </Space>
            ]}>
                <MemberCards id={id} key={hiskey} onChange={() => {
                    fetchData();
                }}/>
            </Box>
            <Row gutter={12}>
                <Col span={12}>
                    <MembershipFee id={id} key={hiskey}/>
                </Col>
                <Col span={12}>
                    <MembershipHistory id={id} key={hiskey}/>
                </Col>
            </Row>

            {chongzhi && <MembershipChongZhiModal
                id={id}
                onOk={() => {
                    fetchData();
                    setchongzhi(false);
                    sethiskey(hiskey + 1);
                }}
                onClose={() => {
                    setchongzhi(false)
                }}
            />}
            {zengsong && <MembershipZengsongModal
                id={id}
                onOk={() => {
                    fetchData();
                    setzengsong(false);
                    sethiskey(hiskey + 1);
                }}
                onClose={() => {
                    setzengsong(false)
                }}
            />}
            {xufei && <MembershipsDatesAddModal
                // data={data}
                visible={xufei}
                onOk={() => {
                    fetchData();
                    setxufei(false);
                    sethiskey(hiskey + 1);
                }}
                onCancel={() => {
                    setxufei(false)
                }}
                id={id}
            />}
            {yanchang && <MembershipYanChangModal
                data={data}
                onOk={() => {
                    fetchData();
                    setyanchang(false);
                    sethiskey(hiskey + 1);
                }}
                onClose={() => {
                    setyanchang(false)
                }}
            />}
            {xiumian && <MembershipXiuMianModal
                // data={xiumian}
                id={xiumian.id}
                onOk={() => {
                    fetchData();
                    setxiumian(false);
                    sethiskey(hiskey + 1);
                }}
                onClose={() => {
                    fetchData();
                    setxiumian(false)
                    sethiskey(hiskey + 1);
                }}
            />}
            {tuihui && <MembershipTuiHuiModal
                data={data}
                onOk={() => {
                    fetchData();
                    settuihui(false);
                    sethiskey(hiskey + 1);
                }}
                onCancel={() => {
                    settuihui(false)
                }}
            />}
            {zhuanrang && <MembershipMemberEditModal
                data={data}
                onOk={() => {
                    fetchData();
                    setzhuanrang(false);
                    sethiskey(hiskey + 1);
                }}
                onCancel={() => {
                    setzhuanrang(false)
                }}
            />}

            {editModal && <MembershipsEditModal
                visible={editModal}
                id={id}
                onOk={() => {
                    fetchData();
                    setEditModal(false);
                }}
                onCancel={() => {
                    setEditModal(false);
                }}
            />}
            {resetModal &&
                <MembershipsResetModal
                    visible={resetModal}
                    onOk={() => {
                        setresetModal(false);
                    }}
                    onCancel={() => {
                        setresetModal(false);
                    }}
                    id={id}
                />
            }
            {cikaAddModal &&
                <UserCardAddModal
                    visible={cikaAddModal}
                    onOk={() => {
                        fetchData();
                        sethiskey(hiskey + 1);
                        setCikaAddModal(false);
                    }}
                    onCancel={() => {
                        setCikaAddModal(false);
                    }}
                    id={id}
                />
            }
            {memberAddModal &&
                <MemberAddModal
                    visible={memberAddModal}
                    onOk={() => {
                        fetchData();
                        setMemberAddModal(false);
                    }}
                    onCancel={() => {
                        setMemberAddModal(false);
                    }}
                    id={id}
                />
            }
            {!!memberMoveModal &&
                <MemberMoveModal
                    visible={!!memberMoveModal}
                    onOk={() => {
                        fetchData();
                        setMemberMoveModal(0);
                    }}
                    onCancel={() => {
                        setMemberMoveModal(0);
                    }}
                    id={memberMoveModal}
                />
            }
            {niankashixiao &&
                <UsersCardsShiXiaoModal
                    data={niankashixiao}
                    onCancel={() => {
                        setniankashixiao(null);
                    }}
                    onOk={() => {
                        fetchData();
                        sethiskey(hiskey + 1);
                        setniankashixiao(null);
                        // loadData()
                    }}
                />
            }

            {tuijiaolianfei &&
                <MembershipTuiJiaoLianFeiModal
                    data={data}
                    onCancel={() => {
                        settuijiaolianfei(null);
                    }}
                    onOk={() => {
                        fetchData();
                        sethiskey(hiskey + 1);
                        settuijiaolianfei(null);
                        // loadData()
                    }}
                />
            }
            {showSigningModal > 0 &&
                <SigningModal
                    visible={!!showSigningModal}
                    membership_id={showSigningModal}
                    onCancel={() => {
                        setShowSigningModal(0);
                    }}
                />
            }
        </PageContent>
    )
};


MembershipDetail.propTypes = {
    id: PropTypes.number.isRequired,
};


const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MembershipDetail));
