/**
 * @name: 数据字典类型
 * @description:
 * @author: Wood
 * @date: 2021/5/23
 * @update:
 */
import React, {useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import {Card, Button, Space, Divider} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import DataTable from '../../../components/data/data-table2';
import SysDictTypeAddModal from './sys_dict_type-add-modal';
import SysDictTypeEditModal from './sys_dict_type-edit-modal';
import {useTable} from 'table-render';
import {PlusOutlined} from '@ant-design/icons';

const SysDictType = () => {
    const [visible, setVisible] = useState(false);
    const [editdata, setEditData] = useState(null);
    // const {refresh} = useTable();


    const table = useRef();
    const config = {
        table: table,
        title: "数据字典",
        tableName: "sys_dict_type",
        pagination: false,
        size: "small",
        columns: [{
            title: '编号',
            dataIndex: 'id',
            sorter: true,
        }, {
            title: 'key',
            dataIndex: 'key',
        }, {
            title: 'value',
            dataIndex: 'value',
        }, {
            title: '描述',
            dataIndex: 'desc',
        }, {
            title: '内置',
            dataIndex: 'is_lock',
            enum: {
                0: '非内置',
                1: '内置',
            },
            filters: [{
                text: '非内置',
                value: 0,
            }, {
                text: '内置',
                value: 1,
            }]
        }, {
            title: '状态',
            dataIndex: 'status',
            enum: {
                0: '正常',
                1: '停用',
            },
            filters: [{
                text: '正常',
                value: 0,
            }, {
                text: '停用',
                value: 1,
            }]
        }, {
            title: '操作',
            fixed: 'right',
            width: 150,
            render: (record) => (<Space split={<Divider type="vertical"/>}>

                {record.is_lock === 0 &&
                <a key="1">
                    <div
                        onClick={() => {
                            setEditData(record);
                        }}
                    >
                        修改
                    </div>
                </a>
                }
                <Link to={`/settings/sys_dict_type/${record.id}`}>字典项</Link>
            </Space>)
        }],
        toolbar: [
            <Button
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => setVisible(true)}
            >添加</Button>
        ]
    };

    return (
        <PageContent>
            <Card bordered={false}>
                <DataTable {...config} />

                {/*<DataTable*/}
                    {/*tableName={"sys_dict_type"}*/}
                    {/*table={{*/}
                        {/*size: "small",*/}
                        {/*columns: [{*/}
                            {/*title: '编号',*/}
                            {/*dataIndex: 'id',*/}
                        {/*}, {*/}
                            {/*title: 'key',*/}
                            {/*dataIndex: 'key',*/}
                        {/*}, {*/}
                            {/*title: 'value',*/}
                            {/*dataIndex: 'value',*/}
                        {/*}, {*/}
                            {/*title: '描述',*/}
                            {/*dataIndex: 'desc',*/}
                        {/*}, {*/}
                            {/*title: '内置',*/}
                            {/*dataIndex: 'is_lock',*/}
                            {/*enum: {*/}
                                {/*0: '非内置',*/}
                                {/*1: '内置',*/}
                            {/*},*/}
                        {/*}, {*/}
                            {/*title: '状态',*/}
                            {/*dataIndex: 'status',*/}
                            {/*enum: {*/}
                                {/*0: '正常',*/}
                                {/*1: '停用',*/}
                            {/*}*/}
                        {/*}, {*/}
                            {/*title: '操作',*/}
                            {/*fixed: 'right',*/}
                            {/*width: 150,*/}
                            {/*render: (record) => (<Space split={<Divider type="vertical"/>}>*/}
                                {/*<a key="1">*/}
                                    {/*<div*/}
                                        {/*onClick={() => {*/}
                                            {/*setEditData(record);*/}
                                        {/*}}*/}
                                    {/*>*/}
                                        {/*修改*/}
                                    {/*</div>*/}
                                {/*</a>*/}
                                {/*<Link to={`/settings/sys_dict_type/${record.id}`}>字典项</Link>*/}
                            {/*</Space>)*/}
                        {/*}],*/}
                        {/*toolbar: [*/}
                            {/*<Button*/}
                                {/*type="primary"*/}
                                {/*icon={<PlusOutlined/>}*/}
                                {/*onClick={() => setVisible(true)}*/}
                            {/*>添加</Button>*/}
                        {/*]*/}
                    {/*}}*/}
                {/*/>*/}
            </Card>
            <SysDictTypeAddModal
                visible={visible}
                onOk={() => {
                    setVisible(false);
                    table.current.refresh()
                }}
                onCancel={() => {
                    setVisible(false)
                }}
            />
            {editdata &&
            <SysDictTypeEditModal
                visible={!!editdata}
                data={editdata}
                onOk={() => {
                    setEditData(null);
                    table.current.refresh()
                }}
                onCancel={() => {
                    setEditData(null);
                }}
            />
            }


        </PageContent>
    )
};


export default SysDictType;


