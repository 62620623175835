import React, {useState, useEffect} from 'react';
import {Modal, Form, DatePicker, Spin} from 'antd';
import {getMembershipDate, postMembershipXiumian1, postXiuMian1Edit} from "../../../lib/api_membership";
import moment from 'moment';
import {getApprovalsLast} from "../../../lib/api_approvals";


const MembershipXiuMianEditModal1 = ({id, visible, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [data, setdata] = useState(null);
    const [help, sethelp] = useState("");
    const [loading, setLoading] = useState(false);
    const [isApprovals, setIsApprovals] = useState(false);

    // const [list, setList] = useState([]);
    const fetchData = () => {
        getMembershipDate(id).then(res => {
            if (res.code === 0) {
                setdata(res.data);
                form.setFieldsValue({
                    begin_time: res.data.xiuka_begin_time ? moment(res.data.xiuka_begin_time) : null,
                    end_time: res.data.xiuka_end_time ? moment(res.data.xiuka_end_time) : null,
                })
            }
        })
    }
    useEffect(fetchData, []);

// 获取审核情况
    const fetchApprovals = () => {
        setLoading(true);
        getApprovalsLast("年卡休卡")
            .then(res => {
                if (res.code === 0) {
                    if (res.data && res.data.sp_status === 1) {
                        // 存在审核记录并且未完成状态
                        setIsApprovals(true);
                    } else {
                        setIsApprovals(false);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(fetchApprovals, []);


    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                // values.id = data.membership_id;
                // values.memberships_fee_id = data.id
                // console.log(values);
                postXiuMian1Edit(id, values.end_time.format("YYYY-MM-DD"))
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk(res.data);
                        } else if (res.code === 1001) {
                            fetchApprovals();
                        }
                    })
            })
    };
    // 表单值发生改变
    const handleValuesChange = (changedValues, allValues) => {
        // 计算休卡时长
        let {begin_time, end_time} = allValues;
        if (begin_time && end_time) {
            let days = end_time.diff(begin_time, "d");
            if (days <= 0) {
                sethelp("结束日期不能早于起始日期")
            } else {
                sethelp(`共计休卡${days}天`)
            }
        } else {
            sethelp("")
        }
    };

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 18},
        },
    };

    if (!data) return null;

    return (
        <Modal
            title="修改第一次休眠结束日期"
            open={true}
            // onOk={form.submit}
            onOk={handleSubmit}
            onCancel={onCancel}
            width={800}
            confirmLoading={loading || isApprovals}
        >
            <Spin spinning={loading || isApprovals} size={"large"} tip={"正在等待审核，审核完成前无法提交新的申请！"}>
                <Form {...formItemLayout}
                      form={form}
                      onValuesChange={handleValuesChange}
                >
                    <Form.Item
                        label="休卡开始日期"
                        name="begin_time"
                    >
                        {data.xiuka_begin_time ? moment(data.xiuka_begin_time).format("YYYY-MM-DD") : null}
                    </Form.Item>
                    <Form.Item
                        label="休卡结束日期"
                        name="end_time"
                        rules={[
                            {
                                required: true,
                                message: '请选择结束日期',
                            },
                        ]}
                        help={help}
                    >
                        <DatePicker
                            format={"YYYY-MM-DD"}
                            // disabled={!!data.xiuka_begin_time}
                            // disabledDate={disabledEndDate}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
};

export default MembershipXiuMianEditModal1;
