import PropTypes from 'prop-types';
import React, {Component, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Modal, Input, message, Form, Descriptions} from 'antd';
import {getMembershipInfo} from "../../../lib/api_membership";
import moment from 'moment';
import MemberEndSpan from "../members/member_end_span";
import UsersCardsStatus from "../users_cards/users_cards_status";
import ResidenceName from "../../../components/residence_name";

const MembershipInfo = ({id}) => {
    const [data, setdata] = useState({});

    useEffect(()=>{
        getMembershipInfo(id)
            .then(res=>{
                if(res.code === 0){
                    setdata(res.data)
                }
            })
    }, []);


    return (
        <Descriptions bordered size={"small"} labelStyle={{width: 150}}>
            <Descriptions.Item label="会籍名称"><Link to={`/member/member_ship/${id}`}>{data.name}</Link></Descriptions.Item>
            <Descriptions.Item label="会籍类型">{data.huiji}</Descriptions.Item>
            <Descriptions.Item label="联系电话">{data.mobile_number}</Descriptions.Item>
            <Descriptions.Item label="车牌号码">{data.license_plate}</Descriptions.Item>
            <Descriptions.Item label="会籍编号">{data.member_number}</Descriptions.Item>
            <Descriptions.Item
                label="开始日期">{data.begin_time ? moment(data.begin_time).format("YYYY-MM-DD") : ""}
            </Descriptions.Item>
            <Descriptions.Item label="剩余教练费">{data.fee_remaining}</Descriptions.Item>
            <Descriptions.Item label="成员数量">{data.member_count}</Descriptions.Item>
            <Descriptions.Item
                label="结束日期">
                <MemberEndSpan date={data.end_time}/>
            </Descriptions.Item>
            <Descriptions.Item label="剩余赠送教练费">{data.fee_free_remaining}</Descriptions.Item>
            <Descriptions.Item label="状态"><UsersCardsStatus status={data.status}/></Descriptions.Item>
            <Descriptions.Item label="剩余天数">{moment(data.end_time).diff(moment(), "d")}</Descriptions.Item>
            <Descriptions.Item label="居住地"><ResidenceName data={data.residence}/></Descriptions.Item>
            <Descriptions.Item label="备注">{data.remark}</Descriptions.Item>
        </Descriptions>
    )

};


MembershipInfo.propTypes = {
    id: PropTypes.number
};

export default MembershipInfo;
