import React from 'react';
import {Modal} from 'antd';
import QRCode from 'react-qr-code';

const QRModal = ({id, onCancel}) => {
    const handleCancel = () => {
        onCancel && onCancel();
    };
    return (
        <Modal
            title="马匹二维码"
            open={true}
            onCancel={handleCancel}
            destroyOnClose={true}
            footer={false}
        >
            <QRCode value={"https://jec.timelite.cn/h5/horse/" + id}/>
        </Modal>
    )
}

export default QRModal;
