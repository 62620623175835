/**
 * @name: 星期选择器
 * @description:
 * @author: Wood
 * @date: 2020/2/10
 * @update:
 */
import PropTypes from 'prop-types';
import React,{Component} from 'react';
import {Checkbox} from 'antd';

class WeekSelect extends Component{

    handleChange = (e)=>{
        console.log(e);
        let {onChange} = this.props;
        onChange && onChange(JSON.stringify(e))
    };

    render(){
        let {value} = this.props;
        let formatValue = [];
        if(value){
            formatValue = JSON.parse(value);
        }
        return (
            <Checkbox.Group value={formatValue} onChange={this.handleChange}>
                <Checkbox value={0}>星期一</Checkbox>
                <Checkbox value={1}>星期二</Checkbox>
                <Checkbox value={2}>星期三</Checkbox>
                <Checkbox value={3}>星期四</Checkbox>
                <Checkbox value={4}>星期五</Checkbox>
                <Checkbox value={5}>星期六</Checkbox>
                <Checkbox value={6}>星期日</Checkbox>
            </Checkbox.Group>
        )
    }
}

WeekSelect.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
};

export default WeekSelect;