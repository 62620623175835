import React, {useState, useEffect} from 'react';
import {TreeSelect} from 'antd';
import {getcwcoaAll} from "../../../lib/api_cw_coa";


/**
 * 添加财务科目表条目
 * @param pid
 * @returns {JSX.Element}
 * @constructor
 */
const CoaSelect = ({value, onChange, leafSelect = false}) => {
    const [treeData, setTreeData] = useState([]);

    const fetchData = () => {
        getcwcoaAll()
            .then((res) => {
                if (res.code === 0) {
                    let data = res.data.map(m => {
                        return {
                            id: m.id,
                            value: m.id,
                            title: m.name,
                            pid: m.pid,
                        }
                    })
                    setTreeData(listToTree(data));
                }
            })
    }

    useEffect(fetchData, []);

    // 递归将列表转成antd的树结构，父节点为pid，pid为0的为顶级节点
    const listToTree = (list, pid = 0) => {
        const tree = [];

        list.forEach(item => {
            if (item.pid === pid) {
                const children = listToTree(list, item.id);
                if (children.length) {
                    item.children = children;
                }
                if(leafSelect){
                    // 只允许叶子选择
                    if(children.length === 0){
                        item.selectable = true;
                    }else {
                        item.selectable = false;
                    }
                }
                tree.push(item);
            }
        });

        return tree;
    }


    if (!treeData.length) return null;

    return (
        <TreeSelect
            showSearch
            value={value}
            allowClear
            treeDefaultExpandAll
            onChange={onChange}
            treeData={treeData}
        />
    )
}

export default CoaSelect;