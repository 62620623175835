import React, {useState, useEffect} from 'react';
import {getSingle, getList, putData} from "../../../lib/api_data";
import PageContent from '../../../components/page-content/page-content';
import {Tree, Modal, message} from 'antd';

const list2tree = (data, pid = 0) => {
    let ls = [];
    let ndata = data.filter(_ => _.pid === pid);
    for (let i = 0; i < ndata.length; i++) {
        let item = ndata[i];
        ls.push({
            title: item.title,
            key: item.id,
            children: list2tree(data, item.id)
        })
    }
    return ls;
};

const Detail = ({id, visible, onOk, onCancel}) => {
    const [data, setData] = useState(null);
    const [treeData, setTreeData] = useState(null);
    const [keys, setKeys] = useState([]);

    const loadMenus = () => {
        getList("menus", {
            sorter: {
                order: "ascend",
                field: "order_index"
            }
        }).then(res => {
            if (res.code === 0) {
                setTreeData(list2tree(res.data))

            }
        })
    };

    const loadData = () => {
        getSingle("roles", {
            id: id
        }).then(res => {
            if (res.code === 0) {
                setData(res.data);
                // 处理选中的至
                if (res.data.resources) {
                    setKeys(JSON.parse(res.data.resources))
                }
                loadMenus();
                // 处理树形结构
                // if()
                // setTreeData(list2tree(res.data.resources))
            }
        })
    };

    useEffect(loadData, []);

    const handleSubmit = () => {
        putData("roles", {
            id: id,
            resources: JSON.stringify(keys)
        }).then(res=>{
            if(res.code === 0){
                message.success("修改成功");
                onOk && onOk()
            }
        })
    };

    const onCheck = (checkedKeys, info) => {
        setKeys(checkedKeys.checked)
        // console.log('onCheck', checkedKeys, info);
    };


    if (!treeData) return null;
    return (
        <Modal
            title="角色授权"
            visible={visible}
            onOk={handleSubmit}
            onCancel={onCancel}
             width={400}
            destroyOnClose={true}
        >
            <Tree
                checkable
                defaultExpandAll
                checkStrictly
                selectable={false}
                // defaultExpandedKeys={['0-0-0', '0-0-1']}
                // defaultSelectedKeys={['0-0-0', '0-0-1']}
                defaultCheckedKeys={keys}
                // onSelect={onSelect}
                onCheck={onCheck}
                treeData={treeData}
            />
        </Modal>
    );
};


export default Detail;