import React from 'react';
import {Form, Input, InputNumber, Radio, Checkbox, DatePicker} from 'antd';
import CoursesTypeCheck from './courses_type_check2';
import moment from 'moment';

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};


const CardForm = ({form, initialValues}) => {
    if(initialValues.expired_date) initialValues.expired_date = moment(initialValues.expired_date);

    return (
        <Form {...formItemLayout}
              form={form}
              initialValues={initialValues}
        >
            <Form.Item
                label="卡片名称"
                name="name"
                rules={[
                    {
                        required: true,
                        message: '请输入卡片名称',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="对外显示名称"
                name="display_name"
                rules={[
                    {
                        required: true,
                        message: '请输入对外显示名称',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="正课"
                name="keshi"
                rules={[
                    {
                        required: true,
                        message: '请输入正课课时数目',
                    },
                ]}
            >
                <InputNumber min={0} step={1} precision={0}/>
            </Form.Item>
            <Form.Item
                label="门市价"
                name="retail_price"
                rules={[
                    {
                        required: true,
                        message: '请输入门市价',
                    },
                ]}
            >
                <InputNumber min={0} precision={2}/>
            </Form.Item>
            <Form.Item
                label="课程"
                name="courses_type"
                rules={[
                    {
                        required: true,
                        message: '请选择课程',
                    },
                ]}
            >
                <CoursesTypeCheck/>
            </Form.Item>
            <Form.Item
                label="使用限制"
                name="time_type"
                rules={[
                    {
                        required: true,
                        message: '请选择使用限制',
                    },
                ]}
            >
                <Radio.Group>
                    <Radio value={0} style={{display: "block"}}>开卡后
                        <Form.Item
                            name="limit_days"
                            noStyle
                        >
                            <InputNumber min={1}/>
                        </Form.Item>
                        天内可使用</Radio>
                    <Radio value={1}>固定日期：到
                        {/*{getFieldDecorator("expired_date", {*/}
                        {/*// initialValue: data.expired_date ? moment(data.expired_date) : null*/}
                        {/*})(*/}
                        {/*<DatePicker format={"YYYY-MM-DD"}/>*/}
                        {/*)}*/}
                        <Form.Item
                            name="expired_date"
                            noStyle
                        >
                            <DatePicker format={"YYYY-MM-DD"}/>
                        </Form.Item>
                        之前可用</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="使用时间">
                <Form.Item
                    name="holiday_use"
                    valuePropName='checked'
                    noStyle
                >
                    <Checkbox>周末</Checkbox>
                </Form.Item>

                <Form.Item
                    name="weekdays_use"
                    valuePropName='checked'
                    noStyle
                >
                    <Checkbox>工作日</Checkbox>
                </Form.Item>

                <Form.Item
                    name="special_use"
                    valuePropName='checked'
                    noStyle
                >
                    <Checkbox>特殊日期</Checkbox>
                </Form.Item>

            </Form.Item>
        </Form>

    )
};


export default CardForm;
