import React, {Component, useRef, useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Form, Radio, DatePicker, TimePicker, Input, Button, message} from 'antd';
import DictRadioGroup from '../../components/dict/dict-radio-group'
import moment from 'moment';
import StaffSelect from './staff_select';
import {postYuYue, postYuYueShangma, postYuYueWancheng, postYuYueQuxiao} from "../../lib/api_yuyue";
import HorseSelect from "./horse_select";

const formItemLayout = {
    labelCol: {
        span: 3
    },
    wrapperCol: {
        span: 21
    },
};


const FormSanke = ({paiban, staff, yuyue, data, onOk}) => {
    const [form] = Form.useForm();
    const [course_type, setcourse_type] = useState(1);
    const [begin_time, setbegin_time] = useState(moment());
    const [end_time, setend_time] = useState(moment().add(15, 'm'));
    const [qita_type, setqita_type] = useState(null);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log(values);

                postYuYue({
                    ...values,
                    id: data.id,
                    yuyue_type: 4,
                    begin_time: values.begin_time.format("YYYY-MM-DD HH:mm"),
                    end_time: values.begin_time.format("YYYY-MM-DD ") + values.end_time.format("HH:mm"),
                })
                    // postMembers(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            message.success("预约成功")
                            onOk && onOk();
                        }
                        // this.handleOK();
                    })
            })
    };

    useEffect(() => {
        console.log(data);
        if (data) {
            if (data.begin_time) {
                data.begin_time = moment(data.begin_time);
                data.end_time = moment(data.begin_time).add(data.duration, "m");
                setbegin_time(data.begin_time);
                setend_time(data.end_time);
            }
            form.setFieldsValue(data)
        }

    }, []);


    const handleValuesChange = (changedValues, {course_type, duration, begin_time, end_time, qita_type}) => {
        console.log(changedValues, {course_type, duration, begin_time, end_time, qita_type});
        setcourse_type(course_type);
        setbegin_time(begin_time);
        // end_time = moment(begin_time).add(duration, 'm');
        setend_time(end_time);
        // form.setFieldsValue({
        //     end_time: end_time
        // })
        setqita_type(qita_type);
        if (qita_type !== '训练') form.setFieldsValue({horse_id: null, qita_xlnr: null});
    };

    return (
        <Form {...formItemLayout} onFinish={handleSubmit}
              form={form}
              style={{display: "flex", flexDirection: "column", height: "100%"}}
              initialValues={{
                  course_type: course_type,
                  begin_time: begin_time,
                  end_time: end_time,
                  duration: 15,
                  user_type: '一般',
              }}
              onValuesChange={handleValuesChange}
        >
            <div style={{flex: 1, overflow: "auto"}}>
                其他类型不计算课时
                <Form.Item label="日期" name={"begin_time"} rules={[
                    {
                        required: true,
                        message: '请选择日期',
                    },
                ]}>
                    <DatePicker format={"YYYY-MM-DD"}
                                minuteStep={5}
                                placeholder={"请选择日期"}
                    />
                </Form.Item>
                <Form.Item label="开始" required>
                    <Form.Item name="begin_time" noStyle rules={[
                        {
                            required: true,
                            message: '请选择开始时间',
                        },
                    ]}>
                        <TimePicker format={"HH:mm"}
                                    minuteStep={5}
                                    placeholder={"请选择时间"}
                        />
                    </Form.Item>
                    <span style={{marginLeft: 5}}>结束：</span>
                    <Form.Item name="end_time" noStyle>
                        <TimePicker format={"HH:mm"}
                                    minuteStep={5}
                                    placeholder={"请选择时间"}
                        />
                    </Form.Item>
                </Form.Item>

                <Form.Item label="教练" name="teacher" rules={[
                    {
                        required: true,
                        message: '请选择教练',
                    },
                ]}>
                    <StaffSelect
                        paiban={paiban}
                        // staffs={staff}
                        yuyue={yuyue}
                        type={course_type}
                        begin_time={begin_time}
                        end_time={end_time}
                    />
                </Form.Item>
                <Form.Item label="内容" name="qita_type" rules={[
                    {
                        required: true,
                        message: `请选择内容`,
                    },
                ]}>
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value="训练">训练</Radio.Button>
                        <Radio.Button value="开会">开会</Radio.Button>
                        <Radio.Button value="公出">公出</Radio.Button>
                        <Radio.Button value="其他">其他</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                {qita_type === "训练" &&
                    <Form.Item label="马匹" name="horse_id">
                        <HorseSelect/>
                    </Form.Item>
                }

                {qita_type === "训练" &&
                    <Form.Item label="训练内容" name="qita_xlnr">
                        <DictRadioGroup type_value={"qita_xlnr"} optionType={"button"}/>
                    </Form.Item>
                }
                <Form.Item label="备注" name="remark">
                    <Input.TextArea/>
                </Form.Item>
            </div>
            <div style={{textAlign: "center"}}>
                {(data.status === 0 || data.status === 1 || !data.id) &&
                    <Button htmlType={"submit"} type={"primary"} size={"large"} style={{width: 250}}>保存</Button>
                }

            </div>
        </Form>
    )
};


const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FormSanke));

