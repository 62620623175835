import React, {useEffect, useState} from 'react';
import {Modal, DatePicker, Form, message, Col, Input, Radio, Row, Spin} from 'antd';
import TeacherSelect from "../teacher-select";
import DictRadioGroup from "../../../components/dict/dict-radio-group";
import {postMembers} from "../../../lib/api_members";
import LevelSelect from "../level-select";
import {getApprovalsLast} from "../../../lib/api_approvals";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


const MemberAddModal = ({visible, id, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [isApprovals, setIsApprovals] = useState(false);

    // 获取审核情况
    const fetchApprovals = () => {
        setLoading(true);
        getApprovalsLast("会籍添加会员")
            .then(res => {
                if (res.code === 0) {
                    if (res.data && res.data.sp_status === 1) {
                        // 存在审核记录并且未完成状态
                        setIsApprovals(true);
                    } else {
                        setIsApprovals(false);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(fetchApprovals, []);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log(values);
                values.membership_id = id;
                postMembers(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            message.success("添加成功").then(() => {
                            })
                            onOk && onOk()
                        } else if (result.code === 1001) {
                            fetchApprovals();
                        }
                    })
            })
    };

    return (
        <Modal
            title={`添加会员`}
            visible={visible}
            onOk={handleSubmit}
            onCancel={onCancel}
            destroyOnClose={true}
            width={1000}
            confirmLoading={loading || isApprovals}
        >
            <Spin spinning={loading || isApprovals} size={"large"} tip={"正在等待审核，审核完成前无法提交新的申请！"}>
                <Form
                    {...layout}
                    form={form}
                    initialValues={{
                        sex: 1,
                        tag1: "",
                        tag2: "",
                    }}
                >
                    <Row gutter={0}>
                        <Col span={8}>
                            <Form.Item
                                label="会员姓名"
                                name="nick_name"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入姓名',
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="会员昵称"
                                name="other_name"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="会员性别"
                                name="sex"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择性别',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value={1}>男</Radio>
                                    <Radio value={2}>女</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="出生年月"
                                name="birthday"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择出生年月',
                                    },
                                ]}
                            >
                                <DatePicker/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="专属教练"
                                name="teacher"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择教练',
                                    },
                                ]}
                            >
                                <TeacherSelect isCoach={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="课程顾问"
                                name="consultant"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择课程顾问',
                                    },
                                ]}
                            >
                                <TeacherSelect isCoach={false}/>
                            </Form.Item>
                        </Col>


                        <Col span={8}>

                            <Form.Item
                                label="训练类型"
                                name="train_type"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择训练类型',
                                    },
                                ]}
                            >
                                <DictRadioGroup type_value={"train_type"}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="标记1"
                                name="tag1"
                            >
                                <DictRadioGroup type_value={"member-tag1"}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="标记2"
                                name="tag2"
                            >
                                <DictRadioGroup type_value={"member-tag2"}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="学员级别"
                                name="level"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择学员级别',
                                    },
                                ]}
                            >
                                <LevelSelect/>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Spin>
        </Modal>
    )
};

export default MemberAddModal;
