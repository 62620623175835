import axios from './rest-axios';

const getcwsrdjAll = (data) => axios.get('/manage/cw_srdj/', data);

const getcwsrdj = (id) => axios.get(`/manage/cw_srdj/${id}/`);

const createcwsrdj = (data) => axios.post('/manage/cw_srdj/', data);

const updatecwsrdj = (data) => axios.put(`/manage/cw_srdj/`, data);

const deletecwsrdj = (id) => axios.delete(`/manage/cw_srdj/${id}/`);

const getcwsrdjstat = (month, coa_id) => axios.get(`/manage/cw_srdj/stat`, {month, coa_id});
const getcwsrdjstatyue = (year, coa_id) => axios.get(`/manage/cw_srdj/statyue`, {year, coa_id});

export {
    getcwsrdjAll,
    getcwsrdj,
    createcwsrdj,
    updatecwsrdj,
    deletecwsrdj,
    getcwsrdjstat,
    getcwsrdjstatyue,
};
