import React, {} from 'react';
import {DatePicker, Form, Input, InputNumber, Modal, Select} from 'antd';
import {createcwsrdj} from "../../../lib/api_cw_srdj";
import CoaSelect from "./coa_select";
import moment from "moment";


const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};


/**
 * 添加财务收入登记
 * @param onCancel
 * @param onOk
 * @returns {JSX.Element}
 * @constructor
 */
const SrdjAddModal = ({onCancel, onOk}) => {
    const [form] = Form.useForm();


    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log(values);
                createcwsrdj({
                    ...values,
                    date: values.date.format('YYYY-MM-DD'),
                })
                    .then(res => {
                        if (res.code === 0) {
                            handleOk();
                        }
                    });
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    const handleOk = () => {
        onOk && onOk();
    }
    const handleCancel = () => {
        onCancel && onCancel();
    }

    return (
        <Modal
            title="添加财务收入登记"
            open={true}
            onCancel={handleCancel}
            onOk={handleSubmit}
        >
            <Form
                {...formItemLayout}
                form={form}
                initialValues={{
                    date: moment().startOf("day"),
                }}
            >
                <Form.Item
                    label="门店"
                    name="md"
                    rules={[
                        {
                            required: true,
                            // eslint-disable-next-line no-template-curly-in-string
                            message: '请选择 ${label}',
                        },
                    ]}
                >
                    <Select allowClear showSearch options={[
                        {label: '门店1', value: '门店1'},
                        {label: '门店2', value: '门店2'},
                    ]}/>
                </Form.Item>
                <Form.Item
                    label="日期"
                    name="date"
                    rules={[
                        {
                            required: true,
                            // eslint-disable-next-line no-template-curly-in-string
                            message: '请选择 ${label}',
                        },
                    ]}
                >
                    <DatePicker/>
                </Form.Item>
                <Form.Item
                    label="科目"
                    name="coa_id"
                    rules={[
                        {
                            required: true,
                            // eslint-disable-next-line no-template-curly-in-string
                            message: '请选择 ${label}',
                        },
                    ]}
                >
                    <CoaSelect leafSelect={true}/>
                </Form.Item>
                <Form.Item
                    label="金额"
                    name="je"
                    rules={[
                        {
                            required: true,
                            // eslint-disable-next-line no-template-curly-in-string
                            message: '请输入 ${label}',
                        },
                    ]}
                >
                    <InputNumber precision={2} style={{width:200}}/>
                </Form.Item>
                <Form.Item
                    label="备注"
                    name="bz"
                >
                    <Input.TextArea/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default SrdjAddModal;