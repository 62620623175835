
import axios from './rest-axios';

const getMessages = () => axios.get(`/manage/messages`);
const getMessageCount = () => axios.get(`/manage/messages/count`);
const putMessageRead = (id) => axios.put(`/manage/messages/${id}/read`);

export {
    getMessages,
    getMessageCount,
    putMessageRead,
};
