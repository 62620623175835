import React, {useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Button, Card, Col, Row, Space, Table, Typography} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {getStables} from "../../../lib/api_stables";
import StablesAddModal from "./stables-add-modal";
import StablesEditModal from "./stables-edit-modal";
import {useInterval} from 'ahooks';
import moment from "moment";
import {Link} from "react-router-dom";
import QRModal from "./qr_modal";
import 马房地图 from "../../../assets/images/马房地图.svg"


const {Column} = Table;

const positions = [
    {name: "A1", left: 360, top: 140, width: 70},
    {name: "A2", left: 440, top: 140, width: 80},
    {name: "A3", left: 530, top: 140, width: 70},
    {name: "A4", left: 610, top: 140, width: 80},
    {name: "C1", left: 190, top: 237, width: 56},
    {name: "C2", left: 246, top: 237, width: 56},
    {name: "C3", left: 302, top: 237, width: 56},
    {name: "C4", left: 358, top: 237, width: 56},
    {name: "C5", left: 414, top: 237, width: 56},
    {name: "C6", left: 470, top: 237, width: 56},
    {name: "C7", left: 526, top: 237, width: 56},
    {name: "C8", left: 582, top: 237, width: 56},
    {name: "C9", left: 638, top: 237, width: 56},
    {name: "C10", left: 694, top: 237, width: 56},
    {name: "C11", left: 750, top: 237, width: 56},
    {name: "C12", left: 806, top: 237, width: 56},
    {name: "B1", left: 190, top: 332, width: 84},
    {name: "B2", left: 274, top: 332, width: 84},
    {name: "B3", left: 360, top: 332, width: 84},
    {name: "B4", left: 446, top: 332, width: 84},
    {name: "B5", left: 532, top: 332, width: 84},
    {name: "B6", left: 618, top: 332, width: 84},
    {name: "B7", left: 694, top: 332, width: 84},
    {name: "B8", left: 770, top: 332, width: 84},
]
const Horses = () => {
    const [list, setlist] = useState([]);
    const [showHorsesAddModal, setShowHorsesAddModal] = useState(false);
    const [editId, setEditId] = useState(0);
    const [showQRModal, setShowQRModal] = useState(0);

    const fetchData = () => {
        getStables()
            .then((res) => {
                if (res.code === 0) {
                    setlist(res.data);
                }
            })
    }

    useEffect(fetchData, []);

    useInterval(() => {
        fetchData();
    }, 3000);

    return (
        <PageContent title={"马房管理"}>
            <Card title={"马房地图"} style={{position: "relative"}}>
                <img src={马房地图} style={{width: "1000px"}} alt={""}/>
                {positions.map((position) => {
                    let l = list.find((item) => item.name === position.name);
                    if (l) {
                        return <div style={{
                            position: "absolute",
                            left: position.left,
                            top: position.top,
                            width: position.width,
                            textAlign: "center"
                        }}>
                            <Link to={`/mafang/stables/${l.id}`}>
                                {l.horses.map((horse) => (<div>{horse.name}</div>))}
                            </Link>
                        </div>
                    } else {
                        return null;
                    }
                })}

            </Card>
            <Card title={"状态列表"} style={{marginTop: 12}}
                  extra={<Row justify="space-between">
                      <Col>

                      </Col>
                      <Col>
                          <Space>
                              <Button type="primary" icon={<PlusOutlined/>}
                                      onClick={() => setShowHorsesAddModal(true)}>添加马房</Button>
                          </Space>
                      </Col>
                  </Row>}
            >

                <Table
                    dataSource={list}
                    rowKey={"id"}
                    pagination={false}
                    size={"small"}
                >
                    <Column
                        title="马房编号"
                        dataIndex="name"
                        key="编号"
                        render={(text, record, index) => {
                            return (<Link to={`/mafang/stables/${record.id}`}>{text}</Link>);
                        }}
                    />
                    <Column
                        title="马匹"
                        dataIndex="horses"
                        key="马匹"
                        render={(horses, record, index) => {
                            return horses.map((horse) => {
                                return (
                                    <div key={horse.id}>
                                        <Link to={`/mafang/horses/${horse.id}`}>{horse.name}</Link>
                                    </div>
                                )
                            })
                        }}
                    />
                    <Column
                        title="监控"
                        key="监控"
                    />
                    <Column
                        title="二维码"
                        key="二维码"
                        render={(value, record, index) => <Typography.Link
                            onClick={() => setShowQRModal(record.id)}>点击查看</Typography.Link>}
                    />
                    {/*<Column*/}
                    {/*    title="视频监控编码"*/}
                    {/*    dataIndex="camera"*/}
                    {/*    key="视频监控编码"*/}
                    {/*/>*/}
                    <Column
                        title="是否有马"
                        dataIndex="horse_inside"
                        key="是否有马"
                        render={(horse_inside) => horse_inside === 1 ? "有" : "无"}
                    />
                    <Column
                        title="更新时间"
                        dataIndex="horse_inside_update_time"
                        key="更新时间"
                        render={(time) => moment(time).format("YYYY-MM-DD HH:mm:ss")}
                    />
                    <Column
                        title="操作"
                        key="操作"
                        render={
                            (text, record) => (
                                <Typography.Link onClick={() => {
                                    console.log(record);
                                    setEditId(record.id);
                                }}>编辑</Typography.Link>
                            )
                        }
                    />
                </Table>
            </Card>
            {showHorsesAddModal &&
                <StablesAddModal
                    onOk={() => {
                        setShowHorsesAddModal(false);
                        fetchData();
                    }}
                    onCancel={() => {
                        setShowHorsesAddModal(false);
                    }}
                />
            }

            {editId > 0 &&
                <StablesEditModal
                    id={editId}
                    onOk={() => {
                        setEditId(0);
                        fetchData();
                    }}
                    onCancel={() => {
                        setEditId(0);
                    }}
                />
            }
            {showQRModal > 0 &&
                <QRModal
                    id={showQRModal}
                    onCancel={() => {
                        setShowQRModal(0);
                    }}
                />
            }


        </PageContent>
    );
}

export default Horses;