import React, {useEffect, useState} from 'react'
import {Radio} from 'antd';
import {getHorses} from "../../lib/api_horses";

const HorseSelect = ({value, onChange}) => {
    const [horseList, setHorseList] = useState([]);

    // 获取所有的马匹
    const fetchData = () => {
        getHorses()
            .then(res => {
                if (res.code === 0) {
                    setHorseList(res.data);
                }
            });
    }
    useEffect(fetchData, []);

    const handleChange = (value) => {
        onChange && onChange(value);
    }

    return (
        <Radio.Group onChange={(e) => handleChange(e.target.value)} value={value}>
            {horseList.map(horse =>
                <Radio key={horse.id} value={horse.id}>{horse.name}</Radio>
            )}
        </Radio.Group>
    )
}
export default HorseSelect