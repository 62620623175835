import React from 'react';
import {Switch, Route} from "react-router-dom";
import CWSRDJTJ from "../duizhang/srdjtj";
import ZCDJ from "../duizhang/zcdj";
import ZCSJ from "../duizhang/zcsj";
import ZCDJTj from "../duizhang/zcdjtj";
import Zcdjtjyue from "../duizhang/zcdjtjyue";
import Srdjtjyue from "../duizhang/srdjtjyue";
import Srycjidu from "../duizhang/srycjidu";
import Srycyuedu from "../duizhang/srycyuedu";
import Zcysjidu from "../duizhang/zcysjidu";
import Zcysyuedu from "../duizhang/zcysyuedu";
import Lrb from "../duizhang/lrb";

const Router = () => (
    <Switch>
        <Route exact path="/duizhang/srdjtj"
               component={CWSRDJTJ}/>
        <Route exact path="/duizhang/zctj"
               component={ZCDJTj}/>
        <Route exact path="/duizhang/zcsj"
               component={ZCSJ}/>
        <Route exact path="/duizhang/zcdj"
               component={ZCDJ}/>
        <Route exact path="/duizhang/zctjyue"
               component={Zcdjtjyue}/>
        <Route exact path="/duizhang/srdjtjyue"
               component={Srdjtjyue}/>
        <Route exact path="/duizhang/srycjidu"
               component={Srycjidu}/>
        <Route exact path="/duizhang/sryc"
               component={Srycyuedu}/>
        <Route exact path="/duizhang/zcysjidu"
               component={Zcysjidu}/>
            <Route exact path="/duizhang/zcys"
                   component={Zcysyuedu}/>
            <Route exact path="/duizhang/lrb"
                   component={Lrb}/>

    </Switch>
);

export default Router;
