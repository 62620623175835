import React,{useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Modal,Table} from 'antd';
import moment from 'moment';
import {getStatMonthDetail} from "../../../lib/api_stat";
import {getStatDateDetail} from "../../../lib/api_stat";

const {Column} = Table;


const UserFileHistoryModal = ({data, type, onCancel}) => {
    // const list = data.user_file_history.filter(m => m.status === 0);


    const [list, setlist] = useState([]);


    useEffect(()=>{
        getStatDateDetail(data.date)
            .then(res=>{
                // console.log(res)
                if(res.code === 0){
                    let orders = JSON.parse(res.data.orders);
                    if(orders){
                        setlist(orders.user_file_history.filter(m=>m.status === type))
                    }
                }

            })
    }, []);


    return (
        <Modal
            title={type === 0 ? "新增会员记录" : "失效会员记录"}
            visible={true}
            footer={null}
            width={800}
            onOk={() => {

            }}
            onCancel={() => {
                onCancel && onCancel()
            }}
        >
            {list.length > 0 ? <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size={"small"}
            >

                <Column
                    title="时间"
                    dataIndex="createdAt"
                    render={(createdAt) => moment(createdAt).format("YYYY-MM-DD HH:mm")}
                />
                <Column
                    title="会员"
                    dataIndex="user_id"
                    render={(user_id)=><Link to={`/member/members/${user_id}`}>{user_id}</Link>}
                />
                <Column
                    title="状态"
                    dataIndex="status"
                    render={(status)=>status === 0 ? "新增":"减少"}
                />
                <Column
                    title="备注"
                    dataIndex="remark"
                />
                {/*<Column*/}
                    {/*title="操作人"*/}
                    {/*dataIndex="create_by"*/}
                {/*/>*/}
            </Table> : null}

        </Modal>
    )
};




export default  UserFileHistoryModal;