const SET_RELOAD = 'calendar/SET_RELOAD';

const initialState = false;

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_RELOAD:
            // console.log("SET_STAFF", {...action.staff});
            return !state;
        default:
            return state;
    }
};

const flashCalendar = function () {
    return {
        type: SET_RELOAD,
    }
};


export {
    reducer as default,
    initialState as initialCalendarReloadState,
    flashCalendar,
}