import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {DatePicker, Table, Space, Select,Modal} from 'antd';
import moment from 'moment';
import {getStatDate} from "../../../lib/api_stat";
import PageContent from '../../../components/page-content/page-content';
import  UserFileHistoryModal from './user_file_history_modal';

const {Column} = Table;
const {Option} = Select;


// 次卡消费
const HYXZModal = ({data, onCancel}) => {
    const list = data.user_file_history.filter(m => m.keshi <= 0 && m.is_gift === 0);
    return (
        <Modal
            title="次卡消费"
            visible={!!data}
            footer={null}
            width={800}
            onOk={() => {

            }}
            onCancel={() => {
                onCancel && onCancel()
            }}
        >
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size={"small"}
            >

                <Column
                    title="时间"
                    dataIndex="createdAt"
                    render={(createdAt) => moment(createdAt).format("YYYY-MM-DD HH:mm")}
                />
                <Column
                    title="会员"
                    dataIndex="user_id"
                    render={(user_id) => (<Link to={`/member/members/${user_id}`}>{user_id}</Link>)}
                />
                <Column
                    title="课时"
                    dataIndex="keshi"
                />
                <Column
                    title="备注"
                    dataIndex="remark"
                />
                <Column
                    title="操作人"
                    dataIndex="create_by"
                />
                <Column
                    title="会员卡"
                    dataIndex="card_id"
                />
                <Column
                    title="预约订单"
                    dataIndex="order_id"
                />
                <Column
                    title="消费金额"
                    dataIndex="price"
                    align="right"
                    render={(price) => parseFloat(price).toFixed(2)}
                />
            </Table>
        </Modal>
    )
};

const StatDay = () => {

    const [list, setlist] = useState([]);
    const [month, setmonth] = useState(moment().format("YYYY-MM"));
    const [l1, setl1] = useState("总预收款");
    const [l2, setl2] = useState("全部");
    const [l3, setl3] = useState("全部");
    const [ufh, setufh] = useState(null);
    const [ufh2, setufh2] = useState(null);

    const fetchData = () => {
        (async () => {
            let res = await getStatDate(month);
            if (res.code === 0) {
                setlist(res.data);
            }
        })().catch()
    };

    useEffect(fetchData, [month]);


    const render赠送课时 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="日期"
                    dataIndex="date"
                    width={130}
                />
                <Column
                    title="赠送"
                    align="right"
                    dataIndex="ckzsczcs"
                />
                <Column
                    title="消耗"
                    align="right"
                    dataIndex="ckzscs"
                />
                <Column
                    title="剩余"
                    align="right"
                    dataIndex="ckzssycs"
                />
            </Table>
        )
    };

    const render赠送教练费 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="日期"
                    dataIndex="date"
                    width={130}
                />
                <Column
                    title="赠送"
                    align="right"
                    dataIndex="nkzsczjlf"
                />
                <Column
                    title="消耗"
                    align="right"
                    dataIndex="nkzsjlf"
                />
                <Column
                    title="剩余"
                    align="right"
                    dataIndex="nkzssyjlf"
                />
            </Table>
        )
    };

    const render总预收款 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="日期"
                    dataIndex="date"
                    width={130}
                />
                <Column
                    title="收入"
                    align="right"
                    render={(record) => {
                        return (parseFloat(record.ckczje) + parseFloat(record.nkczjlf) + parseFloat(record.nkcznf)).toFixed(2)
                    }}
                />
                <Column
                    title="消耗"
                    align="right"
                    render={(record) => {
                        return (parseFloat(record.ckje) + parseFloat(record.nkjlf) + parseFloat(record.nknf)).toFixed(2)
                    }}
                />
                <Column
                    title="剩余"
                    align="right"
                    dataIndex="hjsyje"
                />
                <Column
                    title="操作"
                    align="right"
                    render={(result) => (
                        <Space>
                            <Link to={`/stat/date/${result.date}`}>详情</Link>
                        </Space>
                    )}
                />
            </Table>
        )
    };

    const render次卡会籍 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="日期"
                    dataIndex="date"
                    width={130}
                />
                <Column
                    title="收入"
                    align="right"
                    dataIndex="ckczje"
                />
                <Column
                    title="消耗"
                    align="right"
                    dataIndex="ckje"
                />
                <Column
                    title="剩余"
                    align="right"
                    dataIndex="cksyje"
                />
            </Table>
        )
    };

    const render年卡会籍 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="日期"
                    dataIndex="date"
                    width={130}
                />
                <Column
                    title="收入"
                    align="right"
                    render={(record) => {
                        return (parseFloat(record.nkczjlf) + parseFloat(record.nkcznf)).toFixed(2)
                    }}
                />
                <Column
                    title="消耗"
                    align="right"
                    render={(record) => {
                        return (parseFloat(record.nkjlf) + parseFloat(record.nknf)).toFixed(2)
                    }}
                />
                <Column
                    title="剩余"
                    align="right"
                    render={(record) => {
                        return (parseFloat(record.nksyjlf) + parseFloat(record.nksynf)).toFixed(2)
                    }}
                />
            </Table>
        )
    };

    const render年费 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="日期"
                    dataIndex="date"
                    width={130}
                />
                <Column
                    title="收入"
                    align="right"
                    dataIndex="nkcznf"
                />
                <Column
                    title="消耗"
                    align="right"
                    dataIndex="nknf"
                />
                <Column
                    title="剩余"
                    align="right"
                    dataIndex="nksynf"
                />
            </Table>
        )
    };

    const render教练费 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="日期"
                    dataIndex="date"
                    width={130}
                />
                <Column
                    title="收入"
                    align="right"
                    dataIndex="nkczjlf"
                />
                <Column
                    title="消耗"
                    align="right"
                    dataIndex="nkjlf"
                />
                <Column
                    title="剩余"
                    align="right"
                    dataIndex="nksyjlf"
                />
            </Table>
        )
    };

    const render会员数量 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="日期"
                    dataIndex="date"
                    width={130}
                />
                <Column
                    title="新增"
                    align="right"
                    dataIndex="hyxz"
                    render={(hyxz,record) => <a onClick={()=>{
                        setufh(record)
                    }}>{hyxz}</a>}
                />
                <Column
                    title="失效"
                    align="right"
                    dataIndex="hysx"
                    render={(hysx,record) => <a onClick={()=>{
                        setufh2(record)
                    }}>{hysx}</a>}
                />
                <Column
                    title="剩余"
                    align="right"
                    dataIndex="hysy"
                />
            </Table>
        )
    };



    return (
        <PageContent>
            <Space style={{marginBottom:12}}>
                <DatePicker
                    allowClear={false}
                    onChange={(value) => {
                        // console.log(value.format("YYYY-MM"))
                        setmonth(value.format("YYYY-MM"))
                    }}
                    value={moment(month)}
                    format="YYYY-MM"
                    picker="month"/>
                <Select style={{width: 120}} value={l1} onChange={(v) => {
                    setl1(v);
                    if (v === "总预收款") {
                        setl2("全部");
                        setl3("全部");
                    }
                }}>
                    <Option key={"总预收款"}>总预收款</Option>
                    <Option key={"赠送教练费"}>赠送教练费</Option>
                    <Option key={"赠送课时"}>赠送课时</Option>
                    <Option key={"会员数量"}>会员数量</Option>
                </Select>
                {l1 === "总预收款" &&
                <Select style={{width: 120}} value={l2} onChange={(v) => {
                    setl2(v);
                    if (v === "次卡会籍") {
                        setl3("课时费")
                    }
                    if (v === "年卡会籍") {
                        setl3("全部")
                    }
                }}>
                    <Option key={"全部"}>全部</Option>
                    <Option key={"次卡会籍"}>次卡会籍</Option>
                    <Option key={"年卡会籍"}>年卡会籍</Option>
                </Select>
                }
                {l1 === "总预收款" && l2 === "次卡会籍" &&
                <Select style={{width: 120}} value={l3} onChange={(v) => {
                    setl3(v)
                }}>
                    <Option key={"课时费"}>课时费</Option>
                </Select>
                }
                {l1 === "总预收款" && l2 === "年卡会籍" &&
                <Select defaultValue={"全部"} style={{width: 120}} value={l3} onChange={(v) => {
                    setl3(v)
                }}>
                    <Option key={"全部"}>全部</Option>
                    <Option key={"年费"}>年费</Option>
                    <Option key={"教练费"}>教练费</Option>
                </Select>
                }
            </Space>
            {l1 === "赠送课时" && render赠送课时()}
            {l1 === "赠送教练费" && render赠送教练费()}
            {l1 === "总预收款" && l2 === "全部" && render总预收款()}
            {l1 === "总预收款" && l2 === "次卡会籍" && render次卡会籍()}
            {l1 === "总预收款" && l2 === "年卡会籍" && l3 === "全部" && render年卡会籍()}
            {l1 === "总预收款" && l2 === "年卡会籍" && l3 === "年费" && render年费()}
            {l1 === "总预收款" && l2 === "年卡会籍" && l3 === "教练费" && render教练费()}
            {l1 === "会员数量" && render会员数量()}

            {ufh && <UserFileHistoryModal data={ufh} type={0} onCancel={() => setufh(null)}/>}
            {ufh2 && <UserFileHistoryModal data={ufh2} type={1} onCancel={() => setufh2(null)}/>}

        </PageContent>
    )
};


export default StatDay;