import React, {useEffect, useState} from 'react';
import {getWeather} from "../../lib/api_weather";
import Sum from './images/weather/sun.svg';
import styles from './weather.module.css';
import Cloudy from './images/weather/cloudy.svg';
import PartlyCloudy from './images/weather/partly_cloudy.svg';
import RainStorm from './images/weather/Rain_storm.svg';
import Rainy from './images/weather/rainy.svg';
import Snowy from './images/weather/snowy.svg';
import Snowy2 from './images/weather/snowy-2.svg';
import {useInterval} from 'ahooks';
import moment from 'moment';
import {gettime} from "../../lib/api_dashboard";

const Weather = () => {
    const [weather, setWeather] = useState("");
    const [weatherCode, setWeatherCode] = useState('0');
    const [dateText, setDateText] = useState('');
    const [timeText, setTimeText] = useState('');
    const [time, setTime] = useState("");

    useInterval(() => {
        setDateText(moment(time).format('YYYY-MM-DD'));
        setTimeText(moment(time).format('HH:mm:ss'));
        setTime(moment(time).add(1,"s").format('YYYY-MM-DD HH:mm:ss'))
    }, 1000);

    // 服务器对时功能
    useInterval(() => {
        gettime().then(res=>{
            if(res.code===0){
                setTime(res.data);
            }
        })
    }, 60000, {immediate: true});

    const fetchWeather = () => {
        //get请求url
        getWeather()
            .then(res => {
                if (res.code === 0) {
                    let data = JSON.parse(res.data);
                    console.log(data.results[0].now);
                    setWeather(data.results[0].now.text)
                    setWeatherCode(data.results[0].now.code)
                }
            })
    }
    useEffect(fetchWeather, []);

    return (
        <span className={styles.container}>
            <span className={styles.weather}>
                <span>
                    {['0', '1', '2', '3', '38', '99'].includes(weatherCode) && <img src={Sum} width={50} height={50}/>}
                    {['5', '6', '7', '8'].includes(weatherCode) && <img src={PartlyCloudy} width={50} height={50}/>}
                    {['4', '9'].includes(weatherCode) && <img src={Cloudy} width={50} height={50}/>}
                    {['10', '13', '14', '15', '16', '17', '18', '19'].includes(weatherCode) &&
                        <img src={Rainy} width={50} height={50}/>}
                    {['26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36'].includes(weatherCode) &&
                        <img src={Snowy} width={50} height={50}/>}
                    {['20', '21', '22', '23', '24', '25', '37'].includes(weatherCode) &&
                        <img src={Snowy2} width={50} height={50}/>}
                    {['11', '12'].includes(weatherCode) && <img src={RainStorm} width={50} height={50}/>}
                </span>
                <span style={{marginLeft: 10}}>
                    {weather}
                </span>
                <span className={styles.date}>
                    {dateText}
                </span>
            </span>
            <span>
                <span className={styles.time}>
                    {timeText}
                </span>
            </span>
        </span>
    )
}

export default Weather;