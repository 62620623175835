import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {getMembers} from "../../lib/api_members.js";
import {PlusOutlined} from '@ant-design/icons';
import {Button, Tabs, Input, Row, Col} from 'antd';
import PageContent from '../../components/page-content/page-content';
import MembersAddModal from './members-add-modal';
import MembersList from './members-list';
import Box from '../../components/box';

class Members extends Component {
    state = {
        list: [],

        is_select: false,

        showAddModal: false,
    };

    componentDidMount() {
        this.loadMembers();
        if (this.props.history.location.state && this.props.history.location.state.type === "select") {
            this.setState({
                is_select: true
            })
        }

    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    // 获取学生列表
    loadMembers = () => {
        getMembers()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data
                    })
                } else {

                }
            })
    };

    showAddModal = () => {
        this.setState({
            showAddModal: true
        })
    };
    hideAddModal = () => {
        this.setState({
            showAddModal: false
        })
    };

    handlerSearch = (value) => {
        this.setState({
            filter_key: value
        })
    };

    handlerIncludes = (a, b) => {
        return a && a.length > 0 && a.includes(b);
    };

    handlerFilter = (item, is_file) => {
        let {filter_key} = this.state;
        return item.is_file === is_file &&
            (
                filter_key
                    ? (this.handlerIncludes(item.nick_name, filter_key)
                        || this.handlerIncludes(item.mobile_number, filter_key)
                        || this.handlerIncludes(item.remark, filter_key)
                        || this.handlerIncludes(item.license_plate, filter_key)
                        || this.handlerIncludes(item.other_name, filter_key)
                        || this.handlerIncludes(item.py, filter_key)
                        || this.handlerIncludes(item.login_name, filter_key)
                    )
                    : true
            )
    }

    render() {
        let {list, is_select} = this.state;
        // const {students} = this.props;

        let curlist = list.filter(item => this.handlerFilter(item, 0));
        let endlist = list.filter(item => this.handlerFilter(item, 1));
        if (is_select) {
            curlist = curlist.filter(item => item.card_count > 0)
        }

        return (
            <PageContent title={"会员管理"}
                         routes={[
                             {
                                 path: '',
                                 breadcrumbName: <><span>首页</span></>,
                             },
                             {
                                 path: '//members',
                                 breadcrumbName: '会员管理',
                             },
                         ]}>
                <Box>
                    <Row>
                        <Col flex={"auto"}>
                            <Input.Search
                                placeholder="输入姓名、昵称、联系电话、车牌、备注、拼音等信息进行搜索"
                                onSearch={this.handlerSearch}
                                style={{width: 500}}
                                autoFocus
                            />
                        </Col>
                        <Col>

                            <Link to={'/member/keshi_batch'}>
                                <Button type='primary' icon={<PlusOutlined/>}
                                        style={{marginRight: 10}}>手动消课</Button>
                            </Link>
                            {/*<Button type='primary' icon={<PlusOutlined/>} onClick={this.showAddModal}>添加会员</Button>*/}
                        </Col>
                    </Row>
                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab={`有效(${curlist.length})`} key="1">
                            <MembersList list={curlist} is_select={is_select} onChange={() => this.loadMembers()}/>

                        </Tabs.TabPane>
                        {!is_select &&
                        <Tabs.TabPane tab={`失效(${endlist.length})`} key="2">
                            <MembersList list={endlist} is_select={is_select} onChange={() => this.loadMembers()}/>

                        </Tabs.TabPane>
                        }

                    </Tabs>
                    <MembersAddModal
                        onOK={() => {
                            this.loadMembers();
                            this.hideAddModal()
                        }}
                        onClose={this.hideAddModal}
                        visible={this.state.showAddModal}
                    />
                </Box>
            </PageContent>
        );
    }
}


Members.propTypes = {};

const mapStateToProps = state => ({
    students: state.students,
    dict_types: state.account.dict_types
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Members));




