import React, {useEffect, useState} from 'react';
import {Modal, Form, Radio, message} from 'antd';
import {postLuoma} from "../../lib/api_luoma";
import {getYuYueById} from "../../lib/api_yuyue";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};

const LuomaAddModal = ({yuyue_id, visible, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [users, setUsers] = useState([]);

    const fetchData = () => {
        getYuYueById(yuyue_id)
            .then((res) => {
                if (res.yuyue_type === 1 || res.yuyue_type === 3) {
                    if (res.yuyue_users) {
                        setUsers(res.yuyue_users.map((item) => {
                            return {
                                value: item.user_id,
                                label: item.user.nick_name
                            }
                        }));
                    }
                } else {
                    setUsers([{
                        value: 0,
                        label: res.nick_name
                    }])
                }
            })
    }

    useEffect(fetchData, [yuyue_id]);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                values.yuyue_id = yuyue_id;
                let item = users.find(m => m.value === values.user_id);
                if (!item) {
                    message.error("用户不存在");
                    return;
                }
                values.nick_name = item.label;
                postLuoma(values)
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk(res.data);
                        }
                    })
            })
    }

    const handleCancel = () => {
        onCancel && onCancel();
    }
    return (
        <Modal
            title="发起落马报告"
            visible={visible}
            onOk={handleSubmit}
            onCancel={handleCancel}
            destroyOnClose={true}
            width={600}
        >
            <Form {...formItemLayout}
                  form={form}
                  initialValues={{
                      yuyue_id: yuyue_id,
                      isdown: 1,
                      ishurt: 1,
                      luoma_place: "室内场",
                      user_id: 0,
                  }}
            >
                <Form.Item label="是否落马" name="isdown">
                    <Radio.Group>
                        <Radio value={0}>未落马</Radio>
                        <Radio value={1}>落马</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="是否受伤" name="ishurt">
                    <Radio.Group>
                        <Radio value={0}>未受伤</Radio>
                        <Radio value={1}>受伤</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="落马场地" name="luoma_place">
                    <Radio.Group>
                        <Radio value={"室内场"}>室内场</Radio>
                        <Radio value={"室外场"}>室外场</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="学员" name="user_id" rules={[
                    {
                        required: true,
                        message: '请选择学员',
                    },
                ]}>
                    <Radio.Group>
                        {users.map(item => <Radio value={item.value}>{item.label}</Radio>)}
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default LuomaAddModal;