import React, {Component, useRef, useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Form, Radio, DatePicker, TimePicker, Input, Button, message, InputNumber} from 'antd';
import DictRadioGroup from '../../components/dict/dict-radio-group'
import moment from 'moment';
import StaffSelect from './staff_select';
import {postYuYue, postYuYueShangma, postYuYueWancheng, postYuYueQuxiao} from "../../lib/api_yuyue";
import TeacherSelect from '../members/teacher-select';

const formItemLayout = {
    labelCol: {
        span: 3
    },
    wrapperCol: {
        span: 21
    },
};


const FormQianZai = ({paiban, staff, yuyue, data, onOk}) => {
    const [form] = Form.useForm();
    const nickNameInput = useRef();
    const [course_type, setcourse_type] = useState(1);
    const [begin_time, setbegin_time] = useState(moment());
    const [end_time, setend_time] = useState(moment().add(15, 'm'));

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log(values);

                postYuYue({
                    ...values,
                    id: data.id,
                    yuyue_type: 2,
                    begin_time: values.begin_time.format("YYYY-MM-DD HH:mm"),
                    end_time: values.end_time.format("YYYY-MM-DD HH:mm"),
                })
                // postMembers(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            message.success("预约成功")
                            onOk && onOk();
                        }
                        // this.handleOK();
                    })
            })
    };

    useEffect(() => {
        console.log(data);
        nickNameInput.current.focus();
        if(data) {
            if(data.begin_time){
                data.begin_time = moment(data.begin_time);
                data.end_time = moment(data.begin_time).add(data.duration, "m");
                setbegin_time(data.begin_time);
                setend_time(data.end_time);
            }
            form.setFieldsValue(data)
        }

    }, []);


    const handleValuesChange = (changedValues, {course_type, duration, begin_time, end_time}) => {
        console.log({course_type, duration, begin_time, end_time});
        setcourse_type(course_type);
        setbegin_time(begin_time);
        end_time = moment(begin_time).add(duration, 'm');
        setend_time(end_time);
        form.setFieldsValue({
            end_time: end_time
        })
    };

    return (
        <Form {...formItemLayout} onFinish={handleSubmit}
              form={form}
              style={{display: "flex", flexDirection: "column", height: "100%"}}
              initialValues={{
                  course_type: course_type,
                  begin_time: begin_time,
                  end_time: end_time,
                  duration: 15,
                  user_type: '一般',
              }}
              onValuesChange={handleValuesChange}
        >
            <div style={{flex: 1, overflow: "auto"}}>
                <Form.Item label="课程" name={"course_type"} rules={[
                    {
                        required: true,
                        message: '请选择课程',
                    },
                ]}>
                    <DictRadioGroup type_value={"course_type"} optionType={"button"}/>
                </Form.Item>
                <Form.Item label="时长" name={"duration"} rules={[
                    {
                        required: true,
                        message: '请选择时长',
                    },
                ]}>
                    <DictRadioGroup type_value={"qianzai_min"} optionType={"button"}/>

                    {/*<Radio.Group buttonStyle="solid">*/}
                        {/*<Radio.Button value={15}>15分钟</Radio.Button>*/}
                        {/*<Radio.Button value={30}>30分钟</Radio.Button>*/}
                        {/*<Radio.Button value={45}>45分钟</Radio.Button>*/}
                    {/*</Radio.Group>*/}
                </Form.Item>
                <Form.Item label="日期" name={"begin_time"} rules={[
                    {
                        required: true,
                        message: '请选择日期',
                    },
                ]}>
                    <DatePicker format={"YYYY-MM-DD"}
                                minuteStep={5}
                                placeholder={"请选择日期"}
                    />
                </Form.Item>
                <Form.Item label="开始" required>
                    <Form.Item name="begin_time" noStyle rules={[
                        {
                            required: true,
                            message: '请选择开始时间',
                        },
                    ]}>
                        <TimePicker format={"HH:mm"}
                                    minuteStep={5}
                                    placeholder={"请选择时间"}
                        />
                    </Form.Item>
                    <span style={{marginLeft: 5}}>结束：</span>
                    <Form.Item name="end_time" noStyle>
                        <TimePicker format={"HH:mm"}
                                    disabled
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item label="姓名" required>
                    <Form.Item name="nick_name" noStyle style={{display: 'inline-block', width: '30%'}} rules={[
                        {
                            required: true,
                            message: '请输入姓名',
                        },
                    ]}>
                        <Input
                            ref={nickNameInput}
                            placeholder="姓名"
                            autoFocus
                            style={{width: 130}}
                        />
                    </Form.Item>
                    <span style={{marginLeft: 5}}>身高：</span>

                    <Form.Item name="height" noStyle style={{display: 'inline-block', width: '30%'}}>
                        <Input
                            placeholder="身高"
                            addonAfter="cm"
                            style={{width: 100}}
                        />
                    </Form.Item>
                    <span style={{marginLeft: 5}}>年龄：</span>
                    <Form.Item name="age" noStyle style={{display: 'inline-block', width: '30%'}}>
                        <Input
                            placeholder="年龄"
                            addonAfter="周岁"
                            style={{width: 100}}
                        />
                    </Form.Item>
                </Form.Item>

                <Form.Item label="销售" name="consultant" rules={[
                    {
                        required: true,
                        message: '请选择销售',
                    },
                ]}>
                    <TeacherSelect style={{width: 100}}/>
                </Form.Item>
                <Form.Item label="电话">
                    <Form.Item name="mobile_number" noStyle style={{display: 'inline-block', width: '40%'}}>
                        <Input style={{width: 150}}/>
                    </Form.Item>
                    <span style={{marginLeft: 5}}>车牌：</span>
                    <Form.Item name="license_plate" noStyle style={{display: 'inline-block', width: '40%'}}>
                        <Input style={{width: 150}}/>
                    </Form.Item>
                </Form.Item>
                <Form.Item label="教练" name="teacher" rules={[
                    {
                        required: true,
                        message: '请选择教练',
                    },
                ]}>
                    <StaffSelect
                        paiban={paiban}
                        // staffs={staff}
                        yuyue={yuyue}
                        type={course_type}
                        begin_time={begin_time}
                        end_time={end_time}
                    />
                </Form.Item>
                <Form.Item label="备注" name="remark">
                    <Input.TextArea/>
                </Form.Item>
                {/*<Form.Item label="付款信息">*/}
                {/*    <span style={{marginLeft: 5}}>付款金额：</span>*/}
                {/*    <Form.Item name="fkje" noStyle style={{display: 'inline-block', width: '40%'}}>*/}
                {/*        <InputNumber precision={2} style={{width: 150}}/>*/}
                {/*    </Form.Item>*/}
                {/*    <span style={{marginLeft: 5}}>订单号：</span>*/}
                {/*    <Form.Item name="ddh" noStyle style={{display: 'inline-block', width: '40%'}}>*/}
                {/*        <Input style={{width: 150}}/>*/}
                {/*    </Form.Item>*/}
                {/*</Form.Item>*/}
            </div>
            <div style={{textAlign: "center"}}>
                {(data.status === 0 || data.status === 1 || !data.id) &&
                <Button htmlType={"submit"} type={"primary"} size={"large"} style={{width: 250}}>保存</Button>
                }

            </div>
        </Form>
    )
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FormQianZai));

