import axios from './rest-axios';

const getAllLuoMas = () => axios.get(`/manage/luoma/`);
const getLuoma = (id) => axios.get(`/manage/luoma/${id}`);
const getLuomaListByYuyueId = (yuyue_id) => axios.get(`/manage/luoma/listbyYuyue/${yuyue_id}`);
const postLuoma = (data) => axios.post(`/manage/luoma/`,data);

export {
    getAllLuoMas,
    getLuoma,
    getLuomaListByYuyueId,
    postLuoma,
};
