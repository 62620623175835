
import axios from './rest-axios';

const getMembership = () => axios.get(`/manage/membership`);
const getMembershipGuoQi = () => axios.get(`/manage/membership/guoqi`);
const getMembershipInfo = (id) => axios.get(`/manage/membership/${id}`);
const getMembershipHistory = (id) => axios.get(`/manage/membership/${id}/history`);
const postMembership = (model) => axios.post(`/manage/membership`, model);
const postMembershipXuFei = (model) => axios.post(`/manage/membership/${model.membership_id}/xufei`, model);
const postMembershipXiumian = (model) => axios.post(`/manage/membership/${model.id}/xiumian`, model);
const postMembershipXiumian1 = (model) => axios.post(`/manage/membership/${model.id}/xiumian1`, model);
const postMembershipXiumian2 = (model) => axios.post(`/manage/membership/${model.id}/xiumian2`, model);
const postMembershipXiumianquxiao = (model) => axios.post(`/manage/membership/${model.id}/xiumianquxiao`, model);
const postMembershipYanchang = (model) => axios.post(`/manage/membership/${model.id}/yanchang`, model);

// const putMembershipType = (model) => axios.put(`/v2/membership_type/${model.id}`, model);
const postChongzhi = (id, fee, remark) => axios.post(`/manage/membership/${id}/chongzhi`, {id, fee, remark});
const postZengsong = (id, fee, remark) => axios.post(`/manage/membership/${id}/zengsong`, {id, fee, remark});
const getMembershipFees = (id) => axios.get(`/manage/membership/${id}/fees`, {id});
const getMembershipDatesHistory = (id) => axios.get(`/manage/membership/${id}/dates_history`, {id});
const postMembershipTuiFei = (id,remark) => axios.post(`/manage/membership/${id}/tuihui`, {remark});
const postMembershipZhuanRang = (id,remark, ids) => axios.post(`/manage/membership/${id}/zhuanrang`, {remark, ids});
const postMembershipShixiao = (id) => axios.post(`/manage/membership/shixiao`, {id});
const getMembershipCards = (id) => axios.get(`/manage/membership/${id}/cards`);
const putMembership = (model) =>axios.put(`/manage/membership/${model.id}`, model);
const resetPassword = (id, password) => axios.post(`/manage/membership/${id}/resetpassword`, {password});
const postCard = (model) => axios.post(`/manage/membership/card`, model);
const posttuijiaolianfei = (model) => axios.post(`/manage/membership/${model.id}/tuijiaolianfei`, model);

const postMembershipRefrush = (id) => axios.post(`/manage/membership/${id}/refrush`);
const getMembershipDate=(id)=>axios.get(`/manage/memberships_dates/${id}`)
const postXiuMian1CheXiao=(id)=>axios.post(`/manage/memberships_dates/${id}/xiumian1_chexiao`)
const postXiuMian2CheXiao=(id)=>axios.post(`/manage/memberships_dates/${id}/xiumian2_chexiao`)
const postXiuMian1Edit=(id,end_time)=>axios.post(`/manage/memberships_dates/${id}/xiumian1_edit`,{end_time})
const postXiuMian2Edit=(id,end_time)=>axios.post(`/manage/memberships_dates/${id}/xiumian2_edit`,{end_time})

export {
    getMembership,
    getMembershipGuoQi,
    getMembershipInfo,
    getMembershipHistory,
    postMembership,
    // putMembershipType,
    postChongzhi,
    postZengsong,
    postMembershipXuFei,
    getMembershipFees,
    getMembershipDatesHistory,
    postMembershipTuiFei,
    postMembershipZhuanRang,
    postMembershipShixiao,
    postMembershipYanchang,
    postMembershipXiumian,
    getMembershipCards,
    putMembership,
    resetPassword,
    postCard,
    postMembershipXiumianquxiao,
    posttuijiaolianfei,
    postMembershipRefrush,
    getMembershipDate,
    postMembershipXiumian1,
    postMembershipXiumian2,
    postXiuMian1CheXiao,
    postXiuMian2CheXiao,
    postXiuMian1Edit,
    postXiuMian2Edit,
};
