import React, {Component} from 'react';
import '@ant-design/compatible/assets/index.css';
import {Modal, Input, Form, InputNumber, Radio} from 'antd';
import {postLevel} from "../../../lib/api_levels";


const LevelAddModal = ({visible, onOk, onCancel,p}) => {
    const [form] = Form.useForm();

    const handleOk = (e) => {
        e.preventDefault();

        form.validateFields()
            .then(values => {
                console.log(values)
                postLevel({
                    pid: p.id,
                    level: p.level+1,
                    name: values.name,
                    code: null,
                    order_index: values.order_index,
                    rootid: p.rootid,
                })
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk();
                        }
                    });
            });

    };
    const handleCancel = () => {
        onCancel && onCancel();
    };
    return (
        <Modal
            title="添加"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose={true}>
            <Form form={form} labelCol={{span: 6}}>
                <Form.Item
                    label="名称"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: '请输入名称',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="排序"
                    name="order_index"
                    rules={[
                        {
                            required: true,
                            message: '请输入排序',
                        },
                    ]}
                >
                    <InputNumber/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default LevelAddModal;
