import React from 'react';
import PropTypes from 'prop-types';
import ConfigName from './config_name';
import {train_type} from '../../config';

const TrainTypeName = ({value}) => {
    return <ConfigName config={train_type} value={value} />;
};

TrainTypeName.propTypes = {
    value: PropTypes.number,
};

export default TrainTypeName;

