import React, {useState} from 'react';
import {Button} from 'antd';
import StaffImportModal from './staff-import-modal';


const ButtonImportNew = ({onOk}) => {
    const [visible, setvisible] = useState(false);

    return (
        <>
        <Button onClick={() => {
            setvisible(true)
        }}>从企业微信导入新成员</Button>
        {visible &&
        <StaffImportModal
            visible={visible}
            onCancel={() => setvisible(false)}
            onOk={() => {
                setvisible(false);
                onOk && onOk()
            }}
        />}

        </>
    )
};

export default ButtonImportNew;