import {getMessageCount} from "../lib/api_messages";

const SET_MESSAGE_COUNT = 'messages/SET_MESSAGE_COUNT';

const initialState = 0;

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_MESSAGE_COUNT:
            // console.log("SET_STAFF", {...action.staff});
            return action.count;
        default:
            return state;
    }
};

const flushMessageCount = async function () {
    let res = await getMessageCount();
    if (res.code === 0) {
        return {
            type: SET_MESSAGE_COUNT,
            count: res.data
        }
    }
    return null;
};


export {
    reducer as default,
    initialState as initialMessageCountState,
    flushMessageCount,
}