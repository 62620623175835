import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {DatePicker, Table, Space, Card,Select} from 'antd';
import moment from 'moment';
import {getStatMonth} from "../../../lib/api_stat";
import PageContent from '../../../components/page-content/page-content';
import {Chart, LineAdvance} from 'bizcharts';
import  UserFileHistoryModal from './user_file_history_modal';

const {Column} = Table;
const {Option} = Select;

const StatDay = () => {

    const [list, setlist] = useState([]);
    const [year, setyear] = useState(moment().year());
    const [data, setdata] = useState(null);
    const [l1, setl1] = useState("总预收款");
    const [l2, setl2] = useState("全部");
    const [l3, setl3] = useState("全部");
    const [ufh, setufh] = useState(null);
    const [ufh2, setufh2] = useState(null);

    const fetchData = () => {
        (async () => {
            let res = await getStatMonth(year);
            if (res.code === 0) {
                setlist(res.data);
                let data = [];
                for (let i = res.data.length - 1; i >= 0; i--) {
                    let item = res.data[i];
                    data.push({
                        month: `${item.month}月`,
                        name: "次卡消费",
                        jee: parseFloat(item.ckje)
                    });
                    data.push({
                        month: `${item.month}月`,
                        name: "年卡消费",
                        jee: parseFloat(item.nkjlf)
                    });
                    data.push({
                        month: `${item.month}月`,
                        name: "年卡年费",
                        jee: parseFloat(item.nknf)
                    });
                    data.push({
                        month: `${item.month}月`,
                        name: "总收入",
                        jee: parseFloat(item.hjje)
                    });
                    data.push({
                        month: `${item.month}月`,
                        name: "剩余金额",
                        jee: parseFloat(item.hjsyje)
                    });
                }
                console.log(data);
                setdata(data);
            }
        })().catch()
    };

    useEffect(fetchData, [year]);

    const render赠送课时 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="月份"
                    width={130}
                    render={(record) => `${record.year}-${record.month}`}
                />
                <Column
                    title="赠送"
                    align="right"
                    dataIndex="ckzsczcs"
                />
                <Column
                    title="消耗"
                    align="right"
                    dataIndex="ckzscs"
                />
                <Column
                    title="剩余"
                    align="right"
                    dataIndex="ckzssycs"
                />
            </Table>
        )
    };

    const render赠送教练费 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="月份"
                    width={130}
                    render={(record) => `${record.year}-${record.month}`}
                />
                <Column
                    title="赠送"
                    align="right"
                    dataIndex="nkzsczjlf"
                />
                <Column
                    title="消耗"
                    align="right"
                    dataIndex="nkzsjlf"
                />
                <Column
                    title="剩余"
                    align="right"
                    dataIndex="nkzssyjlf"
                />
            </Table>
        )
    };

    const render总预收款 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="月份"
                    width={130}
                    render={(record) => `${record.year}-${record.month}`}
                />
                <Column
                    title="收入"
                    align="right"
                    render={(record) => {
                        return (parseFloat(record.ckczje) + parseFloat(record.nkczjlf) + parseFloat(record.nkcznf)).toFixed(2)
                    }}
                />
                <Column
                    title="消耗"
                    align="right"
                    render={(record) => {
                        return (parseFloat(record.ckje) + parseFloat(record.nkjlf) + parseFloat(record.nknf)).toFixed(2)
                    }}
                />
                <Column
                    title="剩余"
                    align="right"
                    dataIndex="hjsyje"
                />
                <Column
                    title="操作"
                    align="right"
                    render={(result) => (
                        <Space>
                            <Link to={`/stat/month/${result.year}/${result.month}`}>详情</Link>
                        </Space>
                    )}
                />
            </Table>
        )
    };

    const render次卡会籍 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="月份"
                    width={130}
                    render={(record) => `${record.year}-${record.month}`}
                />
                <Column
                    title="收入"
                    align="right"
                    dataIndex="ckczje"
                />
                <Column
                    title="消耗"
                    align="right"
                    dataIndex="ckje"
                />
                <Column
                    title="剩余"
                    align="right"
                    dataIndex="cksyje"
                />
            </Table>
        )
    };

    const render年卡会籍 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="月份"
                    width={130}
                    render={(record) => `${record.year}-${record.month}`}
                />
                <Column
                    title="收入"
                    align="right"
                    render={(record) => {
                        return (parseFloat(record.nkczjlf) + parseFloat(record.nkcznf)).toFixed(2)
                    }}
                />
                <Column
                    title="消耗"
                    align="right"
                    render={(record) => {
                        return (parseFloat(record.nkjlf) + parseFloat(record.nknf)).toFixed(2)
                    }}
                />
                <Column
                    title="剩余"
                    align="right"
                    render={(record) => {
                        return (parseFloat(record.nksyjlf) + parseFloat(record.nksynf)).toFixed(2)
                    }}
                />
            </Table>
        )
    };

    const render年费 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="月份"
                    width={130}
                    render={(record) => `${record.year}-${record.month}`}
                />
                <Column
                    title="收入"
                    align="right"
                    dataIndex="nkcznf"
                />
                <Column
                    title="消耗"
                    align="right"
                    dataIndex="nknf"
                />
                <Column
                    title="剩余"
                    align="right"
                    dataIndex="nksynf"
                />
            </Table>
        )
    };

    const render教练费 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="月份"
                    width={130}
                    render={(record) => `${record.year}-${record.month}`}
                />
                <Column
                    title="收入"
                    align="right"
                    dataIndex="nkczjlf"
                />
                <Column
                    title="消耗"
                    align="right"
                    dataIndex="nkjlf"
                />
                <Column
                    title="剩余"
                    align="right"
                    dataIndex="nksyjlf"
                />
            </Table>
        )
    };

    const render会员数量 = () => {
        return (
            <Table
                dataSource={list}
                rowKey={"date"}
                pagination={false}
                size={"small"}
            >
                <Column
                    title="月份"
                    width={130}
                    render={(record) => `${record.year}-${record.month}`}
                />
                <Column
                    title="新增"
                    align="right"
                    dataIndex="hyxz"
                    render={(hyxz,record) => <a onClick={()=>{
                        setufh(record)
                    }}>{hyxz}</a>}
                />
                <Column
                    title="失效"
                    align="right"
                    dataIndex="hysx"
                    render={(hysx,record) => <a onClick={()=>{
                        setufh2(record)
                    }}>{hysx}</a>}
                />
                <Column
                    title="剩余"
                    align="right"
                    dataIndex="hysy"
                />
            </Table>
        )
    };

    return (
        <PageContent>
            <Space style={{marginBottom: 12}}>
                <DatePicker
                    allowClear={false}
                    onChange={(value) => {
                        // console.log(value.format("YYYY-MM"))
                        setyear(value.year())
                    }}
                    value={moment(year + "", "YYYY")}
                    format="YYYY"
                    picker="year"/>
                <Select style={{width: 120}} value={l1} onChange={(v) => {
                    setl1(v);
                    if (v === "总预收款") {
                        setl2("全部");
                        setl3("全部");
                    }
                }}>
                    <Option key={"总预收款"}>总预收款</Option>
                    <Option key={"赠送教练费"}>赠送教练费</Option>
                    <Option key={"赠送课时"}>赠送课时</Option>
                    <Option key={"会员数量"}>会员数量</Option>
                </Select>
                {l1 === "总预收款" &&
                <Select style={{width: 120}} value={l2} onChange={(v) => {
                    setl2(v);
                    if (v === "次卡会籍") {
                        setl3("课时费")
                    }
                    if (v === "年卡会籍") {
                        setl3("全部")
                    }
                }}>
                    <Option key={"全部"}>全部</Option>
                    <Option key={"次卡会籍"}>次卡会籍</Option>
                    <Option key={"年卡会籍"}>年卡会籍</Option>
                </Select>
                }
                {l1 === "总预收款" && l2 === "次卡会籍" &&
                <Select style={{width: 120}} value={l3} onChange={(v) => {
                    setl3(v)
                }}>
                    <Option key={"课时费"}>课时费</Option>
                </Select>
                }
                {l1 === "总预收款" && l2 === "年卡会籍" &&
                <Select defaultValue={"全部"} style={{width: 120}} value={l3} onChange={(v) => {
                    setl3(v)
                }}>
                    <Option key={"全部"}>全部</Option>
                    <Option key={"年费"}>年费</Option>
                    <Option key={"教练费"}>教练费</Option>
                </Select>
                }
            </Space>
            {/*{!!data ?*/}
            {/*<Card>*/}
            {/*<Chart padding={[10, 20, 80, 80]} autoFit height={300} data={data}>*/}
            {/*<LineAdvance*/}
            {/*shape="smooth"*/}
            {/*point*/}
            {/*// area*/}
            {/*position="month*jee"*/}
            {/*color="name"*/}
            {/*/>*/}

            {/*</Chart>*/}
            {/*</Card>*/}
            {/*: null*/}
            {/*}*/}


            {l1 === "赠送课时" && render赠送课时()}
            {l1 === "赠送教练费" && render赠送教练费()}
            {l1 === "总预收款" && l2 === "全部" && render总预收款()}
            {l1 === "总预收款" && l2 === "次卡会籍" && render次卡会籍()}
            {l1 === "总预收款" && l2 === "年卡会籍" && l3 === "全部" && render年卡会籍()}
            {l1 === "总预收款" && l2 === "年卡会籍" && l3 === "年费" && render年费()}
            {l1 === "总预收款" && l2 === "年卡会籍" && l3 === "教练费" && render教练费()}
            {l1 === "会员数量" && render会员数量()}


            {ufh && <UserFileHistoryModal data={ufh} type={0} onCancel={() => setufh(null)}/>}
            {ufh2 && <UserFileHistoryModal data={ufh2} type={1} onCancel={() => setufh2(null)}/>}

        </PageContent>
    )
};


export default StatDay;