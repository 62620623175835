import React, {useState, useEffect} from 'react';
import {Table, Modal} from 'antd';
import DictName from '../../../components/dict/dict-name';
import {getCoursesFee} from "../../../lib/api_courses_fee";

const CoursesFeeSelectModal = ({visible, onOk, onCancel}) => {
    const [list, setList] = useState([]);
    const [selectedRowKeys, setselectedRowKeys] = useState([]);

    const loadData = () => {
        getCoursesFee()
            .then(res => {
                if (res.code === 0) {
                    setList(res.data);
                }
            })
    };
    useEffect(loadData, []);

    const onSelectChange = (ids) => {
        console.log(ids)
        setselectedRowKeys(ids);
    };

    const handleSubmit = () => {
        onOk && onOk(selectedRowKeys)
    }
    return (
        <Modal title={"选择课程"}
               visible={visible}
               onOk={handleSubmit}
               onCancel={onCancel}
        >
            <Table
                // title="课程设置"
                size="small"
                pagination={false}
                dataSource={list}
                rowKey={"id"}
                columns={[{
                    title: '课程类型',
                    dataIndex: 'course_type',
                    sorter: (a, b) => a.course_type - b.course_type,
                    width: 100,
                    render: (value) => <DictName type_value={"course_type"} value={value}/>
                }, {
                    title: '教师级别',
                    dataIndex: 'coach_level',
                    sorter: (a, b) => a.coach_level - b.coach_level,
                    width: 100,
                    render: (value) => <DictName type_value={"coach_level"} value={value}/>
                }, {
                    title: '授课方式',
                    dataIndex: 'teaching_method',
                    width: 100,
                    render: (value) => <DictName type_value={"teaching_method"} value={value}/>
                }, {
                    title: '教练费',
                    dataIndex: 'fee',
                    align: "right",
                    width: 100,
                    render: (value) => parseFloat(value).toFixed(2)
                }
                ]}
                rowSelection={{
                    selectedRowKeys,
                    onChange: onSelectChange,
                }}
            />

        </Modal>
    )
};


export default CoursesFeeSelectModal;


