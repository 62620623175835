import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {PlusOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {Button, Table, Divider, Switch, message, Modal, Card, Space} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import StaffAddModal from './staff2-add-modal';
import StaffResetModal from './staff-reset-modal';
import {getStaff, putStaffFile, postStaffFromQYWX} from "../../../lib/api_staff";
import {setStaff} from "../../../reducers/staff";
import AuthComponent from '../../../components/auth_component';
import styles from './staff2.module.css';

const {Column} = Table;
const {confirm} = Modal;

// @hasAuth
@AuthComponent
class Staff extends Component {
    state = {
        list: [],

        showAddModal: false,
        editUser: null,
        resetUser: null
    };

    componentDidMount() {
        // console.log(this);
        // this.hasRole();
        this.loadData()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        getStaff()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data.filter(_=>!_.userid)
                    })
                    // this.props.setStaff(result.data);
                } else {

                }
            })
    };

    tongbufromqywx = () => {
        postStaffFromQYWX()
            .then(res => {
                if (res.code === 0) {
                    message.success("同步成功！")
                    this.loadData()
                }
            })
    }

    showAddModal = () => {
        this.setState({
            showAddModal: true
        })
    };
    hideAddModal = () => {
        this.setState({
            showAddModal: false
        })
    };

    rowClassName = (record, index)=>{
        if(record.enable === 0){
            return styles.status2
        }
    };


    render() {
        let {list} = this.state;

        return (
            <PageContent>
                <Card>
                    <Space>
                        {/*{this.hasRole(3) &&*/}
                            <Button type='primary' icon={<PlusOutlined/>} onClick={this.showAddModal}
                            style={{}}>添加员工</Button>
                        {/*}*/}
                        {/*<Button onClick={this.exportXlsx} disabled>导出</Button>*/}
                        {/*<ButtonTongBu/>*/}
                        {/*<ButtonImportNew onOk={()=>{*/}
                            {/*this.loadData()*/}
                        {/*}} />*/}
                        {/*<Button onClick={()=>{*/}
                            {/*postimportDepartmentFromQYWX()*/}
                                {/*.then(res=>{*/}
                                    {/*if(res.code === 0){*/}
                                        {/*message.success("同步成功！")*/}
                                        {/*this.loadData()*/}
                                    {/*}*/}
                                {/*})*/}
                        {/*}}>同步部门信息</Button>*/}

                        {/*<Button disabled>从企业微信导入新成员</Button>*/}
                    </Space>
                    <Table
                        dataSource={list}
                        rowKey={"id"}
                        pagination={false}
                        size={"small"}
                        rowClassName={this.rowClassName}
                    >
                        <Column
                            title="姓名"
                            dataIndex="name"
                            key="姓名"
                            render={(name, record) => <Link to={`/manage/staff/${record.id}`}>{name}</Link>}
                        />
                        <Column
                            title="企业微信账号"
                            dataIndex="userid"
                            key="企业微信账号"
                        />
                        <Column
                            title="别名"
                            dataIndex="alias"
                            key="别名"
                        />
                        <Column
                            title="职务"
                            dataIndex="position"
                            key="职务"
                            sorter={ (a, b) => a.position - b.position}
                        />
                        <Column
                            title="部门"
                            key="部门"
                            dataIndex="main_department"
                        />
                        <Column
                            title="手机"
                            dataIndex="mobile"
                            key="手机"
                        />
                        <Column
                            title="邮箱"
                            dataIndex="email"
                            key="邮箱"
                        />
                        <Column
                            title="角色"
                            key="角色"
                            render={(record) => {
                                return record.roles.map(_ => _.name).join(',')
                            }}
                        />
                        <Column
                            title="状态"
                            dataIndex="enable"
                            key="状态"
                            width={60}
                            render={(enable) => {
                                return enable === 1 ? "启用" : "已禁用"
                            }}
                        />
                    </Table>
                    <StaffResetModal
                        onOK={() => this.setState({resetUser: null})}
                        onClose={() => this.setState({resetUser: null})}
                        user_info={this.state.resetUser}/>
                    <StaffAddModal
                        onOK={() => {
                            this.loadData();
                            this.hideAddModal()
                        }}
                        onClose={this.hideAddModal}
                        visible={this.state.showAddModal}
                    />


                </Card>
            </PageContent>
        );
    }
}

Staff.propTypes = {};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    setStaff: (staff) => dispatch(setStaff(staff)),
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Staff));



