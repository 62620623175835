
import axios from './rest-axios';

const getKeshiBatch = () => axios.get(`/manage/keshi_batch`);
const postKeshiBatchcika = (model) => axios.post(`/manage/keshi_batch/cika`, model);
const postKeshiBatchjlf = (model) => axios.post(`/manage/keshi_batch/jlf`, model);
const deleteKeshiBatch = (id) => axios.delete(`/manage/keshi_batch/${id}`);


export {
    getKeshiBatch,
    postKeshiBatchcika,
    postKeshiBatchjlf,
    deleteKeshiBatch,
};
