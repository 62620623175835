import axios from './rest-axios';

const getCWZCYSYueDuAll = (year) => axios.get('/manage/cw_zcys_yuedu/', {year});
const getCWZCYSYueDuById = (id) => axios.get(`/manage/cw_zcys_yuedu/${id}`);


const createOrUpdateCWZCYSYueDu = (data) => axios.post('/manage/cw_zcys_yuedu/', data);

export {
    getCWZCYSYueDuAll,
    getCWZCYSYueDuById,
    createOrUpdateCWZCYSYueDu
};
