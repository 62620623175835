import React, {useState, useEffect} from 'react';
import {Select} from "antd";
import {getStaff} from "../../../lib/api_staff";

const defaultOptions = [
    {label: "责任教练", value: "责任教练"},
    {label: "专属客服", value: "专属客服"},
    {label: "前台web端", value: "前台web端"},
];

const TZSelect = ({value, onChange}) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [realValue, setRealValue] = useState([]);

    const fetchData = () => {
        setLoading(true)
        getStaff()
            .then(res => {
                console.log('res', res);
                if (res.code === 0) {
                    let options = [...defaultOptions];
                    res.data.filter(item => item.enable === 1 && item.userid).forEach(item => {
                        options.push({label: item.name, value: item.userid});
                    });
                    setOptions(options);
                    setLoading(false);
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(fetchData, []);

    useEffect(() => {
        setRealValue(JSON.parse(value || "[]"));
    }, [value]);

    const handleChange = (value) => {
        setRealValue(value);
        onChange(JSON.stringify(value));
    }

    return (
        <Select
            mode="multiple"
            allowClear
            style={{
                width: '100%',
            }}
            options={options}
            loading={loading}
            value={realValue}
            onChange={handleChange}
        />
    )
}

export default TZSelect;
