import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {Modal, Form, InputNumber, Input, DatePicker} from 'antd';
import {postMembership} from "../../../lib/api_membership";
import MembershipTypeSelect from '../../manage/membership_type/membership_type_select';
import moment from 'moment';
import MemberSelect from '../member_select';
import {postMembershipZhuanRang} from "../../../lib/api_membership";


const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};

const MembershipMemberEditModal = ({data, onOk, onCancel}) => {
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                // 校验会员人数
                if (values.members.length > data.member_count) {
                    form.setFields([{
                        name: "members",
                        errors: ["会员人数不能超过会籍人数上限！"]
                    }]);
                    return;
                }

                console.log(values)
                postMembershipZhuanRang(
                    data.id,
                    values.remark,
                    values.members
                )
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk(res.data);
                        }
                    })
            })
    };

    useEffect(()=>{
        console.log(data);
        let members = data.users.map(_=>_.id);
        form.setFieldsValue({
            members: members,
        })
    }, []);



    return (
        <Modal
            title="会员信息维护"
            visible={true}
            // onOk={form.submit}
            onOk={handleSubmit}
            onCancel={onCancel}
            destroyOnClose={true}
            width={800}
        >
            <Form {...formItemLayout}
                  form={form}
                  initialValues={{
                      members: data.users.map(_=>_.id)
                  }}
            >
                <Form.Item
                    label="选择会员"
                    name="members"
                    rules={[
                        {
                            required: true,
                            message: '请选择会员',
                        },
                    ]}
                >
                    <MemberSelect/>
                </Form.Item>
                <Form.Item
                    label="转让原因"
                    name="remark"
                    rules={[
                        {
                            required: true,
                            message: '请输入转让原因',
                        },
                    ]}
                >
                    <Input.TextArea rows={4}/>
                </Form.Item>
            </Form>
        </Modal>
    )
};

MembershipMemberEditModal.propTypes = {
    data: PropTypes.object,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};

export default MembershipMemberEditModal;
