import React, {useEffect, useState} from 'react';
import {Spin} from "antd";
import {getApprovalsLast} from "../lib/api_approvals";

const Approvals = ({children, type, key, onChange}) => {
    const [isApprovals, setIsApprovals] = useState(false);
    const [loading, setLoading] = useState(false);
    const fetchApprovals = () => {
        setLoading(true);
        getApprovalsLast(type)
            .then(res => {
                if (res.code === 0) {
                    if (res.data && res.data.sp_status === 1) {
                        // 存在审核记录并且未完成状态
                        setIsApprovals(true);
                    } else {
                        setIsApprovals(false);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(fetchApprovals, [key]);

    useEffect(() => {
        onChange && onChange(loading || isApprovals);
    }, [isApprovals, loading])

    return (
        <Spin spinning={loading || isApprovals} size={"large"} tip={"正在等待审核，审核完成前无法提交新的申请！"}>
            {children}
        </Spin>
    )
};

export default Approvals;