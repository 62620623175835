import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import {getStables} from "../../../lib/api_stables";

const StableSelect = ({value, onChange}) => {
    const [list, setlist] = useState([]);

    const fetchData = () => {
        getStables()
            .then(res => {
                if (res.code === 0) {
                    setlist(res.data.map(m => {
                        return {
                            label: m.name,
                            value: m.id,
                        }
                    }))
                }
            })
    }

    useEffect(fetchData, []);

    const handleChange = (value) => {
        onChange && onChange(value);
    }

    return (
        <Select
            value={value}
            onChange={handleChange}
            options={list}
        />
    );
}
export default StableSelect