import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {postCard} from "../../../lib/api_cards";
import {Modal, Form, Input, Select, Switch} from 'antd';
import TeacherSelect from "../../members/teacher-select";
import {addHorse} from "../../../lib/api_horses";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};


const Horses_add_modal = ({onCancel, onOk}) => {
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                addHorse(values)
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk();
                        }
                    })
            })
    };

    const handleCancel = () => {
        onCancel && onCancel();
    };
    return (
        <Modal
            title="添加马匹"
            visible={true}
            onOk={handleSubmit}
            onCancel={handleCancel}
            destroyOnClose={true}
        >
            <Form
                form={form}
                {...formItemLayout}
                initialValues={{}}
            >
                {/*<Form.Item*/}
                {/*    label="照片"*/}
                {/*    name="photo"*/}
                {/*    rules={[*/}
                {/*        {*/}
                {/*            required: true,*/}
                {/*            message: '请输入照片',*/}
                {/*        },*/}
                {/*    ]}*/}
                {/*>*/}
                {/*    <Input/>*/}
                {/*</Form.Item>*/}
                <Form.Item
                    label="中文名"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: '请输入中文名',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="英文名"
                    name="name_en"
                    rules={[
                        {
                            required: true,
                            message: '请输入英文名',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="权属"
                    name="quanshu"
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="颜色"
                    name="color"
                    rules={[
                        {
                            required: true,
                            message: '请输入颜色',
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="性别"
                    name="sex"
                    rules={[
                        {
                            required: true,
                            message: '请输入性别',
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="品种"
                    name="breed"
                    rules={[
                        {
                            required: true,
                            message: '请输入品种',
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="出生地"
                    name="birthplace"
                    rules={[
                        {
                            required: true,
                            message: '请输入出生地',
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="年龄"
                    name="age"
                    rules={[
                        {
                            required: true,
                            message: '请输入年龄',
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="中马协护照编号"
                    name="passport"
                    rules={[
                        {
                            required: true,
                            message: '请输入中马协护照编号',
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="芯片编号"
                    name="chip_information"
                    rules={[
                        {
                            required: true,
                            message: '请输入芯片编号',
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="备注"
                    name="remark"
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="教练"
                    name="coach"
                    rules={[
                        {
                            required: true,
                            message: '请选择教练',
                        }
                    ]}
                >
                    <TeacherSelect isCoach={true}/>
                </Form.Item>
                <Form.Item
                    label="重点关注"
                    name="zdgz"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>

                <Form.Item
                    label="重点关注内容"
                    name="zdgzbz"
                >
                    <Input/>
                </Form.Item>
            </Form>

        </Modal>
    )
}

export default Horses_add_modal;
