import React, {Component} from 'react';
import {Modal, Form, Input, Select} from 'antd';
import {addStable} from "../../../lib/api_stables";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};


const StablesAddModal = ({onCancel, onOk}) => {
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                addStable(values)
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk();
                        }
                    })
            })
    };

    const handleCancel = () => {
        onCancel && onCancel();
    };
    return (
        <Modal
            title="添加马房"
            visible={true}
            onOk={handleSubmit}
            onCancel={handleCancel}
            destroyOnClose={true}
        >
            <Form
                form={form}
                {...formItemLayout}
                initialValues={{}}
            >
                <Form.Item
                    label="编号"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: '请输入编号',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="视频监控编码"
                    name="camera"
                    rules={[
                        {
                            required: true,
                            message: '请输入camera',
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
            </Form>

        </Modal>
    )
}

export default StablesAddModal;
