import React, {useState, useEffect} from 'react';
import {Card, Button, Space, Divider, Table, message, Calendar, Row, Col, Select, Alert} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import {SaveOutlined} from '@ant-design/icons';
import {getConfig, putConfig} from "../../../lib/api_config";
import SelectCalendar from './select-calendar';
import moment from 'moment';

const MembershipType = ({match}) => {
    const [year, setyear] = useState(moment().year());
    const [dates, setdates] = useState([]);
    const [show, setshow] = useState(false);
    const loadData = () => {
        getConfig("special_date")
            .then(res => {
                if (res.code === 0 && res.data.config) {
                    setdates(JSON.parse(res.data.config));
                    setshow(true);
                }
            })
    };
    useEffect(loadData, []);

    const save = () => {
        console.log(dates);
        // 去重复
        let  data = Array.from(new Set(dates));
        putConfig("special_date", JSON.stringify(data))
            .then(res => {
                if (res.code === 0) {
                    message.success("保存成功")
                }
            })
        ;
    };

    const dateFullCellRender = (value) => {
        console.log(value)
    };

    const handleYearChange = (value) => {
        setyear(value);
    };

    const handleDateChange = (month, value) => {
        console.log(value)
        // 找到这个月的数据并移除
        let datesnew = dates.filter(date => !date.startsWith(month));
        setdates([...datesnew, ...value])
    };

    /**
     * 选中所有周末日期
     */
    const selectAllWeekend = () => {
        let datesnew = dates;
        let begindate = moment(`${year}-01-01`);
        while (begindate.isSameOrBefore(`${year}-12-31`)) {
            let day = begindate.day();
            if (day === 0 || day === 6) {
                if (!datesnew.includes(begindate.format("YYYY-MM-DD"))) {
                    datesnew.push(begindate.format("YYYY-MM-DD"))
                }
            }
            begindate.add(1, "d");
        }
        setdates([...datesnew])
    };

    /**
     * 清除本年所有选中
     */
    const clearAll = () => {
        let newdates =dates.filter(date => !date.startsWith(`${year}`));
        console.log(newdates)
        setdates(newdates)
    };

    return (
        <PageContent title={"特殊日期设置"}>

            <Alert message="殊日期次卡消课*2，年卡教练费*3，特殊日期不允许会员预约" type="warning"/>

            <Row justify="space-between" style={{marginTop: 5}}>
                <Col>
                    <Space>
                        <Select defaultValue={year} style={{width: 120}} onChange={handleYearChange}>
                            <Select.Option value={2022}>2022</Select.Option>
                            <Select.Option value={2023}>2023</Select.Option>
                            <Select.Option value={2024}>2024</Select.Option>
                            <Select.Option value={2025}>2025</Select.Option>
                            <Select.Option value={2026}>2026</Select.Option>
                            <Select.Option value={2027}>2027</Select.Option>
                            <Select.Option value={2028}>2028</Select.Option>
                            <Select.Option value={2029}>2029</Select.Option>
                            <Select.Option value={2030}>2030</Select.Option>
                        </Select>
                        {/*<Button onClick={selectAllWeekend}>选中周末</Button>*/}
                        {/*<Button onClick={clearAll}>全部清除</Button>*/}
                    </Space>
                </Col>
                <Col>
                    <Button type="primary" onClick={save} icon={<SaveOutlined/>}>保存</Button>
                </Col>
            </Row>
            {show &&
            <Row gutter={[12, 12]} style={{marginTop: 5}}>
                <Col span={6}>
                    <SelectCalendar date={moment(`${year}-01-01`)}
                                    value={dates}
                                    onChange={(value) => handleDateChange(`${year}-01`, value)}/>
                </Col>
                <Col span={6}>
                    <SelectCalendar date={moment(`${year}-02-01`)}
                                    value={dates}
                                    onChange={(value) => handleDateChange(`${year}-02`, value)}/>
                </Col>
                <Col span={6}>
                    <SelectCalendar date={moment(`${year}-03-01`)}
                                    value={dates}
                                    onChange={(value) => handleDateChange(`${year}-03`, value)}/>
                </Col>
                <Col span={6}>
                    <SelectCalendar date={moment(`${year}-04-01`)}
                                    value={dates}
                                    onChange={(value) => handleDateChange(`${year}-04`, value)}/>
                </Col>
                <Col span={6}>
                    <SelectCalendar date={moment(`${year}-05-01`)}
                                    value={dates}
                                    onChange={(value) => handleDateChange(`${year}-05`, value)}/>
                </Col>
                <Col span={6}>
                    <SelectCalendar date={moment(`${year}-06-01`)}
                                    value={dates}
                                    onChange={(value) => handleDateChange(`${year}-06`, value)}/>
                </Col>
                <Col span={6}>
                    <SelectCalendar date={moment(`${year}-07-01`)}
                                    value={dates}
                                    onChange={(value) => handleDateChange(`${year}-07`, value)}/>
                </Col>
                <Col span={6}>
                    <SelectCalendar date={moment(`${year}-08-01`)}
                                    value={dates}
                                    onChange={(value) => handleDateChange(`${year}-08`, value)}/>
                </Col>
                <Col span={6}>
                    <SelectCalendar date={moment(`${year}-09-01`)}
                                    value={dates}
                                    onChange={(value) => handleDateChange(`${year}-09`, value)}/>
                </Col>
                <Col span={6}>
                    <SelectCalendar date={moment(`${year}-10-01`)}
                                    value={dates}
                                    onChange={(value) => handleDateChange(`${year}-10`, value)}/>
                </Col>
                <Col span={6}>
                    <SelectCalendar date={moment(`${year}-11-01`)}
                                    value={dates}
                                    onChange={(value) => handleDateChange(`${year}-11`, value)}/>
                </Col>
                <Col span={6}>
                    <SelectCalendar date={moment(`${year}-12-01`)}
                                    value={dates}
                                    onChange={(value) => handleDateChange(`${year}-12`, value)}/>
                </Col>

            </Row>
            }
        </PageContent>
    )
};


export default MembershipType;


