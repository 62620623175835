import axios from './rest-axios';

const getStables = () => axios.get(`/manage/stables`);
const getStablesById = id => axios.get(`/manage/stables/${id}`);
const addStable = horse => axios.post(`/manage/stables`, horse);
const updateStable = horse => axios.put(`/manage/stables/${horse.id}`, horse);
const deleteStable = id => axios.delete(`/manage/stables/${id}`);

export {
    getStables,
    getStablesById,
    addStable,
    updateStable,
    deleteStable,
}