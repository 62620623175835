import React, {useEffect, useState} from 'react';
import {Badge, Button, DatePicker, Form, Input, Space, Table, Tabs} from "antd";
import moment from "moment";
import {getCWZCYSJiDuAll} from "../../lib/api_cw_zcys_jidu";
import Decimal from "decimal.js";
import styles from "./srdjtj.module.css";
import ExcelJS from "exceljs";
import PageContent from "../../components/page-content/page-content";
import Box from "../../components/box";
import {Link} from "react-router-dom";
import Zcys_jidu_edit_modal from "./zcys_jidu_edit_modal";
import {saveAs} from 'file-saver';

const Srycjidu = () => {
    const [data, setData] = useState([]);
    const [COA, setCOA] = useState(1);
    const [showEditModal, setshowEditModal] = useState(null);

    const [year, setyear] = useState(moment());
    const [department, setdepartment] = useState("");


    const fetchData = () => {
        // // setData([]);
        getCWZCYSJiDuAll(year.format("YYYY"))
            .then((res) => {
                if (res.code === 0) {
                    dealData(res.data);
                    // setData(res.data);
                }
            })
    }

    useEffect(fetchData, [year, department]);

    const dealData = (data) => {
        let {coas, results, year} = data;
        // 数据处理
        // let coas_tree = listToTree(coas);
        // 在data数组中筛选出字段"md",进行分组
        // const uniqueMds = [...new Set(srdjs.map(item => item.md))].sort();
        const uniqueMds = ["门店1", "门店2"];
        // const sortedMds = uniqueMds.sort();
        let groupedData = [];
        for (let i = 0; i < uniqueMds.length; i++) {
            let md = uniqueMds[i];
            let filteredData = results.filter(item => item.md === md);
            let item = {
                md,
                columns: buildColumns(year, md)
            }
            tj(item, filteredData, year, coas);
            // 统计数据
            groupedData.push(item);
        }
        let hj = {
            md: "合计",
            columns: buildColumns(year, "合计")
        };
        tj(hj, results, year, coas);
        groupedData.push(hj);
        setData(groupedData);
    }

    const buildColumns = (year, md) => {
        let list = [{
            title: '项目',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 300,
            render: (text, record, index) => {
                if (text === '收入扣减 (填负数)') {
                    return <span style={{color: "red"}}>{text}</span>
                } else {
                    return text;
                }
            }
        }, {
            title: '部门',
            dataIndex: 'department',
            key: 'department',
            fixed: 'left',
            align: "center",
            width: 100,
        }];
        for (let i = 1; i <= 4; i++) {
            list.push({
                title: `${i}季度`,
                dataIndex: i,
                key: i,
                align: "center",
                render: (text, record, index) => {
                    return (
                        <div className={`${styles.cell} ${record[i].je != record[i].je2 ? styles.celldanger : ""}`}
                             onClick={() => {
                                 let item = {
                                     md: md,
                                     year: year,
                                     jidu: i,
                                     coa: record.name,
                                     coa_id: record.id,
                                     je: record[i].je,
                                     bz: record[i].bz,
                                 }
                                 setshowEditModal(record[i]);
                                 console.log(record, item, record[i]);
                             }}>
                            {new Decimal(record[i].je).toNumber()}
                            {/*{record[i].je!==record[i].je2 && ("异常")}*/}
                            {record[i].bz && (
                                <Badge dot={true}/>
                            )}
                        </div>
                    )
                }
            })
        }
        list.push({
            title: "总计",
            dataIndex: "je",
            key: "je",
            align: "center",
            fixed: 'right',
            width: 130,
            render: (text, record) => {
                let num = new Decimal(0);
                for (let i = 1; i <= 4; i++) {
                    num = num.add(record[i].je);
                }
                return num.toString();
            }
        })
        return list;
    }

    const tj = (item, srdjs, year, coas) => {
        let md = item.md;
        let list = JSON.parse(JSON.stringify(coas));

        // 在list的第一行插入数据
        // list.unshift({name: "现金流合计", pid: 0, id: -1});

        // // 增加会员预收款条目
        // list.push({name: "会员预收款", pid: 0, id: 90000000});
        // list.push({name: "1 年费", pid: 90000000, id: 90000001});
        // list.push({name: "2 寄养费", pid: 90000000, id: 90000002});
        // list.push({name: "3 教练费", pid: 90000000, id: 90000003});
        // list.push({name: "4 次卡", pid: 90000000, id: 90000004});

        // 从begin到end把list到数据都初始化为0
        for (let i = 0; i < list.length; i++) {
            let item1 = list[i];
            item1.key = item.md + "_" + item1.id;
            for (let j = 1; j <= 4; j++) {
                item1[j] = {
                    id: 0,
                    je: 0,
                    je2: 0, // 用于检查
                    md: item.md,
                    year: year,
                    jidu: j,
                    coa: item1.name,
                    department: item1.department,
                    coa_id: item1.id,
                    coa_pid: item1.pid,

                };
            }
        }
        // console.log(list);
        for (let i = 0; i < srdjs.length; i++) {
            let {coa_id} = srdjs[i];
            let jidu = srdjs[i].jidu;
            for (let j = 0; j < list.length; j++) {
                let item = list[j];
                if (item.id === coa_id) {
                    if (item[jidu]) {
                        item[jidu].je = new Decimal(item[jidu].je).add(srdjs[i].je).toString();
                        item[jidu].je2 = item[jidu].je;
                    }
                    break;
                }
            }
        }


        // console.log(list, srdjs);

        item.data = listToTree(list, 0, year);
        // return list;

        // // 特殊处理现金流合计
        // let xjl = item.data[0];
        // // xjl.je = item.data[1];
        // for (let i = 1; i <= 4; i++) {
        //     xjl[i].je2 = (new Decimal(item.data[1][i].je2)).toString();
        //     // 减去会员
        //     let hy = item.data[1].children[0].children[0].children[1];
        //     xjl[i].je2 = (new Decimal(xjl[i].je2).sub(hy[i].je2)).toString();
        //     xjl[i].je2 = (new Decimal(xjl[i].je2).add(item.data[2][i].je2)).toString();
        // }
        // console.log(item.data[1].children[0].children[0].children[1]);

        // console.log(xjl)
    }

    // 递归将列表转成antd的树结构，父节点为pid，pid为0的为顶级节点
    const listToTree = (list, pid = 0, year) => {

        const tree = [];

        list.forEach(item => {
            if (item.pid === pid) {
                const children = listToTree(list, item.id, year);
                if (children.length) {
                    item.children = children;
                    // console.log(item);
                    for (let i = 1; i <= 4; i++) {
                        item[i].je2 = item.children.reduce((sum, item) => {
                            // console.log(item);
                            return new Decimal(sum).add(item[i].je2).toString();
                        }, 0);
                    }
                }
                if (department !== "") {
                    if (children.length) {
                        tree.push(item);
                    } else {
                        if (item.department === department) {
                            tree.push(item);
                        }
                    }
                } else {
                    tree.push(item);
                }
            }
        });

        return tree;
    }

    const getExcelColumnName = (columnNumber) => {
        let columnName = '';
        let dividend = columnNumber;

        while (dividend > 0) {
            const remainder = (dividend - 1) % 26;
            columnName = String.fromCharCode(65 + remainder) + columnName;
            dividend = Math.floor((dividend - remainder) / 26);
        }

        return columnName;
    }

    const buildExcelRow = (worksheet, tree, indent, columns) => {
        if (!tree) {
            return;
        }
        tree.forEach((item, index) => {
            const rowCount = worksheet.rowCount;
            const row1 = worksheet.getRow(rowCount + 1);
            item.row = rowCount + 1;
            row1.getCell(1).value = item.name;
            if (indent) {
                row1.getCell(1).alignment = {
                    horizontal: 'left',
                    indent: indent
                }
            }
            // // 数据
            // for (let i = 1; i < columns.length - 1; i++) {
            //     row1.getCell(i + 1).value = parseFloat(item[columns[i].key].je);
            //     // row1.getCell(i + 1).numFmt = '0.00';
            // }
            let je = new Decimal(0);
            // 递归调用，将子节点转成列表
            if (item.children && item.children.length) {
                buildExcelRow(worksheet, item.children, indent + 1, columns);
                // 有子节点，生成求和公式
                for (let i = 1; i < columns.length - 1; i++) {
                    let a = item.children.map(child => {
                        return getExcelColumnName(i + 1) + child.row;
                    }).join(",");
                    let formula = `SUM(${a})`;
                    row1.getCell(i + 1).value = {formula, result: item[columns[i].key].je};
                    je = new Decimal(je).add(item[columns[i].key].je);
                    row1.getCell(i + 1).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {argb: 'e3f5ff'}
                    }
                }
                row1.getCell(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'e3f5ff'}
                }
            } else {
                // 没有子节点，组装数据
                for (let i = 1; i < columns.length - 1; i++) {
                    let note = item[columns[i].key].bz || "";
                    let formula = "=" + item[columns[i].key].je;
                    let result = parseFloat(item[columns[i].key].je);
                    // row1.getCell(i + 1).value = {formula, result};
                    row1.getCell(i + 1).value = result;
                    je = new Decimal(je).add(result);
                    if (note) row1.getCell(i + 1).note = note;

                    // if (item[columns[i].key].length) {
                    //     let note = `${item[columns[i].key][0].je}：${item[columns[i].key][0].bz || ""}\n`;
                    //     let formula = "=" + item[columns[i].key][0].je;
                    //     let result = parseFloat(item[columns[i].key][0].je);
                    //     for (let j = 1; j < item[columns[i].key].length; j++) {
                    //         formula += "+" + item[columns[i].key][j].je;
                    //         result += parseFloat(item[columns[i].key][j].je);
                    //         note += `${item[columns[i].key][j].je}:${item[columns[i].key][j].bz || ""}\n`;
                    //     }
                    //     row1.getCell(i + 1).value = {formula, result};
                    //     je = new Decimal(je).add(result);
                    //     row1.getCell(i + 1).note = note;
                    // } else {
                    //     row1.getCell(i + 1).value = 0;
                    // }
                }
            }
            // 最后一列使用求和公式
            row1.getCell(columns.length).value = {
                formula: `SUM(${getExcelColumnName(2) + item.row}:${getExcelColumnName(columns.length - 1) + item.row})`,
                result: je.toNumber()
            };
            if (item.children && item.children.length) {
                row1.getCell(columns.length).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'e3f5ff'}
                }
            }
        });
    };

    const exportXlsx = async () => {
        const workbook = new ExcelJS.Workbook();
        for (let i = 0; i < data.length; i++) {
            const worksheet = workbook.addWorksheet(data[i].md, {views: [{state: 'frozen', xSplit: 1, ySplit: 2}]});
            worksheet.getCell(1, 1).value = "JEC支出预算-" + data[i].md;
            // 标题
            for (let j = 0; j < data[i].columns.length; j++) {
                worksheet.getCell(2, j + 1).value = data[i].columns[j].title;
            }
            worksheet.getColumn(1).width = 32;
            // 数据
            buildExcelRow(worksheet, data[i].data, 0, data[i].columns)
            // for (let j = 0; j < data[i].data.length; j++) {
            //     buildExcelRow(worksheet, data[i].data[j], 0)
            //     // worksheet.getCell(j + 3, 1).value = data[i].data[j].name; //名称
            //     // for (let k = 1; k < data[i].data[j].length; k++) {
            //     //     worksheet.getCell(j + 3, k + 1).value = data[i].data[j][k].je;
            //     // }
            // }
            worksheet.eachRow((row, rowNum) => {
                row.eachCell((cell, cellNum) => {
                    cell.border = {
                        top: {style: 'thin'},
                        left: {style: 'thin'},
                        bottom: {style: 'thin'},
                        right: {style: 'thin'}
                    };
                    if (rowNum >= 2 && cellNum >= 2) {
                        cell.alignment = {horizontal: 'center', vertical: 'middle'};
                    }
                    if (cell.value === '收入扣减 (填负数)') {
                        cell.style = {
                            font: {
                                color: {argb: 'FF0000'}
                            }
                        }
                    }
                })
            });
        }
        workbook.calcProperties.fullCalcOnLoad = true;

        //将data插入 工作表，并按照级别进行缩进
        const buffer = await workbook.xlsx.writeBuffer()
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        saveAs(blob, `JEC季度支出预算（${year.format("YYYY年")}）_${moment().format("YYYYMMDDHHmmss")}.xlsx`)
    }


    return (
        <PageContent>
            <Box>
                年份：<DatePicker value={year} onChange={(value) => setyear(value)} picker="year" allowClear={false}/>
                部门：<Input value={department} onChange={e => setdepartment(e.target.value)} style={{width: 100}}/>
                <Link to={'/duizhang/zcys'}>月度支出预算</Link>
            </Box>
            <Box title={`季度支出预算（${year.format("YYYY年")}）`}
                 extra={
                     <Space>
                         {/*<Button type='primary' onClick={() => setaddmodal(true)}>添加</Button>*/}
                         <Button type='primary' onClick={exportXlsx}>导出</Button>
                     </Space>
                 }
                 style={{marginBottom: 20, marginTop: 12}}
            >
                {data.length > 0 &&
                    <Tabs>

                        {data.map((item, index) => {
                            return (
                                <Tabs.TabPane tab={item.md} key={index}>
                                    <Table dataSource={item.data}
                                           key={item.md}
                                           columns={item.columns}
                                           pagination={false}
                                           size={"small"}
                                           scroll={{
                                               x: 1000,
                                           }}
                                           expandable={{
                                               defaultExpandAllRows: true
                                           }}
                                           className={styles.table}
                                           bordered
                                           rowClassName={(record, index) => {
                                               if (record.name === '现金流合计' || (record.children && record.children.length > 0)) {
                                                   return styles.row_sum;
                                               } else {
                                                   return null;
                                               }
                                           }}
                                    />
                                </Tabs.TabPane>
                            );
                        })}
                    </Tabs>
                }
            </Box>

            {showEditModal && <Zcys_jidu_edit_modal
                data={showEditModal}
                onCancel={() => setshowEditModal(null)}
                onOk={() => {
                    setshowEditModal(null);
                    fetchData();
                }}
            />}

        </PageContent>
    )
}

export default Srycjidu;
