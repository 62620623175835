import React, {Component, useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Button, Card, Col, Row, Space, Table, Typography} from "antd";
import moment from "moment";
import {Link} from "react-router-dom";
import {getAllLuoMas} from "../../../lib/api_luoma";


const {Column} = Table;
const LuoMaList = () => {
    const [list, setlist] = useState([]);

    const fetchData = () => {
        getAllLuoMas()
            .then((res) => {
                if (res.code === 0) {
                    setlist(res.data);
                }
            })
    }

    useEffect(fetchData, []);


    return (
        <PageContent>
            <Card>
                <Table
                    dataSource={list}
                    rowKey={"id"}
                    pagination={false}
                    size={"small"}
                >
                    <Column
                        title="报告时间"
                        dataIndex="createdAt"
                        key="报告时间"
                        render={(time) => moment(time).format("YYYY-MM-DD HH:mm:ss")}
                    />
                    {/*<Column*/}
                    {/*    title="关联预约"*/}
                    {/*    dataIndex="yuyue_id"*/}
                    {/*    key="关联预约"*/}
                    {/*/>*/}
                    <Column
                        title="会员"
                        dataIndex="user_name"
                        key="会员"
                    />
                    <Column
                        title="教练"
                        dataIndex="teacher"
                        key="教练"
                    />
                    <Column
                        title="是否落马"
                        dataIndex="isdown"
                        key="是否落马"
                        render={(isdown) => isdown === 1 ? "是" : "否"}
                    />
                    <Column
                        title="是否受伤"
                        dataIndex="ishurt"
                        key="是否受伤"
                        render={(isdown) => isdown === 1 ? "是" : "否"}
                    />
                    <Column
                        title="落马场地"
                        dataIndex="luoma_place"
                        key="落马场地"
                    />
                    <Column
                        title="当前处理人"
                        dataIndex="staff_name"
                        key="当前处理人"
                    />
                    <Column
                        title="状态"
                        dataIndex="status"
                        key="状态"
                        render={(status) => {
                            if (status === 0) {
                                return "待责任教练反馈";
                            } else if (status === 1) {
                                return "待总教练反馈";
                            } else if (status === 2) {
                                return "待客户经理反馈";
                            } else if (status === 3) {
                                return "待管理员反馈";
                            } else if (status === 4) {
                                return "结束";
                            }
                        }}
                    />
                    <Column
                        title="操作"
                        dataIndex="id"
                        render={(id)=><Space>
                            <Link to={`/member/luoma/${id}`}>
                                <Typography.Link>详情</Typography.Link>
                            </Link>
                        </Space>}
                    />

                </Table>
            </Card>


        </PageContent>
    );
}

export default LuoMaList;