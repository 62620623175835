import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {PlusOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {Button, Table, Divider, Switch, message, Modal, Card, Space, Input, Row, Col} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import StaffAddModal from './staff2-add-modal';
import StaffResetModal from './staff-reset-modal';
import {getStaff, putStaffFile, postStaffFromQYWX} from "../../../lib/api_staff";
import {setStaff} from "../../../reducers/staff";
import AuthComponent from '../../../components/auth_component';
import ButtonTongBu from './button-tongbu';
import ButtonImportNew from './button-importnew';
import {postimportDepartmentFromQYWX} from "../../../lib/api_staff";
import styles from './staff2.module.css';
import {showStaffDetailDrawer} from "../../../reducers/staff_detail_drawer";

const {Column} = Table;
const {confirm} = Modal;
const {Search} = Input;

// @hasAuth
@AuthComponent
class Staff extends Component {
    state = {
        list: [],
        key: "",

        showAddModal: false,
        editUser: null,
        resetUser: null
    };

    componentDidMount() {
        // console.log(this);
        // this.hasRole();
        this.loadData()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        getStaff()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data.filter(_ => !!_.userid)
                    })
                    // this.props.setStaff(result.data);
                } else {

                }
            })
    };

    tongbufromqywx = () => {
        postStaffFromQYWX()
            .then(res => {
                if (res.code === 0) {
                    message.success("同步成功！")
                    this.loadData()
                }
            })
    }

    showAddModal = () => {
        this.setState({
            showAddModal: true
        })
    };
    hideAddModal = () => {
        this.setState({
            showAddModal: false
        })
    };

    rowClassName = (record, index) => {
        if (record.enable === 0) {
            return styles.status2;
        } else {
            return styles.row;
        }
    };

    onSearch = (value) => {
        this.setState({
            key: value
        })
    };


    render() {
        let {list, key} = this.state;

        if (key) {
            list = list.filter(_ => _.name.indexOf(key) !== -1);
        }
        if(!this.props.menus.some(menu => menu.resource_key === "yggl_cklzyg")){
            list = list.filter(_ => _.enable === 1);
        }

        return (
            <PageContent>
                <Card>
                    <Row justify="space-between">
                        <Col>
                            <Search placeholder="姓名搜索" onSearch={this.onSearch} style={{width: 200}}/>
                        </Col>
                        <Col>
                            {this.props.menus.some(menu => menu.resource_key === "yggl_xg") &&
                                <Space>
                                    {/*{this.hasRole(3) &&*/}
                                    <Button type='primary' icon={<PlusOutlined/>} onClick={this.showAddModal}
                                            style={{}}>添加员工</Button>
                                    {/*}*/}
                                    <Button onClick={this.exportXlsx} disabled>导出</Button>
                                    <ButtonTongBu onSuccess={() => {
                                        this.loadData()
                                    }}/>
                                    <ButtonImportNew onOk={() => {
                                        this.loadData()
                                    }}/>
                                    <Button onClick={() => {
                                        postimportDepartmentFromQYWX()
                                            .then(res => {
                                                if (res.code === 0) {
                                                    message.success("同步成功！")
                                                    this.loadData()
                                                }
                                            })
                                    }}>同步部门信息</Button>

                                    {/*<Button disabled>从企业微信导入新成员</Button>*/}
                                </Space>
                            }
                        </Col>
                    </Row>

                    <Table
                        dataSource={list}
                        rowKey={"id"}
                        pagination={false}
                        size={"small"}
                        rowClassName={this.rowClassName}
                        onRow={(record) => {
                            return {
                                onClick: event => {
                                    this.props.showStaffDetailDrawer(record.id)
                                }, // 点击行
                            };
                        }}
                    >
                        <Column
                            title="工号"
                            dataIndex="gh"
                            key="工号"
                        />
                        <Column
                            title="姓名"
                            dataIndex="name"
                            key="姓名"
                            // render={(name, record) => <Link to={`/manage/staff/${record.id}`}>{name}</Link>}
                        />

                        <Column
                            title="别名"
                            dataIndex="alias"
                            key="别名"
                        />
                        {/*<Column*/}
                        {/*    title="企业微信账号"*/}
                        {/*    dataIndex="userid"*/}
                        {/*    key="企业微信账号"*/}
                        {/*/>*/}
                        <Column
                            title="职务"
                            dataIndex="position"
                            key="职务"
                            filters={[
                                {
                                    text: 'Coach',
                                    value: 'Coach',
                                },
                                {
                                    text: 'Manager',
                                    value: 'Manager',
                                },
                                {
                                    text: 'Intern',
                                    value: 'Intern',
                                },
                            ]}
                            onFilter={(value, record) => record.position.indexOf(value) !== -1}

                        />
                        {/*<Column*/}
                        {/*    title="职级"*/}
                        {/*    key="职级"*/}
                        {/*    dataIndex="main_department"*/}
                        {/*/>*/}
                        <Column
                            title="部门"
                            key="部门"
                            dataIndex="main_department"
                            filters={[
                                {
                                    text: '教练',
                                    value: '教练',
                                },
                                {
                                    text: '丹枫马会',
                                    value: '丹枫马会',
                                },
                                {
                                    text: '车场',
                                    value: '车场',
                                },
                                {
                                    text: '前厅',
                                    value: '前厅',
                                },
                            ]}
                            onFilter={(value, record) => record.main_department.indexOf(value) !== -1}
                        />
                        <Column
                            title="手机"
                            dataIndex="mobile"
                            key="手机"
                        />
                        <Column
                            title="邮箱"
                            dataIndex="email"
                            key="邮箱"
                        />
                        <Column
                            title="角色"
                            key="角色"
                            render={(record) => {
                                return record.roles.map(_ => _.name).join(',')
                            }}
                        />
                        <Column
                            title="状态"
                            dataIndex="enable"
                            key="状态"
                            width={60}
                            render={(enable) => {
                                return enable === 1 ? "启用" : "已禁用"
                            }}
                        />
                    </Table>
                    <StaffResetModal
                        onOK={() => this.setState({resetUser: null})}
                        onClose={() => this.setState({resetUser: null})}
                        user_info={this.state.resetUser}/>
                    <StaffAddModal
                        onOK={() => {
                            this.loadData();
                            this.hideAddModal()
                        }}
                        onClose={this.hideAddModal}
                        visible={this.state.showAddModal}
                    />


                </Card>
            </PageContent>
        );
    }
}

Staff.propTypes = {};

const mapStateToProps = state => ({
    menus: state.menus
});

const mapDispatchToProps = dispatch => ({
    setStaff: (staff) => dispatch(setStaff(staff)),
    showStaffDetailDrawer: (id) => dispatch(showStaffDetailDrawer(id)),
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Staff));



