import PropTypes from 'prop-types';
import React, {Component, useEffect, useState} from 'react';
import {Modal, Input, message, Form, InputNumber, Spin} from 'antd';
import nzhcn from 'nzh/cn';
import {postZengsong} from "../../../lib/api_membership";
import {getApprovalsLast} from "../../../lib/api_approvals";

const formItemLayout = {
    labelCol: {
        xs: {span: 6},
    },
    wrapperCol: {
        xs: {span: 18},
    },
};
const MembershipZengsongModal = ({id, onOk, onClose}) => {
    const [form] = Form.useForm();
    const [price, setprice] = useState(0);

    const [loading, setLoading] = useState(false);
    const [isApprovals, setIsApprovals] = useState(false);

    // 获取审核情况
    const fetchApprovals = () => {
        setLoading(true);
        getApprovalsLast("赠送教练费")
            .then(res => {
                if (res.code === 0) {
                    if (res.data && res.data.sp_status === 1) {
                        // 存在审核记录并且未完成状态
                        setIsApprovals(true);
                    } else {
                        setIsApprovals(false);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(fetchApprovals, []);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                postZengsong(id, values.price, values.remark)
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk();
                            message.success("赠送成功")
                        } else if (res.code === 1001) {
                            fetchApprovals();
                        }
                    })
            })
    };

    return (
        <Modal
            title="赠送教练费"
            visible={true}
            onOk={handleSubmit}
            onCancel={onClose}
            destroyOnClose={true}
            confirmLoading={loading || isApprovals}
        >
            <Spin spinning={loading || isApprovals} size={"large"} tip={"正在等待审核，审核完成前无法提交新的申请！"}>
                <Form {...formItemLayout}
                      form={form}
                >
                    <Form.Item
                        label="赠送金额"
                        required
                    >
                        <Form.Item
                            name="price"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入赠送金额',
                                },
                            ]}
                        >
                            <InputNumber
                                // defaultValue={1000}
                                formatter={value => `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/¥\s?|(,*)/g, '')}
                                style={{width: 100}}
                                onChange={(value) => setprice(value)}
                                min={0}
                            />

                        </Form.Item>
                        {nzhcn.toMoney(price || 0)}
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remark"
                    >
                        <Input.TextArea/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )

};


MembershipZengsongModal.propTypes = {
    onClose: PropTypes.func,
    onOk: PropTypes.func,
    id: PropTypes.number
};

export default MembershipZengsongModal;
