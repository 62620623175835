import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PageContent from '../../components/page-content/page-content';
import {Button, Table, DatePicker,Tabs,Space} from 'antd';
import {getStat6} from '../../lib/api_stat'
import Box from '../../components/box';
import moment from 'moment';
import excelUtil from '../../lib/excelUtil.js'
import JiaoAn2 from './jiaolian2'

const {Column} = Table;
const {TabPane} = Tabs;


// @hasAuth
class Staff extends Component {
    state = {
        date: moment(),
        list1: [],
        list2: [],

        detailItem: null
    };

    componentDidMount() {
        // console.log(this);
        // this.hasRole();
        this.loadData()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        let year = moment(this.state.date).year();
        getStat6(year)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data,
                    })
                    // this.props.setStaff(result.data);
                } else {

                }
            })
    };
    onChange = (date, dateString) => {
        console.log(date, dateString);
        this.setState({
            date: date
        }, () => {
            this.loadData()
        })
    };

    columns = [{
        title: '',
        key: 'name',
    }, {
        title: '1月份',
        key: '1月份',
    }, {
        title: '2月份',
        key: '2月份',
    }, {
        title: '3月份',
        key: '3月份',
    }, {
        title: '4月份',
        key: '4月份',
    }, {
        title: '5月份',
        key: '5月份',
    }, {
        title: '6月份',
        key: '6月份',
    }, {
        title: '7月份',
        key: '7月份',
    }, {
        title: '8月份',
        key: '8月份',
    }, {
        title: '9月份',
        key: '9月份',
    }, {
        title: '10月份',
        key: '10月份',
    }, {
        title: '11月份',
        key: '11月份',
    }, {
        title: '12月份',
        key: '12月份',
    }];

    exportXlsx = () => {
        excelUtil.exportExcel(this.columns, this.state.list, `会员消费统计${this.state.date.year()}年.xlsx`)
    };


    render() {
        let {list} = this.state;
        return (

                <Box title={"会员消费统计"}>
                    <Space>
                    <DatePicker
                        allowClear={false}
                        onChange={this.onChange}
                        value={this.state.date}
                        picker="year"/>
                    <Button onClick={this.exportXlsx}>导出</Button>
                    </Space>
                    <Table
                        dataSource={list}
                        rowKey={"name"}
                        pagination={false}
                        size={"small"}
                    >
                        <Column
                            title=""
                            dataIndex="name"
                            key="name"
                        />
                        <Column
                            title="1月份"
                            dataIndex="1月份"
                            key="1月份"
                        />
                        <Column
                            title="2月份"
                            dataIndex="2月份"
                            key="2月份"
                        />
                        <Column
                            title="3月份"
                            dataIndex="3月份"
                            key="3月份"
                        />
                        <Column
                            title="4月份"
                            dataIndex="4月份"
                            key="4月份"
                        />
                        <Column
                            title="5月份"
                            dataIndex="5月份"
                            key="5月份"
                        />
                        <Column
                            title="6月份"
                            dataIndex="6月份"
                            key="6月份"
                        />
                        <Column
                            title="7月份"
                            dataIndex="7月份"
                            key="7月份"
                        />
                        <Column
                            title="8月份"
                            dataIndex="8月份"
                            key="8月份"
                        />
                        <Column
                            title="9月份"
                            dataIndex="9月份"
                            key="9月份"
                        />
                        <Column
                            title="10月份"
                            dataIndex="10月份"
                            key="10月份"
                        />
                        <Column
                            title="11月份"
                            dataIndex="11月份"
                            key="11月份"
                        />
                        <Column
                            title="12月份"
                            dataIndex="12月份"
                            key="12月份"
                        />



                    </Table>

                </Box>


        );
    }
}


export default withRouter(Staff);



