/**
 * @name: 成员信息显示
 * @description:
 * @author: Wood
 * @date: 2021/6/28
 * @update:
 */
import React, {useState, useRef} from 'react';
import {Card, Button, Space, Divider} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import DataTable from '../../../components/data/data-table2';
import StaffConfigAddModal from './staff-config-add-modal';
import SysDictEditModal from './staff-config-edit-modal';
// import {useTable} from '../../../components/m-table';
import {PlusOutlined} from '@ant-design/icons';

const SysDict = ({match}) => {
    const [visible, setVisible] = useState(false);
    const [editdata, setEditData] = useState(null);
    // const {refresh} = useTable();
    // console.log(match.params.type_id);
    const table = useRef();
    const config = {
        table: table,
        title: "成员信息显示",
        tableName: "staff_config",
        // pageQuery: match.params,
        pagination: false,
        size: "small",
        columns: [{
            title: '名称',
            dataIndex: 'name',
        }, {
            title: '排序',
            dataIndex: 'order_index',
            sorter: true,
            // sortOrder:"descend"
        }, {
            title: '内置',
            dataIndex: 'is_lock',
            enum: {
                0: '非内置',
                1: '内置',
            },
            filters: [{
                text: '非内置',
                value: 0,
            }, {
                text: '内置',
                value: 1,
            }]
        }, {
            title: '操作',
            fixed: 'right',
            width: 150,
            render: (record) => (<Space split={<Divider type="vertical" />}>
                {record.is_lock === 0 &&
                <a onClick={() => {
                    setEditData(record);
                }}>
                        修改
                </a>
                }
                <a>
                    授权
                </a>
            </Space>)
        }],
        toolbar: [
            <Button
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => setVisible(true)}
            >添加</Button>
        ]
    };

    return (
        <PageContent>
            <Card bordered={false}>
                <DataTable {...config} />
            </Card>
            <StaffConfigAddModal
                visible={visible}
                onOk={() => {
                    setVisible(false);
                    table.current.refresh()
                }}
                onCancel={() => {
                    setVisible(false)
                }}
            />
            {editdata &&
            <SysDictEditModal
                visible={!!editdata}
                data={editdata}
                onOk={() => {
                    setEditData(null);
                    table.current.refresh()
                }}
                onCancel={() => {
                    setEditData(null);
                }}
            />
            }


        </PageContent>
    )
};


export default SysDict;


