import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Modal, Form, InputNumber, Input, DatePicker, Spin} from 'antd';
import {postMembershipYanchang} from "../../../lib/api_membership";
import moment from "moment";
import {getApprovalsLast} from "../../../lib/api_approvals";

/*
* 延长有效期对话框
* 数据里面的操作就是相当于增加了一条年费记录，费用为0
* 开始时间为不早于最后一条记录的截止时间
* 结束时间不做限制
* */
const MembershipYanchangModal = ({data, visible, onOk, onClose}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [isApprovals, setIsApprovals] = useState(false);

    // 获取审核情况
    const fetchApprovals = () => {
        setLoading(true);
        getApprovalsLast("延长有效期")
            .then(res => {
                if (res.code === 0) {
                    if (res.data && res.data.sp_status === 1) {
                        // 存在审核记录并且未完成状态
                        setIsApprovals(true);
                    } else {
                        setIsApprovals(false);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(fetchApprovals, []);


    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                values.id = data.id;
                console.log(values);
                postMembershipYanchang({
                    id: data.id,
                    begin_time: moment(values.begin_time).format('YYYY-MM-DD'),
                    end_time: moment(values.end_time).format('YYYY-MM-DD')
                })
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk(res.data);
                        } else if (res.code === 1001) {
                            fetchApprovals();
                        }
                    })
            })
    };

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 18},
        },
    };

    return (
        <Modal
            title="vip会籍延长有效期"
            visible={true}
            // onOk={form.submit}
            onOk={handleSubmit}
            onCancel={onClose}
            destroyOnClose={true}
            width={800}
            confirmLoading={loading || isApprovals}
        >
            <Spin spinning={loading || isApprovals} size={"large"} tip={"正在等待审核，审核完成前无法提交新的申请！"}>
                <Form {...formItemLayout}
                      form={form}
                    // onSubmit={this.handleSubmit}
                      initialValues={{
                          price: 0,
                          member_count: 0,
                          limit_days: 0,
                          // begin_time: moment(data.end_time).add(1, "d")
                      }}
                    // ref={this.formRef}
                >
                    <Form.Item
                        label="开始日期"
                        name="begin_time"
                        rules={[
                            {
                                required: true,
                                message: '请选择开始日期',
                            },
                        ]}
                    >
                        <DatePicker
                            format={"YYYY-MM-DD"}

                        />
                    </Form.Item>
                    <Form.Item
                        label="结束日期"
                        name="end_time"
                        rules={[
                            {
                                required: true,
                                message: '请选择结束日期',
                            },
                        ]}
                    >
                        <DatePicker format={"YYYY-MM-DD"}/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
};

MembershipYanchangModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};

export default MembershipYanchangModal;
