const SET_memberships = 'memberships/set_memberships';

const initialState = [];

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_memberships:
            // console.log("SET_STAFF", {...action.staff});
            return [...action.data];
        default:
            return state;
    }
};

const setMemberships = function (arr) {
    return {
        type: SET_memberships,
        data: arr
    }
};


export {
    reducer as default,
    initialState as initialMembershipsState,
    setMemberships,
}