import axios from './rest-axios';

const getPaiBanAuto = () => axios.get(`/manage/paiban_auto`);
const postPaiBanAuto = (model) => axios.post(`/manage/paiban_auto`, model);
const putPaiBanAuto = (model) => axios.put(`/manage/paiban_auto/${model.id}`, model);
const deletePaiBanAuto = (id) => axios.delete(`/manage/paiban_auto/${id}`);
const postPaiBanExec = (id, date) => axios.post(`/manage/paiban_auto/${id}/exec`, {date});
const postPaiBanExecAll = (begin_date) => axios.post(`/manage/paiban_auto/exec`, {begin_date});
const putPaiBanAutoExec = (id, auto_exec) => axios.put(`/manage/paiban_auto/${id}/auto_exec`, {auto_exec: auto_exec ? 1 : 0});


export {
    getPaiBanAuto,
    postPaiBanAuto,
    putPaiBanAuto,
    deletePaiBanAuto,
    postPaiBanExec,
    postPaiBanExecAll,
    putPaiBanAutoExec
};
