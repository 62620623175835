/**
 * @name: 账户信息相关接口
 * @description:
 * @author: Wood
 * @date: 2019/3/26
 * @update: 2019/4/24
 */
import axios from './rest-axios';

const getApprovalsLast = (sqlx) => axios.get('/manage/approvals/last', {sqlx});
const refreshApprovals = (sp_no) => axios.post(`/manage/approvals/${sp_no}/refresh` );

export {
    getApprovalsLast,
    refreshApprovals,
};
