import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getMembershipDatesHistory} from "../../../lib/api_membership";
import {Table, Popover, notification, Button, Modal} from 'antd';
import moment from 'moment';
import Markdown from 'markdown-to-jsx';
import Box from '../../../components/box/index';
import {FullscreenOutlined} from '@ant-design/icons';


const {Column} = Table;


const MembershipDatesHistory = ({id, visible, onCancel}) => {
    const [list, setList] = useState([]);

    const fetchData = () => {
        getMembershipDatesHistory(id)
            .then(res => {
                if (res.code === 0) {
                    setList(res.data);
                }
            })
    };

    useEffect(fetchData, []);

    return (
        <Modal
            visible={visible}
            title={"年费记录"}
            footer={false}
            onCancel={onCancel}
        >
            <Table
                size={"small"}
                dataSource={list}
                rowKey={"id"}
                pagination={false}
            >
                <Column
                    title="index"
                    key="index"
                    render={(text, record, index) => `${list.length - index }`}
                />
                <Column
                    title="日期"
                    dataIndex="date"
                    key="时间"
                    width={180}
                    render={(time) => moment(time).format("YYYY-MM-DD ddd")}
                />
                <Column
                    title="金额"
                    dataIndex="fee"
                    key="金额"
                />
            </Table>
        </Modal>
    )
};


MembershipDatesHistory.propTypes = {
    id: PropTypes.number.isRequired,
    visible: PropTypes.bool,
    onCancel: PropTypes.func
};


const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MembershipDatesHistory));
