import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Modal} from 'antd';
import Form, {connectForm} from 'form-render';
import {putData} from "../../../lib/api_data";

const schema = {
    "type": "object",
    "properties": {
        "name": {
            "title": "门店名称",
            "required": true,
            "disabled": false,
            "readOnly": false,
            "hidden": false,
            "props": {
                "allowClear": false
            },
            "type": "string"
        }
    },
    "column": 1,
    "labelWidth": 150,
    "displayType": "row",
    "showDescIcon": true
};

class RoomAddModal extends Component {
    handleCancel = () => {
        this.props.onCancel && this.props.onCancel();
    };

    onFinish = (formData, errors) => {
        if (errors.length > 0) {
        } else {
            console.log(formData);
            formData.type_id = this.props.type_id;
            putData("stores", formData)
                .then(res => {
                    if (res.code === 0) {
                        this.props.onOk && this.props.onOk(res.data);
                    }
                })
        }
    };

    componentDidMount (){
        const {form, visible, data} = this.props;
        form.setValues(data)
    }


    render() {
        const {form, visible, data} = this.props;
        return (
            <Modal
                title="修改门店"
                visible={visible}
                onOk={form.submit}
                onCancel={this.handleCancel}
                width={800}
                destroyOnClose={true}
            >
                <Form
                    form={form}
                    schema={schema}
                    onFinish={this.onFinish}
                    // initialValues={data}
                />
            </Modal>
        )
    }
}


RoomAddModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};


export default connectForm(RoomAddModal);
