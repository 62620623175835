/**
 * 马房路由
 */
import React from 'react';
import {Switch, Route} from "react-router-dom";
import Horses from "./horses/horses";
import Stables from "./stables/stables";
import StableDetail from "./stables/detail";
import HorsesDetail from "./horses/detail";
import Mafang_weisheng from "../paiban/mafang_weisheng";

const Router = () => (
    <Switch>

        <Route exact path="/mafang/horses"
               component={Horses}/>
        <Route exact path="/mafang/stables"
               component={Stables}/>
        <Route exact path="/mafang/stables/:id"
               component={({match}) => <StableDetail id={parseInt(match.params.id)}/>}/>
        <Route exact path="/mafang/horses/:id"
               component={({match}) => <HorsesDetail id={parseInt(match.params.id)}/>}/>
        <Route exact path="/mafang/mafang_weisheng"
               component={Mafang_weisheng}/>
    </Switch>
);

export default Router;
