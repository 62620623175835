import React, {useState, useEffect} from 'react';
import {Modal, DatePicker, Form, InputNumber, message, Select, Spin} from 'antd';
import moment from 'moment';
import {postCard} from "../../../lib/api_membership";
import {getCards} from "../../../lib/api_cards";
import {getApprovalsLast} from "../../../lib/api_approvals";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


const UserCardAddModal = ({visible, id, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [cards, setCards] = useState([]);
    const [card, setCard] = useState(null);
    const [avg, setAvg] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isApprovals, setIsApprovals] = useState(false);

    const fetchCards = () => {
        getCards()
            .then(res => {
                if (res.code === 0) {
                    setCards(res.data)
                }
            })
    };

    useEffect(fetchCards, []);

    // 获取审核情况
    const fetchApprovals = () => {
        setLoading(true);
        getApprovalsLast("添加次卡")
            .then(res => {
                if (res.code === 0) {
                    if (res.data && res.data.sp_status === 1) {
                        // 存在审核记录并且未完成状态
                        setIsApprovals(true);
                    } else {
                        setIsApprovals(false);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(fetchApprovals, []);

    const Jisuan = () => {
        let payments = form.getFieldValue("payments");
        let keshi_original = form.getFieldValue("keshi_original");
        setAvg(Math.ceil(payments / keshi_original))
    };

    const handleCardChange = (value) => {
        let card = cards.find(_ => _.id === value);
        if (card) {
            setCard(card);
            let begin_time = moment().startOf('day');
            let end_time = moment(begin_time).add(card.limit_days - 1, 'd');
            form.setFieldsValue({
                begin_time: begin_time,
                end_time: end_time,
                keshi_original: card.keshi,
                payments: 0,
                retail_price: card.retail_price
            })
            Jisuan()
        }
    };

    const handleBeginTimeChange = (value) => {
        let begin_time = moment(value).startOf('day');
        let end_time = moment(begin_time).add(card.limit_days - 1, 'd');
        form.setFieldsValue({
            begin_time: begin_time,
            end_time: end_time,
        })
    };

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                values.membership_id = id;
                postCard({
                    ...values,
                    begin_time: moment(values.begin_time).format("YYYY-MM-DD"),
                    end_time: moment(values.end_time).format("YYYY-MM-DD")
                })
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            message.success("添加成功").then(() => {
                            })
                            onOk && onOk()
                        } else if (result.code === 1001) {
                            fetchApprovals();
                        }
                    })
            })
    };

    return (
        <Modal
            title={`添加次卡`}
            visible={visible}
            onOk={handleSubmit}
            onCancel={onCancel}
            destroyOnClose={true}
            confirmLoading={loading || isApprovals}
        >
            <Spin spinning={loading || isApprovals} size={"large"} tip={"正在等待审核，审核完成前无法提交新的申请！"}>
            <Form
                {...layout}
                form={form}
                initialValues={{
                    annual_fee: 0,
                    keshi_free_original: 0
                }}
            >
                <Form.Item
                    label="会员卡"
                    name="card_id"
                    rules={[
                        {
                            required: true,
                            message: '请选择会员卡',
                        },
                    ]}
                >
                    <Select onChange={handleCardChange}>
                        {cards.map(card => <Select.Option value={card.id}>{card.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                {!!card && <>
                    <Form.Item
                        label="开始日期"
                        name="begin_time"
                        rules={[
                            {
                                required: true,
                                message: '请选择开始日期',
                            },
                        ]}
                    >
                        <DatePicker onChange={handleBeginTimeChange}/>
                    </Form.Item>

                    <Form.Item
                        label="结束日期"
                        name="end_time"
                        rules={[
                            {
                                required: true,
                                message: '请选择结束日期',
                            },
                        ]}
                    >
                        <DatePicker/>
                    </Form.Item>

                    <Form.Item
                        label="正课"
                        name="keshi_original"
                        rules={[
                            {
                                required: true,
                                message: '请输入正课课时',
                            },
                        ]}
                    >
                        <InputNumber onChange={Jisuan}/>
                    </Form.Item>
                    <Form.Item
                        label="赠课"
                        name="keshi_free_original"
                        rules={[
                            {
                                required: true,
                                message: '请输入赠课课时',
                            },
                        ]}
                    >
                        <InputNumber/>
                    </Form.Item>

                    <Form.Item
                        label="门市价"
                        name="retail_price"
                    >
                        <InputNumber readOnly precision={2}/>
                    </Form.Item>

                    <Form.Item
                        label="实际支付总金额"
                        rules={[
                            {
                                required: true,
                                message: '请输入支付金额',
                            },
                        ]}>
                        <Form.Item
                            // label="支付金额"
                            name="payments"
                            noStyle
                            rules={[
                                {
                                    required: true,
                                    message: '请输入支付金额',
                                },
                            ]}
                        >
                            <InputNumber onChange={Jisuan}/>

                        </Form.Item>
                        <div>
                            每课时：{avg}元<br/>
                        </div>
                    </Form.Item>


                </>}

            </Form>
            </Spin>
        </Modal>
    )
};

export default UserCardAddModal;
