/**
 * @name: 课程管理路由
 * @description:
 * @author: Wood
 * @date: 2021/4/25
 * @update:
 */
import React from 'react';
import {Switch, Route} from "react-router-dom";
import Stores from './stores/stores';
import Cards from './cards/cards';
import Staff from './staff/staff2';
import StaffDetail from './staff/detail';
import CoursesFee from './courses-fee/courses-fee';
import MembershipType from './membership_type/membership_type';
import XcxConfig from './xcx';
import Calendar from './calendar';
import Staff3 from './staff/staff3';
import Levels from './levels';
import Huamingce from "./huamingce/huamingce";
import Approvals from "./approvals/approvals";
import CWCOA from "./cw/coa";
import CWSRDJ from "./cw/srdj";

const Router = () => (
    <Switch>
        <Route exact path="/manage/stores"
               component={Stores}/>
        <Route exact path="/manage/cards"
               component={Cards}/>
        <Route exact path="/manage/staff"
               component={Staff}/>
        <Route exact path="/manage/staff/:id"
               component={({match}) => <StaffDetail id={parseInt(match.params.id)}/>}/>
        <Route exact path="/manage/courses_fee"
               component={CoursesFee}/>
        <Route exact path="/manage/membership_type"
               component={MembershipType}/>
        <Route exact path="/manage/xcx"
               component={XcxConfig}/>
        <Route exact path="/manage/calendar"
               component={Calendar}/>
        <Route exact path="/manage/sysstaffs"
               component={Staff3}/>
        <Route exact path="/manage/levels"
               component={Levels}/>

        <Route exact path="/manage/huamingce"
               component={Huamingce}/>

        <Route exact path="/manage/cwcoa"
               component={CWCOA}/>
        <Route exact path="/manage/cwsrdj"
               component={CWSRDJ}/>

    </Switch>
);

export default Router;
