import axios from './rest-axios';

const getMaFangWeiShengList = (begin_date, end_date) => axios.get('/manage/mafang_weisheng', {begin_date, end_date});
const getMaFangWeiSheng = (date) => axios.get(`/manage/mafang_weisheng/${date}`);
const updateMaFangWeiSheng = (date, model) => axios.post(`/manage/mafang_weisheng/${date}`, model);

export {
    getMaFangWeiShengList,
    getMaFangWeiSheng,
    updateMaFangWeiSheng,
};
