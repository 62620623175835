import React, {Component, useEffect, useState} from 'react';
import {Button, Modal, Table, Form, Input, InputNumber, Radio} from "antd";
import ResourceSelect from "./resource_select";
import {postMenu} from "../../../lib/api_menu";


const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};


const ResourceAddModal = ({id, onOk, onCancel}) => {
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log(values);
                postMenu(values)
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk();
                        }
                    })
            })
    };

    return (
        <Modal
            title="添加资源"
            visible={true}
            onOk={handleSubmit}
            onCancel={onCancel}
            width={800}
            destroyOnClose={true}
        >
            <Form
                {...formItemLayout}
                form={form}
                initialValues={{
                    pid: id,
                    order_index: 0,
                    type: 0,
                }}
            >
                <Form.Item
                    label="父节点"
                    name="pid"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <ResourceSelect/>
                </Form.Item>

                <Form.Item
                    label="类型"
                    name="type"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={0}>菜单</Radio>
                        <Radio value={1}>模块</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="标题"
                    name="title"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="简称"
                    name="minititle"
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="资源标识"
                    name="resource_key"
                >
                    <Input/>
                </Form.Item>


                <Form.Item
                    label="路径"
                    name="path"
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="图标"
                    name="icon"
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="排序"
                    name="order_index"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber step={1} precision={0}/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ResourceAddModal;