import React, {useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Button, Card, Descriptions, Space, Table, Typography, Popconfirm, message, Tag} from "antd";
import {getStablesById} from "../../../lib/api_stables";
import {Link} from "react-router-dom";
import moment from "moment";
import Box from "../../../components/box";
import {updateHorse} from "../../../lib/api_horses";
import HorsesLinkModal from "./horses-link-modal";
import {LinkOutlined} from '@ant-design/icons';

const {Column} = Table;

const Detail = ({id}) => {
    const [data, setData] = useState({});
    const [showLinkModal, setShowLinkModal] = useState(false);

    const fetchData = () => {
        getStablesById(id)
            .then(res => {
                if (res.code === 0) {
                    setData(res.data)
                }
            })
    }

    useEffect(fetchData, []);

    const getvideo = () => {
        // 获取视频流地址
    }

    const handleUnLink = (id) => {
        updateHorse({
            id: id,
            stable_id: 0
        }).then(res => {
            if (res.code === 0) {
                message.success('马房解绑成功');
                fetchData();
            }
        })
    };

    return (
        <PageContent title="马房详情">
            <Box>
                <Descriptions bordered size={"small"} labelStyle={{width: 150}}>
                    <Descriptions.Item label="马房编号">{data.name}</Descriptions.Item>
                    <Descriptions.Item label="视频监控编码">{data.camera}</Descriptions.Item>
                    <Descriptions.Item label="是否有马">{data.horse_inside === 1 ? "是" : "否"}</Descriptions.Item>
                    <Descriptions.Item
                        label="更新时间">{moment(data.horse_inside_update_time).format("YYYY-MM-DD HH:mm:ss")}</Descriptions.Item>
                </Descriptions>
                {/*<video src={}></video>*/}
            </Box>
            <Box title={"马匹"} style={{marginTop: 10}} extra={[<Space>
                <Button onClick={() => setShowLinkModal(true)} icon={<LinkOutlined />}>关联马匹</Button>
            </Space>]}>
                <Table
                    dataSource={data.horses}
                    rowKey={"id"}
                    pagination={false}
                    size={"small"}
                >
                    <Column
                        title="姓名"
                        dataIndex="name"
                        key="姓名"
                        render={(name, record) => <Link to={`/mafang/horses/${record.id}`}>{name}</Link>}
                    />
                    <Column
                        title="颜色"
                        dataIndex="color"
                        key="颜色"
                    />
                    <Column
                        title="性别"
                        dataIndex="sex"
                        key="性别"
                    />
                    <Column
                        title="品种"
                        dataIndex="breed"
                        key="品种"
                    />
                    <Column
                        title="出生地"
                        dataIndex="birthplace"
                        key="出生地"
                    />
                    <Column
                        title="年龄"
                        dataIndex="age"
                        key="年龄"
                    />
                    <Column
                        title="护照"
                        dataIndex="passport"
                        key="护照"
                    />
                    <Column
                        title="芯片信息"
                        dataIndex="chip_information"
                        key="芯片信息"
                    />
                    <Column
                        title="备注"
                        dataIndex="remark"
                        key="备注"
                    />
                    <Column
                        title="教练"
                        dataIndex="coach_name"
                        key="教练"
                        render={(text, record) => <Link to={`/manage/staff/${record.coach}`}>{text}</Link>}
                    />
                    <Column
                        title="马房"
                        dataIndex="stable_name"
                        key="马房"
                    />
                    <Column
                        title="标签"
                        dataIndex="tags"
                        key="标签"
                        render={(text, record) => {
                            if (record.tags) {
                                return JSON.parse(record.tags).map(tag => <Tag color="blue" key={tag}>{tag}</Tag>);
                            } else {
                                return null;
                            }
                        }}
                    />
                    <Column
                        title="操作"
                        key="操作"
                        render={
                            (text, record) => (
                                <Popconfirm
                                    title="是否取消马匹和该马房的绑定关系？"
                                    onConfirm={() => handleUnLink(record.id)}
                                >
                                    <Typography.Link>取消关联</Typography.Link>
                                </Popconfirm>
                            )
                        }
                    />
                </Table>
            </Box>
            {showLinkModal &&
                <HorsesLinkModal
                    id={id}
                    onOk={() => {
                        fetchData();
                        setShowLinkModal(false);
                    }}
                    onCancel={()=>{
                        setShowLinkModal(false);
                    }}
                />
            }

        </PageContent>
    )
}

export default Detail;