import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Select} from 'antd';
import {getStaff} from "../../lib/api_staff";

class TeacherSelect extends Component {

    onChange = (value) => {
        this.props.onChange && this.props.onChange(value)
    };

    render() {
        let {staff, isCoach, ...props} = this.props;
        if (isCoach) {
            staff = staff.filter(item => item.enable === 1 && item.userid && item.is_coach === true);
        } else {
            staff = staff.filter(item => item.enable === 1 && item.userid);
        }
        return (
            <Select
                showSearch
                value={this.props.value}
                optionFilterProp="children"
                onChange={this.onChange}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                {...props}
            >
                {staff.map(item => (
                    <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                ))}
            </Select>
        )
    }
}

TeacherSelect.propTypes = {
    isCoach: PropTypes.bool,
    value: PropTypes.number,
    onChange: PropTypes.func
};

const mapStateToProps = state => ({
    staff: state.staff
});

const mapDispatchToProps = dispatch => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeacherSelect);


