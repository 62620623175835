import axios from './rest-axios';

const getHorsesTrainingTasks = () => axios.get(`/manage/horses_training_tasks`);
const getHorsesTrainingTasksById = id => axios.get(`/manage/horses_training_tasks/${id}`);
const addHorsesTrainingTasks = horse => axios.post(`/manage/horses_training_tasks`, horse);
const updateHorsesTrainingTasks = horse => axios.put(`/manage/horses_training_tasks/${horse.id}`, horse);
const deleteHorsesTrainingTasks = id => axios.delete(`/manage/horses_training_tasks/${id}`);

export {
    getHorsesTrainingTasks,
    getHorsesTrainingTasksById,
    addHorsesTrainingTasks,
    updateHorsesTrainingTasks,
    deleteHorsesTrainingTasks
}