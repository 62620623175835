import React, {useEffect} from 'react'
import {Form, Input, message, Modal, Select} from "antd";
import {getMaFangWeiSheng, updateMaFangWeiSheng} from "../../../lib/api_mafang_weisheng";
import {getStaff} from "../../../lib/api_staff";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};
const names = ["ximaqu", "xiuxishi", "anjufang", "mafangtongdao", "xunlianchangdi", "cesuo"];


const Mafang_weisheng_edit_modal = ({date, onCancel, onOk}) => {
    const [form] = Form.useForm();
    const [options, setOptions] = React.useState([]);

    // 获取教练列表
    const fetchTeachers = () => {
        getStaff()
            .then(res=>{
                if (res.code===0){
                    console.log(res.data);
                    setOptions(res.data.filter(item=>item.enable===1&&item.is_coach===true).map(item=>{
                        return {
                            label: item.name,
                            value: item.id+''
                        }
                    }))
                }
            })
    }

    // 获取当天的排班情况
    const fetchData = () => {
        getMaFangWeiSheng(date)
            .then(res=>{
                if(res.code===0){
                    // setOptions(res.data);
                    for (const name of names) {
                        if(res.data && res.data[name]){
                            form.setFieldsValue({[name]: res.data[name].split(',')});
                        }
                    }
                    // form.setFieldsValue(res.data);

                    fetchTeachers();
                }
            })
    }
    useEffect(fetchData, []);

    const handleOk = () => {
        form.validateFields()
            .then(values => {
                console.log(values);
                updateMaFangWeiSheng(date,{
                    ximaqu: values.ximaqu.join(','),
                    xiuxishi: values.xiuxishi.join(','),
                    anjufang: values.anjufang.join(','),
                    mafangtongdao: values.mafangtongdao.join(','),
                    xunlianchangdi: values.xunlianchangdi.join(','),
                    cesuo: values.cesuo.join(','),
                }).then(res=>{
                    if(res.code===0){
                        message.success('保存成功');
                        onOk && onOk();
                    }
                })
            })
    }
    const handleCancel = () => {
        onCancel && onCancel();
    }

    return (
        <Modal
            title={`修改排班：${date}`}
            open={true}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose={true}
            width={800}
        >
            <Form
                form={form}
                {...formItemLayout}
            >
                <Form.Item
                    label="洗马区"
                    name="ximaqu"
                    rules={[
                        {
                            required: true,
                            message: '请选择',
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        options={options}
                    />
                </Form.Item>
                <Form.Item
                    label="休息室"
                    name="xiuxishi"
                    rules={[
                        {
                            required: true,
                            message: '请选择',
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        options={options}
                    />
                </Form.Item>
                <Form.Item
                    label="鞍具房"
                    name="anjufang"
                    rules={[
                        {
                            required: true,
                            message: '请选择',
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        options={options}
                    />
                </Form.Item>
                <Form.Item
                    label="马房通道"
                    name="mafangtongdao"
                    rules={[
                        {
                            required: true,
                            message: '请选择',
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        options={options}
                    />
                </Form.Item>
                <Form.Item
                    label="训练场地"
                    name="xunlianchangdi"
                    rules={[
                        {
                            required: true,
                            message: '请选择',
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        options={options}
                    />
                </Form.Item>
                <Form.Item
                    label="厕所"
                    name="cesuo"
                    rules={[
                        {
                            required: true,
                            message: '请选择',
                        },
                    ]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        options={options}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default Mafang_weisheng_edit_modal;