import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Table, Divider, Switch, message, Popconfirm, Layout, Calendar, Tabs} from 'antd';
import PageContent from '../../components/page-content/page-content';
import {getStaff, putStaffFile} from "../../lib/api_staff";
import {getPaiBanAuto, deletePaiBanAuto, postPaiBanExecAll, putPaiBanAutoExec} from "../../lib/api_paiban_auto";
import PaiBanAddModal from './paiban-add-modal';
import PaiBanEditModal from './paiban-edit-modal';
import PaiBanCalendar from './paiban-calendar';
import PaiBanCalendarWeek from './paiban-calendar-week';
import PaiExecModal from './paiban-exec-modal';
import Box from '../../components/box';
import moment from 'moment';
import AuthComponent from '../../components/auth_component';

const {Column} = Table;

@AuthComponent
class PaiBan extends Component {
    state = {
        list: [],
        // staff: [],

        showAddModal: false,
        editConfig: null,
        execID: 0,
    };

    componentDidMount() {
        this.loadData()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        this.loadPaiBanAuto();
    };
    loadPaiBanAuto = () => {
        // 获得出勤配置
        getPaiBanAuto()
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data
                    })
                } else {
                    message.error(result.message);
                }
            });
    };

    showAddModal = () => {
        this.setState({
            showAddModal: true
        })
    };

    hideAddModal = () => {
        this.setState({
            showAddModal: false
        })
    };

    handleConfigDelete = (id) => {
        deletePaiBanAuto(id)
            .then(result => {
                if (result.code === 0) {
                    this.loadData()
                } else {
                    message.error(result.message);
                }
            })
    };

    execPaiBan = () => {
        postPaiBanExecAll()
            .then(res => {
                console.log(res);
            })
    };

    AutoExecChange = (id, checked) => {
        console.log(checked)
        putPaiBanAutoExec(id, checked)
            .then(res => {
                if (res.code === 0) {
                    message.success(res.message)
                    this.loadPaiBanAuto()
                } else {
                    message.error(res.message)
                }
            })
    };

    render() {
        let {list} = this.state;

        return (
            <PageContent>
                <Box>
                    <Tabs defaultActiveKey="1" size={"large"}>
                        <Tabs.TabPane tab="日排班" key="1">
                            <PaiBanCalendar/>

                        </Tabs.TabPane>
                        <Tabs.TabPane tab="周排班" key="2">
                            <PaiBanCalendarWeek/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="排班计划" key="3">

                            {/*{this.hasRole([3, 4]) &&*/}
                            <Button type='primary' icon={<PlusOutlined/>} onClick={this.showAddModal}
                                    style={{marginBottom: 16}}>添加排班计划</Button>
                            {/*// }*/}

                            {/*<Button type='primary' icon={<PlusOutlined/>} onClick={this.execPaiBan}*/}
                            {/*style={{marginBottom: 16}}>执行计划</Button>*/}
                            <Table
                                dataSource={list}
                                rowKey={"id"}
                                pagination={false}

                            >
                                <Column
                                    title="工作日"
                                    dataIndex="weekdays"
                                    key="weekdays"
                                    render={(weekdays) => {
                                        if (weekdays) {
                                            weekdays = JSON.parse(weekdays);
                                            weekdays = weekdays.sort().map(weekday => {
                                                return ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日"][parseInt(weekday)];
                                            });
                                            return weekdays.join(',')
                                        }
                                        return null;
                                    }}
                                />
                                <Column
                                    title="时间段"
                                    dataIndex="time1"
                                    key="time1"
                                    render={(time1, record) => {
                                        let arr = [];
                                        if (record.time1) {
                                            let list = JSON.parse(record.time1);
                                            if (list.length === 2) {
                                                arr.push(<div>上班 {list[0]} - 下班 {list[1]}</div>)
                                            }
                                        }
                                        if (record.time2) {
                                            let list = JSON.parse(record.time2);
                                            if (list.length === 2) {
                                                arr.push(<div>上班 {list[0]} - 下班 {list[1]}</div>)
                                            }
                                        }
                                        if (record.time3) {
                                            let list = JSON.parse(record.time3);
                                            if (list.length === 2) {
                                                arr.push(<div>上班 {list[0]} - 下班 {list[1]}</div>)
                                            }
                                        }
                                        return arr;
                                    }}
                                />

                                <Column
                                    title="教练"
                                    dataIndex="user_ids"
                                    key="user_ids"
                                    render={(user_ids) => {
                                        if (user_ids) {
                                            user_ids = user_ids.split(',');
                                            return user_ids.map(id => {
                                                let staf = this.props.staff.find(sta => sta.id === parseInt(id));
                                                if (staf) {
                                                    return staf.name
                                                } else {
                                                    return null
                                                }
                                            }).join(",")
                                        }
                                    }}
                                />
                                {/*<Column*/}
                                {/*title="自动执行"*/}
                                {/*dataIndex="auto_exec"*/}
                                {/*key="auto_exec"*/}
                                {/*render={(last_exec, record) => {*/}
                                {/*return(<Switch checkedChildren="开" unCheckedChildren="关" checked={last_exec === 1} onChange={(checked)=> this.AutoExecChange(record.id, checked)} />)*/}
                                {/*}}*/}
                                {/*/>*/}
                                {/*<Column*/}
                                {/*title="最后执行时间"*/}
                                {/*dataIndex="last_exec"*/}
                                {/*key="last_exec"*/}
                                {/*render={(last_exec) => {*/}
                                {/*if(last_exec){*/}
                                {/*return moment(last_exec).format("YYYY-MM-DD HH:mm:ss")*/}
                                {/*}*/}
                                {/*}}*/}
                                {/*/>*/}
                                {/*{this.hasRole([3, 4]) &&*/}

                                <Column
                                    title="操作"
                                    key="action"
                                    render={(text, record) => (
                                        <div>
                                            <a href="javascript:void(0)" onClick={() => {
                                                this.setState({editConfig: record})
                                            }}>修改</a>
                                            <Divider type="vertical"/>
                                            <Popconfirm
                                                title="确定要删除这个设置吗？"
                                                onConfirm={() => this.handleConfigDelete(record.id)}
                                                // onCancel={cancel}
                                                // okText="Yes"
                                                // cancelText="No"
                                            >
                                                <a href="javascript:void(0)">删除</a>
                                            </Popconfirm>

                                        </div>
                                    )}
                                />
                                {/*// }*/}
                            </Table>
                        </Tabs.TabPane>
                    </Tabs>


                    <PaiExecModal
                        id={this.state.execID}
                        onOK={() => {
                            // this.loadData();
                            this.setState({execID: 0})
                        }}
                        onClose={() => {
                            this.setState({execID: 0})
                        }}
                    />
                    <PaiBanAddModal
                        onOK={() => {
                            this.loadData();
                            this.hideAddModal()
                        }}
                        onClose={this.hideAddModal}
                        visible={this.state.showAddModal}
                    />
                    <PaiBanEditModal
                        onOK={() => {
                            this.loadData();
                            this.setState({editConfig: null})
                        }}
                        onClose={() => {
                            this.setState({editConfig: null})
                        }}
                        data={this.state.editConfig}
                    />
                </Box>
            </PageContent>
        );
    }
}

PaiBan.propTypes = {};


const mapStateToProps = state => ({
    staff: state.staff
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PaiBan));


