import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {postStaff} from "../../lib/api_staff";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Radio, Checkbox } from 'antd';
import md5 from 'md5';
import TimeInput from './time-input';
import JiaoLianCheck from './jiaolian_check';
import {postPaiBanAuto} from "../../lib/api_paiban_auto";
import WeekSelect from './week-select';

class PaiBanAddModal extends Component {

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                postPaiBanAuto(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        this.handleOK();
                    })
                // postStaff({...values, password: md5(values.password)})
                //     .then(result => {
                //         console.log(result);
                //         // 刷新数据
                //         this.handleOK();
                //     })
            }
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };

        return (
            <Modal
                title="添加排班计划"
                visible={this.props.visible}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                destroyOnClose={true}
                width={800}
            >
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <Form.Item label="工作日">
                        {getFieldDecorator("weekdays", {
                            rules:[{
                                required: true,
                                message: '请选择工作日',
                            }]
                        })(
                            <WeekSelect />
                        )}
                    </Form.Item>

                    <Form.Item label="时间段1">
                        {getFieldDecorator("time1", {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入时间段',
                                },
                            ]
                        })(<TimeInput/>)}
                    </Form.Item>

                    <Form.Item label="时间段2">
                        {getFieldDecorator("time2", {})(<TimeInput/>)}
                    </Form.Item>
                    <Form.Item label="时间段3">
                        {getFieldDecorator("time3", {})(<TimeInput/>)}
                    </Form.Item>
                    <Form.Item label="教练">
                        {getFieldDecorator("user_ids", {
                            rules: [
                                {
                                    required: true,
                                    message: '请选择教练',
                                },
                            ]
                        })(<JiaoLianCheck/>)}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

PaiBanAddModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};

export default Form.create({name: 'staff-add'})(PaiBanAddModal);
