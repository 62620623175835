import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    message,
    Descriptions,
    Avatar,
    Button,
    Space,
    Table,
    Popconfirm,
    Drawer,
    Row,
    Col,
    Divider,
    Typography
} from 'antd';
import {getStaffDetail, postStaffCoursesFee, deleteStaffCoursesFee} from "../../../lib/api_staff";
import StaffResetModal from './staff2-reset-modal';
import StaffEditModal from './staff2-edit-modal';
import CoursesFeeSelectModal from '../courses-fee/courses-fee-select-modal';
import DictName from '../../../components/dict/dict-name';
import {hideStaffDetailDrawer} from "../../../reducers/staff_detail_drawer";
import styles from './staff_detail_drawer.module.css';
import LevelName from "../../members/level-name";
import UsersCardsStatus from "../../members/users_cards/users_cards_status";

const StaffDetailDrawer = ({staffId, hideStaffDetailDrawer, menus}) => {
    const [data, setData] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [editUser, seteditUser] = useState(null);
    const [resetUser, setresetUser] = useState(null);
    const loadData = () => {
        if (!staffId) {
            setData(null);
            return;
        }
        getStaffDetail(staffId)
            .then((result) => {
                if (result.code === 0) {
                    setData(result.data);
                }
            })
    };

    useEffect(loadData, [staffId]);

    const postCousesFee = (ids) => {
        postStaffCoursesFee(staffId, ids)
            .then(res => {
                if (res.code === 0) {
                    loadData();
                    message.success("保存成功!")
                }
            })
    };

    const handleDeleteCoursesFee = (id) => {
        deleteStaffCoursesFee(staffId, id)
            .then(res => {
                if (res.code === 0) {
                    loadData();
                    message.success("移除成功!")
                }
            })
    };
    return !data ? null : (
        <Drawer width={900}
                visible={!!staffId}
                placement="right"
                onClose={() => hideStaffDetailDrawer()}
                title="员工详情"
                extra={<Space>
                    {menus.some(menu => menu.resource_key === "yggl_xg") && <>
                        <Button onClick={() => {
                            seteditUser(data);
                        }}>修改</Button>
                        <Button onClick={() => {
                            setresetUser(data);
                        }}>重置密码</Button>
                    </>
                    }
                </Space>}
        >
            <Row align="middle">
                <Col flex="100px">
                    <Avatar size={72} src={data.avatar}/>
                </Col>
                <Col flex="auto">
                    <Space style={{fontSize: 16, fontWeight: 600}}>
                        <span>{data.name}</span>
                        <span>{data.alias}</span>
                    </Space>
                    <div style={{marginTop: 9}}>
                        工号：{data.gh}
                    </div>
                </Col>
            </Row>
            <Divider style={{margin: "16px 0"}}/>
            <div className={styles.panel}>
                <div className={styles.panelTitle}>
                    基本信息
                    <span className={styles.subTitle}>企业微信账号：{data.userid}</span>
                </div>
                <Descriptions>
                    <Descriptions.Item label="姓名">{data.name}</Descriptions.Item>
                    <Descriptions.Item label="性别">{data.gender === 1 ? "男" : "女"}</Descriptions.Item>
                    <Descriptions.Item label="绑定手机号">{data.mobile}</Descriptions.Item>
                    <Descriptions.Item label="部门">{data.main_department}</Descriptions.Item>
                    <Descriptions.Item label="职位">{data.position}</Descriptions.Item>
                    {/*<Descriptions.Item label="教练级别">{data.staff_order_by}</Descriptions.Item>*/}
                    <Descriptions.Item label="邮箱">{data.email}</Descriptions.Item>
                    <Descriptions.Item label="状态">{data.enable === 1 ? "启用" : "已禁用"}</Descriptions.Item>
                    {/*<Descriptions.Item label="状态">{data.status}</Descriptions.Item>*/}
                </Descriptions>
            </div>
            <Divider style={{margin: "16px 0"}}/>
            <div className={styles.panel}>
                <div className={styles.panelTitle}>
                    其他信息
                </div>
                <Descriptions>
                    {data.extattr && JSON.parse(data.extattr).attrs.map(_ => {
                        if (_.type === 0) {
                            return <Descriptions.Item label={_.name}>{_.text.value}</Descriptions.Item>
                        } else if (_.type === 1) {
                            return <Descriptions.Item label={_.name}><a
                                href={_.web.url}>{_.web.title}</a></Descriptions.Item>
                        } else {
                            return null;
                        }
                    })}
                </Descriptions>
            </div>
            <Divider style={{margin: "16px 0"}}/>
            <div className={styles.panel}>
                <div className={styles.panelTitleContainer}>
                    <div className={styles.panelTitle}>
                        课程类型
                    </div>
                    <div className={styles.extra}>
                        {menus.some(menu => menu.resource_key === "yggl_xg") && <>
                            <Button type={"primary"} onClick={() => setShowAddModal(true)}>添加</Button>
                        </>
                        }
                    </div>
                </div>
                <Table
                    dataSource={data.courses_fees}
                    pagination={false}
                    size={"small"}
                    columns={[
                        {
                            title: "课程",
                            dataIndex: "course_type",
                            key: "课程",
                            render: (value) => <DictName type_value={"course_type"} value={value}/>
                        },
                        {
                            title: "教师级别",
                            dataIndex: "coach_level",
                            key: "教师级别",
                            render: (value) => <DictName type_value={"coach_level"} value={value}/>
                        },
                        {
                            title: "授课方式",
                            dataIndex: "teaching_method",
                            key: "授课方式",
                            render: (value) => <DictName type_value={"teaching_method"} value={value}/>
                        },
                        {
                            title: "教练费",
                            dataIndex: "fee",
                            key: "教练费",
                            render: (value) => parseFloat(value).toFixed(2)
                        },
                        {
                            title: "操作",
                            key: "操作",
                            render: (record) => {
                                if(menus.some(menu => menu.resource_key === "yggl_xg")){
                                    return (
                                        <Popconfirm
                                            title="确定要删除这个设置吗？"
                                            onConfirm={() => handleDeleteCoursesFee(record.id)}
                                            // onCancel={cancel}
                                            // okText="Yes"
                                            // cancelText="No"
                                        >
                                            <Typography.Text type="danger"
                                                             style={{cursor: "pointer"}}>移除</Typography.Text>
                                        </Popconfirm>
                                    )
                                }else {
                                    return null
                                }

                            }
                        },
                    ]}
                />
            </div>
            <Divider style={{margin: "16px 0"}}/>
            <div className={styles.panel}>
                <div className={styles.panelTitleContainer}>
                    <div className={styles.panelTitle}>
                        学员({data.users.length}位)
                    </div>
                </div>
                <Table
                    dataSource={data.users}
                    pagination={false}
                    size={"small"}
                    columns={[
                        {
                            title: "会籍号",
                            dataIndex: "membership_member_number",
                            render: (text, record) => (
                                <Link to={`/member/member_ship/${record.membership_id}`}
                                      onClick={hideStaffDetailDrawer}
                                >
                                    {text}
                                </Link>
                            )
                        }, {
                            title: "会籍类型",
                            dataIndex: "membership_huiji",
                        }, {
                            title: "昵称",
                            dataIndex: "nick_name",
                            // /member/members/806
                            render: (text, record) => (
                                <Link to={`/member/members/${record.id}`}
                                      onClick={hideStaffDetailDrawer}
                                >
                                    {text}
                                </Link>
                            )
                        },
                        {
                            title: "级别",
                            dataIndex: "level",
                            render: (text, record) => <LevelName value={text}/>
                        },
                        {
                            title: "截止有效期",
                            dataIndex: "membership_end_time",
                        },
                        {
                            title: "状态",
                            dataIndex: "status",
                            defaultSortOrder: 'ascend',
                            sorter: (a, b) => a.status - b.status,
                            render: (status) => <UsersCardsStatus status={status}/>
                        },

                    ]}
                />
            </div>

            <Divider style={{margin: "16px 0"}}/>
            <div className={styles.panel}>
                <div className={styles.panelTitleContainer}>
                    <div className={styles.panelTitle}>
                        责任会籍({data.users2.length}位)
                    </div>
                </div>
                <Table
                    dataSource={data.users2}
                    pagination={false}
                    size={"small"}
                    columns={[
                        {
                            title: "会籍号",
                            dataIndex: "membership_member_number",
                            render: (text, record) => (
                                <Link to={`/member/member_ship/${record.membership_id}`}
                                      onClick={hideStaffDetailDrawer}
                                >
                                    {text}
                                </Link>
                            )
                        }, {
                            title: "会籍类型",
                            dataIndex: "membership_huiji",
                        }, {
                            title: "昵称",
                            dataIndex: "nick_name",
                            // /member/members/806
                            render: (text, record) => (
                                <Link to={`/member/members/${record.id}`}
                                      onClick={hideStaffDetailDrawer}
                                >
                                    {text}
                                </Link>
                            )
                        },
                        {
                            title: "级别",
                            dataIndex: "level",
                            render: (text, record) => <LevelName value={text}/>
                        },
                        {
                            title: "截止有效期",
                            dataIndex: "membership_end_time",
                        },
                        {
                            title: "状态",
                            dataIndex: "status",
                            defaultSortOrder: 'ascend',
                            sorter: (a, b) => a.status - b.status,
                            render: (status) => <UsersCardsStatus status={status}/>
                        },

                    ]}
                />
            </div>


            <StaffResetModal
                onOK={() => setresetUser(null)}
                onClose={() => setresetUser(null)}
                user_info={resetUser}
            />
            <StaffEditModal
                onOK={() => {
                    loadData();
                    seteditUser(null);
                }}
                onClose={() => {
                    seteditUser(null);
                }}
                user_info={editUser}
            />
            <CoursesFeeSelectModal
                visible={showAddModal}
                onOk={(ids) => {
                    setShowAddModal(false);
                    // 保存数据
                    postCousesFee(ids)
                }}
                onCancel={() => {
                    setShowAddModal(false);
                }}
            />
        </Drawer>
    )
};

StaffDetailDrawer.propTypes = {};

export default withRouter(connect(
    state => ({
        staffId: state.staff_id,
        menus: state.menus
    }),
    dispatch => ({
        hideStaffDetailDrawer: () => dispatch(hideStaffDetailDrawer()),
    })
)(StaffDetailDrawer));



