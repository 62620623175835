import React from 'react';
import moment from 'moment';
import {Tooltip} from 'antd';

const MemberEndSpan = ({date})=>{
    if (date) {
        let days = moment(date).diff(moment(), 'days');
        if (days < 30) {
            return <Tooltip title="过期"><span style={{color: "red"}}>{moment(date).format("YYYY-MM-DD")}</span></Tooltip>
        }
        if (days < 90) {
            return <Tooltip title={`剩余${days}天`}><span style={{color: "orange"}}>{moment(date).format("YYYY-MM-DD")}</span></Tooltip>
        }
        // 计算剩余天数
        return <Tooltip title={`剩余${days}天`}>{moment(date).format("YYYY-MM-DD")}</Tooltip>;
    }
    return null
};

export default MemberEndSpan;