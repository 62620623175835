const SET_USERS_CARDS = 'users_cards/set_users_cards';

const initialState = [];

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_USERS_CARDS:
            // console.log("SET_STAFF", {...action.staff});
            return [...action.data];
        default:
            return state;
    }
};

const setUsersCards = function (arr) {
    return {
        type: SET_USERS_CARDS,
        data: arr
    }
};


export {
    reducer as default,
    initialState as initialUsersCardsState,
    setUsersCards,
}