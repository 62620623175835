import axios from './rest-axios';

const getAllLevels = () => axios.get(`/manage/levels/`);
const postLevel = (model) => axios.post(`/manage/levels/`, model);
const putLevel = (model) => axios.put(`/manage/levels/${model.id}`, model);
const deleteLevel = (id) => axios.delete(`/manage/levels/${id}`);

export {
    getAllLevels,
    postLevel,
    putLevel,
    deleteLevel,
};
