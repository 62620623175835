import React, {useEffect} from 'react';
import {Modal, DatePicker, Form, message, Col, Input, Radio, Row} from 'antd';
import TeacherSelect from "../teacher-select";
import DictRadioGroup from "../../../components/dict/dict-radio-group";
import {postMembers, putMember} from "../../../lib/api_members";
import LevelSelect from "../level-select";
import {getMember2} from "../../../lib/api_members";
import moment from "moment";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


const MemberEditModal = ({visible, id, onOk, onCancel}) => {
    const [form] = Form.useForm();

    /**
     * 获取用户信息
     */
    const fetchData = () => {
        getMember2(id)
            .then(res => {
                if (res.code === 0) {
                    form.setFieldsValue({
                        nick_name: res.data.nick_name,
                        other_name: res.data.other_name,
                        sex: res.data.sex,
                        birthday: moment(res.data.birthday),
                        teacher: res.data.teacher,
                        consultant: res.data.consultant,
                        train_type: res.data.train_type,
                        tag1: res.data.tag1,
                        tag2: res.data.tag2,
                        level: res.data.level,
                    });
                }
            })
    }

    useEffect(fetchData, []);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log(values);

                putMember({
                    id: id,
                    nick_name: values.nick_name,
                    other_name: values.other_name,
                    sex: values.sex,
                    birthday: moment(values.birthday).format("YYYY-MM-DD"),
                    teacher: values.teacher,
                    consultant: values.consultant,
                    train_type: values.train_type,
                    tag1: values.tag1,
                    tag2: values.tag2,
                    level: values.level,
                })
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            message.success("修改成功").then(() => {
                            })
                            onOk && onOk()
                        }
                    })
            })
    };

    return (
        <Modal
            title={`修改会员信息`}
            visible={visible}
            onOk={handleSubmit}
            onCancel={onCancel}
            destroyOnClose={true}
            width={1000}
        >
            <Form
                {...layout}
                form={form}
                initialValues={{
                    sex: 1,
                    tag1: "",
                    tag2: "",
                }}
            >
                <Row gutter={0}>
                    <Col span={8}>
                        <Form.Item
                            label="会员姓名"
                            name="nick_name"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入姓名',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="会员昵称"
                            name="other_name"
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="会员性别"
                            name="sex"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择性别',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value={1}>男</Radio>
                                <Radio value={2}>女</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="出生年月"
                            name="birthday"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择出生年月',
                                },
                            ]}
                        >
                            <DatePicker/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="专属教练"
                            name="teacher"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择教练',
                                },
                            ]}
                        >
                            <TeacherSelect isCoach={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="课程顾问"
                            name="consultant"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择课程顾问',
                                },
                            ]}
                        >
                            <TeacherSelect isCoach={false}/>
                        </Form.Item>
                    </Col>


                    <Col span={8}>

                        <Form.Item
                            label="训练类型"
                            name="train_type"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择训练类型',
                                },
                            ]}
                        >
                            <DictRadioGroup type_value={"train_type"}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="标记1"
                            name="tag1"
                        >
                            <DictRadioGroup type_value={"member-tag1"}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="标记2"
                            name="tag2"
                        >
                            <DictRadioGroup type_value={"member-tag2"}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="学员级别"
                            name="level"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择学员级别',
                                },
                            ]}
                        >
                            <LevelSelect/>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    )
};

export default MemberEditModal;
