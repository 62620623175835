import React, {useState, useEffect} from 'react';
import {Card, Button, Space, Divider, Popconfirm, Table} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import SysDictAddModal from './courses-fee-add-modal';
import SysDictEditModal from './courses-fee-edit-modal';
import {PlusOutlined} from '@ant-design/icons';
import DictName from '../../../components/dict/dict-name';
import {deleteCoursesFee, getCoursesFee} from "../../../lib/api_courses_fee";

const SysDict = ({match}) => {
    const [list, setList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [editdata, setEditData] = useState(null);

    const handleDelete = (id) => {
        deleteCoursesFee(id)
            .then(res => {
                if (res.code === 0) {
                    loadData()
                }
            })
    };

    const loadData = ()=>{
        getCoursesFee()
            .then (res=>{
                if(res.code === 0){
                    setList(res.data);
                }
            })
    };
    useEffect(loadData, []);

    return (
        <PageContent>
            <Card bordered={false}>
                <Button
                    type="primary"
                    icon={<PlusOutlined/>}
                    onClick={() => setVisible(true)}
                >添加</Button>
                <Table
                    // title="课程设置"
                    size="small"
                    pagination={false}
                    dataSource={list}
                    columns={[{
                        title: '显示名称',
                        dataIndex: 'display_name',
                    },{
                        title: '课程类型',
                        dataIndex: 'course_type',
                        sorter: (a, b)=> a.course_type-b.course_type,
                        width: 100,
                        render: (value) => <DictName type_value={"course_type"} value={value}/>
                    }, {
                        title: '教师级别',
                        dataIndex: 'coach_level',
                        sorter: (a, b)=> a.coach_level-b.coach_level,
                        width: 100,
                        render: (value) => <DictName type_value={"coach_level"} value={value}/>
                    }, {
                        title: '授课方式',
                        dataIndex: 'teaching_method',
                        width: 100,
                        render: (value) => <DictName type_value={"teaching_method"} value={value}/>
                    }, {
                        title: '客户教练费',
                        dataIndex: 'fee',
                        align: "right",
                        width: 100,
                        render: (value) => parseFloat(value).toFixed(2)
                    }, {
                        title: '操作',
                        fixed: 'right',
                        render: (record) => (<Space split={<Divider type="vertical"/>}>
                            <a onClick={() => {
                                setEditData(record);
                            }}>
                                修改
                            </a>
                            <Popconfirm
                                title="确定要删除这个设置吗？"
                                onConfirm={() => handleDelete(record.id)}
                                // onCancel={cancel}
                                // okText="Yes"
                                // cancelText="No"
                            >
                                <a>
                                    删除
                                </a>
                            </Popconfirm>
                            {/*<a onClick={() => {*/}
                            {/*setdetaildata(record)*/}
                            {/*}}>*/}
                            {/*授权*/}
                            {/*</a>*/}
                        </Space>)
                    }]}/>
            </Card>
            <SysDictAddModal
                visible={visible}
                onOk={() => {
                    setVisible(false);
                    loadData()
                }}
                onCancel={() => {
                    setVisible(false)
                }}
                {...match.params}
            />
            {editdata &&
            <SysDictEditModal
                visible={!!editdata}
                data={editdata}
                onOk={() => {
                    setEditData(null);
                    loadData()
                }}
                onCancel={() => {
                    setEditData(null);
                }}
                {...match.params}
            />
            }
        </PageContent>
    )
};


export default SysDict;


