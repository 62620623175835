import axios from './rest-axios';

const getCWSRYCJiDuAll = (year) => axios.get('/manage/cw_sryc_jidu/', {year});
const getCWSRYCJiDuById = (id) => axios.get(`/manage/cw_sryc_jidu/${id}`);


const createOrUpdateCWSRYCJiDu = (data) => axios.post('/manage/cw_sryc_jidu/', data);

export {
    getCWSRYCJiDuAll,
    getCWSRYCJiDuById,
    createOrUpdateCWSRYCJiDu
};
