import React, {useEffect, useState} from 'react';
import {getStablesById} from "../../lib/api_stables";
import {Card} from "antd";

const MaFangDetail = ({id}) => {

    const [data, setData] = useState(null);

    // 获取马房数据
    const fetchData = () => {
        getStablesById(id).then(res => {
            console.log(res)
            if (res.code === 0) {
                setData(res.data);
            }
        }).catch(err => {
            console.log(err);
        })

    }

    useEffect(fetchData, []);

    if (!data)
        return <div>loading...</div>;
    return (
        <div style={{backgroundColor: "#f5f5f5", padding: "20px"}}>
            <Card
                title={"马房编号"}
            >
                马房编号：{data.name}
            </Card>
            {data.horses.map(horse => {
                return (
                    <a href={`/h5/horse/${horse.id}`}>
                        <Card
                            title={horse.name}
                            style={{marginTop: 12}}
                        >
                            <p>中文名：{horse.name}</p>
                            <p>英文名：{horse.name}</p>
                            <p>权属：{horse.name}</p>
                            <p>教练：{horse.coach_name}</p>
                            <p>年龄：{horse.age}</p>
                            <p>颜色：{horse.color}</p>
                            <p>责任教练：{horse.coach_name}</p>
                        </Card>
                    </a>
                );
            })}
        </div>
    );
}

export default MaFangDetail;