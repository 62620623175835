import axios from './rest-axios';

const getHorses = () => axios.get(`/manage/horses`);
const getHorsesById = id => axios.get(`/manage/horses/${id}`);
const addHorse = horse => axios.post(`/manage/horses`, horse);
const updateHorse = horse => axios.put(`/manage/horses/${horse.id}`, horse);
const deleteHorse = id => axios.delete(`/manage/horses/${id}`);
const getHorseTasks = id => axios.get(`/manage/horses/${id}/tasks`);
const postHorseSYJH = model => axios.post(`/manage/horses/${model.horse_id}/syjh`, model);
const getHorseSYJH = id => axios.get(`/manage/horses/${id}/syjh`);
const deleteHorseSYJH = (id, syjhid) => axios.delete(`/manage/horses/${id}/syjh/${syjhid}`);
const getHorseYuyue = (id, page, pagesize) => axios.get(`/manage/horses/${id}/yuyue`, {page, pagesize});
const getHorseZt = (id) => axios.get(`/manage/horses/${id}/zt`, {});
const getHorseZtlasted = (id) => axios.get(`/manage/horses/${id}/zt/lasted`, {});
const postHorseZt = (id, model) => axios.post(`/manage/horses/${id}/zt`, model);
const deleteHorseZt = (id, ztid) => axios.delete(`/manage/horses/${id}/zt/${ztid}`);

export {
    getHorses,
    getHorsesById,
    addHorse,
    updateHorse,
    deleteHorse,
    getHorseTasks,
    postHorseSYJH,
    getHorseSYJH,
    deleteHorseSYJH,
    getHorseYuyue,
    getHorseZt,
    postHorseZt,
    deleteHorseZt,
    getHorseZtlasted,
}