import React, {Component, useEffect} from 'react';
import {Modal, Form, Input, Select, message} from 'antd';
import {getStablesById, updateStable} from "../../../lib/api_stables";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};


const StablesEditModal = ({id, onCancel, onOk}) => {
    const [form] = Form.useForm();

    const fetchData = () => {
        getStablesById(id)
            .then(res => {
                if (res.code === 0) {
                    form.setFieldsValue(res.data);
                } else {
                    message.error(res.message);
                    onCancel && onCancel();
                }
            })
    }

    useEffect(fetchData, []);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                values.id = id;
                updateStable(values)
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk();
                        }
                    })
            })
    };

    const handleCancel = () => {
        onCancel && onCancel();
    };

    return (
        <Modal
            title="修改马匹信息"
            visible={true}
            onOk={handleSubmit}
            onCancel={handleCancel}
            destroyOnClose={true}
        >
            <Form
                form={form}
                {...formItemLayout}
                initialValues={{}}
            >
                <Form.Item
                    label="编号"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: '请输入编号',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="视频监控编码"
                    name="camera"
                    rules={[
                        {
                            required: true,
                            message: '请输入camera',
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default StablesEditModal;
