import React, {useState, useEffect} from 'react';
import {Card, Table, Input, Select, TimePicker, Space, InputNumber} from 'antd';
import {getCoursesFee} from "../../../lib/api_courses_fee";
import DictName from '../../../components/dict/dict-name';
import moment from 'moment';

const format = 'HH:mm';

const {Option} = Select;

const QuXiaoConfig = ({value, onChange}) => {
    const [list, setList] = useState([]);


    const loadData = () => {

    };
    useEffect(loadData, []);


    const handleChange = () => {
        onChange && onChange(value);
    };


    return (
        <Card bordered={false} title={"无责取消设置"}>
            <Space>
                每个季度可以无责取消预约
                <InputNumber
                    min={0}
                    defaultValue={(value && value.num) || 0}
                    onChange={(e) => {
                        // console.log(e)
                        if (!value) value = {};
                        value.num = e;
                        handleChange()
                    }}
                />次。

            </Space>
        </Card>
    )
};


export default QuXiaoConfig;


