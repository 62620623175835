const SET_STORES = 'stores/SET_STORES';

const initialState = [];

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_STORES:
            // console.log("SET_STAFF", {...action.staff});
            return [...action.stores];
        default:
            return state;
    }
};

const setStores = function (stores) {
    return {
        type: SET_STORES,
        stores: stores
    }
};


export {
    reducer as default,
    initialState as initialStoresState,
    setStores,
}