const SHOWSTAFFDETAILDRAWER = 'staff_detail_drawer/SHOW';
const HIDESTAFFDETAILDRAWER = 'staff_detail_drawer/HIDE';

const initialState = 0;

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    console.log(state, action);
    switch (action.type) {
        case SHOWSTAFFDETAILDRAWER:
            return action.staffId;
        case HIDESTAFFDETAILDRAWER:
            return 0;
        default:
            return state;
    }
};

const showStaffDetailDrawer = (id) => {
    return {
        type: SHOWSTAFFDETAILDRAWER,
        staffId: id
    }
};


const hideStaffDetailDrawer = function () {
    return {
        type: HIDESTAFFDETAILDRAWER
    }
};

export {
    reducer as default,
    initialState as initialStaffDetailDrawerState,
    showStaffDetailDrawer,
    hideStaffDetailDrawer
}