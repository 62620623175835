import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PageContent from '../../components/page-content/page-content';
import {Button, Table, DatePicker} from 'antd';
import {getStat4} from '../../lib/api_stat'
import Box from '../../components/box';
import moment from 'moment';
import excelUtil from '../../lib/excelUtil.js'
import JiaoAn2 from './jiaolian2'

const {Column} = Table;


// @hasAuth
class Staff extends Component {
    state = {
        date: moment(),
        list: [],
    };

    componentDidMount() {
        // console.log(this);
        // this.hasRole();
        this.loadData()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        let begin_time = this.state.date.format("YYYY-MM-01");
        let end_time = moment(this.state.date).endOf('month').format("YYYY-MM-DD")+" 23:59:59";
        getStat4(begin_time, end_time)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data
                    })
                    // this.props.setStaff(result.data);
                } else {

                }
            })
    };
    onChange = (date, dateString) => {
        console.log(date, dateString);
        this.setState({
            date: date
        }, () => {
            this.loadData()
        })
    };

    columns = [{
        title: '教练',
        dataIndex: '教练',
        key: '教练',
    }, {
        title: '时长',
        dataIndex: '时长',
        key: '时长',
    }, {
        title: '开始时间',
        dataIndex: '开始时间',
        key: '开始时间',
    }, {
        title: '结束时间',
        dataIndex: '结束时间',
        key: '结束时间',
    }, {
        title: '学员',
        dataIndex: '学员',
        key: '学员',
    }, {
        title: '手机',
        dataIndex: '手机',
        key: '手机',
    }, {
        title: '车牌',
        dataIndex: '车牌',
        key: '车牌',
    }, {
        title: '备注',
        dataIndex: '备注',
        key: '备注',
    }, {
        title: '客户类型',
        dataIndex: '客户类型',
        key: '客户类型',
    }];

    exportXlsx = () => {
        excelUtil.exportExcel(this.columns, this.state.list, `${this.state.date.format("M")}月赠课清单.xlsx`)
    };


    render() {
        let {list} = this.state;
        return (
            <Box>
                <DatePicker
                    allowClear={false}
                    onChange={this.onChange}
                    value={this.state.date}
                    picker="month"/>
                <Button onClick={this.exportXlsx}>导出</Button>
                <Table
                    dataSource={list}
                    rowKey={"教练"}
                    pagination={false}
                    size={"small"}
                >
                    <Column
                        title="教练"
                        dataIndex="教练"
                        key="教练"
                    />
                    <Column
                        title="时长"
                        dataIndex="时长"
                        key="时长"
                    />
                    <Column
                        title="开始时间"
                        dataIndex="开始时间"
                        key="开始时间"
                    />
                    <Column
                        title="结束时间"
                        dataIndex="结束时间"
                        key="结束时间"
                    />
                    <Column
                        title="学员"
                        dataIndex="学员"
                        key="学员"
                    />
                    <Column
                        title="手机"
                        dataIndex="手机"
                        key="手机"
                    />
                    <Column
                        title="车牌"
                        dataIndex="车牌"
                        key="车牌"
                    />
                    <Column
                        title="备注"
                        dataIndex="备注"
                        key="备注"
                    />
                    <Column
                        title="客户类型"
                        dataIndex="客户类型"
                        key="客户类型"
                    />

                </Table>

            </Box>


        );
    }
}


export default withRouter(Staff);



