import React, {useEffect, useState} from 'react';
import {Select, TreeSelect} from "antd";
import {getHorses} from "../../../lib/api_horses";
import {getMenus} from "../../../lib/api_menu";

const ResourceSelect = ({value, onChange}) => {
    const [list, setlist] = useState([]);

    const fetchData = () => {
        getMenus()
            .then(res => {
                if (res.code === 0) {
                    let list = [];
                    let item = {
                        title: "根节点",
                        value: 0,
                        children: buildTree(res.data, 0),
                    };
                    list.push(item);
                    setlist(list);
                }
            })
    }

    useEffect(fetchData, []);

    // 组装成树结构
    const buildTree = (data, pid) => {
        let list = [];
        let fdata = data.filter(item => item.pid === pid);
        for (let i = 0; i < fdata.length; i++) {
            let item = fdata[i];
            list.push({
                title: item.title,
                value: item.id,
                children: buildTree(data, item.id)
            });
        }
        if (list.length > 0)
            return list;
        else
            return null;
    }


    const handleChange = (value) => {
        onChange && onChange(value);
    }

    return (
        <TreeSelect
            showSearch
            style={{
                width: '100%',
            }}
            value={value}
            dropdownStyle={{
                maxHeight: 400,
                overflow: 'auto',
            }}
            placeholder="Please select"
            allowClear
            treeDefaultExpandAll
            onChange={handleChange}
            treeData={list}
        />
    );
}
export default ResourceSelect