import PropTypes from 'prop-types';
import React, {useRef} from 'react';
import {Table, Search, TableProvider, useTable} from 'table-render';
import {Button, Space} from 'antd';
// import request from 'umi-request';
import {getList} from "../../lib/api_data";


const DataTable = ({tableName, search, table, pageQuery}) => {
    const {changeTab} = useTable();

    // console.log("this.props", this);



    const searchApi = params => {
        // console.log("this.props", this);
        // console.log('params:', params);
        if(table.pagination === false){
            delete params.current;
            delete params.pageSize;
        }
        params = Object.assign({}, pageQuery, params);
        // console.log('params:', params);
        return getList(tableName, params)
            .then(res => {
                console.log('response:', res);
                if (res && res.data) {
                    return {
                        rows: res.data,
                        total: res.count
                    }; // 注意一定要返回 rows 和 total
                }
            })
            .catch(e => console.log('Oops, error', e));
    };

    const columns = table.columns || [];
    search = search || {
        hidden: true
    };
    const schema = search.schema || {
        type: 'object',
        properties: {}
    };

    const toolbarRender = () => {
        return table.toolbar || null;
    };

    const aaa = () => {
        return <Space/>
    };

    return (
        <div>
            <Search schema={schema}
                    api={searchApi} hidden={search.hidden}/>
            <Table
                headerTitle={table.headerTitle}
                columns={columns}
                size={table.size || "default"}
                rowKey="id"
                pagination={table.pagination }
                toolbarRender={toolbarRender}
                toolbarAction
                style={{
                    padding: 0
                }}
            />
        </div>
    );
};

DataTable.propTypes = {
    tableName: PropTypes.string,
    search: PropTypes.object,
    table: PropTypes.object,
    pageQuery: PropTypes.object
};
export default DataTable;
