import React, {useState, useEffect} from 'react';
import Box from "../../components/box";
import {Button, DatePicker, Space, Table} from "antd";
import moment from "moment";
import {getStatmapi} from "../../lib/api_stat";
import excelUtil from "../../lib/excelUtil";

const MaPi = () => {
    const [list, setList] = useState([]);
    const [date, setDate] = useState(moment());

    const fetchData = () => {
        console.log(date.format("YYYY-MM-DD"), date.startOf('week').format("YYYY-MM-DD"), date.endOf('week').format("YYYY-MM-DD"));
        getStatmapi(date.startOf('week').format("YYYY-MM-DD"), date.endOf('week').format("YYYY-MM-DD"))
            .then(res => {
                if (res.code === 0) {
                    setList(res.data);
                }
            })
    }

    useEffect(fetchData, [date]);

    const columns = [{
        title: '马匹',
        dataIndex: 'horse_name',
        key: 'horse_name',
    }, {
        title: '15分钟（次）',
        dataIndex: 'total_duration_over_15',
        key: 'total_duration_over_15',
    }, {
        title: '30分钟（次）',
        dataIndex: 'total_duration_over_30',
        key: 'total_duration_over_30',
    }, {
        title: '45分钟（次）',
        dataIndex: 'total_duration_over_45',
        key: 'total_duration_over_45',
    }, {
        title: '合计(分钟)',
        dataIndex: 'total_duration',
        key: 'total_duration',
    }];

    const exportXlsx = () => {
        excelUtil.exportExcel(columns, list, `马匹时长统计${date.startOf('week').format("YYYY-MM-DD")}-${date.endOf('week').format("YYYY-MM-DD")}.xlsx`)
    };


    return (
        <Box>
            <Space>
                <DatePicker
                    allowClear={false}
                    onChange={(date, datestring) => setDate(date)}
                    value={date}
                    picker="week"/>
                <Button onClick={exportXlsx}>导出</Button>
            </Space>
            <Table
                dataSource={list}
                rowKey={"horse_name"}
                pagination={false}
                size={"small"}
                columns={columns}
            />

        </Box>
    )
}

export default MaPi;