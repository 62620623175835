import React, {useState, useEffect} from 'react';
import {Collapse, Tabs,Row,Col} from "antd";
import {postMemberLevels} from "../../../lib/api_members";

const {Panel} = Collapse;

const MemberLevels = ({id}) => {
    const [items, setitems] = useState([]);

    const fetchData = () => {
        postMemberLevels(id)
            .then((res) => {
                if (res.code === 0) {
                    setitems(res.data);
                }
            })
    }

    useEffect(fetchData, []);

    return (
        <Tabs>
            {items.map(level1 =>
                <Tabs.TabPane tab={`${level1.name} ${level1.sld}/${level1.sld_total}`} key={level1.id}>
                    <Collapse ghost>
                        {level1.children.map(level2 =>
                            <Panel header={`${level2.name} ${level2.sld}/${level2.sld_total}`} key={level2.id}>
                                <Collapse ghost>
                                    {level2.children.map(level3 =>
                                        <Panel header={`${level3.name} ${level3.sld}/${level3.sld_total}`} key={level3.id}>
                                            {level3.children.map(level4 => <Row justify="space-between">
                                                <Col>{level4.name}</Col>
                                                <Col>熟练度：{level4.sld}/{level4.sld_total}</Col>
                                            </Row>)}
                                        </Panel>
                                    )}
                                </Collapse>
                            </Panel>
                        )}
                    </Collapse>
                </Tabs.TabPane>
            )}

        </Tabs>
    );
}

export default MemberLevels;