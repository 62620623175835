import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {Button, Col, DatePicker, Form, Input, Popconfirm, Row, Select, Space, Table, Typography} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import Box from '../../../components/box';
import {getcwsrdjAll, deletecwsrdj} from "../../../lib/api_cw_srdj";
import SrdjAddModal from "./srdj_add_modal";
import SrdjEditModal from "./srdj_edit_modal";
import excelUtil from "../../../lib/excelUtil";
import moment from "moment/moment";
import ExcelJS from "exceljs";
import {saveAs} from 'file-saver';
import CoaSelect from "./coa_select";
import useUrlState from '@ahooksjs/use-url-state';


const {Column} = Table;
const {RangePicker} = DatePicker;


const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};


const Srdj = (props) => {
    const [data, setData] = useState([]);
    const [addmodal, setaddmodal] = useState(false);
    const [editmodal, seteditmodal] = useState(0);
    const [form] = Form.useForm();
    const [state, setState] = useUrlState({
        begin: moment().subtract(1, 'months').format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
    });


    const fetchData = () => {
        getcwsrdjAll(state)
            .then((res) => {
                if (res.code === 0) {
                    setData(res.data);
                }
            })
    }

    useEffect(fetchData, [state]);

    const deleteSrdj = (id) => {
        deletecwsrdj(id).then((res) => {
            if (res.code === 0) {
                fetchData();
            }
        })
    }
    //
    // // 递归将树结构转成列表
    // const treeToList = (worksheet, tree, indent) => {
    //     // 判断tree是否为空
    //     if (!tree) {
    //         return [];
    //     }
    //     const list = [];
    //
    //     tree.forEach(item => {
    //         const rowCount = worksheet.rowCount;
    //         const row1 = worksheet.getRow(rowCount + 1);
    //         // row1.height = 20;
    //         row1.getCell(1).value = item.name;
    //         row1.getCell(2).value = item.department||"";
    //         row1.getCell(1).border = {
    //             top: {style: 'thin'},
    //             left: {style: 'thin'},
    //             bot: {style: 'thin'},
    //             right: {style: 'thin'}
    //         }
    //         row1.getCell(2).border = {
    //             top: {style: 'thin'},
    //             left: {style: 'thin'},
    //             bot: {style: 'thin'},
    //             right: {style: 'thin'}
    //         }
    //         // 缩进
    //         if (indent) {
    //             row1.getCell(1).alignment = {
    //                 horizontal: 'left',
    //                 indent: indent
    //             }
    //         }
    //
    //         // 递归调用，将子节点转成列表
    //         if (item.children && item.children.length) {
    //            treeToList(worksheet, item.children, indent + 1);
    //         }
    //     });
    //
    //     return list;
    // }
    //
    // const exportXlsx = async () => {
    //
    //     // let data1 = treeToList(data);
    //
    //     // 导出excel
    //     const workbook = new ExcelJS.Workbook();
    //     for (let i = 0; i < data.length; i++) {
    //         const worksheet = workbook.addWorksheet(data[i].name);
    //         worksheet.columns = [ // 设置工作表的列信息
    //             {header: '名称', key: 'name', width: 32},
    //             {header: '部门', key: 'department', width: 20},
    //         ]
    //         // 设置标题行的边框样式
    //         const row1 = worksheet.getRow(1);
    //         row1.height = 20;
    //         worksheet.getRow(1).eachCell((cell) => {
    //             cell.border = {
    //                 top: {style: 'thin'},
    //                 left: {style: 'thin'},
    //                 bottom: {style: 'thin'},
    //                 right: {style: 'thin'}
    //             };
    //             cell.font = {
    //                 name: "黑体",
    //                 bold: true,
    //                 color: {
    //                     // 注意：在 exceljs 中所有的的颜色值均为 argb 格式，且不带 # 符号
    //                     // argb: "ffffff",
    //                 },
    //             }
    //             // cell.fill = {
    //             //     type: "pattern",
    //             //     pattern: "solid",
    //             //     fgColor: {
    //             //         argb: "808080",
    //             //     },
    //             // }
    //         });
    //         // 设置标题行的居中对齐
    //         worksheet.getRow(1).eachCell((cell) => {
    //             cell.alignment = {horizontal: 'center', vertical: 'middle'};
    //         });
    //
    //         const data1 = treeToList(worksheet, data[i].children, 0);
    //
    //         // 将数据添加到工作表中
    //         worksheet.addRows(data1);
    //
    //     }
    //     //将data插入 工作表，并按照级别进行缩进
    //     const buffer = await workbook.xlsx.writeBuffer()
    //     const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    //     saveAs(blob, `财务科目表_${moment().format("YYYYMMDDHHmmss")}.xlsx`)
    //
    //
    //     // let columns = [
    //     //     {title: '名称', dataIndex: 'name', key: 'name',},
    //     //     {title: '部门', dataIndex: 'department', key: 'department',},
    //     // ]
    //     // excelUtil.exportExcel(columns, data1, `财务科目表_${moment().format("YYYYMMDDHHmmss")}.xlsx`)
    // };


    return (
        <PageContent>
            <Box title={"筛选"}>
                <Form
                    {...formItemLayout}
                    form={form}
                    initialValues={{
                        md: state.md,
                        date: [moment(state.begin, "YYYY-MM-DD"), moment(state.end, "YYYY-MM-DD")],
                        coa_id: state.coa_id,
                        bz: state.bz,
                    }}
                    onFinish={(values) => {
                        console.log(values)
                        setState({
                            md: values.md,
                            begin: values.date[0].format("YYYY-MM-DD"),
                            end: values.date[1].format("YYYY-MM-DD"),
                            coa_id: values.coa_id,
                            bz: values.bz,
                        })
                    }}
                >
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                label="门店"
                                name="md"
                            >
                                <Select allowClear showSearch
                                    // value={state.md}
                                    // onChange={(value) => {
                                    //     setState({md: value})
                                    // }}
                                        options={[
                                            {label: '门店1', value: '门店1'},
                                            {label: '门店2', value: '门店2'},
                                        ]}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="日期"
                                name="date"
                            >
                                <RangePicker allowClear={false}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="科目"
                                name="coa_id"
                            >
                                <CoaSelect/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="备注"
                                name="bz"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{textAlign: "right"}}>
                            <Button type='primary' htmlType={"submit"}>搜索</Button>
                        </Col>
                    </Row>
                </Form>
            </Box>
            <Box title={"财务收入登记"}
                 extra={
                     <Space>
                         <Button type='primary' onClick={() => setaddmodal(true)}>添加</Button>
                         {/*<Button type='primary' onClick={exportXlsx}>导出</Button>*/}
                     </Space>
                 }
                 style={{marginBottom: 20, marginTop: 12}}
            >
                {data.length > 0 &&
                    <Table
                        dataSource={data}
                        rowKey={"id"}
                        pagination={false}
                        size={"small"}
                    >
                        {/*自动编号*/}
                        <Column title="编号" dataIndex="id" key="编号"
                                render={(text, record, index) => {
                                    return (
                                        <Space>
                                            {index + 1}
                                        </Space>
                                    )
                                }}
                        />
                        <Column title="门店" dataIndex="md" key="门店"/>
                        <Column title="日期" dataIndex="date" key="日期"/>
                        <Column title="科目" dataIndex="coa" key="科目"/>
                        <Column title="金额" dataIndex="je" key="金额"/>
                        <Column title="备注" dataIndex="bz" key="备注"/>
                        <Column title="操作" dataIndex="id" key="操作"
                                width={150}
                                render={(text, record, index) => {
                                    return (
                                        <Space>
                                            {/*<Typography.Link*/}
                                            {/*    onClick={() => setaddmodal(record.id)}>添加子项</Typography.Link>*/}
                                            <Typography.Link
                                                onClick={() => seteditmodal(record.id)}>修改</Typography.Link>
                                            <Popconfirm
                                                title="确定删除吗?"
                                                onConfirm={() => {
                                                    deleteSrdj(record.id);
                                                }}
                                                okText="确定"
                                                cancelText="取消"
                                            >
                                                <Typography.Link>删除</Typography.Link>
                                            </Popconfirm>
                                        </Space>
                                    )
                                }}
                        />
                    </Table>
                }
            </Box>

            {addmodal > 0 && <SrdjAddModal
                onOk={() => {
                    setaddmodal(false);
                    fetchData();
                }}
                onCancel={() => {
                    setaddmodal(false);
                }}
            />}
            {editmodal > 0 && <SrdjEditModal
                id={editmodal}
                onOk={() => {
                    seteditmodal(0);
                    fetchData();
                }}
                onCancel={() => {
                    seteditmodal(0);
                }}
            />}
        </PageContent>
    )
}

export default withRouter(Srdj);