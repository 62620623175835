
import React from 'react';

const UsersCardsStatus = ({status}) => {
    if(status === 0) return <span class="text-green">正常</span>;
    if(status === 1) return <span class="text-red">失效</span>;
    return <span>未知</span>;
};

export default UsersCardsStatus;
