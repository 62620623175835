import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {getUsersCards} from "../../../lib/api_users_cards";
import PageContent from '../../../components/page-content/page-content';
import {Card, Button, Space, Divider, Popconfirm, Table} from 'antd';
import moment from 'moment';
import CardDetailCiKaModal from '../members/card-detail-cika-modal';
import Box from '../../../components/box';
import MemberEndSpan from '../members/member_end_span';
import UsersCardsStatus from './users_cards_status';
import UsersCardsShiXiaoModal from '../users_cards/users_cards_shixiao_modal';


const {Column} = Table;


const UsersCards = ({match}) => {
    const [list, setList] = useState([]);
    const [cikadetail, setcikadetail] = useState(null);
    const [shixiao, setshixiao] = useState(null);

    const loadData = () => {
        getUsersCards()
            .then(res => {
                if (res.code === 0) {
                    setList(res.data);
                }
            })
    };
    useEffect(loadData, []);


    const rowClassName = (record, index) => {
        if (record.status === 1) {
            return "text-gray"
        }
    };


    return (
        <PageContent>
            <Box bordered={false}>
                <Table
                    // title="课程设置"
                    size="small"
                    // pagination={false}
                    pagination={{
                        position: ["topRight", "bottomRight"],
                        defaultPageSize: 20
                    }}
                    dataSource={list}
                    rowKey={"id"}
                    rowClassName={rowClassName}
                    scroll={{
                        x: 1500
                    }}
                    sticky
                >
                    <Column
                        title="会籍"
                        key="会籍"
                        dataIndex={["membership","name"]}
                        fixed='left'
                        render={(name, record) => <Link
                            to={`/member/member_ship/${record.membership_id}`}>{name}</Link>}
                    />
                    <Column
                        title="卡片名称"
                        key="卡片名称"
                        dataIndex="name"
                        fixed='left'
                        render={(name, record) => <a onClick={() => setcikadetail(record.id)}>{name}</a>}
                    />
                    <Column
                        title="原课时"
                        key="原课时"
                        width={60}
                        dataIndex="keshi_original"
                    />
                    <Column
                        title="剩余课时"
                        key="剩余课时"
                        width={80}
                        dataIndex="keshi_remaining"
                        render={(keshi_remaining)=>{
                            if(keshi_remaining > 0){
                                return <span class="text-red">{keshi_remaining}</span>
                            }
                            return keshi_remaining;
                        }}
                    />
                    <Column
                        title="开始时间"
                        key="开始时间"
                        dataIndex="begin_time"
                        width={120}
                        render={(begin_time) => moment(begin_time).format("YYYY-MM-DD")}
                    />
                    <Column
                        title="结束时间"
                        key="结束时间"
                        dataIndex="end_time"
                        width={120}
                        render={(end_time) => <MemberEndSpan date={end_time} />}
                    />
                    <Column
                        title="状态"
                        key="状态"
                        width={70}
                        dataIndex="status"
                        filters={[{text: "正常", value: 0}, {text: "失效", value: 1}]}
                        onFilter={(value, record) => record.status === value}
                        render={(status) => <UsersCardsStatus status={status} />}
                    />
                    <Column
                        title="支付金额"
                        key="支付金额"
                        dataIndex="payments"
                        align="right"
                        render={(payments) => payments.toFixed(2)}
                    />
                    <Column
                        title="剩余金额"
                        key="剩余金额"
                        dataIndex="payments_remaining"
                        align="right"
                        render={(payments) => payments.toFixed(2)}
                    />
                    <Column
                        title="平均每课时价格"
                        key="平均每课时价格"
                        dataIndex="payments_avg"
                        align="right"
                        render={(payments) => payments.toFixed(2)}
                    />
                    <Column
                        title="总赠送课时"
                        key="总赠送课时"
                        dataIndex="keshi_free_original"
                    />
                    <Column
                        title="剩余赠送课时"
                        key="剩余赠送课时"
                        dataIndex="keshi_free_remaining"
                    />
                    <Column
                        title="操作"
                        key="操作"
                        fixed='right'
                        render={(record) => {
                            return <Button type="danger" ghost onClick={() => setshixiao(record)}
                                           disabled={record.status === 1}>失效</Button>;
                        }}
                    />
                </Table>
            </Box>
            {cikadetail &&
            <CardDetailCiKaModal
                onOK={() => {
                    loadData();
                    setcikadetail(null);
                }}
                onClose={() => {
                    loadData();
                    setcikadetail(null);
                }}
                id={cikadetail}
            />
            }
            {shixiao &&
            <UsersCardsShiXiaoModal
                data={shixiao}
                onCancel={() => {
                    setshixiao(null);
                }}
                onOk={() => {
                    setshixiao(null);
                    loadData()
                }}
            />
            }
        </PageContent>
    )
};


export default UsersCards;