import React, {useState, useEffect} from 'react';
import {Card, Button, Space, Divider, Table, message} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import {PlusOutlined} from '@ant-design/icons';
import {getMembershipType, putMembershipType} from "../../../lib/api_membership_type";
import CourseFeeConfig from './course_fee_config';
import StaffConfig from './staff_config';
import ZhouqiConfig from './zhouqi_config';
import QuXiaoConfig from './quxiao_config';
import {getConfig, putConfig} from "../../../lib/api_config";

const MembershipType = ({match}) => {
    const [config, setconfig] = useState({});
    const [show, setshow] = useState(false);
    const loadData = () => {
        getConfig("xcx-huiyuan")
            .then(res => {
                if (res.code === 0 && res.data.config) {
                    setconfig(JSON.parse(res.data.config));
                    setshow(true);
                }
            })
    };
    useEffect(loadData, []);

    const save = () => {
        putConfig("xcx-huiyuan", JSON.stringify(config))
            .then(res => {
                if (res.code === 0) {
                    message.success("保存成功")
                }
            })
        ;
    };

    return (
        <PageContent title={"会员端小程序设置"}>
            <Button type="primary" size={"large"} onClick={save}>保存</Button>
            <div>
                {show && <Space direction="vertical" style={{width: "100%"}}>
                    <ZhouqiConfig
                        value={config.zhouqi}
                        onChange={(value) => {
                            config.zhouqi = value;
                            console.log(value)
                        }}/>
                    <QuXiaoConfig
                        value={config.quxiao}
                        onChange={(value) => {
                            config.quxiao = value;
                            console.log(value)
                        }}/>
                    <CourseFeeConfig
                        value={config.course_fee}
                        onChange={(value) => {
                            config.course_fee = value;
                            console.log(value)
                        }}/>
                    <StaffConfig
                        value={config.staff}
                        onChange={(value) => {
                            config.staff = value;
                            console.log(value)
                        }}/>
                </Space>}
            </div>

        </PageContent>
    )
};


export default MembershipType;


