/**
 * @name: 左侧菜单
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update: 2019/4/21
 */
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import styles from './sidebar.module.css';
import FirstSider from './first-sider';
import SecondSider from './second-sidebar';
// import menuConfig, {getFirstMenu} from './menu.config';


const list2tree = (list, node=null)=>{
    if(!node){
        // 根节点
        let ls = list.filter(_=>_.pid === 0);
        ls.forEach(_=> list2tree(list, _));
        return ls
    }else{
        node.children = list.filter(_=>_.pid === node.id);
        node.children.forEach(_=> list2tree(list, _));
    }
};

const getFirstMenu = function (path, menus) {
    let pathname = path;
    let index = pathname.indexOf('/', 1);
    index = index === -1 ? pathname.length : index;
    let firstpath = pathname.substring(0, index);
    console.log( firstpath);
    // 获取第一层菜单
    if (firstpath) {
        let menu2 = menus.find(_ => _.path === firstpath);
        console.log( menu2, menus);
        if (menu2) {
            return menu2;
        }
    }
    return menus[0];
};

const SideBar = ({path, menus}) => {
    console.log(menus);
    const [menu, setMenu] = useState(null);
    const [current, setCurrent] = useState(null);


    const menulist = list2tree(menus.filter(m=>m.type===0));
    // 根据链接生成菜单
    // let pathname = props.history.location.pathname;

    const Path2Menu = () => {
        let menu2 = getFirstMenu(path, menulist);
        setMenu(menu2);
        setCurrent(menu2)
    };

    useEffect(Path2Menu, [path]);
    // useEffect(Path2Menu, []);

    if(!menu) return null;

    return (
        <aside
            className={styles.asider}
            onMouseLeave={_ => setMenu(current)}
            style={{
                width: menu.children.length > 0 ? 224: 92
            }}
        >
            <FirstSider
                path={menu.path}
                onHover={menu => setMenu(menu)}
                key={menu.path}
                menus={menulist}
            />
            {menu.children.length > 0 && <SecondSider
                menu={menu}
                path={path}
                key={path}/>}

        </aside>
    )
};

FirstSider.propTypes = {
    path: PropTypes.string,
};

export default withRouter(SideBar);
export {getFirstMenu};