import React, {useEffect, useState} from 'react';
import {
    Input,
    Row,
    Col,
    Radio,
    DatePicker,
    Form,
    Cascader,
    Card,
    Button,
    Space,
    InputNumber,
    Modal,
    Spin,
} from 'antd';
import TeacherSelect from '../teacher-select';
import {PlusOutlined, SaveOutlined, DeleteOutlined} from '@ant-design/icons';
import MemberNumber from '../member-number';
import options from '../../../lib/cascader-address-options';
import StoreSelect from '../store-select';
import DictRadioGroup from '../../../components/dict/dict-radio-group';
import MembershipsTypeSelect from './memberships_type_select';
import MembershipsCardSelect from './memberships_card_select';
import PageContent from "../../../components/page-content/page-content";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {postMembership} from "../../../lib/api_membership";
import md5 from "md5";
import LevelSelect from "../level-select";
import {getApprovalsLast} from "../../../lib/api_approvals";

function filter(inputValue, path) {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
}

/**
 * 创建会籍页面
 * @returns {JSX.Element}
 * @constructor
 */
const MembershipsAdd = () => {
    const [formRef] = Form.useForm();
    const [membership_type, setMembership_type] = useState("");
    const niankaTypeId = Form.useWatch(["nianka", "type_id"], formRef);
    const cikaCardId = Form.useWatch(["cika", "card_id"], formRef);
    const [avg, setAvg] = useState(0);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [isApprovals, setIsApprovals] = useState(false);

    // 获取审核情况
    const fetchApprovals = () => {
        setLoading(true);
        getApprovalsLast("新会籍")
            .then(res => {
                if (res.code === 0) {
                    if (res.data && res.data.sp_status === 1) {
                        // 存在审核记录并且未完成状态
                        setIsApprovals(true);
                    } else {
                        setIsApprovals(false);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(fetchApprovals, []);

    const handleSubmit = e => {
        e.preventDefault();
        formRef.validateFields()
            .then(values => {
                setLoading(true)
                values.password = md5(values.password);
                postMembership(values)
                    .then(result => {
                        // 刷新数据
                        if (result.code === 0) {
                            // this.handleOK();
                            history.replace(`/member/member_ship/${result.data.id}`);
                        } else if (result.code === 1001) {
                            fetchApprovals();
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })

    };

    /**
     * 会籍类型发生变化
     */
    const handleMemberShipTypeChange = (e) => {
        setMembership_type(e.target.value);
    }

    /**
     * 年卡变化
     * @param e
     * @param item
     */
    const handleNiankaChange = (e, item) => {
        let begin_time = moment(moment().format("YYYY-MM-DD"));
        let end_time = moment(begin_time).add(item.limit_days - 1, "d");
        formRef.setFieldsValue({
            nianka: {
                ...formRef.getFieldValue("nianka"),
                begin_time: begin_time,
                end_time: end_time,
                payments: item.price,
                limit_days: item.limit_days,
            }
        })
    }

    /**
     * 年卡开始日期变化
     * @param value
     */
    const handleNiankaTimeChange = (value) => {
        let item = formRef.getFieldValue("nianka");
        let begin_time = value;
        let end_time = moment(begin_time).add(item.limit_days - 1, "d");
        formRef.setFieldsValue({
            nianka: {
                ...formRef.getFieldValue("nianka"),
                begin_time: begin_time,
                end_time: end_time,
            }
        })
    }

    const handleCardChange = (e, item) => {
        let begin_time = moment().startOf('day');
        let end_time = moment(begin_time).add(item.limit_days - 1, 'd');
        formRef.setFieldsValue({
            cika: {
                ...formRef.getFieldValue("cika"),
                begin_time: begin_time,
                end_time: end_time,
                keshi_original: item.keshi,
                keshi_free_original: 0,
                payments: item.retail_price,
                retail_price: item.retail_price
            }
        })
        Jisuan()
    }

    /**
     * 计算平均每次课程花费
     * @constructor
     */
    const Jisuan = () => {
        let cika = formRef.getFieldValue("cika");
        let payments = cika.payments;
        let keshi_original = cika.keshi_original;
        setAvg(Math.ceil(payments / keshi_original))
    };

    // const layout = {
    //     labelCol: {flex: "83px"},
    //     wrapperCol: {span: 16},
    // };


    return (
        <PageContent>
            <Spin spinning={loading || isApprovals} size={"large"} tip={"正在等待审核，审核完成前无法提交新的申请！"}>
                <Form
                    labelCol={{flex: "83px"}}
                    // onSubmit={handleSubmit}
                    form={formRef}
                    initialValues={{
                        store_id: 1,
                        password: "jec8052400"
                    }}
                >
                    <Card title="会籍信息" size="small" style={{marginBottom: 12}}>
                        <Row gutter={0}>

                            <Col span={8}>
                                <Form.Item
                                    label="联系电话"
                                    name="mobile_number"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入联系电话',
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="车牌号码"
                                    name="license_plate"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="初始密码"
                                    name="password"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={0}>
                            <Col span={16}>
                                <Form.Item
                                    label="会籍编号"
                                    name="member_number"
                                >
                                    <MemberNumber/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="门店"
                                    name="store_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择门店',
                                        },
                                    ]}
                                >
                                    <StoreSelect/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={0}>
                            <Col span={16}>
                                <Form.Item
                                    label="居住地"
                                    name="residence"
                                >
                                    <Cascader
                                        expandTrigger="hover"
                                        options={options}
                                        showSearch={{filter}}
                                        placeholder="请选择居住地"
                                        changeOnSelect
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            label="备注"
                            name="remark"
                        >
                            <Input.TextArea autosize={{minRows: 2}}/>
                        </Form.Item>
                    </Card>
                    <Card title="会籍类型" size="small" style={{marginBottom: 12}}>
                        <Row gutter={0}>
                            <Col span={8}>
                                <Form.Item
                                    label="会籍类型"
                                    name="membership_type"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择会籍类型',
                                        },
                                    ]}
                                >
                                    <Radio.Group
                                        onChange={handleMemberShipTypeChange}
                                        options={[{
                                            value: '次卡',
                                            label: '次卡',
                                        }, {
                                            value: '年卡',
                                            label: '年卡',
                                        }]}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {membership_type === '年卡' && (
                            <>
                                <Row gutter={0}>
                                    <Col span={16}>
                                        <Form.Item
                                            label="年卡类型"
                                            name={["nianka", "type_id"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请选择年卡',
                                                },
                                            ]}
                                        >
                                            <MembershipsTypeSelect onChange={handleNiankaChange}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                    </Col>


                                </Row>
                                {(niankaTypeId) &&
                                    <Row gutter={0}>
                                        <Col span={8}>
                                            <Form.Item
                                                label="开始日期"
                                                name={["nianka", "begin_time"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择开始日期',
                                                    },
                                                ]}
                                            >
                                                <DatePicker format={"YYYY-MM-DD"} allowClear={false}
                                                            onChange={handleNiankaTimeChange}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="结束日期"
                                                name={["nianka", "end_time"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择结束日期',
                                                    },
                                                ]}
                                            >
                                                <DatePicker format={"YYYY-MM-DD"} disabled/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="实际支付年费"
                                                name={["nianka", "payments"]}
                                                labelCol={{flex: "120px"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入实际支付年费',
                                                    },
                                                ]}
                                            >
                                                <InputNumber precision={2} min={0}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                }
                            </>
                        )}
                        {membership_type === '次卡' && (
                            <>
                                <Row gutter={0}>
                                    <Col span={16}>
                                        <Form.Item
                                            label="会员卡"
                                            name={["cika", "card_id"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请选择会员卡',
                                                },
                                            ]}
                                        >
                                            <MembershipsCardSelect onChange={handleCardChange}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {(cikaCardId) &&
                                    <Row gutter={0}>
                                        <Col span={8}>
                                            <Form.Item
                                                label="开始日期"
                                                name={["cika", "begin_time"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择开始日期',
                                                    },
                                                ]}
                                            >
                                                <DatePicker allowClear={false}/>
                                            </Form.Item>
                                            <Form.Item
                                                label="结束日期"
                                                name={["cika", "end_time"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择结束日期',
                                                    },
                                                ]}
                                            >
                                                <DatePicker allowClear={false}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="正课"
                                                name={["cika", "keshi_original"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入正课课时',
                                                    },
                                                ]}
                                            >
                                                <InputNumber onChange={Jisuan}/>
                                            </Form.Item>
                                            <Form.Item
                                                label="赠课"
                                                name={["cika", "keshi_free_original"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入赠课课时',
                                                    },
                                                ]}
                                            >
                                                <InputNumber/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="门市价"
                                                name={["cika", "retail_price"]}
                                                labelCol={{flex: "120px"}}
                                            >
                                                <InputNumber readOnly precision={2}/>
                                            </Form.Item>

                                            <Form.Item
                                                label="实际支付总金额"
                                                labelCol={{flex: "120px"}}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入支付金额',
                                                    },
                                                ]}>
                                                <Form.Item
                                                    // label="支付金额"
                                                    name={["cika", "payments"]}
                                                    noStyle
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '请输入支付金额',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber onChange={Jisuan}/>

                                                </Form.Item>
                                                <div>
                                                    每课时：{avg}元<br/>
                                                    {/*{this.state.error ? <Text type="danger">{this.state.error}</Text> :""}*/}
                                                    {/*{payments % keshi_original !== 0}*/}
                                                </div>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                }
                            </>
                        )}
                    </Card>
                    <Form.List name="users" initialValue={[{
                        sex: 1,
                        tag1: "",
                        tag2: "",
                    }]}>
                        {(fields, {add, remove}) =>
                            fields.map((field) => (
                                <Card title="会员信息" size="small"
                                      style={{marginBottom: 12}}
                                      extra={<Space>
                                          <Button type="link" onClick={() => add()}><PlusOutlined/> 增加</Button>
                                          {fields.length > 1 &&
                                              <Button type="link"
                                                      onClick={() => remove(field.name)}><DeleteOutlined/> 删除</Button>}
                                      </Space>}
                                      key={field.name}
                                >
                                    <Row gutter={0}>
                                        <Col span={8}>
                                            <Form.Item
                                                label="会员姓名"
                                                name={[field.name, "nick_name"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请输入姓名',
                                                    },
                                                ]}
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="会员昵称"
                                                name={[field.name, "other_name"]}
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="会员性别"
                                                name={[field.name, "sex"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择性别',
                                                    },
                                                ]}
                                            >
                                                <Radio.Group>
                                                    <Radio value={1}>男</Radio>
                                                    <Radio value={2}>女</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="出生年月"
                                                name={[field.name, "birthday"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择出生年月',
                                                    },
                                                ]}
                                            >
                                                <DatePicker/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="专属教练"
                                                name={[field.name, "teacher"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择教练',
                                                    },
                                                ]}
                                            >
                                                <TeacherSelect isCoach={true}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="课程顾问"
                                                name={[field.name, "consultant"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择课程顾问',
                                                    },
                                                ]}
                                            >
                                                <TeacherSelect isCoach={false}/>
                                            </Form.Item>
                                        </Col>


                                        <Col span={8}>

                                            <Form.Item
                                                label="训练类型"
                                                name={[field.name, "train_type"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择训练类型',
                                                    },
                                                ]}
                                            >
                                                <DictRadioGroup type_value={"train_type"}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="标记1"
                                                name={[field.name, "tag1"]}
                                            >
                                                <DictRadioGroup type_value={"member-tag1"}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="标记2"
                                                name={[field.name, "tag2"]}
                                            >
                                                <DictRadioGroup type_value={"member-tag2"}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="学员级别"
                                                name={[field.name, "level"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择学员级别',
                                                    },
                                                ]}
                                            >
                                                <LevelSelect/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>

                            ))
                        }
                    </Form.List>
                    <div style={{textAlign: "center"}}>
                        <Button type="primary" htmlType="submit" size={'large'}
                                icon={<SaveOutlined/>}
                                onClick={handleSubmit}
                                loading={loading}
                                disabled={isApprovals}
                        >
                            立即创建会员
                        </Button>
                    </div>
                </Form>
            </Spin>
            {/*{isApprovals && <Modal title="等待审核" open={true} footer={null} closable={false}>*/}
            {/*    <p><Spin/>正在等待审核，审核完成前无法提交新的申请！</p>*/}
            {/*</Modal>}*/}

        </PageContent>
    )
}

export default MembershipsAdd;
