import React, {Component, useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Button, Card, Col, Row, Space, Table, Tag, Typography} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {getHorses} from "../../../lib/api_horses";
import Horses_add_modal from "./horses_add_modal";
import Horses_edit_modal from "./horses_edit_modal";
import {Link} from "react-router-dom";
import QRModal from "./qr_modal";

const {Column} = Table;
const Horses = () => {
    const [list, setlist] = useState([]);
    const [showHorsesAddModal, setShowHorsesAddModal] = useState(false);
    const [editId, setEditId] = useState(0);
    const [showQRModal, setShowQRModal] = useState(0);

    const fetchData = () => {
        getHorses()
            .then((res) => {
                if (res.code === 0) {
                    setlist(res.data);
                }
            })
    }

    useEffect(fetchData, []);

    return (
        <PageContent title={"马匹管理"}>
            <Card>
                <Row justify="space-between">
                    <Col>

                    </Col>
                    <Col>
                        <Space>
                            <Button type="primary" icon={<PlusOutlined/>}
                                    onClick={() => setShowHorsesAddModal(true)}>添加马匹</Button>
                        </Space>
                    </Col>
                </Row>
                <Table
                    dataSource={list}
                    rowKey={"id"}
                    pagination={false}
                    size={"small"}
                >
                    <Column
                        title="中文名"
                        dataIndex="name"
                        key="中文名"
                    />
                    <Column
                        title="英文名"
                        dataIndex="name_en"
                        key="英文名"
                    />
                    <Column
                        title="权属"
                        dataIndex="quanshu"
                        key="权属"
                    />
                    <Column
                        title="教练"
                        dataIndex="coach_name"
                        key="教练"
                        render={(text, record) => <Link to={`/manage/staff/${record.coach}`}>{text}</Link>}
                    />
                    <Column
                        title="马房"
                        dataIndex="stable_name"
                        key="马房"
                        render={(text, record) => <Link to={`/manage/stables/${record.stable_id}`}>{text}</Link>}
                    />
                    <Column
                        title="重点关注"
                        dataIndex="zdgz"
                        key="重点关注"
                        render={(text, record) => {
                            if (text === 1) {
                                return <Tag color="red">是</Tag>;
                            } else {
                                return <Tag color="blue">否</Tag>;
                            }
                        }}
                    />
                    <Column
                        title="标签"
                        dataIndex="tags"
                        key="标签"
                        render={(text, record) => {
                            if (record.tags) {
                                return JSON.parse(record.tags).map(tag => <Tag color="blue" key={tag}>{tag}</Tag>);
                            } else {
                                return null;
                            }
                        }}
                    />
                    <Column
                        title="二维码"
                        dataIndex="stable_name"
                        key="二维码"
                        render={(value, record, index) => <Typography.Link
                            onClick={() => setShowQRModal(record.id)}>点击查看</Typography.Link>}
                    />

                    <Column
                        title="操作"
                        key="操作"
                        render={
                            (text, record) => (
                                <Link to={`/mafang/horses/${record.id}`}>详情</Link>
                            )
                        }
                    />
                </Table>
            </Card>
            {showHorsesAddModal &&
                <Horses_add_modal
                    onOk={() => {
                        setShowHorsesAddModal(false);
                        fetchData();
                    }}
                    onCancel={() => {
                        setShowHorsesAddModal(false);
                    }}
                />
            }

            {editId > 0 &&
                <Horses_edit_modal
                    id={editId}
                    onOk={() => {
                        setEditId(0);
                        fetchData();
                    }}
                    onCancel={() => {
                        setEditId(0);
                    }}
                />
            }

            {showQRModal > 0 &&
                <QRModal
                    id={showQRModal}
                    onCancel={() => {
                        setShowQRModal(0);
                    }}
                />
            }


        </PageContent>
    );
}

export default Horses;