
import axios from './rest-axios';

const getUserCardInfo = (id) => axios.get(`/manage/users_cards/${id}`);
const postUserCardAddTime = (id, days) => axios.post(`/v1/users_cards/${id}/addtime`, {days});
const postUserCardAddKeShi = (id, model) => axios.post(`/v1/users_cards/${id}/addkeshi`, model);
const postUserCardAddFee = (id, model) => axios.post(`/v1/users_cards/${id}/addfee`, model);
const putUserCardStatus = (id, model) => axios.put(`/manage/users_cards/${id}/status`, model);
const postUserCardPayments = (id, model) => axios.post(`/v1/users_cards/${id}/set_payments`, model);
const getUsersCards = () => axios.get(`/manage/users_cards/cards`);
const postUsersCardsShiXiao = (id, remark) => axios.post(`/manage/users_cards/shixiao`, {id, remark});
const deleteUsersCards = (id) => axios.delete(`/manage/users_cards/${id}`, {id});


export {
    getUserCardInfo,
    postUserCardAddTime,
    postUserCardAddKeShi,
    postUserCardAddFee,
    putUserCardStatus,
    postUserCardPayments,
    getUsersCards,
    postUsersCardsShiXiao,
    deleteUsersCards,
};
