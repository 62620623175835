import React, {useState, useEffect} from 'react';
import {Card, Button, Space, Divider, Table} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import MembershipTypeAddModal from './membership_type_add_modal';
import {PlusOutlined} from '@ant-design/icons';
import {getMembershipType, putMembershipType} from "../../../lib/api_membership_type";


const MembershipType = ({match}) => {
    const [list, setList] = useState([]);
    const [visible, setVisible] = useState(false);

    const handleChangeStatus = (record) => {
        record.status = record.status === 1 ? 0 : 1;
        putMembershipType(record)
            .then(res => {
                if (res.code === 0) {
                    loadData()
                }
            })
    };

    const loadData = () => {
        getMembershipType()
            .then(res => {
                if (res.code === 0) {
                    setList(res.data);
                }
            })
    };
    useEffect(loadData, []);

    return (
        <PageContent>
            <Card bordered={false}>
                <Button
                    type="primary"
                    icon={<PlusOutlined/>}
                    onClick={() => setVisible(true)}
                >添加</Button>
                <Table
                    // title="课程设置"
                    size="small"
                    pagination={false}
                    dataSource={list}
                    rowKey={"id"}
                    columns={[{
                        title: 'VIP会籍名称',
                        dataIndex: 'name',
                        key: "VIP会籍名称"
                    }, {
                        title: '价格',
                        dataIndex: 'price',
                        key: "价格"
                    }, {
                        title: '成员数量',
                        dataIndex: 'member_count',
                        key: "成员数量"
                    }, {
                        title: '使用天数',
                        dataIndex: 'limit_days',
                        key: "使用天数"
                    }, {
                        title: '状态',
                        dataIndex: 'status',
                        key: "状态",
                        render: (status => {
                            return status === 1 ? "停用" : "启用"
                        })
                    }, {
                        title: '操作',
                        fixed: 'right',
                        render: (record) => (<Space split={<Divider type="vertical"/>}>
                            <a onClick={() => {
                                handleChangeStatus(record);
                            }}>
                                {record.status === 1 ? "启用" : "停用"}
                            </a>
                        </Space>)
                    }]}/>
            </Card>
            <MembershipTypeAddModal
                visible={visible}
                onOk={() => {
                    setVisible(false);
                    loadData()
                }}
                onCancel={() => {
                    setVisible(false)
                }}
                {...match.params}
            />

        </PageContent>
    )
};


export default MembershipType;


