import React, {useEffect, useState} from 'react'
import {Button, Modal, message} from "antd";
import {get_membership_signing, post_send, post_membership_signing} from "../../../lib/api_signing";
import QRCode from "react-qr-code";


const SigningModal = ({membership_id, visible, onCancel}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        get_membership_signing(membership_id)
            .then(res => {
                if (res.code === 0) {
                    setData(res.data);
                }
            });
    }

    useEffect(fetchData, []);

    const createSigning = () => {
        post_membership_signing(membership_id)
            .then(res => {
                if (res.code === 0) {
                    fetchData();
                }
            });
    };

    const sendQRCode = () => {
        post_send(data.id)
            .then(res => {
                if (res.code === 0) {
                    message.success("已发送");
                    fetchData();
                }
            });
    }

    return (
        <Modal
            title="签约"
            open={visible}
            footer={null}
            onCancel={onCancel}
            destroyOnClose={true}
        >
            {data ? (
                <div>
                    <div>签约状态：{data.status === 0 ? "未签" : "已签"}
                        <Button onClick={fetchData}>刷新</Button>
                    </div>
                    <div><img src={data.qianming}/></div>

                    {data.status === 0 && <div>
                        <div>
                        <QRCode value={"https://jec.timelite.cn/h5/signingmembership/" + membership_id}/>
                        </div>
                        <Button type="primary" onClick={sendQRCode}>发送二维码</Button>
                    </div>}

                </div>
            ) : (
                <div>
                    <div>签约状态：未生成</div>
                    <Button type="primary" onClick={createSigning}>创建签约</Button>
                </div>
            )}

        </Modal>
    )
}

export default SigningModal;