import React, {useEffect} from 'react';
import {DatePicker, Form, Input, InputNumber, Modal, Select, Spin} from 'antd';
import {updatecwsrdj, getcwsrdj} from "../../lib/api_cw_srdj";
import {createOrUpdateCWSRYCYueDu, getCWSRYCYueDuById} from "../../lib/api_cw_sryc_yuedu";
import moment from "moment";


const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};


/**
 * 添加财务收入登记
 * @param onCancel
 * @param onOk
 * @returns {JSX.Element}
 * @constructor
 */
const SrycjiduEditModal = ({data, onCancel, onOk}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);


    const fetchData = () => {
        if (data.id > 0) {
            setLoading(true)
            getCWSRYCYueDuById(data.id)
                .then(res => {
                    if (res.code === 0) {
                        form.setFieldsValue({
                            je: res.data.je,
                            bz: res.data.bz,
                        });
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    useEffect(fetchData, []);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                if (data.coa === "收入扣减 (填负数)") {
                    if (values.je > 0) {
                        Modal.error({
                            title: '错误',
                            content: '收入扣减 (填负数) 金额必须为负数',
                        });
                        return;
                    }
                }
                console.log(values);
                createOrUpdateCWSRYCYueDu({
                    // id: data.id,
                    md: data.md,
                    year: data.year,
                    month: data.month,
                    coa: data.coa,
                    je: values.je,
                    coa_id: data.coa_id,
                    bz: values.bz,
                    coa_pid: data.coa_pid,
                })
                    .then(res => {
                        if (res.code === 0) {
                            handleOk();
                        }
                    });
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    const handleOk = () => {
        onOk && onOk();
    }
    const handleCancel = () => {
        onCancel && onCancel();
    }

    return (
        <Modal
            title="修改收入预测"
            open={true}
            onCancel={handleCancel}
            onOk={handleSubmit}
            confirmLoading={loading}
        >
            <Spin spinning={loading}>
                <Form
                    {...formItemLayout}
                    form={form}
                    initialValues={{
                        je: data.je,
                        bz: data.bz
                    }}
                >
                    <Form.Item label="门店">{data.md}</Form.Item>
                    <Form.Item label="年份">{data.year}</Form.Item>
                    <Form.Item label="月份">{data.month}月</Form.Item>
                    <Form.Item label="科目">{data.coa}</Form.Item>
                    <Form.Item
                        label="金额"
                        name="je"
                        rules={[
                            {
                                required: true,
                                // eslint-disable-next-line no-template-curly-in-string
                                message: '请输入 ${label}',
                            },
                        ]}
                    >
                        <InputNumber precision={2} style={{width: 200}}/>
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="bz"
                    >
                        <Input.TextArea/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}

export default SrycjiduEditModal;