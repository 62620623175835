import React, {useEffect, useState} from 'react';
import {Card} from "antd";
import {get_current} from "../../lib/api_signing";
import QRCode from "react-qr-code";
import {useRafInterval} from 'ahooks';

const SigningQRCode = () => {
    const [data, setData] = useState(null);

    // 获取马房数据
    const fetchData = () => {
        get_current().then(res => {
            if (res.code === 0) {
                setData(res.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    // useEffect(fetchData, []);

    useRafInterval(fetchData, 2000, {immediate: true});

    if (!data)
        return <div>loading...</div>;
    return (
        <div style={{backgroundColor: "#f5f5f5", padding: "20px"}}>
            {data.yuyue_id > 0 && <QRCode value={"https://jec.timelite.cn/h5/signing/" + data.yuyue_id}/>}
            {data.membership_id > 0 && <QRCode value={"https://jec.timelite.cn/h5/signingmembership/" + data.membership_id}/>}

        </div>
    );
}

export default SigningQRCode;