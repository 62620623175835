
// 角色
const roles = [{
    id: 1,
    name: '教练',
}, {
    id: 2,
    name: '前台',
}, {
    id: 4,
    name: '教学主管',
}, {
    id: 5,
    name: '销售',
}, {
    id: 3,
    name: '管理员',
},];
// 预约类型
const yuyue_types = [{
    id: 0,
    name: '散客',
}, {
    id: 1,
    name: '会员',
}, {
    id: 2,
    name: '潜在',
}, {
    id: 3,
    name: '小组',
}];
// 课程分类
const courses_type_category = [{
    id: 1,
    name: '骑乘',
}, {
    id: 2,
    name: '马房和理论',
}, {
    id: 3,
    name: '活动',
}];
// 教练级别
const coach_level = [{
    id: 1,
    name: '初级',
}, {
    id: 2,
    name: '中级',
}, {
    id: 3,
    name: '高级',
}, {
    id: 4,
    name: '击剑',
}];
// // 门店编号
// const school_numbers = [{
//     id: "HUMG",
//     name: "HUMG"
// }];

const teacher_level_price = [{
    id: 1,
    price: 150
}, {
    id: 2,
    price: 150
}, {
    id: 3,
    price: 300
}, {
    id: 4,
    price: 75
}];
const train_type = [{
    id: 1,
    name: "A类考核教练绩效"
}, {
    id: 2,
    name: "B类异地不考核绩效"
}, {
    id: 3,
    name: "C类特殊情况不考核绩效"
}];

const train_type_short = [{
    id: 1,
    name: "A"
}, {
    id: 2,
    name: "B"
}, {
    id: 3,
    name: "C"
}];

export {
    roles,
    yuyue_types,
    courses_type_category,
    coach_level,
    // school_numbers,
    teacher_level_price,
    train_type,
    train_type_short
};
