import React, {useState, useEffect} from 'react';
import {Cascader, Col, Form, Input, message, Modal, Row, Spin} from "antd";
import MemberNumber from "../member-number";
import StoreSelect from "../store-select";
import options from "../../../lib/cascader-address-options";
import {getMembershipInfo, putMembership} from "../../../lib/api_membership";
import {getApprovalsLast} from "../../../lib/api_approvals";

function filter(inputValue, path) {
    return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
}

const MembershipsEditModal = ({visible, id, onCancel, onOk}) => {
    const [form] = Form.useForm();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isApprovals, setIsApprovals] = useState(false);

    // 获取审核情况
    const fetchApprovals = () => {
        setLoading(true);
        getApprovalsLast("修改会籍信息")
            .then(res => {
                if (res.code === 0) {
                    if (res.data && res.data.sp_status === 1) {
                        // 存在审核记录并且未完成状态
                        setIsApprovals(true);
                    } else {
                        setIsApprovals(false);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(fetchApprovals, []);

    const fetchData = () => {
        getMembershipInfo(id)
            .then(res => {
                if (res.code === 0) {
                    setData(res.data);
                    form.setFieldsValue({
                        mobile_number: res.data.mobile_number,
                        license_plate: res.data.license_plate,
                        residence: JSON.parse(res.data.residence),
                        remark: res.data.remark,
                    });
                }
            })
    }

    useEffect(fetchData, []);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log(values);
                values.id = id;
                putMembership(values)
                    .then(res => {
                        if (res.code === 0) {
                            message.success("修改成功");
                            onOk && onOk();
                        } else if (res.code === 1001) {
                            fetchApprovals();
                        }
                    })
            })
    }
    const handleCancel = () => {
        onCancel && onCancel();
    }
    return (
        <Modal
            title="修改会籍信息"
            visible={visible}
            onOk={handleSubmit}
            onCancel={handleCancel}
            width={800}
            destroyOnClose={true}
            confirmLoading={loading || isApprovals}
        >
            <Spin spinning={loading || isApprovals} size={"large"} tip={"正在等待审核，审核完成前无法提交新的申请！"}>
                <Form
                    labelCol={{flex: "83px"}}
                    // onSubmit={handleSubmit}
                    form={form}
                    initialValues={data}
                >
                    <Row gutter={0}>

                        <Col span={12}>
                            <Form.Item
                                label="联系电话"
                                name="mobile_number"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入联系电话',
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="车牌号码"
                                name="license_plate"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={0}>
                        <Col span={24}>
                            <Form.Item
                                label="居住地"
                                name="residence"
                            >
                                <Cascader
                                    expandTrigger="hover"
                                    options={options}
                                    showSearch={{filter}}
                                    placeholder="请选择居住地"
                                    changeOnSelect
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label="备注"
                        name="remark"
                    >
                        <Input.TextArea autosize={{minRows: 2}}/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}

export default MembershipsEditModal;