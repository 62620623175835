import React, {useEffect, useState} from 'react'
import PageContent from "../../../components/page-content/page-content";
import {DatePicker, Space, Table, Typography} from "antd";
import IconTeacherName from "../../dashboard/images/我的.svg";
import IconGQStatus from "../../dashboard/images/状态进度.svg";
import IconCheckCircleFilled from "../../dashboard/images/CheckCircleFilled.svg";
import IconWarningFilled from "../../dashboard/images/WarningFilled.svg";
import {getMaFangWeiShengList} from "../../../lib/api_mafang_weisheng";
import moment from "moment";
import Mafang_weisheng_edit_modal from './mafang_weisheng_edit_modal';



const Mafang_weisheng = () => {
    const [current_date, setcurrent_date] = useState(moment().startOf('day'));
    const [list, setList] = useState([]);
    const [editDate, setEditDate] = useState(null);


    const columnsmfgqws = [
        {
            title: '',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: '洗马区',
            dataIndex: 'ximaqu',
            key: '洗马区',
        },
        {
            title: '休息室',
            dataIndex: 'xiuxishi',
            key: '休息室',
        },
        {
            title: '鞍具房',
            dataIndex: 'anjufang',
            key: '鞍具房',
        },
        {
            title: '马房通道',
            dataIndex: 'mafangtongdao',
            key: '马房通道',
        },
        {
            title: '训练场地',
            dataIndex: 'xunlianchangdi',
            key: '训练场地',
        },
        {
            title: '厕所',
            dataIndex: 'cesuo',
            key: '厕所',
        },
        {
            title: '操作',
            key: '操作',
            render: (record) => (<Space>
                <Typography.Link onClick={()=>setEditDate(record.date)}>修改</Typography.Link>
            </Space>)
        },
    ];

    const fetchData = () => {
        getMaFangWeiShengList(
            moment(current_date).weekday(0).format('YYYY-MM-DD'),
            moment(current_date).weekday(6).add(1, "d").format('YYYY-MM-DD'))
            .then(res => {
                if (res.code === 0) {
                    console.log(res.data);
                    // 组装数据
                    let list = [];
                    for (let i = 0; i < 7; i++) {
                        let date = moment(current_date).weekday(i).format('YYYY-MM-DD');
                        let item = res.data.find(m => moment(m.date).format('YYYY-MM-DD') === date);
                        if (item) {
                            list.push({
                                key: date,
                                date: date,
                                ximaqu: item.ximaqu,
                                xiuxishi: item.xiuxishi,
                                anjufang: item.anjufang,
                                mafangtongdao: item.mafangtongdao,
                                xunlianchangdi: item.xunlianchangdi,
                                cesuo: item.cesuo,
                            });
                        } else {
                            list.push({
                                key: date,
                                date: date,
                                ximaqu: null,
                                xiuxishi: null,
                                anjufang: null,
                                mafangtongdao: null,
                                xunlianchangdi: null,
                                cesuo: null,
                            })
                        }
                    }
                    setList(list);
                }
            })
        ;
    }

    useEffect(fetchData, [current_date]);

    const handleWeekChange = (value) => {
        console.log(value);
        setcurrent_date(value);
    }
    return (
        <PageContent>
            <DatePicker onChange={handleWeekChange} value={current_date} picker="week"/>
            <Table dataSource={list} columns={columnsmfgqws} bordered pagination={false} size={"small"}/>

            {editDate ? <Mafang_weisheng_edit_modal
                date={editDate}
                onCancel={() => {
                    setEditDate(null);
                }}
                onOk={() => {
                    setEditDate(null);
                    fetchData();
                }}
            /> : null
            }

        </PageContent>
    )
}

export default Mafang_weisheng;