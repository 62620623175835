import PropTypes from 'prop-types';
import React from 'react'
import styles from './calendar.module.css';
import moment from 'moment';

const Box = ({id, nick_name, mobile_number, license_plate, remark, begin_time, end_time, times, step, onClick, yuyue_type}) => {

    // console.log({id, nick_name, begin_time, end_time, times, step})

    // 定位到指定位置，转换成秒数计算
    let start_time = times[0];
    let begin_time1 = moment(begin_time);
    let end_time1 = moment(end_time);
    let begin_time_sec = begin_time1.diff(start_time, "seconds")
    let end_time_sec = end_time1.diff(start_time, "seconds")

    let top = begin_time_sec / step / 60 * 30;
    let bottom = end_time_sec / step / 60 * 30;
    let height = bottom - top;

    let desabledheight = 10 / step * 30;

    // console.log(`yuyue${id % 2}`)

    // const opacity = isDragging ? 0.4 : 1
    return (
        <div className={styles[`yuyue${yuyue_type}`]}
             style={{top: top, height: height + desabledheight, display:"flex", flexDirection:"column"}}
             onClick={onClick}>
            <div style={{flex:1, padding:"3px 5px", overflow: "auto"}}>
            {nick_name}<br/>
            {mobile_number}<br/>
            {license_plate}<br/>
            {remark}
            </div>
            <div style={{ height: desabledheight, backgroundColor:"#eeeeee"}}>

            </div>
        </div>
    )
};

Box.propTypes = {
    id: PropTypes.number,
    nick_name: PropTypes.string,
    begin_time: PropTypes.string,
    end_time: PropTypes.string,
    times: PropTypes.array,
    step: PropTypes.number,
};
export default Box
