import React, {Component} from 'react';
import {Switch, Route, withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import './App.css';
import Login from './containers/login/login';
import './assets/react-contextmenu.css';
import AuthComponent from './components/auth_component';
import SiderBar, {getFirstMenu} from './components/sider-menu/sidebar';
import './wssocket';
import App2 from './App2';
import Dashboard_mafang from './containers/dashboard/dashboard_mafang'
import Dashboard from './containers/dashboard/dashboard'
import MaFangDetail from "./containers/dashboard/mafang_detail";
import HorseDetail from "./containers/dashboard/horse_detail";
import Signing from "./containers/dashboard/signing";
import Signingmembership from "./containers/dashboard/signingmembership";
import SigningQRCode from "./containers/dashboard/signingqrcode";


@AuthComponent
class App extends Component {
    state = {
        path: "/",
        siderWidth: 206
    };

    routeChange = (route) => {
        console.log(route); // 这个route里面有当前路由的各个参数信息
        this.setState({
            path: route.pathname
        }, () => {
            // 获取菜单情况
            console.log(this.state.path);
            if (this.props.menus) {
                let menu = getFirstMenu(this.state.path, this.props.menus);
                console.log(menu);
                if (menu && menu.children && menu.children.length === 0) {
                    this.setState({
                        siderWidth: 80
                    })
                } else {
                    this.setState({
                        siderWidth: 206
                    })
                }
            }

        })
    };

    componentDidMount() {
        // 路由监听
        this.historyinstance = this.props.history.listen(this.routeChange);
        // setTimeout(() => {
        //     this.routeChange(this.props.history.location)
        // }, 2000)
        // this.routeChange(this.props.history.location)
    }

    componentWillUnmount() {
        // 取消监听
        this.historyinstance && this.historyinstance();
    }

    componentWillReceiveProps(newProps) {
        this.routeChange(this.props.history.location);
    }


    render() {
        // let {account, menus} = this.props;
        // if (!account) {
        //     return (<Login/>)
        // }
        // console.log(menus);
        return (
            <div>
                <Switch>
                    <Route exact path="/jsc"
                           component={Dashboard}/>
                    <Route exact path="/mafang"
                           component={Dashboard_mafang}/>
                    <Route exact path="/h5/mafang/:id"
                           component={({match}) => <MaFangDetail id={parseInt(match.params.id)}/>}/>
                    <Route exact path="/h5/horse/:id"
                           component={({match}) => <HorseDetail id={parseInt(match.params.id)}/>}/>
                    <Route exact path="/h5/signing/:id"
                           component={({match}) => <Signing id={parseInt(match.params.id)}/>}/>
                    <Route exact path="/h5/signingmembership/:id"
                           component={({match}) => <Signingmembership id={parseInt(match.params.id)}/>}/>
                    <Route exact path="/h5/signingqrcode"
                           component={SigningQRCode}/>
                    <Route path="/"
                           component={App2}/>
                </Switch>
            </div>
        );

    }
}


const mapStateToProps = state => ({
    // school: state.school,
    account: state.account,
    menus: state.menus
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App));
