/**
 * @name: 课程管理路由
 * @description:
 * @author: Wood
 * @date: 2021/4/25
 * @update:
 */
import React from 'react';
import {Switch, Route} from "react-router-dom";
import MembershipDetail from './membership/membership_detail';
import Members from './members';
import MembersDetail2 from './members/member_detail';
import KeshiBatch from './keshi_batch/keshi_batch';
import UsersCards from './users_cards/users_cards';
import GuoQiNianKa from './nianka/memberships';
import Memberships from './memberships/memberships';
import MembershipsAdd from './memberships/memberships_add';
import TeachingDetail from "../calendar/teaching-detail";
import LuoMaList from "./luoma/luoma_list";
import LuomaDetail from "./luoma/luoma_detail";
import Approvals from "../manage/approvals/approvals";

const Router = () => (
    <Switch>
        <Route exact path="/member/members"
               component={Members}/>
        {/*<Route exact path="/member/members/:members_id"*/}
        {/*component={({match}) =>*/}
        {/*<MembersDetail id={parseInt(match.params.members_id)}/>}/>*/}
        <Route exact path="/member/members/:members_id"
               component={({match}) =>
                   <MembersDetail2 id={parseInt(match.params.members_id)}/>}/>
        <Route exact path="/member/member_ship"
               component={Memberships}/>
        {/*<Route exact path="/member/member_ships"*/}
        {/*       component={Memberships}/>*/}
        <Route exact path="/member/member_ship/add"
               component={MembershipsAdd}/>
        <Route exact path="/member/member_ship/:id"
               component={({match}) => <MembershipDetail id={parseInt(match.params.id)}/>}/>

        <Route exact path="/member/keshi_batch"
               component={KeshiBatch}/>
        <Route exact path="/member/cards"
               component={UsersCards}/>
        <Route exact path="/member/nianka"
               component={GuoQiNianKa}/>
        <Route exact path="/member/test" component={() => <TeachingDetail yuyue_id={30019} user_id={845}/>}/>

        <Route exact path="/member/luoma"
               component={LuoMaList}/>
        <Route exact path="/member/luoma/:id"
               component={({match}) => <LuomaDetail id={parseInt(match.params.id)}/>}/>

            <Route exact path="/member/approvals"
                   component={Approvals}/>

    </Switch>
);

export default Router;
