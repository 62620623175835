import React, {useEffect, useState} from 'react';
import RScaleScreen from 'r-scale-screen'

import styles from './dashboard_mafang.module.css';
import Weather from "./weather";
import {Table} from 'antd';
import DpTable from './dp_table';
import {getgqws, getjrkc} from "../../lib/api_dashboard";
import {useTimeout, useInterval} from 'ahooks';
import IconTeacherName from './images/我的.svg';
import IconGQStatus from './images/状态进度.svg';
import IconCheckCircleFilled from './images/CheckCircleFilled.svg'
import IconWarningFilled from './images/WarningFilled.svg'
import Icon良好 from './images/良好.svg'
import Icon待清洁 from './images/待清洁.svg'

const columnsjrkc = [
    {
        title: '上课时间',
        dataIndex: 'time',
        key: '上课时间',
        width: '25%',
        align:  'left',
    },
    {
        title: '教练',
        dataIndex: 'teacher_name',
        key: '教练',
        width: '25%',
        align:  'left',
    },
    {
        title: '时长',
        dataIndex: 'duration',
        key: '时长',
        width: '25%',
        align:  'left',
    },
    {
        title: '学员',
        dataIndex: 'nick_name',
        key: '学员',
        width: '25%',
        align:  'left',
    },
];
const columnszrmfws = [
    {
        title: '马匹马房',
        dataIndex: 'name',
        key: '马匹马房',
        width: '33%',
        align:'left',
    },
    {
        title: '责任人',
        dataIndex: 'teacher',
        key: '责任人',
        width: '33%',
        align:'left',
    },
    {
        title: '状态',
        dataIndex: 'status',
        key: '状态',
        width: '33%',
        align:'left',
        render: (value, record, index) => {
            if (value === 0) return <img src={Icon良好}/>
            else return <img src={Icon待清洁}/>
        }
    },

];
const columnsmfgqws = [
    {
        title: '',
        dataIndex: 'name',
        key: 'key',
        render: (value, record, index) => {
            if (index === 0) return <img src={IconTeacherName}/>
            else return <img src={IconGQStatus}/>
        }
    },
    {
        title: '洗马区',
        dataIndex: 'ximaqu',
        key: '洗马区',
        width: '110px',
        render: (value, record, index) => {
            if (index === 0) return value;
            else {
                if (value !== 1) {
                    return <img src={IconCheckCircleFilled}/>;
                } else {
                    return <img src={IconWarningFilled}/>;
                }
            }
        }
    },
    {
        title: '休息室',
        dataIndex: 'xiuxishi',
        key: '休息室',
        width: '110px',
        render: (value, record, index) => {
            if (index === 0) return value;
            else {
                if (value !== 1) {
                    return <img src={IconCheckCircleFilled}/>;
                } else {
                    return <img src={IconWarningFilled}/>;
                }
            }
        }
    },
    {
        title: '鞍具房',
        dataIndex: 'anjufang',
        key: '鞍具房',
        width: '110px',
        render: (value, record, index) => {
            if (index === 0) return value;
            else {
                if (value !== 1) {
                    return <img src={IconCheckCircleFilled}/>;
                } else {
                    return <img src={IconWarningFilled}/>;
                }
            }
        }
    },
    {
        title: '马房通道',
        dataIndex: 'mafangtongdao',
        key: '马房通道',
        width: '110px',
        render: (value, record, index) => {
            if (index === 0) return value;
            else {
                if (value !== 1) {
                    return <img src={IconCheckCircleFilled}/>;
                } else {
                    return <img src={IconWarningFilled}/>;
                }
            }
        }
    },
    {
        title: '训练场地',
        dataIndex: 'xunlianchangdi',
        key: '训练场地',
        width: '110px',
        render: (value, record, index) => {
            if (index === 0) return value;
            else {
                if (value !== 1) {
                    return <img src={IconCheckCircleFilled}/>;
                } else {
                    return <img src={IconWarningFilled}/>;
                }
            }
        }
    },
    {
        title: '厕所',
        dataIndex: 'cesuo',
        key: '厕所',
        width: '110px',
        render: (value, record, index) => {
            if (index === 0) return value;
            else {
                if (value !== 1) {
                    return <img src={IconCheckCircleFilled}/>;
                } else {
                    return <img src={IconWarningFilled}/>;
                }
            }
        }
    },
];


const data = [
    // {
    //     key: '1',
    //     name: 'Blue boy (A.03)',
    //     teacher: "史新三",
    //     status: 0,
    // },
    // {
    //     key: '2',
    //     name: '德高 (B.05)',
    //     teacher: "韩响",
    //     status: 1,
    // },
];

const data2 = [
    {
        key: '1',
        name: '1',
        ximaqu: "程天龙",
    },
    {
        key: '2',
        name: '2',
        ximaqu: 1,
        xiuxishi: 0,
        anjufang: 0,
        mafangtongdao: 0,
        xunlianchangdi: 1,
        cesuo: 0,
    }
];


const DashboardMaFang = () => {
    const [jrkc, setJrkc] = useState([]);
    const [jrkcS, setJrkcS] = useState([]);
    const [jrkcP, setJrkcP] = useState(1);
    const [gqws, setGqws] = useState([])
    // 获取今日课程详情
    useInterval(() => {
        if (jrkc.length === 0 || jrkcP * 10 > jrkc.length) {
            getjrkc().then(res => {
                if (res.code === 0) {
                    let jrkc = res.data;
                    setJrkc(jrkc);
                    let ls = jrkc.slice(0, 10);
                    setJrkcS(ls);
                    setJrkcP(1);
                }
            })
        } else {// 下一页
            let ls = jrkc.slice((jrkcP) * 10, (jrkcP + 1) * 10);
            setJrkcS(ls);
            setJrkcP(jrkcP + 1);
        }
    }, 10000, {immediate: true});

    // 获取马房公区卫生情况
    useInterval(() => {
        getgqws().then(res => {
            if (res.code === 0) {
                setGqws(res.data);
            }
        })
    }, 10000, {immediate: true});
    // const fetchJrkc = () => {
    //     getjrkc().then(res => {
    //         console.log(res);
    //         if (res.code === 0) {
    //             console.log(res.data);
    //             setJrkc(res.data);
    //             setTimeout(() => {
    //                 nextJrkc(1);
    //             }, 100)
    //         }
    //     })
    // }
    // const nextJrkc = (page) => {
    //     console.log(jrkc);
    //     // 如果页数超过总数量
    //     if ((page - 1) * 10 < jrkc.length) {
    //         let ls = jrkc.slice((page - 1) * 10, page * 10);
    //         console.log(ls);
    //         setJrkcS(ls);
    //         setTimeout(() => {
    //             nextJrkc(page + 1);
    //         }, 5000);
    //     } else {
    //         setTimeout(() => {
    //             fetchJrkc();
    //         }, 5000);
    //     }
    // }

    // useEffect(fetchJrkc, []);


    return (
        <>
            <RScaleScreen height={1080} width={1920} bodyOverflowHidden={true} fullScreen={true}
                          boxStyle={{backgroundColor: '#0A0B12'}}>
                <div className={styles.header}>
                    <div className={styles.logo}></div>
                    <div className={styles.rightBar}>
                        <div className={styles.weather}>
                            <Weather/>
                        </div>
                    </div>
                </div>
                <div className={styles.jrkb}>
                    <DpTable columns={columnsjrkc} dataSource={jrkcS}/>
                </div>
                <div className={styles.mfgqws}>
                    <DpTable columns={columnsmfgqws} dataSource={gqws} thStyle={{height: 64,fontSize:20}} tdStyle={{height: 64,fontSize:20}}/>
                </div>
                <div className={styles.zrmfws}>
                    <DpTable columns={columnszrmfws} dataSource={data} thStyle={{height: 64,fontSize:20}} tdStyle={{height: 64,fontSize:20}}/>
                </div>
                {/*<div className={styles.bodyContainer}>*/}
                {/*    <div className={styles.leftContainer}>*/}
                {/*        */}
                {/*    </div>*/}
                {/*    <div className={styles.rightContainer}></div>*/}
                {/*</div>*/}
            </RScaleScreen>
        </>
    );
}

export default DashboardMaFang;