import React, {useState, useEffect} from 'react';
import {getGrowup} from "../../lib/api_growup";
import {Button, Descriptions, Tree} from 'antd';
import Box from '../../components/box/index';
import styles from './teaching-detail.module.css';
import {Link} from 'react-router-dom';

const TeachingDetail = ({yuyue_id, user_id}) => {
    const [data, setdata] = useState(null);
    const [expandedKeys, setexpandedKeys] = useState([]);

    const fetchData = () => {
        getGrowup(yuyue_id, user_id)
            .then(res => {
                if (res.code === 0) {
                    console.log(res.data);
                    setdata(res.data);
                    if (res.data.levels) {
                        dealTree(res.data.levels);
                        let keys = [];
                        for (let i = 0; i < res.data.levels.length; i++) {
                            keys.push(res.data.levels[i].id);
                            for (let j = 0; j < res.data.levels[i].children.length; j++) {
                                keys.push(res.data.levels[i].children[j].id);
                            }
                        }
                        setexpandedKeys(keys)
                    }
                }
            })
    };

    useEffect(fetchData, []);

    // 处理树结构
    const dealTree = (list) => {
        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            let titles = [];
            if (item.code) titles.push(item.code);
            if (item.name) titles.push(item.name);
            if (item.stars) {
                let starstr = '';
                for (let j = 0; j < item.stars; j++) {
                    starstr += '★';
                }
                titles.push(starstr);
            }
            item.title = titles.join(' ');
            dealTree(item.children);
        }
    }

    const onExpand = (expandedKeysValue) => {
        console.log('onExpand', expandedKeysValue);
        // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.
        setexpandedKeys(expandedKeysValue);
    };

    if (data) {
        return (<div>
            <Box title={"教学评价"} extra={[<span>{data.level_title} {data.anshi_label}</span>]}>
                <Descriptions column={1}>
                    <Descriptions.Item label="教师">{data.teacher.nick_name}</Descriptions.Item>
                </Descriptions>
                <Tree
                    onExpand={onExpand}
                    treeData={data.levels}
                    fieldNames={{
                        title: "title",
                        key: "id"
                    }}
                    expandedKeys={expandedKeys}
                />
            </Box>
            <Box title={"评价详情"} style={{marginTop: 12}} bodyClassName={styles.dianpin}>
                <p>{data.dianping_content}</p>
                {data.dianping_videos ? <>
                    <video src={JSON.parse(data.dianping_videos).url} controls/>
                </> : null}
                {data.dianping_photos ? <>
                        {data.dianping_photos.split(',').map(m => <img src={m}/>)}
                    </>
                    : null}
            </Box>
            <Box title={"会员评价"} style={{marginTop: 12}}>
                <Descriptions column={1}>
                    <Descriptions.Item label="会员">{data.user.nick_name}</Descriptions.Item>
                    <Descriptions.Item label="感受">{data.reply_face}</Descriptions.Item>
                    <Descriptions.Item label="评价">{data.reply_content}</Descriptions.Item>
                </Descriptions>
            </Box>
            <Link to={`/member/members/${user_id}`} style={{marginTop: 12}}>
                <Button block type="primary" size="large">查看学员详情</Button>
            </Link>
        </div>)
    } else {
        return (<div></div>)
    }

}

export default TeachingDetail;