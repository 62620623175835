import React, {useEffect, useState} from 'react';
import {Badge, Button, DatePicker, Form, Space, Table, Tabs} from "antd";
import moment from "moment";
import {getCWSRYCJiDuAll} from "../../lib/api_cw_sryc_jidu";
import Decimal from "decimal.js";
import styles from "./srdjtj.module.css";
import ExcelJS from "exceljs";
import PageContent from "../../components/page-content/page-content";
import Box from "../../components/box";
import {Link} from "react-router-dom";
import Srcy_jidu_edit_modal from "./sryc_jidu_edit_modal";
import {saveAs} from 'file-saver';
import {getCWLRB} from "../../lib/api_cw_lrb";

const Srycjidu = () => {
    const [data, setData] = useState([]);
    const [showEditModal, setshowEditModal] = useState(null);

    const [year, setyear] = useState(moment());


    const fetchData = () => {
        getCWLRB(year.format("YYYY"))
            .then((res) => {
                if (res.code === 0) {
                    setData(res.data);
                }
            })
    }

    useEffect(fetchData, [year]);

    const columns = [{
        title: '项目',
        dataIndex: 'name',
        key: 'name',
        width: 200,
    }, {
        title: '一季度',
        dataIndex: 'q1',
        key: 'q1',
        width: 200,

    }, {
        title: '二季度',
        dataIndex: 'q2',
        key: 'q2',
        width: 200,

    }, {
        title: '三季度',
        dataIndex: 'q3',
        key: 'q3',
        width: 200,

    }, {
        title: '四季度',
        dataIndex: 'q4',
        key: 'q4',
        width: 200,

    }];

    //
    const exportXlsx = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("利润表", {views: [{state: 'frozen', xSplit: 1, ySplit: 1}]});
        worksheet.getCell(1, 1).value = `${year.format("YYYY年")}利润表`;
        // 标题
        for (let j = 0; j < columns.length; j++) {
            worksheet.getCell(2, j + 1).value = columns[j].title;
        }
        // 数据
        for(let i = 0; i < data.length; i++) {
            worksheet.getCell(i + 3, 1).value = data[i].name; //名称
            worksheet.getCell(i + 3, 2).value = data[i].q1;
            worksheet.getCell(i + 3, 3).value = data[i].q2;
            worksheet.getCell(i + 3, 4).value = data[i].q3;
            worksheet.getCell(i + 3, 5).value = data[i].q4;
        }
        worksheet.eachRow((row, rowNum) => {
            row.eachCell((cell, cellNum) => {
                cell.border = {
                    top: {style: 'thin'},
                    left: {style: 'thin'},
                    bottom: {style: 'thin'},
                    right: {style: 'thin'}
                };
                if (rowNum >= 2 && cellNum >= 2) {
                    cell.alignment = {horizontal: 'center', vertical: 'middle'};
                }
            });
        });
        workbook.calcProperties.fullCalcOnLoad = true;

        //将data插入 工作表，并按照级别进行缩进
        const buffer = await workbook.xlsx.writeBuffer()
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        saveAs(blob, `JEC利润表（${year.format("YYYY年")}）_${moment().format("YYYYMMDDHHmmss")}.xlsx`)
    }


    return (
        <PageContent>
            <Box>
                年份：<DatePicker value={year} onChange={(value) => setyear(value)} picker="year" allowClear={false}/>
            </Box>
            <Box title={`利润表（${year.format("YYYY年")}）`}
                 extra={
                     <Space>
                         {/*<Button type='primary' onClick={() => setaddmodal(true)}>添加</Button>*/}
                         <Button type='primary' onClick={exportXlsx}>导出</Button>
                     </Space>
                 }
                 style={{marginBottom: 20, marginTop: 12}}
            >
                <Table dataSource={data}
                       columns={columns}
                       pagination={false}
                       bordered
                />
            </Box>
        </PageContent>
    )
}

export default Srycjidu;
