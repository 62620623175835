import React, {useState, useEffect} from 'react';
import {Card, Button, Space, Divider, Table, message, Row, Col, Popconfirm} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import {PlusOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';
import {getAllLevels, deleteLevel} from "../../../lib/api_levels";
import styles from './levels.module.css';
import LevelAddModal from './level-add-modal';
import LevelEditModal from './level-edit-modal';
import Level4AddModal from './level4-add-modal';
import Level4EditModal from './level4-edit-modal';


const {Column} = Table;


const Levels = ({match}) => {
    // 原始数据
    const [data, setdata] = useState([]);
    const [level1, setlevel1] = useState([]);
    const [level1index, setlevel1index] = useState(-1);
    const [level2, setlevel2] = useState([]);
    const [level2index, setlevel2index] = useState(-1);
    const [level3, setlevel3] = useState([]);
    const [level3index, setlevel3index] = useState(-1);
    const [level4, setlevel4] = useState([]);
    const [level4index, setlevel4index] = useState(-1);
    const [level1addmodal, setlevel1addmodal] = useState(false);
    const [level1editmodal, setlevel1editmodal] = useState(false);
    const [level4editmodal, setlevel4editmodal] = useState(false);
    const [level2addmodal, setlevel2addmodal] = useState(false);
    const [parentitem, setparentitem] = useState(null);
    const [parentitem4, setparentitem4] = useState(null);
    const [rootid, setrootid] = useState(0);
    const loadData = () => {
        getAllLevels().then(res => {
            if (res.code === 0) {
                setdata(res.data);
                setlevel1(res.data.filter(m => m.level == '1'));
                // if(level1index)
                // setTimeout(()=>{
                //     setLevel1(0);
                // }, 1000);
            }
        })
    };
    useEffect(loadData, []);

    useEffect(() => {
        if (level1index !== -1 && level1[level1index]) {
            setlevel2(data.filter(m => m.pid === level1[level1index].id));
            setrootid(level1[level1index].id);
        }
    }, [data, level1index]);

    useEffect(() => {
        if (level2index !== -1 && level2[level2index]) {
            setlevel3(data.filter(m => m.pid === level2[level2index].id));
        }
    }, [data, level2index]);

    useEffect(() => {
        if (level3index !== -1 && level3[level3index]) {
            setlevel4(data.filter(m => m.pid === level3[level3index].id));
        }
    }, [data, level3index]);

    const setLevel1 = (index) => {
        setlevel1index(index);
    };
    const setLevel2 = (index) => {
        setlevel2index(index);
    };
    const setLevel3 = (index) => {
        setlevel3index(index);
    };

    const handleDeleteLevel = (id) => {
        // 判断是否有子项目
        let childs = data.filter(m => m.pid === id);
        if (childs.length > 0) {
            message.error("请先删除子项目");
            return false
        }

        deleteLevel(id).then(res => {
            if (res.code === 0) {
                loadData();
            }
        });
        return true;
    };

    return (
        <PageContent title={"教学目标设置"}>

            <Row gutter={[12, 12]}>
                <Col span={6}>
                    <Card title="级别" bodyStyle={{padding: 0}}
                          extra={<a href="#" onClick={() => setparentitem({id:0,level:0})}><PlusOutlined/></a>}
                    >
                        {level1.map((item, index) => (
                            <div className={`${styles.item} ${level1index === index ? styles.active : ""}`}
                                 onClick={() => setLevel1(index)}>
                                <span className={styles.desc}>{item.order_index}.{item.name}</span>
                                <span className={styles.opt}>
                                <EditOutlined onClick={() => setlevel1editmodal(item)}/>
                                <Popconfirm
                                    title="确定删除这个级别?"
                                    onConfirm={() => handleDeleteLevel(item.id)}
                                    okText="删除"
                                    cancelText="取消"
                                ><DeleteOutlined/></Popconfirm>
                                </span>
                            </div>))}
                    </Card>


                </Col>
                {level1index !== -1 &&
                <Col span={6}>
                    <Card title="分类" bodyStyle={{padding: 0}}
                          extra={<a href="#" onClick={() => setparentitem(level1[level1index])}><PlusOutlined/></a>}
                    >
                        {level2.map((item, index) => (
                            <div className={`${styles.item} ${level2index === index ? styles.active : ""}`}
                                 onClick={() => setLevel2(index)}>
                                <span className={styles.desc}>{item.order_index}.{item.name}</span>
                                <span className={styles.opt}>
                                <EditOutlined onClick={() => setlevel1editmodal(item)}/>
                                <Popconfirm
                                    title="确定删除这个分类?"
                                    onConfirm={() => handleDeleteLevel(item.id)}
                                    okText="删除"
                                    cancelText="取消"
                                ><DeleteOutlined/></Popconfirm>
                                </span>
                            </div>))}
                    </Card>
                </Col>
                }
                {level2index !== -1 &&
                <Col span={6}>

                    <Card title="教学任务" bodyStyle={{padding: 0}}
                          extra={<a href="#" onClick={() => setparentitem(level2[level2index])}><PlusOutlined/></a>}
                    >
                        {level3.map((item, index) => (
                            <div className={`${styles.item} ${level3index === index ? styles.active : ""}`}
                                 onClick={() => setLevel3(index)}>
                                <span className={styles.desc}>{item.order_index}.{item.name}</span>
                                <span className={styles.opt}>
                                <EditOutlined onClick={() => setlevel1editmodal(item)}/>
                                <Popconfirm
                                    title="确定删除这个分类?"
                                    onConfirm={() => handleDeleteLevel(item.id)}
                                    okText="删除"
                                    cancelText="取消"
                                ><DeleteOutlined/></Popconfirm>
                                </span>
                            </div>))}
                    </Card>
                </Col>
                }
                {level3index !== -1 &&
                <Col span={6}>
                    <Card title="知识要点" bodyStyle={{padding: 0}}
                          extra={<a href="#" onClick={() => setparentitem4(level3[level3index])}><PlusOutlined/></a>}
                    >
                        {level4.map((item, index) => (
                            <div className={`${styles.item} ${level4index === index ? styles.active : ""}`}
                                >
                                <span className={styles.desc}>{item.code}.{item.name}</span>
                                <span className={styles.opt}>
                                <EditOutlined onClick={() => setlevel4editmodal(item)}/>
                                <Popconfirm
                                    title="确定删除这个分类?"
                                    onConfirm={() => handleDeleteLevel(item.id)}
                                    okText="删除"
                                    cancelText="取消"
                                ><DeleteOutlined/></Popconfirm>
                                </span>
                            </div>))}
                    </Card>

                </Col>
                }
            </Row>
            {parentitem && <LevelAddModal
                onOk={() => {
                    loadData();
                    // setlevel1addmodal(false);
                    setparentitem(null);
                }}
                onCancel={() => {
                    // setlevel1addmodal(false);
                    setparentitem(null);
                }}
                visible={!!parentitem}
                p={parentitem}
            />}

            {level1editmodal && <LevelEditModal
                onOk={() => {
                    loadData();
                    setlevel1editmodal(null);
                }}
                onCancel={() => setlevel1editmodal(null)}
                data={level1editmodal}
                visible={!!level1editmodal}
            />}
            {parentitem4 && <Level4AddModal
                onOk={() => {
                    loadData();
                    setparentitem4(null);
                }}
                onCancel={() => setparentitem4(null)}
                visible={!!parentitem4}
                p={parentitem4}
            />}
            {level4editmodal && <Level4EditModal
                onOk={() => {
                    loadData();
                    setlevel4editmodal(null);
                }}
                onCancel={() => setlevel4editmodal(null)}
                data={level4editmodal}
                visible={!!level4editmodal}
            />}

        </PageContent>
    )
};


export default Levels;


