
import axios from './rest-axios';

const getUnConfirmYuYueStat = () => axios.get(`/manage/yuyue_stat/unconfirm`, {});
const postConfirmYuYueStat = (date) => axios.post(`/manage/yuyue_stat/confirm`, {date});

export {
    getUnConfirmYuYueStat,
    postConfirmYuYueStat,
};
