import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import provinces from 'china-division/dist/provinces.json';
import cities from 'china-division/dist/cities.json';
import areas from 'china-division/dist/areas.json';

const ResidenceName = ({data}) => {
    if (!data) return null;
    data = JSON.parse(data);
    if (!data) return null;
    if (data.length != 3) return null;
    const matchProvince = provinces.find(province => province.code === data[0]);
    const matchCity = cities.find(province => province.code === data[1]);
    const matchArea = areas.find(province => province.code === data[2]);
    let arr = [];
    if (matchProvince) arr.push(matchProvince.name);
    if (matchCity) arr.push(matchCity.name);
    if (matchArea) arr.push(matchArea.name);
    return arr.join("/");
};
ResidenceName.propTypes = {
    data: PropTypes.string,
};


const mapStateToProps = state => ({
    // staff: state.staff,
});

const mapDispatchToProps = dispatch => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResidenceName);

