import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PageContent from '../../components/page-content/page-content';
import {Button, Table, DatePicker, Modal} from 'antd';
import {getStat2} from '../../lib/api_stat'
import Box from '../../components/box';
import moment from 'moment';
import excelUtil from '../../lib/excelUtil.js'

const {Column} = Table;


// @hasAuth
class Staff extends Component {
    state = {
        list: []
    };

    componentDidMount() {
        // console.log(this);
        // this.hasRole();
        this.loadData()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        let {begin_time, end_time, nick_name} = this.props;
        getStat2(begin_time, end_time, nick_name)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data
                    })
                    // this.props.setStaff(result.data);
                } else {

                }
            })
    };

    columns = [{
        title: '序号',
        dataIndex: '序号',
        key: '序号',
    }, {
        title: '日期',
        dataIndex: '日期',
        key: '日期',
    }, {
        title: '15分钟数量',
        dataIndex: '15分钟数量',
        key: '15分钟数量',
    }, {
        title: '30分钟数量',
        dataIndex: '30分钟数量',
        key: '30分钟数量',
    }, {
        title: '45分钟数量',
        dataIndex: '45分钟数量',
        key: '45分钟数量',
    }, {
        title: '处罚',
        dataIndex: '处罚',
        key: '处罚',
    }, {
        title: '总时长',
        dataIndex: '总时长',
        key: '总时长',
    }, {
        title: '备注',
        dataIndex: '备注',
        key: '备注',
    }];

    exportXlsx = () => {
        excelUtil.exportExcel(this.columns, this.state.list, `${moment(this.props.begin_time).format("YYYY年M月")}课时统计-${this.props.nick_name}.xlsx`)
    };

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    render() {
        let {list} = this.state;
        return (
            <Modal
                title={`${this.props.nick_name}${moment(this.props.begin_time).format("YYYY年M月")}课时统计`}
                visible={this.props.visible}
                // onOk={this.handleSubmit}
                onCancel={this.handleClose}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                    <Button onClick={this.exportXlsx}>导出</Button>
                    <Table
                        dataSource={list}
                        rowKey={"序号"}
                        pagination={false}
                        size={"small"}
                    >
                        <Column
                            title="序号"
                            dataIndex="序号"
                            key="序号"
                        />
                        <Column
                            title="日期"
                            dataIndex="日期"
                            key="日期"
                        />
                        <Column
                            title="15分钟数量"
                            dataIndex="15分钟数量"
                            key="15分钟数量"
                        />
                        <Column
                            title="30分钟数量"
                            dataIndex="30分钟数量"
                            key="30分钟数量"
                        />
                        <Column
                            title="45分钟数量"
                            dataIndex="45分钟数量"
                            key="45分钟数量"
                        />
                        <Column
                            title="处罚"
                            dataIndex="处罚"
                            key="处罚"
                        />
                        <Column
                            title="总时长"
                            dataIndex="总时长"
                            key="总时长"
                        />
                        <Column
                            title="备注"
                            dataIndex="备注"
                            key="备注"
                        />


                    </Table>
            </Modal>
        );
    }
}

Staff.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    begin_time: PropTypes.string.isRequired,
    end_time: PropTypes.string.isRequired,
    nick_name: PropTypes.string.isRequired,
}

export default withRouter(Staff);



