import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {postCard} from "../../../lib/api_cards";
import {Modal, Form, Input, Select, Table} from 'antd';
import TeacherSelect from "../../members/teacher-select";
import {getHorseYuyue} from "../../../lib/api_horses";
import moment from "moment/moment";

const {Column} = Table;

const HorsesTrainingList = ({id}) => {
    const [list, setList] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const fetchData = () => {
        getHorseYuyue(id, tableParams.pagination.current, tableParams.pagination.pageSize).then(res => {
            if (res.code === 0) {
                setList(res.data.list);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: res.data.total,
                        // 200 is mock data, you should read it from server
                        // total: data.totalCount,
                    },
                });
            }
        })
    }
    useEffect(fetchData, [JSON.stringify(tableParams)]);
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };

    return (
        <Table
            dataSource={list}
            rowKey={"id"}
            size={"small"}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
        >
            <Column
                title={"时间"}
                dataIndex={"begin_time"}
                key={"时间"}
                width={150}
                render={(text, record, index) => moment(record.begin_time).format("YYYY-MM-DD HH:mm")}
            />
            <Column
                title={"训练内容"}
                dataIndex={"yuyue_type"}
                key={"训练内容"}
                // render={(text, record, index) => moment(record.begin_time).format("YYYY-MM-DD HH:mm")}
            />

            <Column
                title={"时长"}
                dataIndex={"duration"}
                key={"时长"}
                width={50}
                // render={(text, record, index) => moment(record.begin_time).format("YYYY-MM-DD HH:mm")}
            />
            <Column
                title={"教练"}
                dataIndex={"teacher"}
                key={"教练"}
                width={80}
                // render={(text, record, index) => moment(record.begin_time).format("YYYY-MM-DD HH:mm")}
            />
        </Table>
    )
}

export default HorsesTrainingList;
