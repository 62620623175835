import PropTypes from 'prop-types';

const ConfigNames = ({config, value}) => {
    if(!value || value.length===0) return '-';
    if (typeof value === "string" && value.startsWith("[")){
        value  = JSON.parse(value);
    }
    return config.filter(item=> value.includes(item.id)).map(item => item.name).join(',');
};

ConfigNames.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    config: PropTypes.array,
};

export default ConfigNames;

