import axios from './rest-axios';

// const getStaff = () => axios.get(`/v1/staff`);
const postYuYue = (model) => axios.post(`/manage/yuyue`, model);
const getYuYue = () => axios.get(`/v1/yuyue`);
const postYuYueShangma = (id, users) => axios.post(`/manage/yuyue/shangma`, {id, users});
const postYuYueWancheng = (id) => axios.post(`/manage/yuyue/wancheng`, {id});
const postYuYueQuxiao = (id) => axios.post(`/manage/yuyue/quxiao`, {id});
const postYuYueQuXiaoShangma = (id) => axios.post(`/manage/yuyue/qxshangma`, {id});
const postYuYueTongBao = (date, ids) => axios.post(`/v1/yuyue/tongbao`, {date, ids});
const putYuYueDrag = (model) => axios.put(`/manage/yuyue/${model.id}/drag`, model);
const getYuYueStat = (date) => axios.get(`/manage/yuyue/stat`, {date});
const postYuYueStat = (list) => axios.post(`/manage/yuyue/stat`, {data: list});
const putYuYueStat = (data) => axios.put(`/v1/yuyue/stat`, data);

const postYuYueDelete = (id) => axios.post(`/manage/yuyue/delete`, {id});
const postYuYueUnlock = (id) => axios.post(`/manage/yuyue/unlock`, {id});
const postYuYueLock = (id) => axios.post(`/manage/yuyue/lock`, {id});

const getYuYueById = (id) => axios.get(`/manage/yuyue/${id}`);
const putFKXX = (data) => axios.put('/manage/yuyue/fkxx', data);
const putkuandai = (data) => axios.put('/manage/yuyue/kuandai', data);

export {
    // getStaff,
    postYuYue,
    getYuYue,
    postYuYueShangma,
    postYuYueWancheng,
    postYuYueQuxiao,
    postYuYueQuXiaoShangma,
    postYuYueTongBao,
    putYuYueDrag,
    getYuYueStat,
    postYuYueStat,
    postYuYueDelete,
    putYuYueStat,
    postYuYueUnlock,
    postYuYueLock,
    getYuYueById,
    putFKXX,
    putkuandai,
};
