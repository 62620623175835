/**
 * @name: 一级菜单
 * @description:
 * @author: Wood
 * @date: 2021/4/25
 * @update:
 */
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {Menu, Dropdown, Badge, Avatar} from 'antd';
import styles from './first-sider.module.css';
// import menuConfig from './menu.config';
// import AccountMenu from '../account-menu/account-menu2';
import {
    CalendarOutlined,
    CrownOutlined,
    UserOutlined,
    BookOutlined,
    CreditCardOutlined,
    ClockCircleOutlined,
    BarChartOutlined,
    SettingOutlined,
    MessageOutlined, LockOutlined,LogoutOutlined,
} from '@ant-design/icons';
import {connect} from 'react-redux';
import {flushMessageCount} from "../../reducers/message_count";
import logo from '../../assets/images/logo.svg';
import logo2 from '../../assets/images/logo2.svg';
import {signOut} from "../../reducers/account";
import {showPasswordModal} from "../../reducers/password-modal";
import 预约 from "../../assets/images/预约.svg"
import 概览 from "../../assets/images/概览.svg"
import 会员 from "../../assets/images/会员.svg"
import 出勤 from "../../assets/images/出勤.svg"
import 消息 from "../../assets/images/消息.svg"
import 管理 from "../../assets/images/管理.svg"
import 统计 from "../../assets/images/统计.svg"
import 设置 from "../../assets/images/设置.svg"
import 马房 from "../../assets/images/马房.svg"


const getIcon = (name) => {
    switch (name) {
        case "预约":
            return <img src={预约}/>
        case "会员":
            return <img src={会员}/>
        case "出勤":
            return <img src={出勤}/>
        case "概览":
            return <img src={概览}/>
        case "设置":
            return <img src={设置}/>
        case "消息":
            return <img src={消息}/>
        case "管理":
            return <img src={管理}/>
        case "统计":
            return <img src={统计}/>
        case "马房":
            return <img src={马房}/>
        default:
            return null;
    }
};


/**
 * 一级菜单
 * @param path 页面路径
 * @param onHover 鼠标移入事件
 * @param menus 菜单列表
 * @param signOut 退出登录函数
 * @constructor
 */
const FirstSider = ({path, onHover, menus, signOut, account, message_count, flushMessageCount,showPasswordModal}) => {
    useEffect(flushMessageCount, []);
    return (
        <div className={styles.firstSider}>
            <div style={{
                padding: "14px 16px"
            }}>
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: '1',
                                label: (
                                    <a onClick={showPasswordModal}>
                                        修改密码
                                    </a>
                                ),
                                icon: <LockOutlined/>,
                            },
                            {
                                key: '2',
                                label: (
                                    <a onClick={signOut}>
                                        退出登录
                                    </a>
                                ),
                                icon: <LogoutOutlined/>,
                            },
                        ],
                    }}
                >
                    <div>
                    <Avatar size={48}  src={account.avatar}/>
                        <div style={{fontSize:12,fontWeight:"bold",color:"#FFFFFF"}}>
                    {account.name}
                        </div>
                    </div>
                </Dropdown>
                {/*<img src={logo} className={styles.logo2}/>*/}
            </div>
            <Menu
                mode="inline"
                theme="dark"
                defaultSelectedKeys={[path]}
                style={{
                    background: "#001533",
                    flex: 1
                }}
            >
                {menus.map(menu => {
                    // 存在子节点
                    if (menu.children.length > 0) {
                        return (
                            <Menu.Item
                                key={menu.path}
                                icon={getIcon(menu.icon)}
                                className={styles.menuItem}
                                onMouseEnter={_ => onHover && onHover(menu)}
                            >
                                <Link to={menu.children[0].path}>
                                    {menu.minititle}
                                </Link>
                            </Menu.Item>
                        )
                    } else {
                        return (
                            <Menu.Item key={menu.path}
                                       icon={getIcon(menu.icon)}
                                       className={styles.menuItem}>
                                <Link to={menu.path}>
                                    {menu.minititle}
                                </Link>
                            </Menu.Item>
                        )
                    }
                })}
                <div style={{
                    marginTop: 0,
                    width:80,
                }}  className={styles.xiaoxi}>
                    <Badge count={message_count} offset={[-5, 20]} size="small">
                        <Menu.Item
                            key={"/messages"}
                            icon={<img src={消息}/>}
                            className={styles.menuItem}
                        >
                            <Link to={"/messages"}>
                                消息
                            </Link>
                        </Menu.Item>
                    </Badge>
                </div>
            </Menu>
            <div>
                <img src={logo2} width={56} height={59} style={{margin:"30px 12px"}}/>
            </div>
        </div>
    )
};

/**
 * 属性
 * @type {{path: "页面路径", onHover: function}}
 */
FirstSider.propTypes = {
    path: PropTypes.string,
    onHover: PropTypes.func,
    signOut: PropTypes.func,
};


const mapStateToProps1 = state => ({
    // school: state.school,
    account: state.account,
    message_count: state.message_count,
});

const mapDispatchToProps1 = dispatch => ({
    flushMessageCount: () => {
        (async () => {
            dispatch(await flushMessageCount())
        })().catch()
    },

    signOut: () => dispatch(signOut()),
    showPasswordModal: () => dispatch(showPasswordModal()),
});


export default connect(
    mapStateToProps1,
    mapDispatchToProps1
)(FirstSider);


// export default FirstSider;