/**
 * @name: 班级详情
 * @description:
 * @author: Wood
 * @date: 2019/3/28
 * @update: 2019/2/24
 */
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Button,
    Table,
    Space,
    Modal,
    Typography, Divider,
} from 'antd';
import moment from 'moment';
import CardDetailCiKaModal from './card-detail-cika-modal';
import styles from './member_cards.module.css';
import {ExclamationCircleOutlined, FileTextOutlined} from '@ant-design/icons';
import MemberEndSpan from './member_end_span';
import {DeleteOutlined} from '@ant-design/icons';
import UsersCardsShiXiaoModal from '../users_cards/users_cards_shixiao_modal';
import UsersCardsStatus from '../users_cards/users_cards_status';
import {getMembershipCards} from "../../../lib/api_membership";
import {deleteUsersCards} from "../../../lib/api_users_cards";

const {Column} = Table;
const {confirm} = Modal;


const MemberCards = ({id, update, onChange}) => {
    const [cards, setcards] = useState([]);
    const [cikadetail, setcikadetail] = useState(null);
    const [shixiao, setshixiao] = useState(null);

    const fetchData = () => {
        getMembershipCards(id)
            .then(res => {
                if (res.code === 0) {
                    setcards(res.data)
                }
            })
    };

    useEffect(fetchData, [update]);


    const handleDelete = (record) => {
        // let {id} = this.props.data;
        confirm({
            title: '确定要删除这条记录吗？',
            icon: <ExclamationCircleOutlined/>,
            content: '删除后数据无法恢复，请谨慎操作！',
            okText: '确定删除',
            okType: 'danger',
            cancelText: '不删除',
            onOk: () => {
                deleteUsersCards(record.id)
                    .then(res => {
                        if (res.code === 0) {
                            fetchData();
                            onChange && onChange();
                            // this.loadStudentInfo()
                            // this.setState({})
                            // this.props.onClose && this.props.onClose();
                        } else {
                            // message.error(res.message);
                        }
                    })

            },
            onCancel() {
                console.log('Cancel');
            },
        });

    };


    return (
        <div>
            <Table
                dataSource={cards}
                rowKey={"id"}
                pagination={false}
                size="small"
                rowClassName={(record, index) => {
                    return record.status === 1 ? styles.disabled : ""
                }}
                scroll={{
                    x: 1000,
                }}
            >
                {/*<Column*/}
                {/*    title="编号"*/}
                {/*    dataIndex="id"*/}
                {/*    key="id"*/}
                {/*    width={50}*/}
                {/*/>*/}
                <Column
                    title="状态"
                    dataIndex="status"
                    key="status"
                    width={50}
                    fixed="left"
                    render={(status) => <UsersCardsStatus status={status}/>}
                />
                <Column
                    title="名称"
                    dataIndex="name"
                    key="name"
                />
                <Column
                    title="开始日期"
                    dataIndex="begin_time"
                    key="begin_time"
                    width={120}
                    render={(begin_time) => {
                        return moment(begin_time).format("YYYY-MM-DD")
                    }}
                />
                <Column
                    title="结束日期"
                    dataIndex="end_time"
                    width={120}
                    key="end_time"
                    render={(end_time) => <MemberEndSpan date={end_time}/>}
                />
                <Column
                    title="正课"
                    key="正课"
                    width={80}
                    align="right"
                    render={(record) => {
                        return `${record.keshi_remaining}/${record.keshi_original}`;
                    }}
                />
                <Column
                    title="赠课"
                    key="赠课"
                    width={80}
                    align="right"
                    render={(record) => {
                        return `${record.keshi_free_remaining}/${record.keshi_free_original}`;
                    }}
                />

                <Column
                    title="总支付"
                    dataIndex="payments"
                    key="总支付"
                    width={100}
                    align="right"
                    render={(payments, record) => {
                        return payments.toFixed(2);
                    }}
                />
                <Column
                    title="剩余金额"
                    dataIndex="payments_remaining"
                    key="剩余金额"
                    width={100}
                    align="right"
                    render={(payments_remaining, record) => {
                        return payments_remaining.toFixed(2);
                    }}
                />
                <Column
                    title="均价"
                    dataIndex="payments_avg"
                    key="均价"
                    width={80}
                    align="right"
                    render={(payments_avg, record) => {
                        return payments_avg.toFixed(2);
                    }}
                />
                {/*<Column*/}
                {/*title="相关课程"*/}
                {/*dataIndex="courses_type"*/}
                {/*key="courses_type"*/}
                {/*render={(courses_type) => {*/}
                {/*return this.props.courses_type.filter(item => courses_type.split(',').includes(item.id + '')).map(item => item.name).join(',')*/}
                {/*}}*/}
                {/*/>*/}
                <Column
                    title="操作"
                    key="操作"
                    align="right"
                    width={140}
                    fixed="right"
                    render={(record) => {
                        // cardEditModal
                        let arr = [];

                        if (record.card_type === 0) {
                            // arr.push(<Button key="1"
                            //                  onClick={() => this.setState({cardEditModal: record})}>修改</Button>);
                            arr.push(<Typography.Link key="1"
                                             onClick={() => setcikadetail(record.id)}><FileTextOutlined/> 详情</Typography.Link>);
                        } else {
                            arr.push(<Typography.Link key="1"
                                             onClick={() => this.setState({cardDetailModal: record})}><FileTextOutlined/> 详情</Typography.Link>);
                        }


                        // if (record.card_type === 1) {
                        //     arr.push(
                        //         <Button key="2" type="primary"
                        //                 onClick={() => this.setState({chongzhiModal: record})}>
                        //             充值
                        //         </Button>
                        //     );
                        // }
                        // console.log(record);
                        if (record.keshi_original === record.keshi_remaining && record.keshi_free_original === record.keshi_free_remaining) {
                            arr.push(<Typography.Link type="danger" key="3"
                                             onClick={() => handleDelete(record)}><DeleteOutlined /> 删除</Typography.Link>);
                        } else {
                            arr.push(<Typography.Link type="danger" onClick={() => setshixiao(record)}
                                             disabled={record.status === 1}><DeleteOutlined /> 失效</Typography.Link>)

                        }

                        return <Space split={<Divider type="vertical"/>}
                                      size={0}>{arr}</Space>;
                    }}
                />

            </Table>
            {cikadetail &&
            <CardDetailCiKaModal
                onOK={() => {
                    fetchData();
                    setcikadetail(null);
                }}
                onClose={() => {
                    fetchData();
                    setcikadetail(null);
                }}
                id={cikadetail}
            />
            }

            {shixiao &&
            <UsersCardsShiXiaoModal
                data={shixiao}
                onCancel={() => {
                    setshixiao(null);
                }}
                onOk={() => {
                    setshixiao(null);
                    fetchData()
                }}
            />
            }
        </div>
    )
};


MemberCards.propTypes = {
    id: PropTypes.number.isRequired,
};


const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberCards));
