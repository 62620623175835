import React, {useState, useEffect} from 'react';
import {
    Button,
    Col,
    DatePicker,
    message,
    Row,
    Space,
    Table,
    Tree,
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import Box from '../../components/box';
import moment from "moment/moment";
import {getwfl, getyfl, postadd, postfromqywx, postremove, postmuti} from "../../lib/api_cw_zcdj";
import {getcwcoaAll} from "../../lib/api_cw_coa";
import Decimal from "decimal.js";


const {Column} = Table;


const Zcdj = (props) => {
    const [data, setData] = useState([]);
    const [coa_data, setCoaData] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [coa_id, setCoaId] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRows2, setSelectedRows2] = useState([]);
    const [yfl_data, setYflData] = useState([]);
    const [month, setmonth] = useState(moment());
    const [md, setmd] = useState("门店1");


    /**
     * 获取未分类
     */
    const fetchWFL = () => {
        getwfl(month.format("YYYY-MM"))
            .then((res) => {
                if (res.code === 0) {
                    setData(res.data);
                }
            })
    }
    useEffect(fetchWFL, [month]);


    /**
     * 获取已经分类
     */
    const fetchYFL = () => {
        getyfl(month.format("YYYY-MM"), md)
            .then((res) => {
                if (res.code === 0) {
                    setYflData(res.data);
                }
            })
    }

    useEffect(fetchYFL, [month, md]);

    const fetchCoa = () => {
        getcwcoaAll()
            .then((res) => {
                if (res.code === 0) {
                    console.log(res.data);
                    setExpandedKeys(res.data.map(item => item.id));
                    setCoaData(listToTree(res.data, 2))
                }
            })
    }
    useEffect(fetchCoa, [yfl_data]);

    // 递归将列表转成antd的树结构，父节点为pid，pid为0的为顶级节点
    const listToTree = (list, pid = 0) => {
        const tree = [];

        list.forEach(item => {
            if (item.pid === pid) {
                const children = listToTree(list, item.id);
                if (children.length) {
                    item.children = children;
                } else {
                    // 过滤并求和je字段
                    let je = new Decimal(0);
                    yfl_data.filter(yfl => yfl.coa_id === item.id).forEach(yfl => {
                        je = je.add(new Decimal(yfl.je));
                    })
                    // 将合计内容添加到name字段
                    item.name = `${item.name}(${je.toFixed(2)})`;
                }
                item.selectable = children.length === 0;
                tree.push(item);
            }
        });

        return tree;
    }


    // const deleteSrdj = (id) => {
    //     deletecwsrdj(id).then((res) => {
    //         if (res.code === 0) {
    //             fetchData();
    //         }
    //     })
    // }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedRows(selectedRowKeys);
        },
    };
    const rowSelection2 = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedRows2(selectedRowKeys);
        },
    };

    const onSelect = (selectedKeys, info) => {
        if (selectedKeys.length > 0) {
            setCoaId(selectedKeys[0]);
        }
    };

    const handleAdd = () => {
        if (coa_id === 0) {
            message.error("请选择科目");
            return;
        }

        postadd(selectedRows, coa_id, md).then((res) => {
            if (res.code === 0) {
                fetchWFL();
                fetchYFL();
            }
        });
    }

    const handleRemove = () => {
        if (selectedRows2.length === 0) {
            message.error("请选择要删除的数据");
            return;
        }
        postremove(selectedRows2)
            .then((res) => {
                if (res.code === 0) {
                    fetchWFL();
                    fetchYFL()
                }
            })
    }


    return (
        <PageContent>

            <Row gutter={[12, 12]} align="middle" justify={"space-between"}>
                <Col span={24}>
                    月份：<DatePicker value={month} onChange={(value) => setmonth(value)} picker="month"
                                     allowClear={false}/>
                </Col>
                <Col span={11}>
                    <Box title={"马术支出未分类"}
                         bodyStyle={{height: "700px", overflow: "auto"}}
                    >
                        <Table
                            dataSource={data}
                            rowKey={"id"}
                            pagination={false}
                            size={"small"}
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                            }}
                            scroll={{
                                x: 700,
                                y: 600,
                            }}
                        >
                            {/*自动编号*/}
                            <Column title="审批编号" dataIndex="sp_no" key="审批编号" width={130} fixed={'left'}/>
                            <Column title="来源" dataIndex="ly" key="来源" width={100} fixed={'left'}/>
                            <Column title="付款日期" dataIndex="fkrq" key="付款日期" width={110}/>
                            <Column title="金额" dataIndex="je" key="金额" width={100}/>
                            <Column title="备注" dataIndex="bz" key="备注"/>
                        </Table>
                    </Box>
                </Col>
                <Col span={2}>
                    <Space gutter={[12, 12]} direction={"vertical"}>
                        <Button onClick={handleAdd} disabled={coa_id === 0 || selectedRows.length === 0}>添加</Button>
                        <Button onClick={handleRemove} disabled={selectedRows2.length === 0}>移除</Button>
                    </Space>
                </Col>
                <Col span={11}>
                    <Box title={"科目表"} bodyStyle={{height: "700px", overflow: "auto"}}>
                        <div style={{height: 300, overflow: "auto"}}>
                            <Tree
                                // checkable
                                // defaultExpandedKeys={['0-0-0', '0-0-1']}
                                // defaultSelectedKeys={['0-0-0', '0-0-1']}
                                // defaultCheckedKeys={['0-0-0', '0-0-1']}
                                // onSelect={onSelect}
                                // onCheck={onCheck}
                                expandedKeys={expandedKeys}
                                fieldNames={{
                                    title: "name",
                                    key: "id",
                                }}
                                treeData={coa_data}
                                onSelect={onSelect}
                                selectedKeys={[coa_id]}
                            />
                        </div>
                        <div style={{height: 339}}>
                            <Table
                                dataSource={yfl_data.filter((item) => item.coa_id === coa_id)}
                                rowKey={"id"}
                                pagination={false}
                                size={"small"}
                                rowSelection={{
                                    type: 'checkbox',
                                    ...rowSelection2,
                                }}
                                scroll={{
                                    x: 600,
                                    y: 300,
                                }}
                            >
                                {/*自动编号*/}
                                <Column title="审批编号" dataIndex="sp_no" key="审批编号" width={130}
                                        fixed={'left'}/>
                                <Column title="付款日期" dataIndex="fkrq" key="付款日期" width={110}/>
                                <Column title="金额" dataIndex="je" key="金额" width={80}/>
                                <Column title="备注" dataIndex="bz" key="备注"/>
                            </Table>
                        </div>
                    </Box>
                </Col>
            </Row>
        </PageContent>
    )
}

export default Zcdj;