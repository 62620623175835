
import axios from './rest-axios';

const getCoursesFee = () => axios.get(`/manage/courses_fee`);
const postCoursesFee = (model) => axios.post(`/manage/courses_fee`, model);
const putCoursesFee = (model) => axios.put(`/manage/courses_fee/${model.id}`, model);
const deleteCoursesFee = (id) => axios.delete(`/manage/courses_fee/${id}`);

export {
    getCoursesFee,
    postCoursesFee,
    putCoursesFee,
    deleteCoursesFee,
};
