import React, {useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Button, Card, Checkbox, Form, Input, Select, Slider, Spin, Switch} from "antd";
import {getConfig, putConfig} from "../../../lib/api_config";
import TZSelect from "./tz_select";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};

const Config = () => {
    const [form] = Form.useForm();
    const [config, setConfig] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        setLoading(true);
        getConfig("config")
            .then(res => {
                let config = JSON.parse(res.data.config);
                config = {
                    ...config,
                    shenhe_xinhuiji: false,
                };
                setConfig(config);
                form.setFieldsValue(config);
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(fetchData, []);


    const handleSubmit = (values) => {
        console.log('Success:', values);
        putConfig("config", JSON.stringify(values))
            .then(res => {
                if (res.code === 0) {
                    fetchData();
                }
            })
            .catch(err => {
                console.log('Failed:', err);
            });
    }
    return (
        <PageContent>
            <Spin spinning={loading}>
                <Card bordered={false} title={"系统配置"}>
                    <Form
                        {...formItemLayout}
                        form={form}
                        onFinish={handleSubmit}
                    >
                        {/*<Form.Item*/}
                        {/*    label="小组课主教比例"*/}
                        {/*    name="xiaozu_zhujiao_bili"*/}
                        {/*>*/}
                        {/*    <Slider max={1} min={0} step={0.01}/>*/}
                        {/*</Form.Item>*/}
                        <Form.Item label={"通知配置"}>
                            {/*<Form.Item*/}
                            {/*    label="会籍生效"*/}
                            {/*    name="tz_hjsx"*/}
                            {/*    valuePropName="checked"*/}
                            {/*>*/}
                            {/*    <Checkbox.Group options={[*/}
                            {/*        {label:"责任教练", value:"责任教练"},*/}
                            {/*        {label:"专属客服", value:"专属客服"},*/}
                            {/*        {label:"前台web端", value:"前台web端"},*/}
                            {/*        {label:"指定对象", value:"指定对象"},*/}
                            {/*    ]}/>*/}
                            {/*</Form.Item>*/}
                            <Form.Item
                                label="会籍生效"
                                name="tz_hjsx"
                            >
                                <TZSelect/>
                            </Form.Item>
                            <Form.Item
                                label="会籍失效"
                                name="tz_hjshixiao"
                            >
                                <TZSelect/>
                            </Form.Item>
                            <Form.Item
                                label="休卡"
                                name="tz_xiuka"
                            >
                                <TZSelect/>
                            </Form.Item>
                            <Form.Item
                                label="延长有效期"
                                name="tz_yanchang"
                            >
                                <TZSelect/>
                            </Form.Item>
                            <Form.Item
                                label="小程序预约"
                                name="tz_xcxyuyue"
                            >
                                <TZSelect/>
                            </Form.Item>
                            <Form.Item
                                label="小程序取消"
                                name="tz_xcxquxiaoyuyue"
                            >
                                <TZSelect/>
                            </Form.Item>
                        </Form.Item>


                        <Form.Item
                            wrapperCol={{
                                offset: 6,
                                span: 18,
                            }}
                        >
                            <Button size={"large"} type={"primary"} htmlType="submit">保存</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Spin>
        </PageContent>
    )
}

export default Config;
