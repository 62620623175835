import PropTypes from 'prop-types';
import React,{useEffect,useState} from 'react';
import {Modal, Form} from 'antd';
import {getCard,putCard} from "../../../lib/api_cards";
import CardForm from './cards-form';

const CardsEditModal = ({id, onOK, onClose}) => {
    const [form] = Form.useForm();
    const [data, setdata] = useState(null);

    useEffect(()=>{
        getCard(id)
            .then(res=>{
                if(res.code === 0){
                    setdata(res.data)
                }
            })
    }, []);

    const handleOK = () => {
        onOK && onOK();
    };

    const handleClose = () => {
        onClose && onClose();
    };

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log(values)
                values.id = data.id;
                values.holiday_use = values.holiday_use ? 1 : 0;
                values.weekdays_use = values.weekdays_use ? 1 : 0;
                values.special_use = values.special_use ? 1 : 0;
                values.expired_date = values.expired_date ? values.expired_date.format("YYYY-MM-DD") : null;
                putCard(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        handleOK();
                    })
            })
    };

    return (
        <Modal
            title="修改次卡信息"
            visible={!!data}
            onOk={handleSubmit}
            onCancel={handleClose}
            destroyOnClose={true}
        >
            <CardForm form={form} initialValues={data}/>
        </Modal>
    )
}


CardsEditModal.propTypes = {
    onClose: PropTypes.func,
    onOK: PropTypes.func,
    id: PropTypes.number
};

export default CardsEditModal;
