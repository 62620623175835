/**
 * @name: 默认页面布局
 * @description:
 * @author: Wood
 * @date: 2019/4/17
 * @update: 2019/4/24
 */
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import {Layout,PageHeader} from 'antd';
import styles from './page-content.module.css';
const {Header, Content} = Layout;

function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;
    // console.log(paths);
    return last ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
    );
}
const PageContent = ({children, title, subTitle, routes,extra, headerContent}) => (
    <>
    <Header className={styles.headerWrapper}>
        {/*<Title level={2}>{title}</Title>*/}
        <PageHeader
            title={title}
            breadcrumb={{itemRender, routes}}
            subTitle={subTitle}
            extra={extra}
        >
            {headerContent}
        </PageHeader>
    </Header>
    <Content className={styles.contentWrapper}>
        <div className={styles.content}>
            {children}
        </div>
    </Content>
    </>
);

PageContent.propTypes = {
    title: PropTypes.any,
    wrapperClassName: PropTypes.string,
};


export default PageContent;