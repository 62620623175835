import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    Button,
    Divider,
    Descriptions,
    Space,
    message,
    Modal,
    Tag,
    Row,
    Col,
    Popconfirm,
} from 'antd';
import moment from 'moment';
import {
    postYuYue,
    postYuYueShangma,
    postYuYueWancheng,
    postYuYueQuxiao,
    postYuYueQuXiaoShangma,
    postYuYueDelete,
    postYuYueUnlock,
    postYuYueLock,
} from "../../lib/api_yuyue";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import StaffName from '../../components/staff_name';
import YuyueTypeName from '../../components/yuyue_type_name';
import YuyueStatus from '../../components/yuyue_status';
import {teacher_level_price} from "../../config";
import DictName from '../../components/dict/dict-name';
import MemberCardName from './member-card-name';
import HorseSelectModal from "./horse_select_modal";
import SigningModal from "./signing_modal";
import FuKuanModal from "./fukuan_modal";
import KuanDaiModal from "./kuandai_modal";

const {confirm} = Modal;

class YuyueDetail extends Component {

    state = {
        showHorseSelectModal: false,
        showHorseSelectModalYuyueId: 0,
        showSigningModal:0,
        showFuKuanModal:0,
        showKuanDaiModal:0,
    };

    componentDidMount() {
    }


    handleShangma = () => {
        let {id} = this.props.data;
        this.setState({
            showHorseSelectModal: true,
            showHorseSelectModalYuyueId: id,
        });

    };
    handleWancheng = () => {
        let {id} = this.props.data;
        postYuYueWancheng(id)
            .then(res => {
                if (res.code === 0) {
                    this.props.data.status = 2;
                    this.props.onOk && this.props.onOk();
                    this.setState({})
                }
            })
    };

    handleQuXiao = () => {
        let {id} = this.props.data;
        postYuYueQuxiao(id)
            .then(res => {
                if (res.code === 0) {
                    this.props.onClose && this.props.onClose();
                } else {
                    message.error(res.message);
                }
            })
        // confirm({
        //     title: '确定要删除这条预约信息吗？',
        //     icon: <ExclamationCircleOutlined/>,
        //     content: '删除后数据无法恢复，请谨慎操作！',
        //     okText: '确定删除',
        //     okType: 'danger',
        //     cancelText: '不删除',
        //     onOk: () => {
        //         postYuYueQuxiao(id)
        //             .then(res => {
        //                 if (res.code === 0) {
        //                     this.props.onClose && this.props.onClose();
        //                 } else {
        //                     message.error(res.message);
        //                 }
        //             })
        //
        //     },
        //     onCancel() {
        //         console.log('Cancel');
        //     },
        // });

    };
    handleDelete = () => {
        let {id} = this.props.data;
        postYuYueDelete(id)
            .then(res => {
                if (res.code === 0) {
                    this.props.onClose && this.props.onClose();
                } else {
                    // message.error(res.message);
                }
            })
    };
    handleUnlock = () => {
        let {id} = this.props.data;
        postYuYueUnlock(id)
            .then(res => {
                if (res.code === 0) {
                    message.success("已解锁");
                    this.props.onClose && this.props.onClose();
                } else {
                    // message.error(res.message);
                }
            })
    };
    handleLock = () => {
        let {id} = this.props.data;
        postYuYueLock(id)
            .then(res => {
                if (res.code === 0) {
                    message.success("已锁定");
                    this.props.onClose && this.props.onClose();
                } else {
                    // message.error(res.message);
                }
            })
    };

    handleQuXiaoShangma = () => {
        let {id} = this.props.data;
        confirm({
            title: '确定要取消上马吗？',
            icon: <ExclamationCircleOutlined/>,
            // content: '删除后数据无法恢复，请谨慎操作！',
            okText: '确定取消',
            okType: 'danger',
            cancelText: '不取消',
            onOk: () => {
                postYuYueQuXiaoShangma(id)
                    .then(res => {
                        if (res.code === 0) {
                            this.props.data.status = 0;
                            this.props.onOk && this.props.onOk();
                            this.setState({})
                        } else {
                            message.error(res.message);
                        }
                    })

            },
            onCancel() {
                console.log('Cancel');
            },
        });

    };


    renderMembers = (menberstr) => {
        let text_arr = [];
        if (menberstr) {
            let members = JSON.parse(menberstr);
            if (members && members.length > 0) {
                members.forEach(member => {
                    text_arr.push(<Link to={`/member/members/${member.user_id}`}>{member.nick_name}</Link>);
                    text_arr.push(",");
                    text_arr.push(<MemberCardName card_id={member.card_id}/>);
                    text_arr.push(",");
                    text_arr.push(member.mobile_number);
                    text_arr.push(",");
                    text_arr.push(member.license_plate);
                    text_arr.push(<br/>);

                });
                // todo
            }
        }
        return text_arr;
    };

    renderType = (menberstr) => {
        if (menberstr) {
            let members = JSON.parse(menberstr);
            if (members && members.length > 0) {
                return members.length === 1 ? "1V1" : "小组"
            }
        }
        return null;
    };

    renderTeachers = (teachers) => {
        console.log(teachers);
        teachers = teachers ? JSON.parse(teachers) : [];
        console.log(teachers);
        return teachers.map(item => this.props.staff.find(staf => staf.id === item).name).join(',')
    }

    handleQianYue = () => {
        let {id} = this.props.data;
        this.setState({
            showSigningModal: id,
        });
    }

    render() {
        let {data, onEdit} = this.props;
        let extra_time = 0;
        console.log(data);
        switch (data.duration) {
            case 15:
                extra_time = 5;
                break;
            case 30:
                extra_time = 10;
                break;
            case 45:
                extra_time = 10;
                break;
            case 90:
                extra_time = 10;
                break;
            default:
                extra_time = 0;
                break;
        }

        // 获取卡片信息

        let {users_cards, staff} = this.props;
        let card_str = "";
        if (data.yuyue_type === 1) {
            // 会员
            // 获取卡片信息
            let card = users_cards.find(item => item.id === data.card_id);
            if (card) {
                console.log("card", card);
                if (card.card_type === 0) {
                    card_str = `${card.name}（消${data.anshi}/共${card.keshi_remaining}）`;
                } else if (card.card_type === 1) {
                    // 获取教练价格
                    let teacher = staff.find(_ => _.id === data.teacher);
                    console.log(teacher);
                    if (teacher) {
                        let price = teacher_level_price.find(_ => _.id === teacher.teacher_level);
                        card_str = `${card.name}（消${data.anshi * price.price}/共${card.payments_remaining}）`;
                    } else {
                        card_str = `未找到教练`
                    }
                    // teacher_level_price
                    // card_str = `${card.name}（消${data.anshi}/共${card.payments_remaining}）`;
                }
            }
        }

        return (
            <div style={{display: "flex", flexDirection: "column", height: "100%"}}>

                <div style={{flex: 1, overflow: "auto", fontSize: 20}}>
                    <Descriptions title={
                        <Row>
                            <Col flex={"auto"}>{data.yuyue_type !== 3 ? data.nick_name : ""}</Col>
                            <Col>
                                <Tag><YuyueTypeName id={data.yuyue_type}/></Tag>
                                <YuyueStatus id={data.status}/>
                            </Col>
                        </Row>}
                                  column={1}
                                  contentStyle={{fontSize: 18}}
                                  labelStyle={{fontSize: 18, color: "#999999"}}
                    >

                        {(data.yuyue_type === 1 || data.yuyue_type === 3) &&
                        <Descriptions.Item>
                            <div>{this.renderMembers(data.members)}</div>
                        </Descriptions.Item>
                        }
                        {(data.yuyue_type === 1 ) &&
                        <Descriptions.Item label="类型">
                            <div>{this.renderType(data.members)}</div>
                        </Descriptions.Item>
                        }
                        {(data.yuyue_type === 3) &&
                        <Descriptions.Item label="类型">
                            <div>小组预排课</div>
                        </Descriptions.Item>
                        }
                        {card_str &&
                        <Descriptions.Item label="卡片">{card_str}</Descriptions.Item>
                        }

                        <Descriptions.Item label="课程">
                            <DictName type_value={"course_type"} value={data.type}/>
                        </Descriptions.Item>
                        {(data.yuyue_type === 1 || data.yuyue_type === 3) &&
                        <Descriptions.Item label="鞍时">{data.anshi}鞍时（{data.duration}min）</Descriptions.Item>
                        }
                        {(data.yuyue_type === 0 || data.yuyue_type === 2) &&
                        <Descriptions.Item label="时长">{data.duration}min（休{extra_time}min）</Descriptions.Item>
                        }
                        <Descriptions.Item label="日期">{moment(data.begin_time).format("YYYY-MM-DD")}</Descriptions.Item>
                        <Descriptions.Item label="时间">
                            {moment(data.begin_time).format("HH:mm")}
                            - {moment(data.end_time).add(extra_time, "m").format("HH:mm")}</Descriptions.Item>
                        <Descriptions.Item label="教练"><StaffName id={data.teacher}/></Descriptions.Item>
                        {(data.yuyue_type === 1 || data.yuyue_type === 3) &&
                        <Descriptions.Item label="助教">{this.renderTeachers(data.teachers)}</Descriptions.Item>
                        }
                        {(data.yuyue_type === 0 || data.yuyue_type === 2) &&
                        <>
                            <Descriptions.Item label="姓名/房号">{data.nick_name}</Descriptions.Item>
                            <Descriptions.Item label="身高">{data.height}</Descriptions.Item>
                            <Descriptions.Item label="年龄">{data.age}</Descriptions.Item>
                        <Descriptions.Item label="电话">{data.mobile_number}</Descriptions.Item>
                        <Descriptions.Item label="车牌">{data.license_plate}</Descriptions.Item>

                        </>
                        }
                        {(data.yuyue_type === 0) &&
                        <Descriptions.Item label="类型">{data.user_type}</Descriptions.Item>
                        }
                        <Descriptions.Item label="备注">{data.remark}</Descriptions.Item>
                        {data.yuyue_type === 2 &&
                        <Descriptions.Item label="顾问"><StaffName id={data.consultant}/></Descriptions.Item>
                        }
                        {data.yuyue_type === 4 &&<>
                            <Descriptions.Item label="内容">{data.qita_type}</Descriptions.Item>
                            {data.qita_type==="训练" && <>
                                <Descriptions.Item label="马匹">{data.horse_name}</Descriptions.Item>
                                <Descriptions.Item label="训练内容">{data.qita_xlnr}</Descriptions.Item>
                            </>}
                        </>
                        }
                        <Descriptions.Item label="创建">{data.createdAt} <StaffName
                            id={data.create_by}/></Descriptions.Item>
                        <Descriptions.Item label="更新">{data.updatedAt} <StaffName
                            id={data.update_by}/></Descriptions.Item>
                    </Descriptions>

                </div>
                <div style={{}}>
                    <Space direction="vertical" style={{width: "100%"}}>
                        <Button type={"primary"} size={"large"} block onClick={this.handleQianYue}>签约</Button>
                        {(data.status === 0 || !data.id) && !data.is_lock &&
                        <Button type={"primary"} size={"large"} block onClick={onEdit}>修改</Button>
                        }
                        {(data.status === 0 && data.yuyue_type !== 4) &&
                        <Button type={"primary"} size={"large"} block onClick={this.handleShangma}>已上马</Button>
                        }
                        {data.status === 1 &&
                        <div>
                            <Space direction="vertical" style={{width: "100%"}}>
                                <Button default size={"large"} block onClick={onEdit}>修改</Button>
                                <Button danger size={"large"} block onClick={this.handleQuXiaoShangma}>取消上马</Button>
                            </Space>
                            <Divider/>
                            <Button type={"primary"} size={"large"} block onClick={this.handleWancheng}>下课</Button>
                        </div>
                        }
                        {(data.yuyue_type===0 || data.yuyue_type===2)&&
                            <Button  type={"primary"} size={"large"} block onClick={()=>this.setState({showFuKuanModal:this.props.data.id})}>修改付款信息</Button>
                        }

                        {(data.yuyue_type===0 || data.yuyue_type===2)&&
                            <Button  type={"primary"} size={"large"} block onClick={()=>this.setState({showKuanDaiModal:this.props.data.id})}>关联款待审批</Button>
                        }


                    </Space>
                    {data.is_confirm === 0 && data.status === 0 && !data.is_lock &&
                    <div>
                        <Divider/>
                        <Popconfirm
                            title="删除后数据无法恢复，请谨慎操作！"
                            onConfirm={this.handleQuXiao}
                            // onCancel={cancel}
                            okText="确定删除"
                            okType='danger'
                            cancelText="不删除"
                        >
                            <Button danger size={"large"} block>取消预约</Button>
                        </Popconfirm>
                    </div>
                    }

                    {data.is_confirm === 0 && data.status === 2 && !data.is_lock &&
                    <div>
                        <Divider/>
                        <Popconfirm
                            title="删除后数据无法恢复，请谨慎操作！"
                            onConfirm={this.handleDelete}
                            // onCancel={cancel}
                            okText="确定删除"
                            okType='danger'
                            cancelText="不删除"
                        >
                            <Button danger size={"large"} block>删除</Button>
                        </Popconfirm>
                    </div>
                    }

                    {data.yuyue_type!==4 ? (data.is_lock === 1 ?
                        <div style={{marginTop: 10}}>
                            <Button size={"large"} block onClick={this.handleUnlock}>解锁</Button>
                        </div>
                        :
                        <div style={{marginTop: 10}}>
                            <Button size={"large"} block onClick={this.handleLock}>锁定</Button>
                        </div>): null}
                </div>
                <HorseSelectModal
                    visible={this.state.showHorseSelectModal}
                    yuyue_id={this.state.showHorseSelectModalYuyueId}
                    onOk={()=>{
                        this.setState({
                            showHorseSelectModal:false,
                            showHorseSelectModalYuyueId:0,
                        });
                        this.props.data.status = 1;
                        this.props.onOk && this.props.onOk();
                    }}
                    onCancel={()=>{
                        this.setState({
                            showHorseSelectModal:false,
                            showHorseSelectModalYuyueId:0,
                        })
                    }}
                />
                {this.state.showSigningModal>0 &&
                    <SigningModal
                        visible={!!this.state.showSigningModal}
                        yuyue_id={this.state.showSigningModal}
                        onCancel={()=>{
                            this.setState({
                                showSigningModal:0,
                            })
                        }}
                    />
                }
                {this.state.showFuKuanModal>0 &&
                    <FuKuanModal
                        visible={!!this.state.showFuKuanModal}
                        id={this.state.showFuKuanModal}
                        onCancel={()=>{
                            this.setState({
                                showFuKuanModal:0,
                            })
                        }}
                        onOk={()=>{
                            this.setState({
                                showFuKuanModal:0,
                            })
                        }}
                    />
                }

                {this.state.showKuanDaiModal>0 &&
                    <KuanDaiModal
                        visible={!!this.state.showKuanDaiModal}
                        id={this.state.showKuanDaiModal}
                        onCancel={()=>{
                            this.setState({
                                showKuanDaiModal:0,
                            })
                        }}
                        onOk={()=>{
                            this.setState({
                                showKuanDaiModal:0,
                            })
                        }}
                    />
                }

            </div>
        )
    }
}

YuyueDetail.propTypes = {
    // 原始记录
    data: PropTypes.object,
    onEdit: PropTypes.func,
};


const mapStateToProps = state => ({
    school: state.school,
    staff: state.staff,
    users_cards: state.users_cards
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(YuyueDetail));

