import React, {} from 'react';
import {Form} from 'antd';
import '@ant-design/compatible/assets/index.css';
import {Modal, Input, message} from 'antd';
import md5 from 'md5';
import {resetPassword} from "../../../lib/api_membership";

/**
 * 重置密码弹窗
 * @param visible
 * @param id
 * @param onOk
 * @param onCancel
 * @returns {JSX.Element}
 * @constructor
 */
const MembershipsResetModal = ({visible, id, onOk, onCancel}) => {
    const [form] = Form.useForm();

    const handleCancel = () => {
        onCancel && onCancel();
    };

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields()
            .then(values => {
                console.log(values);
                resetPassword(id, md5(values.password))
                    .then(res => {
                        if (res.code === 0) {
                            message.success("重置成功").then(() => {
                            });
                            onOk && onOk();
                        }
                    })
            })
    };

    return (
        <Modal
            title="重置密码"
            visible={visible}
            onOk={handleSubmit}
            onCancel={handleCancel}
            destroyOnClose={true}
        >
            <div>
                {/*姓名：{this.props.user_info && this.props.user_info.nick_name}<br/>*/}
                {/*手机号：{this.props.user_info && this.props.user_info.mobile_number}*/}
            </div>
            <Form onSubmit={handleSubmit} form={form}>
                <Form.Item label="重置密码"
                           name="password"
                           rules={[{
                               required: true,
                               message: '请输入密码',
                           }]}
                           initialValue="jec8052400"
                >
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default MembershipsResetModal;
