/**
 * @name: 账户信息相关接口
 * @description:
 * @author: Wood
 * @date: 2019/3/26
 * @update: 2019/4/24
 */
import axios from './rest-axios';

const getWeather = () => axios.get('/manage/weather');

export {
    getWeather,
};
