import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import styles from './memberships_type_select.module.css';
import {getCards} from "../../../lib/api_cards";
import moment from "moment";

/**
 * 次卡选择器
 * @param value
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
const MembershipsCardSelect = ({value, onChange}) => {
    const [list, setList] = useState([]);

    /**
     * 获取会籍类型数据
     */
    const fetchData = () => {
        getCards()
            .then(res => {
                if (res.code === 0) {
                    setList(res.data.filter(m => m.status === 0))
                }
            })
    }

    useEffect(fetchData, []);

    const handleChange = (value) => {
        onChange && onChange(value, list.find(m => m.id === value))
    };

    const getLabel =(m)=>{
        let str = `${m.name} / ￥${m.retail_price} / `;
        if(m.time_type===0){
            str += `开卡后${m.limit_days}天内可使用 / `;
        }else {
            str += `固定日期：到${moment(m.expired_date).format("YYYY-MM-DD")}之前可用 / `;
        }
        if(m.holiday_use === 1 ) str += '周末';
        if(m.weekdays_use === 1 ) str += '工作日';
        if(m.special_use === 1 ) str += '特殊日期';
        return str;
    }

    return (<Select
        style={{
            width: '100%',
        }}
        placeholder="选择会籍类型"
        // defaultValue={['china']}
        defaultValue={value}
        onChange={handleChange}
        optionLabelProp="label"
    >
        {list.map(m => (
            <Select.Option value={m.id} label={(<>{getLabel(m)}</>)} key={m.id}>
                <div className={styles.option}>
                    <span style={{flex: 1}}>{m.name}</span>
                    <span style={{width: 100}}>￥{m.retail_price}</span>
                    {m.time_type===0
                        ?(<span style={{width: 200}}>
                            开卡后{m.limit_days}天内可使用
                        </span>)
                    :(
                        <span style={{width: 200}}>
                            固定日期：到{moment(m.expired_date).format("YYYY-MM-DD")}之前可用
                        </span>
                        )}
                    <span style={{width: 70}}>{m.holiday_use === 1 && "周末"}
                        {m.weekdays_use === 1 && "工作日"}
                        {m.special_use === 1 && "特殊日期"}</span>
                </div>
            </Select.Option>
        ))}

    </Select>)
}

export default MembershipsCardSelect;