/**
 * @name: reducer集合
 * @description:
 * @author: Wood
 * @date: 2019/3/26
 * @update:
 */
import {combineReducers} from 'redux';
import accountReducer, {initialAccountState} from "./account.js";
import loginModalReducer, {initialLoginModalState} from "./login-modal.jsx";
import staffReducer, {initialStaffState} from "./staff";
import passwordModalReducer, {initialPasswordModalState} from "./password-modal.js";
import studentsReducer, {initialStudentsState} from "./students";
import usersCardsReducer, {initialUsersCardsState} from "./users_cards";
import storesReducer, {initialStoresState} from "./stores";
import menusReducer, {initialMenusState} from "./menus";
import membershipsReducer, {initialMembershipsState} from "./memberships";
import messageCountReducer, {initialMessageCountState} from "./message_count";
import calendarReloadReducer, {initialCalendarReloadState} from "./calendar_reload";
import staffDetailDrawerReducer, {initialStaffDetailDrawerState} from "./staff_detail_drawer";

const initialState = {
    account: initialAccountState,
    // school: initialSchoolState,
    loginModal: initialLoginModalState,
    staff: initialStaffState,
    passwordModal: initialPasswordModalState,
    students: initialStudentsState,
    users_cards: initialUsersCardsState,
    stores: initialStoresState,
    menus: initialMenusState,
    memberships: initialMembershipsState,
    message_count: initialMessageCountState,
    calendar_reload: initialCalendarReloadState,
    staff_id: initialStaffDetailDrawerState,
    // schoolModal: initialSchoolModalState,
};

const reducer = combineReducers({
    account: accountReducer,
    // school: schoolReducer,
    loginModal: loginModalReducer,
    staff: staffReducer,
    passwordModal: passwordModalReducer,
    students: studentsReducer,
    users_cards: usersCardsReducer,
    stores: storesReducer,
    menus: menusReducer,
    memberships: membershipsReducer,
    message_count: messageCountReducer,
    calendar_reload: calendarReloadReducer,
    staff_id: staffDetailDrawerReducer,
    // schoolModal: schoolModalReducer,
});


export {
    reducer as default,
    initialState,
}