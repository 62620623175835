import PropTypes from 'prop-types';
import React from 'react';
import {Tag} from 'antd';

const YuyueStatus = ({id}) => {
    switch (id) {
        case -1:
            return <Tag color="error">取消预约</Tag>;
        case 0:
            return <Tag color="processing">已预约</Tag>;
        case 1:
            return <Tag color="warning">已上马</Tag>;
        case 2:
            return <Tag color="success">已下课</Tag>;
        default:
            return <Tag>其他</Tag>
    }
};
YuyueStatus.propTypes = {
    id: PropTypes.number,
};
export default YuyueStatus;

