/**
 * @name: 课程管理路由
 * @description:
 * @author: Wood
 * @date: 2021/4/25
 * @update:
 */
import React from 'react';
import {Switch, Route} from "react-router-dom";
import SysDictType from './sys_dict_type/sys_dict_type';
import SysDict from './sys_dict_type/sys_dict2';
import Roles from './roles/roles';
import RoleDetail from './roles/detail';
import StaffConfig from './staff-config/staff-config';
import Config from './config/config';
import Resource from "./resource/resources";
import Staff3 from '../manage/staff/staff3';

const Router = () => (
    <Switch>
        <Route exact path="/settings/sys_dict_type"
               component={SysDictType}/>
        <Route exact path="/settings/sys_dict_type/:type_id"
               component={SysDict}/>
        <Route exact path="/settings/roles"
               component={Roles}/>
        <Route exact path="/settings/roles/:id"
               component={({match}) => <RoleDetail id={parseInt(match.params.id)}/>}/>
        <Route exact path="/settings/s_config"
               component={StaffConfig}/>
        <Route exact path="/settings/config"
               component={Config}/>
        <Route exact path="/settings/resource"
               component={Resource}/>

            <Route exact path="/settings/sysstaffs"
                   component={Staff3}/>

    </Switch>
);

export default Router;
