import React, {} from 'react';
import {Form, Input, Modal} from 'antd';
import {createcwcoa} from "../../../lib/api_cw_coa";
import CoaSelect from "./coa_select";


const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};


/**
 * 添加财务科目表条目
 * @param pid
 * @param onCancel
 * @param onOk
 * @returns {JSX.Element}
 * @constructor
 */
const CoaAddModal = ({pid, onCancel, onOk}) => {
    const [form] = Form.useForm();


    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log(values);
                createcwcoa(values)
                    .then(res => {
                        if (res.code === 0) {
                            handleOk();
                        }
                    });
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    const handleOk = () => {
        onOk && onOk();
    }
    const handleCancel = () => {
        onCancel && onCancel();
    }

    return (
        <Modal
            title="添加财务科目"
            open={true}
            onCancel={handleCancel}
            onOk={handleSubmit}
        >
            <Form
                {...formItemLayout}
                form={form}
                initialValues={{
                    pid: pid
                }}
            >
                <Form.Item
                    label="上级科目"
                    name="pid"
                    rules={[
                        {
                            required: true,
                            message: '请选择',
                        },
                    ]}
                >
                    <CoaSelect/>
                </Form.Item>
                <Form.Item
                    label="科目名称"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: '请输入科目名称',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="部门"
                    name="department"
                >
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CoaAddModal;