import React, {useState, useEffect} from 'react';
import {Calendar} from 'antd';
import styles from './select-calendar.module.css';
import moment from 'moment';

const SelectCalendar = ({value, onChange, date}) => {
    const [dates, setdates] = useState(value || []);


    useEffect(()=>{
        setdates(value)
    }, [value]);

    const dateFullCellRender = (value) => {
        if (value.isSame(date, "month")) {
            if (dates.includes(value.format("YYYY-MM-DD"))) {
                return <div className={`ant-picker-cell-inner ${styles.cellselected}`}>{value.date()}</div>;
            }
            return <div className={styles.cell}>{value.date()}</div>;
        } else {
            return null;
        }
    };
    const handleChange = (value) => {
        if (value.isSame(date, "month")) {
            console.log(value);
            let datestr = value.format("YYYY-MM-DD");
            let newdates = []
            if (dates.includes(datestr)) {
                newdates = dates.filter(_ => _ !== datestr);
                setdates(newdates)
            } else {
                newdates = [...dates, datestr];
                setdates(newdates);
            }
            onChange && onChange(newdates)
        }else{
            // console.log("1123")
        }
    };

    return (
        <Calendar
            value={date}
            validRange={[moment(date).startOf("month"),moment(date).endOf("month")]}
            fullscreen={false}
            headerRender={({value}) => {
                return <center>{value.format("M月")}</center>
            }}
            dateFullCellRender={dateFullCellRender}
            onSelect={handleChange}
        />
    )
};


export default SelectCalendar;


