import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {
    Button,
    DatePicker,
    message,
    Space,
    Table,
    Upload
} from 'antd';
import PageContent from '../../components/page-content/page-content';
import Box from '../../components/box';
import {postfromqywx, postmuti, getAll, updatefkrq} from "../../lib/api_cw_zcdj";
import {UploadOutlined} from "@ant-design/icons";
import XLSX from "xlsx";


const {Column} = Table;


const Zcsj = (props) => {
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loadingQYWX, setLoadingQYWX] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState(false);
    // const [state, setState] = useUrlState({current: 1, pageSize: 10});
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [fkrq, setFkrq] = useState(null); // 付款日期

    /**
     * 从企业微信同步
     */
    const fetchqywxData = () => {
        setLoadingQYWX(true);
        postfromqywx()
            .then((res) => {
                if (res.code === 0) {
                    message.success(res.message);
                    fetchData();
                }
            })
            .finally(() => {
                setLoadingQYWX(false);
            })
    }

    /**
     * 获取数据
     */
    const fetchData = () => {
        console.log(tableParams);
        getAll({
            page: tableParams.pagination.current,
            size: tableParams.pagination.pageSize,
            filters: tableParams.filters
        })
            .then((res) => {
                if (res.code === 0) {
                    setData(res.data.records);
                    setTableParams({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: res.data.total,
                        }
                    })
                }
            })
    }
    useEffect(fetchData, [JSON.stringify(tableParams)]);


    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedRows(selectedRowKeys);
        },
    };


    const handleChange = (info) => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);

            // 在这里处理文件读取
            if (info.file.status === 'done') {
                // 这里通常用于处理上传完成的逻辑，但因为我们不实际上传，所以跳过
            } else if (info.file.status === 'error') {
                // 处理上传失败的逻辑
            }

            // 如果需要读取文件内容，应该使用 info.file.originFileObj
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, {type: 'array'});


                /* 获取第一个工作表 */
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];

                /* 将工作表转换为 JSON 对象 */
                const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1});

                // 在这里你可以使用 jsonData 进行进一步的操作，比如更新组件的状态或发送到服务器
                console.log(jsonData);

                // 移除第一行
                jsonData.shift();

                // 取出所有的审批编号
                const approval_numbers = jsonData.map((row) => row[0]);
                console.log(approval_numbers);

                setLoadingUpload(true);
                postmuti(approval_numbers).then((res) => {
                    if (res.code === 0) {
                        fetchData();
                    }
                }).finally(() => {
                    setLoadingUpload(false);
                })

                // ... 后续处理 workbook 的逻辑
            };
            reader.readAsArrayBuffer(info.file.originFileObj);

            // 如果你想更新上传的文件列表（通常用于显示上传进度），可以使用 setFileList
            // setFileList(info.fileList);
        }
    }

    const beforeUpload = (file) => {
        // 这里可以添加一些文件验证逻辑，比如检查文件类型等
        const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel';
        if (!isExcel) {
            message.error('请上传 Excel 格式的文件！');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('上传文件大小不能超过 2MB!');
        }
        return isExcel && isLt2M;
    };

    const handleTableChange = (pagination, filters, sorter) => {
        console.log(pagination, filters, sorter);
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };

    // 设置付款日期
    const handleSetFKRQ = () => {
        console.log(selectedRows, fkrq);
        updatefkrq(selectedRows, fkrq).then((res) => {
            if (res.code === 0) {
                message.success(res.message);
                fetchData();
            }
        })
    }

    const hasSelected = selectedRows.length > 0;

    return (
        <PageContent>
            <Box title={"支出数据"}
                // bodyStyle={{height: "700px", overflow: "auto"}}
                 extra={
                     <Space>
                         <Button type='primary' onClick={fetchqywxData}
                                 loading={loadingQYWX}>从企业微信同步马术报销数据</Button>
                         {/*<Button type='primary' onClick={exportXlsx}>导出</Button>*/}
                         <Upload
                             name="file"
                             listType="text"
                             className="avatar-uploader"
                             showUploadList={false}
                             beforeUpload={beforeUpload}
                             onChange={handleChange}
                         >
                             <Button icon={<UploadOutlined/>} loading={loadingUpload}>Excel导入</Button>
                         </Upload>
                     </Space>
                 }
            >
                <Space>
                <span>
                  {hasSelected ? `已选择 ${selectedRows.length} 项` : ''}
                </span>
                    <DatePicker value={fkrq} onChange={(e) => setFkrq(e)}/>
                    <Button type="primary" disabled={!hasSelected || !fkrq}
                            onClick={handleSetFKRQ}>设置付款日期</Button>
                </Space>
                <Table
                    dataSource={data}
                    rowKey={"id"}
                    pagination={tableParams.pagination}
                    // size={"small"}
                    onChange={handleTableChange}

                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    scroll={{
                        x: 700,
                        y: 600,
                    }}
                >
                    {/*自动编号*/}
                    <Column title="审批编号" dataIndex="sp_no" key="审批编号" width={150} fixed={'left'}/>
                    <Column title="来源" dataIndex="ly" key="来源" width={100} fixed={'left'}/>
                    <Column title="提交日期" dataIndex="date" key="日期" width={130}/>
                    <Column title="付款日期" dataIndex="fkrq" key="fkrq" width={130} filters={[{
                        text: '未设置',
                        value: 0,
                    }, {
                        text: '已设置',
                        value: 1,
                    },]}/>
                    <Column title="金额" dataIndex="je" key="金额" width={130}/>
                    <Column title="备注" dataIndex="bz" key="备注"/>
                </Table>
            </Box>

        </PageContent>
    )
}

export default Zcsj;