import React, {useState, useEffect} from 'react';
import {Modal, Form, DatePicker} from 'antd';
import {getMembershipDate, postMembershipXiumian2} from "../../../lib/api_membership";
import moment from 'moment';
import Approvals from "../../../components/approvals";
import defaultFormItemLayout from "../../../components/util";


const MembershipXiuMianModal2 = ({id, visible, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [data, setdata] = useState(null);
    const [help, sethelp] = useState("");
    const [refresh, setRefresh] = useState(0);
    const [loading, setLoading] = useState(false);

    // const [list, setList] = useState([]);
    const fetchData = () => {
        getMembershipDate(id).then(res => {
            if (res.code === 0) {
                setdata(res.data);
            }
        })
    }
    useEffect(fetchData, []);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                // values.id = data.membership_id;
                // values.memberships_fee_id = data.id
                // console.log(values);
                postMembershipXiumian2({
                    id: data.membership_id,
                    memberships_fee_id: data.id,
                    begin_time: values.begin_time.format("YYYY-MM-DD"),
                    end_time: values.end_time.format("YYYY-MM-DD"),
                })
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk(res.data);
                        } else if (res.code === 1001) {
                            setRefresh(refresh + 1);
                        }
                    })
            })
    };
    // 表单值发生改变
    const handleValuesChange = (changedValues, allValues) => {
        // 计算休卡时长
        let {begin_time, end_time} = allValues;
        if (begin_time && end_time) {
            let days = end_time.diff(begin_time, "d");
            if (days <= 0) {
                sethelp("结束日期不能早于起始日期")
            } else {
                sethelp(`共计休卡${days}天`)
            }
        } else {
            sethelp("")
        }
    };

    useEffect(() => {
        let begin_time = moment().add(1, 'd').startOf("d");
        form.setFieldsValue({
            begin_time: begin_time
        })
    }, []);

    return (
        <Modal
            title="申请第二次休眠"
            open={true}
            // onOk={form.submit}
            onOk={handleSubmit}
            onCancel={onCancel}
            width={800}
            confirmLoading={loading}
        >
            <Approvals type="年卡休卡" key={refresh} onChange={(v) => setLoading(v)}>
                <Form {...defaultFormItemLayout}
                      form={form}
                      onValuesChange={handleValuesChange}
                >
                    <Form.Item
                        label="休卡开始日期"
                        name="begin_time"
                        rules={[
                            {
                                required: true,
                                message: '请选择开始日期',
                            },
                        ]}
                    >
                        <DatePicker
                            format={"YYYY-MM-DD"}
                            // disabled={!!data.xiuka_begin_time}
                            // disabledDate={disabledDate}
                        />
                    </Form.Item>
                    <Form.Item
                        label="休卡结束日期"
                        name="end_time"
                        rules={[
                            {
                                required: true,
                                message: '请选择结束日期',
                            },
                        ]}
                        help={help}
                    >
                        <DatePicker
                            format={"YYYY-MM-DD"}
                            // disabled={!!data.xiuka_begin_time}
                            // disabledDate={disabledEndDate}
                        />
                    </Form.Item>
                </Form>
            </Approvals>
        </Modal>
    )
};

export default MembershipXiuMianModal2;
