import React, {useState, useEffect} from 'react';
import {Card, Table} from 'antd';
import {getCoursesFee} from "../../../lib/api_courses_fee";
import DictName from '../../../components/dict/dict-name';
import {getStaff} from "../../../lib/api_staff";

const {Column} = Table;


const StaffConfig = ({value, onChange}) => {
    const [list, setList] = useState([]);


    const loadData = () => {
        getStaff()
            .then(res => {
                if (res.code === 0) {
                    setList(res.data.filter(_ => _.enable === 1 && !!_.userid));
                }
            })
    };
    useEffect(loadData, []);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            onChange && onChange(selectedRowKeys);
        },
        defaultSelectedRowKeys: value
    };

    return (
        <Card bordered={false} title={"可预约教练"}>
            <Table
                // title="课程设置"
                size="small"
                pagination={false}
                dataSource={list}
                rowKey={"id"}
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}>
                <Column
                    title="姓名"
                    dataIndex="name"
                    key="姓名"
                />

                <Column
                    title="别名"
                    dataIndex="alias"
                    key="别名"
                />
                <Column
                    title="职务"
                    dataIndex="position"
                    key="职务"
                    filters={[
                        {
                            text: 'Coach',
                            value: 'Coach',
                        },
                        {
                            text: 'Manager',
                            value: 'Manager',
                        },
                        {
                            text: 'Intern',
                            value: 'Intern',
                        },
                    ]}
                    onFilter={(value, record) => record.position.indexOf(value) !== -1}

                />
                <Column
                    title="部门"
                    key="部门"
                    dataIndex="main_department"
                />
                <Column
                    title="手机"
                    dataIndex="mobile"
                    key="手机"
                />

                <Column
                    title="角色"
                    key="角色"
                    render={(record) => {
                        return record.roles.map(_ => _.name).join(',')
                    }}
                />
                <Column
                    title="状态"
                    dataIndex="enable"
                    key="状态"
                    width={60}
                    render={(enable) => {
                        return enable === 1 ? "启用" : "已禁用"
                    }}
                />
            </Table>
        </Card>
    )
};


export default StaffConfig;


