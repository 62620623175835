import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom'
// import './index.css';
import App from './App3';
import * as serviceWorker from './serviceWorker';
import store from './reducers';
import {ConfigProvider} from 'antd';
// import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import {DndProvider} from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import {TableProvider} from 'table-render';


moment.locale('zh-cn');


ReactDOM.render((
    <BrowserRouter>
        <Provider store={store}>
            <ConfigProvider locale={zhCN}>
                <DndProvider backend={Backend}>
                    <TableProvider>
                    <App/>
                    </TableProvider>
                </DndProvider>
            </ConfigProvider>
        </Provider>
    </BrowserRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
