
import axios from './rest-axios';

const getCards = () => axios.get(`/manage/cards`);
const getCard = (id) => axios.get(`/manage/cards/${id}`);
const postCard = (model) => axios.post(`/manage/cards`, model);
const putCard = (model) => axios.put(`/manage/cards/${model.id}`, model);


export {
    getCards,
    getCard,
    postCard,
    putCard,
};
