/**
 * @name: 登录框reducer
 * @description:
 * @author: Wood
 * @date: 2019/4/4
 * @update:
 */
const SHOWLOGINMODAL = 'login-modal/SHOW';
const HIDELOGINMODAL = 'login-modal/HIDE';

const initialState = false;

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SHOWLOGINMODAL:
            return true;
        case HIDELOGINMODAL:
            return false;
        default:
            return state;
    }
};

const showLoginModal = function () {
    return {
        type: SHOWLOGINMODAL
    }
};

const hideLoginModal = function () {
    return {
        type: HIDELOGINMODAL
    }
};

export {
    reducer as default,
    initialState as initialLoginModalState,
    showLoginModal,
    hideLoginModal
}