import React, {useState, useEffect} from 'react';
import {Select} from 'antd';
import {getAllLevels} from "../../lib/api_levels";


const LevelSelect = ({value, onChange, style}) => {
    const [list, setlist] = useState([]);
    const fetchData = () => {
        getAllLevels()
            .then(res => {
                if (res.code === 0) {
                    let list = res.data.filter(m=>m.level===1);
                    list.push({
                        name:"其他级别",
                        id: 0
                    });
                    setlist(list);
                }
            })
    };
    useEffect(fetchData, []);


    const handleChange = (value) => {
        console.log(value);
        onChange && onChange(value)
    };


    return (
        <Select
            value={value}
            onChange={handleChange}
            style={style}
        >
            {list.map(item => (
                <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
            ))}
        </Select>
    )
};

export default LevelSelect;
