import axios from './rest-axios';

const getjrkc = () => axios.get('/manage/dashboard/jrkc');
const getgqws = () => axios.get('/manage/dashboard/gqws');
const gettime = () => axios.get('/manage/dashboard/time');
const getzdgzmp = () => axios.get('/manage/dashboard/zdgzmp');
const getdevice_info = () => axios.get('/manage/dashboard/device_info');
const getmpwyjl = () => axios.get('/manage/dashboard/mpwyjl');

export {
    getjrkc,
    getgqws,
    gettime,
    getzdgzmp,
    getdevice_info,
    getmpwyjl,
};
