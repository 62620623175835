import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Layout, Menu, Dropdown, Avatar,Space,Breadcrumb} from 'antd';

import {
    UserOutlined,
    LogoutOutlined,
    LockOutlined,
} from '@ant-design/icons';
import {signOut} from "./reducers/account";
import {showPasswordModal} from "./reducers/password-modal";
import {useLocation, withRouter} from "react-router-dom";
import SiderBar, {getFirstMenu} from './components/sider-menu/sidebar';


const HeaderBar = ({account,menus,signOut, showPasswordModal}) => {
    const location = useLocation();
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    useEffect(() => {
        // 当路径变化时，执行该函数
        console.log(location.pathname);
        //  查找路径
        if(menus){
            let list = menus.filter(m=>(location.pathname.startsWith(m.path)));
            console.log(list);
            setBreadcrumbs(list.map(m=><Breadcrumb.Item>
                {m.title}
            </Breadcrumb.Item>
            ))
        }
    }, [location]);

    // useEffect(()=>{
    //     this.historyinstance = this.props.history.listen(this.routeChange);
    // },[]);

    const items = [
        {
            key: '1',
            label: (
                <a onClick={showPasswordModal}>
                    修改密码
                </a>
            ),
            icon: <LockOutlined/>,
        },
    ];

    return (
        <div style={{
            height: 50,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#fff",
            padding: "0 24px",
            boxShadow: "0px 2px 6px 0px rgba(164, 186, 239, 0.12)",
        }}>
            <Breadcrumb>
                {breadcrumbs}
            </Breadcrumb>
            <Space>
                <Avatar onClick={signOut} size={32} icon={<LogoutOutlined />} style={{backgroundColor:"#F2F3F5",color:"#4E5969",cursor:"pointer"}}/>
                <Dropdown
                    menu={{
                        items,
                    }}
                >
                    <Avatar size={32}  src={account.avatar}/>
                </Dropdown>
            </Space>
        </div>
    )
}

const mapStateToProps = state => ({
    menus: state.menus,
    account: state.account,
});

const mapDispatchToProps = dispatch => ({
    signOut: () => dispatch(signOut()),
    showPasswordModal: () => dispatch(showPasswordModal()),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderBar));


