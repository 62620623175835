/**
 * 选择会籍
 */

import React, {useState, useEffect} from 'react';
import {Select} from "antd";
import {getMembership} from "../../../lib/api_membership";

const MembershipSelect = ({value, onChange}) => {
    const [options, setOptions] = useState([]);
    // 获取所有会籍信息
    const fetchData = () => {
        getMembership().then(res => {
            if (res.code === 0) {
                // 对数据按照status进行排序
                res.data.sort((a, b) => a.status - b.status);
                // 过滤出huiji=马主会籍
                res.data = res.data.filter(item => item.huiji === "马主会籍");
                // 设置options
                setOptions(res.data.map(item => ({
                    label: `${item.name}(${item.mobile_number})(${item.status === 1 ? "停用" : "正常"})`,
                    value: item.id
                })));
            }
        });
    }

    useEffect(fetchData, []);

    return (<Select
        showSearch
        placeholder="输入关键字搜索"
        optionFilterProp="children"
        filterOption={(input, option) => option.label.includes(input)}
        options={options}
        value={value}
        onChange={onChange}
    />)
}

export default MembershipSelect;