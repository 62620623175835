import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {Table, Typography} from 'antd';
import moment from 'moment';
import styles from '../members-list.module.css';
import MemberEndSpan from '../members/member_end_span';

const {Column} = Table;

/**
 * 会籍列表
 * @param list
 * @returns {JSX.Element}
 * @constructor
 */
const MemberShipsList = ({list}) => {
    const history = useHistory();

    return (
        <div>
            <Table
                size={"small"}
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                rowClassName={styles.row}
                onRow={record => {
                    return {
                        onClick: () => {
                            history.push(`/member/member_ship/${record.id}`)
                        },
                    };
                }}
                scroll={{x: 1300}}
            >
                <Column
                    title="会籍编号"
                    dataIndex="member_number"
                    key="会籍编号"
                    width={140}
                    sorter={(a, b) => a.member_number < b.member_number ? 1 : -1}
                    fixed={"left"}
                />
                <Column
                    title="会员"
                    dataIndex="name"
                    key="会员"
                    width={100}
                    sorter={(a, b) => a.login_name < b.login_name ? 1 : -1}
                    fixed={"left"}
                />
                <Column
                    title="会籍类型"
                    dataIndex="huiji"
                    key="会籍类型"
                    width={100}
                    fixed={"left"}
                />
                <Column
                    title="联系电话"
                    dataIndex="mobile_number"
                    width={120}
                    key="mobile_number"
                />
                <Column
                    title="车牌"
                    dataIndex="license_plate"
                    width={120}
                    key="车牌"
                />

                <Column
                    title="剩余教练费"
                    dataIndex="fee_remaining"
                    key="剩余教练费"
                    width={120}
                    align="right"
                />
                <Column
                    title="剩余赠送教练费"
                    dataIndex="fee_free_remaining"
                    key="剩余赠送教练费"
                    width={120}
                    align="right"
                />

                <Column
                    title="首次办卡"
                    dataIndex="begin_time"
                    key="首次办卡日期"
                    width={110}
                    sorter={(a, b) => {
                        if (!a.begin_time) {
                            return -1;
                        }
                        if (!b.begin_time) {
                            return 1;
                        }
                        return moment(a.begin_time).isBefore(b.begin_time) ? -1 : 1
                    }}
                    // render={(nick_name, record) => (
                    //     <span style={{color: "#1890ff"}}>{nick_name}</span>
                    // )}
                />
                <Column
                    title="有效期"
                    dataIndex="end_time"
                    key="有效期"
                    width={110}
                    filters={[{text: "有效", value: 0}, {text: "临期", value: 1}, {text: "过期", value: 2}]}
                    onFilter={(value, record) => {
                        let member_end2 = moment(record.end_time);
                        let now = moment();
                        let days = member_end2.diff(now, 'days');

                        switch (value) {
                            case 0:
                                return days > 0;
                            case 1:
                                return days >= 0 && days < 90;
                            case 2:
                                return days < 0;
                            default:
                                return false;
                        }
                    }}
                    render={(end_time) => <MemberEndSpan date={end_time}/>}
                />
                <Column
                    title="备注"
                    dataIndex="remark"
                    key="remark"
                    render={(remark) => (
                        <Typography.Text ellipsis style={{"width": 100}} title={remark}>{remark}</Typography.Text>
                    )}
                />
            </Table>

        </div>
    )
}

MemberShipsList.propTypes = {
    list: PropTypes.array,
};

export default MemberShipsList;


