import React, {useEffect, useState} from 'react';
import RScaleScreen from 'r-scale-screen'

import styles from './dashboard.module.css';
import Weather from "./weather";
import {Table} from 'antd';
import DpTable from './dp_table';
import {getdevice_info, getgqws, getjrkc, getmpwyjl, getzdgzmp} from "../../lib/api_dashboard";
import {useTimeout, useInterval} from 'ahooks';
import IconTeacherName from './images/我的.svg';
import IconGQStatus from './images/状态进度.svg';
import IconCheckCircleFilled from './images/CheckCircleFilled.svg'
import IconWarningFilled from './images/WarningFilled.svg'
import Icon良好 from './images/良好.svg'
import Icon待清洁 from './images/待清洁.svg'
import Icon气温 from './images/气温.svg'
import Icon湿度 from './images/湿度.svg'
import Icon噪音 from './images/噪音.svg'
import Icon空气 from './images/空气.svg'
import {getStables} from "../../lib/api_stables";

const columnszrmfws = [
    {
        title: '位置',
        dataIndex: 'name',
        key: '马匹马房',
        width: '33%',
    },
    {
        title: '维修内容',
        dataIndex: 'teacher',
        key: '责任人',
        width: '33%',
    },

];

const columnszdgz = [{
    title: "马匹",
    dataIndex: "name",
    key: '马匹',
    width: '33%',
}, {
    title: "责任人",
    dataIndex: "coach_name",
    key: '责任人',
    width: '33%',
}, {
    title: "问题",
    dataIndex: "zdgzbz",
    key: '问题',
    width: '33%',
}]

const data = [
    // {
    //     key: '1',
    //     name: 'Blue boy (A.03)',
    //     teacher: "史新三",
    //     status: 0,
    // },
    // {
    //     key: '2',
    //     name: '德高 (B.05)',
    //     teacher: "韩响",
    //     status: 1,
    // },
];

const mapitems = [
    {name: "B1", top: 34, left: 270},
    {name: "B2", top: 38, left: 327},
    {name: "B3", top: 42, left: 384},
    {name: "C1", top: 90, left: 225},
    {name: "C2", top: 96, left: 310},
];


const DashboardMaFang = () => {
    const [mfqk, setMfqk] = useState([]);   // 马房情况
    const [zdgz, setzdgz] = useState([]);   // 重点关注马匹
    const [device_info, setdevice_info] = useState({})  //传感器数据
    const [columnsmpwyjl, setcolumnsmpwyjl]= useState([]);  //马匹喂养记录
    const [mpwyjl, setmpwyjl] = useState([]);  //马匹喂养记录

    /**
     * 获取马房情况
     */
    useInterval(() => {
        getStables().then(res => {
            if (res.code === 0) {
                setMfqk(res.data);
            }
        })
    }, 10000, {immediate: true});


    /**
     * 获取重点关注马匹情况
     */
    useInterval(() => {
        getzdgzmp().then(res => {
            if (res.code === 0) {
                setzdgz(res.data);
            }
        })
    }, 10000, {immediate: true});


    /**
     * 获取传感器数据
     */
    useInterval(() => {
        getdevice_info().then(res => {
            if (res.code === 0 && res.data) {
                setdevice_info(JSON.parse(res.data.data));
            }
        })
    }, 60000, {immediate: true});

    /**
     * 获取马匹喂养记录
     */
    useInterval(() => {
        getmpwyjl().then(res => {
            if (res.code === 0) {
                setmpwyjl(res.data.data);
                setcolumnsmpwyjl(res.data.columns);
            }
        })
    }, 100000, {immediate: true});

    return (
        <>
            <RScaleScreen height={1080} width={1920} bodyOverflowHidden={true} fullScreen={true}
                          boxStyle={{backgroundColor: '#0A0B12'}}>
                <div className={styles.header}>
                    <div className={styles.logo}></div>
                    <div className={styles.rightBar}>
                        <div className={styles.weather}>
                            <Weather/>
                        </div>
                    </div>
                </div>
                <div className={styles.hjzt}>
                    <div className={styles.item}>
                        <div className={styles.item_icon}>
                            <img src={Icon气温}/>
                        </div>
                        <div>
                            <div className={styles.item_value}>{device_info.Temp}°C</div>
                            <div className={styles.item_name}>环境温度</div>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_icon}>
                            <img src={Icon湿度}/>
                        </div>
                        <div>
                            <div className={styles.item_value}>{device_info.Humi}%</div>
                            <div className={styles.item_name}>环境湿度</div>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_icon}>
                            <img src={Icon噪音}/>
                        </div>
                        <div>
                            <div className={styles.item_value}>{device_info.Noise}db</div>
                            <div className={styles.item_name}>噪音分贝</div>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_icon}>
                            <img src={Icon空气}/>
                        </div>
                        <div>
                            <div className={styles.item_value}>{device_info["PM2.5"]}</div>
                            <div className={styles.item_name}>PM2.5</div>
                        </div>
                    </div>
                </div>
                <div className={styles.mapContainer}>
                    <div className={styles.map}>
                        {mapitems.map(m => {
                            let mf = mfqk.find(x => x.name === m.name);
                            if (!mf) return null;
                            // 是否重点关注
                            let iszd = zdgz.some(x => x.stable_name === m.name);
                            if (mf.horse_inside === 1)
                                return (
                                    <div className={styles.item} style={{top: m.top, left: m.left}}>
                                        {iszd && <div className={styles.zdgz}/>}
                                    </div>
                                )
                            else {
                                return (
                                    <div className={styles.item2} style={{top: m.top, left: m.left}}>

                                    </div>
                                )
                            }
                        })}
                    </div>
                    <div className={styles.mapbg}></div>
                    <div className={styles.iteminfo}>
                        <div className={styles.iteminfo1}>在厩马匹 {mfqk.filter(m => m.horse_inside === 1).length}</div>
                        <div className={styles.iteminfo2}>外出马匹 {mfqk.filter(m => m.horse_inside === 0).length}</div>
                        <div className={styles.iteminfo3}>需打扫马房 0</div>
                        <div className={styles.iteminfo4}>重点关注 {zdgz.length}</div>
                    </div>
                </div>
                {/*<div className={styles.jrkb}>*/}
                {/*    <DpTable columns={columnsjrkc} dataSource={jrkcS}/>*/}
                {/*</div>*/}
                <div className={styles.wyjl}>
                    <DpTable columns={columnsmpwyjl} dataSource={mpwyjl} thStyle={{height: 64}} tdStyle={{height: 64}}/>

                </div>
                <div className={styles.tsgz}>
                    <div className={styles.tsgzNum}>{zdgz.length}</div>
                    <DpTable columns={columnszdgz} dataSource={zdgz} thStyle={{height: 64}} tdStyle={{height: 64}}/>

                </div>
                <div className={styles.wxrw}>
                    <DpTable columns={columnszrmfws} dataSource={data} thStyle={{height: 64}} tdStyle={{height: 64}}/>
                </div>
                {/*<div className={styles.bodyContainer}>*/}
                {/*    <div className={styles.leftContainer}>*/}
                {/*        */}
                {/*    </div>*/}
                {/*    <div className={styles.rightContainer}></div>*/}
                {/*</div>*/}
            </RScaleScreen>
        </>
    );
}

export default DashboardMaFang;