import React, {useState, useEffect} from 'react';
import {Table, Typography} from 'antd';
import {deleteHorseZt, getHorseZt} from "../../../lib/api_horses";
import moment from "moment/moment";

const {Column} = Table;

const HorsesZTList = ({id}) => {
    const [list, setList] = useState([]);

    const fetchData = () => {
        getHorseZt(id).then(res => {
            if (res.code === 0) {
                setList(res.data);
            }
        })
    }
    useEffect(fetchData, []);

    const handleDelete = (ztid) => {
        deleteHorseZt(id,ztid).then(res => {
            if(res.code === 0){
                fetchData();
            }
        });
    }

    return (
        <Table
            dataSource={list}
            rowKey={"id"}
            size={"small"}
        >
            <Column
                title={"时间"}
                dataIndex={"time"}
                key={"时间"}
                width={150}
                render={(text, record, index) => moment(record.time).format("YYYY-MM-DD HH:mm")}
            />
            <Column
                title={"状态"}
                dataIndex={"status"}
                key={"状态"}
                // render={(text, record, index) => moment(record.begin_time).format("YYYY-MM-DD HH:mm")}
            />
            <Column
                title={"备注"}
                key={"备注"}
                render={(text, record, index) => {
                    let txt = [];
                    if(record.ws==='是') txt.push(<div>外伤:{record.wsbz}</div>);
                    if(record.jb==='是') txt.push(<div>疾病:{record.jbbz}</div>);
                    if(record.qt==='是') txt.push(<div>其他:{record.qtbz}</div>);
                    return txt;
                }}
            />
            <Column
                title={"操作"}
                key={"操作"}
                render={(text, record, index) => <Typography.Link
                    onClick={() => handleDelete(record.id)}>删除</Typography.Link>}
            />
        </Table>
    )
}

export default HorsesZTList;
