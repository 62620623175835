import axios from './rest-axios';

const getCWSRYCYueDuAll = (year) => axios.get('/manage/cw_sryc_yuedu/', {year});
const getCWSRYCYueDuById = (id) => axios.get(`/manage/cw_sryc_yuedu/${id}`);


const createOrUpdateCWSRYCYueDu = (data) => axios.post('/manage/cw_sryc_yuedu/', data);

export {
    getCWSRYCYueDuAll,
    getCWSRYCYueDuById,
    createOrUpdateCWSRYCYueDu
};
