import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Checkbox} from 'antd';
import {rangeInRange, timeInRange, rangeIntersect} from '../../lib/time-util';


const StaffCheck = ({value, onChange, staff,paiban,begin_time, end_time}) => {
    const [ids, setids] = useState([]);

    // 更改事件
    const handleChange = (value) => {
        onChange && onChange(JSON.stringify(value))
    };



    useEffect(() => {
        let ids = [];
        if (value) {
            ids = JSON.parse(value);
        }
        setids(ids);

        console.log(value,paiban,begin_time, end_time);

        for (let i in staff) {
            let staf = staff[i];
            let paibans_filter = paiban.filter(item => item.user_id === staf.id);
            // 休息
            if (!paibans_filter.some(item => rangeInRange(begin_time, end_time, item.begin_time, item.end_time))) {
                staf.isRest = true;
                continue;
            }
        }

    }, [value,begin_time, end_time]);

    return (
        <Checkbox.Group
            // buttonStyle="solid"
            value={ids}
            style={{whiteSpace: "normal"}}
            onChange={(value) => handleChange(value)}
        >
            {staff.filter(staf=>staf.is_coach && staf.enable === 1 && !staf.isRest).map(item =>
                <Checkbox value={item.id} key={item.id}>{item.name}</Checkbox>
            )}
            <span style={{color:"#cccccc"}}>休息：</span>
            {staff.filter(staf=>staf.is_coach && staf.enable === 1 && staf.isRest).map(item =>
                <Checkbox value={item.id} key={item.id} style={{color:"#cccccc"}}>{item.name}</Checkbox>
            )}
        </Checkbox.Group>
    )
};


const mapStateToProps = state => ({
    staff: state.staff
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(StaffCheck));

// export default StaffSelect;