import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Radio} from 'antd';
import {rangeInRange, timeInRange, rangeIntersect} from '../../lib/time-util';


const StaffSelect = ({value, onChange, paiban, staff: staffs, yuyue, type, begin_time, end_time}) => {
    const [staff, setStaff] = useState([]);

    // 更改事件
    const handleChange = (e) => {
        onChange && onChange(e.target.value)
    };

    // 教师推荐
    const recommendTeacher = () => {
        console.log({value, onChange, paiban, staffs, yuyue, type, begin_time, end_time});
        let list = [];
        for (let i in staffs) {
            let staf = staffs[i];
            if (!staf.is_coach) continue;
            if (staf.enable !== 1) continue;
            if(type !== undefined){
                if (staf.courses_fees) {
                    if (!staf.courses_fees.some(_ => _.course_type === parseInt(type))) {
                        continue;
                    }
                }
            }
            list.push(staf);

            staf.isRest = false;  // 休息
            staf.isRecommend = false;  // 推荐
            staf.isBusy = false;  // 忙

            let paibans_filter = paiban.filter(item => item.user_id === staf.id);
            // 休息
            if (!paibans_filter.some(item => rangeInRange(begin_time, end_time, item.begin_time, item.end_time))) {
                staf.isRest = true;
                continue;
            }
            // 忙
            let yuyue_filter = yuyue.filter(item => item.teacher === staf.id && item.id !== value);
            if (yuyue_filter.some(item => rangeIntersect(begin_time, end_time, item.begin_time, item.end_time))) {
                staf.isBusy = true;
                continue;
            }
            // 推荐
            if (staf.courses_type) {
                let courses_type = JSON.parse(staf.courses_type);
                console.log(courses_type, type);
                if (courses_type.includes(type)) {
                    staf.isRecommend = true;
                    continue;
                }
            }

        }
        setStaff(list);
    };

    useEffect(recommendTeacher, [value, type, begin_time, end_time]);

    return (
        <Radio.Group buttonStyle="solid"
                     value={value}
                     style={{whiteSpace: "normal"}}
                     onChange={handleChange}
        >
            <div>
                {staff.filter(item => item.isRecommend).map(item =>
                    <Radio.Button key={item.id}
                                  value={item.id}>
                        {item.name}
                        {item.isRest &&
                        <span>(<span style={{color: "red"}}>休</span>)</span>}
                        {item.isBusy &&
                        <span>(<span style={{color: "red"}}>忙</span>)</span>}
                        {item.isRecommend &&
                        <span>(<span style={{color: "green"}}>荐</span>)</span>}
                    </Radio.Button>)}
            </div>
            <div>
                {staff.filter(item => !item.isRecommend).map(item =>
                    <Radio.Button key={item.id}
                                  value={item.id}>
                        {item.name}
                        {item.isRest &&
                        <span>(<span style={{color: "red"}}>休</span>)</span>}
                        {item.isBusy &&
                        <span>(<span style={{color: "red"}}>忙</span>)</span>}
                        {item.isRecommend &&
                        <span>(<span style={{color: "green"}}>荐</span>)</span>}
                    </Radio.Button>)}
            </div>
        </Radio.Group>
    )
};


const mapStateToProps = state => ({
    staff: state.staff
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(StaffSelect));

// export default StaffSelect;