/**
 * @name: 休眠功能
 * @description:
 * 操作顺序：
 * 1。选择休眠的卡片
 * 2。选择时间范围，时间不能早于当前时间，不能晚于截止时间
 * 3。休眠操作相当于将一条记录拆成两条，需要重新计算年费
 * @author: Wood
 * @date: 2021/9/24
 * @update:
 */
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {
    Modal,
    Form,
    InputNumber,
    Input,
    DatePicker,
    message,
    Table,
    Spin,
    Button,
    Card,
    Space,
    Descriptions
} from 'antd';
import {
    getMembershipDate,
    postMembershipXiumian,
    postMembershipXiumianquxiao,
    postXiuMian1CheXiao,
    postXiuMian2CheXiao
} from "../../../lib/api_membership";
import moment from 'moment';
import MembershipTypeSelect from "../../manage/membership_type/membership_type_select";
import {getApprovalsLast} from "../../../lib/api_approvals";
import MembershipXiuMianModal1 from "./membership_xiumian_modal1";
import MembershipXiuMianModal2 from "./membership_xiumian_modal2";
import MembershipXiuMianEditModal1 from "./membership_xiumian_edit_modal1";
import MembershipXiuMianEditModal2 from "./membership_xiumian_edit_modal2";


const MembershipXiuMianModal = ({id, visible, onOk, onClose}) => {
    const [form] = Form.useForm();
    const [help, sethelp] = useState("");
    const [loading, setLoading] = useState(false);
    const [isApprovals, setIsApprovals] = useState(false);
    const [xiuka1, setXiuka1] = useState(0);
    const [data, setdata] = useState(null);
    const [xiukaedit1, setXiukaedit1] = useState(0);
    const [xiuka2, setXiuka2] = useState(0);
    const [xiukaedit2, setXiukaedit2] = useState(0);

    const fetchData = () => {
        getMembershipDate(id).then(res => {
            if (res.code === 0) {
                setdata(res.data);
            }
        })
    }
    useEffect(fetchData, []);
    // const [list, setList] = useState([]);
// 获取审核情况
    const fetchApprovals = () => {
        setLoading(true);
        getApprovalsLast("年卡休卡")
            .then(res => {
                if (res.code === 0) {
                    if (res.data && res.data.sp_status === 1) {
                        // 存在审核记录并且未完成状态
                        setIsApprovals(true);
                    } else {
                        setIsApprovals(false);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(fetchApprovals, []);

    const handleQuxiao = () => {
        form.validateFields()
            .then(values => {
                postMembershipXiumianquxiao({
                    id: data.membership_id,
                    memberships_fee_id: data.id,
                    end_time: values.end_time.format("YYYY-MM-DD"),
                })
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk(res.data);
                        }
                    })
            })
    }

    const handleSubmit = () => {
        if (!!data.xiuka_begin_time) {
            message.error("不能修改休卡时间");
            return;
        }
        form.validateFields()
            .then(values => {
                // values.id = data.membership_id;
                // values.memberships_fee_id = data.id
                // console.log(values);
                postMembershipXiumian({
                    id: data.membership_id,
                    memberships_fee_id: data.id,
                    begin_time: values.begin_time.format("YYYY-MM-DD"),
                    end_time: values.end_time.format("YYYY-MM-DD"),
                })
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk(res.data);
                        } else if (res.code === 1001) {
                            fetchApprovals();
                        }
                    })
            })
    };
    // 表单值发生改变
    const handleValuesChange = (changedValues, allValues) => {
        // 计算休卡时长
        let {begin_time, end_time} = allValues;
        if (begin_time && end_time) {
            let days = end_time.diff(begin_time, "d");
            if (days <= 0) {
                sethelp("结束日期不能早于起始日期")
            } else {
                sethelp(`共计休卡${days}天`)
            }
        } else {
            sethelp("")
        }
    };

    const handleCheXiao1 = () => {
        postXiuMian1CheXiao(id).then(res => {
            if (res.code === 0) {
                message.success("撤销成功");
                fetchData();
            }
        })
    }

    const handleCheXiao2 = () => {
        postXiuMian2CheXiao(id).then(res => {
            if (res.code === 0) {
                message.success("撤销成功");
                fetchData();
            }
        })
    }

    // useEffect(() => {
    //     console.log(data);
    //     let begin_time = moment().add(1, 'd').startOf("d");
    //     form.setFieldsValue({
    //         begin_time: begin_time
    //     })
    //     if (data.xiuka_begin_time) {
    //         form.setFieldsValue({
    //             begin_time: moment(data.xiuka_begin_time)
    //         })
    //     }
    //     if (data.xiuka_end_time) {
    //         form.setFieldsValue({
    //             end_time: moment(data.xiuka_end_time)
    //         })
    //     }
    // }, []);

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 18},
        },
    };

    if(!data) return null;

    return (
        <Modal
            title="年卡休卡"
            visible={true}
            footer={false}
            // onOk={form.submit}
            // onOk={handleQuxiao}
            onCancel={onClose}
            // destroyOnClose={true}
            width={800}
            // okText="调整休眠时间"
            confirmLoading={loading || isApprovals}
        >
            <Descriptions title="年卡信息" bordered>
                <Descriptions.Item label="类型">{data.name}</Descriptions.Item>
                <Descriptions.Item label="开始日期">{data.begin_time}</Descriptions.Item>
                <Descriptions.Item label="结束日期">{data.end_time}</Descriptions.Item>
                <Descriptions.Item label="剩余年费">{data.payments_remaining}</Descriptions.Item>
                <Descriptions.Item label="剩余天数">{data.limit_days_remaining}</Descriptions.Item>
            </Descriptions>
            <Card title="第一次休卡" size="small" style={{marginTop: 10}}>
                <div>开始日期：{data.xiuka_begin_time}</div>
                <div>结束日期：{data.xiuka_end_time}</div>
                <Space>
                    <Button onClick={() => setXiuka1(data.id)}>申请第一次休卡</Button>
                    <Button onClick={() => setXiukaedit1(data.id)}>修改第一次休卡结束日期</Button>
                    <Button onClick={handleCheXiao1}>撤销第一次休卡</Button>
                </Space>
                {xiuka1 > 0 && (
                    <MembershipXiuMianModal1
                        id={data.id}
                        onCancel={() => {
                            setXiuka1(0);
                        }}
                        onOk={() => {
                            setXiuka1(0);
                            fetchData();
                        }}
                    />
                )}
                {xiukaedit1 > 0 && (
                    <MembershipXiuMianEditModal1
                        id={data.id}
                        onCancel={() => {
                            setXiukaedit1(0);
                        }}
                        onOk={() => {
                            setXiukaedit1(0);
                            fetchData();
                        }}
                    />
                )}
            </Card>
            <Card title="第二次休卡" size="small" style={{marginTop: 10}}>
                <div>开始日期：{data.xiuka_begin_time2}</div>
                <div>结束日期：{data.xiuka_end_time2}</div>
                <Space>
                    <Button onClick={() => setXiuka2(data.id)}>申请第二次休卡</Button>
                    <Button onClick={() => setXiukaedit2(data.id)}>修改第二次休卡结束日期</Button>
                    <Button onClick={handleCheXiao2}>撤销第二次休卡</Button>
                </Space>
                {xiuka2 > 0 && (
                    <MembershipXiuMianModal2
                        id={data.id}
                        onCancel={() => {
                            setXiuka2(0);
                        }}
                        onOk={() => {
                            setXiuka2(0);
                            fetchData();
                        }}
                    />
                )}
                {xiukaedit2 > 0 && (
                    <MembershipXiuMianEditModal2
                        id={data.id}
                        onCancel={() => {
                            setXiukaedit2(0);
                        }}
                        onOk={() => {
                            setXiukaedit2(0);
                            fetchData();
                        }}
                    />
                )}
            </Card>
        </Modal>
    )

    if (data.xiuka_begin_time) {
        return (
            <Modal
                title="调整休眠时间"
                visible={true}
                // onOk={form.submit}
                onOk={handleQuxiao}
                onCancel={onClose}
                destroyOnClose={true}
                width={800}
                okText="调整休眠时间"
                confirmLoading={loading || isApprovals}
            >
                <Spin spinning={loading || isApprovals} size={"large"} tip={"正在等待审核，审核完成前无法提交新的申请！"}>
                    <Form {...formItemLayout}
                          form={form}
                          onValuesChange={handleValuesChange}
                    >
                        <Form.Item label="类型">
                            <span className="ant-form-text">{data.name}</span>
                        </Form.Item>
                        <Form.Item label="开始日期">
                            <span className="ant-form-text">{data.begin_time}</span>
                        </Form.Item>
                        <Form.Item label="结束日期">
                            <span className="ant-form-text">{data.end_time}</span>
                        </Form.Item>
                        <Form.Item label="剩余年费">
                            <span className="ant-form-text">{data.payments_remaining}</span>
                        </Form.Item>
                        <Form.Item label="剩余天数">
                            <span className="ant-form-text">{data.limit_days_remaining}</span>
                        </Form.Item>
                        <Form.Item label="休卡开始日期">
                            <span className="ant-form-text">{data.xiuka_begin_time}</span>
                        </Form.Item>
                        <Form.Item
                            label="休卡结束日期"
                            name="end_time"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择结束日期',
                                },
                            ]}
                            help={"截止时间不能早于当前时间"}
                        >
                            <DatePicker
                                format={"YYYY-MM-DD"}
                                // disabledDate={disabledEndDate}
                            />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        )
    } else {
        return (
            <Modal
                title="休眠"
                visible={true}
                // onOk={form.submit}
                onOk={handleSubmit}
                onCancel={onClose}
                destroyOnClose={true}
                width={800}
                confirmLoading={loading || isApprovals}
            >
                <Spin spinning={loading || isApprovals} size={"large"} tip={"正在等待审核，审核完成前无法提交新的申请！"}>
                    <Form {...formItemLayout}
                          form={form}
                          onValuesChange={handleValuesChange}
                    >
                        <Form.Item label="类型">
                            <span className="ant-form-text">{data.name}</span>
                        </Form.Item>
                        <Form.Item label="开始日期">
                            <span className="ant-form-text">{data.begin_time}</span>
                        </Form.Item>
                        <Form.Item label="结束日期">
                            <span className="ant-form-text">{data.end_time}</span>
                        </Form.Item>
                        <Form.Item label="剩余年费">
                            <span className="ant-form-text">{data.payments_remaining}</span>
                        </Form.Item>
                        <Form.Item label="剩余天数">
                            <span className="ant-form-text">{data.limit_days_remaining}</span>
                        </Form.Item>
                        <Form.Item
                            label="休卡开始日期"
                            name="begin_time"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择开始日期',
                                },
                            ]}
                        >
                            <DatePicker
                                format={"YYYY-MM-DD"}
                                disabled={!!data.xiuka_begin_time}
                                // disabledDate={disabledDate}
                            />
                        </Form.Item>
                        <Form.Item
                            label="休卡结束日期"
                            name="end_time"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择结束日期',
                                },
                            ]}
                            help={help}
                        >
                            <DatePicker
                                format={"YYYY-MM-DD"}
                                disabled={!!data.xiuka_begin_time}
                                // disabledDate={disabledEndDate}
                            />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        )
    }
};

MembershipXiuMianModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};

export default MembershipXiuMianModal;
