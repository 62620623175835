/**
 * @name: reducer中间件
 * @description:
 * @author: Wood
 * @date: 2019/3/26
 * @update:
 */
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
// import React from 'react';
// import {createLogger} from 'redux-logger'

import reducer, {initialState} from './reducers';

let enhancer = applyMiddleware(thunk);
// if(process.env.NODE_ENV === "development"){
//     enhancer = compose(
//         applyMiddleware(thunk, createLogger()),
//     )
// }

// console.log(typeof reducers)
let store = createStore(
    reducer,
    initialState,
    enhancer
);

export default store;