import PropTypes from 'prop-types';
import React, {Component, useEffect, useState} from 'react';
import {Modal, Input, Form, DatePicker, InputNumber, message, Spin} from 'antd';
import MemberCardSelecter from '../../calendar/member-card-selecter2';
import {postKeshiBatchcika} from "../../../lib/api_keshi_batch";
import {getApprovalsLast} from "../../../lib/api_approvals";

const {TextArea} = Input;

const StaffAddModal = ({visible, onClose, onOK}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [isApprovals, setIsApprovals] = useState(false);


    // 获取审核情况
    const fetchApprovals = () => {
        setLoading(true);
        getApprovalsLast("手动次卡消课")
            .then(res => {
                if (res.code === 0) {
                    if (res.data && res.data.sp_status === 1) {
                        // 存在审核记录并且未完成状态
                        setIsApprovals(true);
                    } else {
                        setIsApprovals(false);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(fetchApprovals, []);


    const handleClose = () => {
        onClose && onClose();
    };
    const handleOK = () => {
        onOK && onOK();
    };

    const handleSubmit = e => {
        e.preventDefault();

        form.validateFields()
            .then(values => {
                console.log('Received values of form: ', values);
                values.set_time = values.set_time.format("YYYY-MM-DD HH:mm:00")
                if (!values.users) {
                    return message.error("请选择会员");
                }
                let users = JSON.parse(values.users);
                if (!users || users.length === 0) {
                    return message.error("请选择会员");
                }
                if (users.some(item => item.card_id === 0)) {
                    return message.error("请选择会员卡");
                }

                postKeshiBatchcika(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            handleOK();
                        } else if (result.code === 1001) {
                            fetchApprovals();
                        }
                    })
            });
    };

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 18},
        },
    };

    return (
        <Modal
            title="添加次卡消课"
            visible={visible}
            onOk={handleSubmit}
            onCancel={handleClose}
            destroyOnClose={true}
            width={800}
            confirmLoading={loading || isApprovals}
        >
            <Spin spinning={loading || isApprovals} size={"large"} tip={"正在等待审核，审核完成前无法提交新的申请！"}>
                <Form {...formItemLayout}
                    // onSubmit={this.handleSubmit}
                      initialValues={{
                          password: "123456"
                      }}
                      form={form}
                >
                    <Form.Item
                        label="时间"
                        name="set_time"
                        rules={[
                            {
                                required: true,
                                message: '请选择时间',
                            },
                        ]}
                    >
                        <DatePicker format={"YYYY-MM-DD HH:mm"} showTime/>
                    </Form.Item>
                    <Form.Item
                        label="次卡扣除课时"
                        name="keshi"
                        rules={[
                            {
                                required: true,
                                message: '请输入课时',
                            },
                        ]}
                    >
                        <InputNumber/>
                    </Form.Item>
                    <Form.Item
                        label="事由"
                        name="remark"
                        rules={[
                            {
                                required: true,
                                message: '请输入事由',
                            },
                        ]}
                    >
                        <TextArea autoSize={{minRows: 2, maxRows: 6}}/>

                    </Form.Item>
                    <Form.Item
                        label="会员卡"
                        name="users"
                        rules={[
                            {
                                required: true,
                                message: '请选择会员卡',
                            },
                        ]}
                    >
                        <MemberCardSelecter useFee={false}/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}

StaffAddModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};

export default StaffAddModal;
