import React from 'react';
import styles from './dp_table.module.css';


const DpTable = ({columns, dataSource, tdStyle, thStyle}) => {
    return (
        <table className={styles.table}>
            <thead>
            <tr>
                {columns.map(col => <th style={{
                    ...thStyle,
                    width: col.width ? col.width : null,
                    textAlign: col.align ? col.align : null,
                }}>{col.title}</th>)}
            </tr>
            </thead>
            <tbody>
            {dataSource.map((data, index) =>
                <tr>
                    {columns.map(col => <td
                        style={{
                            ...tdStyle,
                            width: col.width ? col.width : null,
                            textAlign: col.align ? col.align : null,
                        }}>{col.render ? col.render(data[col.dataIndex], data, index) : data[col.dataIndex]}</td>)}
                </tr>
            )}
            </tbody>
        </table>
    )
}
export default DpTable;