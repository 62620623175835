import React, {useState, useEffect} from 'react';
import {Modal, DatePicker, Form, InputNumber, message, Select, Spin} from 'antd';
import moment from 'moment';
import {postCard, postMembershipXuFei} from "../../../lib/api_membership";
import {getCards} from "../../../lib/api_cards";
import MembershipsTypeSelect from "./memberships_type_select";
import {getMembershipType} from "../../../lib/api_membership_type";
import {getApprovalsLast} from "../../../lib/api_approvals";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

/**
 * 添加年卡
 * @param visible
 * @param id
 * @param onOk
 * @param onCancel
 * @returns {JSX.Element}
 * @constructor
 */
const MembershipsDatesAddModal = ({visible, id, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [list, setList] = useState([]);
    const [card, setCard] = useState(null);
    const [avg, setAvg] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isApprovals, setIsApprovals] = useState(false);

    const fetchMembershipType = () => {
        getMembershipType()
            .then(res => {
                if (res.code === 0) {
                    setList(res.data.filter(m => m.id !== 0 && m.status === 0))
                }
            })
    };

    useEffect(fetchMembershipType, []);

    // 获取审核情况
    const fetchApprovals = () => {
        setLoading(true);
        getApprovalsLast("添加年卡")
            .then(res => {
                if (res.code === 0) {
                    if (res.data && res.data.sp_status === 1) {
                        // 存在审核记录并且未完成状态
                        setIsApprovals(true);
                    } else {
                        setIsApprovals(false);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(fetchApprovals, []);

    const Jisuan = () => {
        let payments = form.getFieldValue("payments");
        let keshi_original = form.getFieldValue("keshi_original");
        setAvg(Math.ceil(payments / keshi_original))
    };

    const handleTypeChange = (value) => {
        let item = list.find(_ => _.id === value);
        if (item) {
            setCard(item);
            let begin_time = moment().startOf('day');
            let end_time = moment(begin_time).add(item.limit_days - 1, 'd');
            form.setFieldsValue({
                begin_time: begin_time,
                end_time: end_time,
                payments: item.price,
                limit_days: item.limit_days,
                price: item.price,
            })
        }
    };

    const handleBeginTimeChange = (value) => {
        let begin_time = moment(value).startOf('day');
        let end_time = moment(begin_time).add(card.limit_days - 1, 'd');
        form.setFieldsValue({
            begin_time: begin_time,
            end_time: end_time,
        })
    };

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                postMembershipXuFei({
                    membership_id: id,
                    type_id: values.type_id,
                    begin_time: moment(values.begin_time).format("YYYY-MM-DD"),
                    end_time: moment(values.end_time).format("YYYY-MM-DD"),
                    payments: values.payments,
                })
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            message.success("添加成功").then(() => {
                            })
                            onOk && onOk()
                        } else if (result.code === 1001) {
                            fetchApprovals();
                        }
                    })
            })
    };

    return (
        <Modal
            title={`添加年卡`}
            visible={visible}
            onOk={handleSubmit}
            onCancel={onCancel}
            destroyOnClose={true}
            confirmLoading={loading || isApprovals}
        >
            <Spin spinning={loading || isApprovals} size={"large"} tip={"正在等待审核，审核完成前无法提交新的申请！"}>
                <Form
                    {...layout}
                    form={form}
                    initialValues={{
                        annual_fee: 0,
                        keshi_free_original: 0
                    }}
                >
                    <Form.Item
                        label="年卡类型"
                        name="type_id"
                        rules={[
                            {
                                required: true,
                                message: '请选择年卡',
                            },
                        ]}
                    >
                        <MembershipsTypeSelect onChange={handleTypeChange}/>
                    </Form.Item>
                    {!!card && <>
                        <Form.Item
                            label="开始日期"
                            name="begin_time"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择开始日期',
                                },
                            ]}
                        >
                            <DatePicker style={{width: 150}}
                                        format={"YYYY-MM-DD"}
                                        allowClear={false}
                                        onChange={handleBeginTimeChange}/>
                        </Form.Item>

                        <Form.Item
                            label="结束日期"
                            name="end_time"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择结束日期',
                                },
                            ]}
                        >
                            <DatePicker style={{width: 150}} format={"YYYY-MM-DD"} disabled/>
                        </Form.Item>
                        <Form.Item
                            label="实际支付年费"
                            name="payments"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入实际支付年费',
                                },
                            ]}
                        >
                            <InputNumber style={{width: 150}} precision={2} min={0}/>
                        </Form.Item>

                    </>}

                </Form>
            </Spin>
        </Modal>
    )
};

export default MembershipsDatesAddModal;
