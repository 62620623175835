import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PageContent from '../../components/page-content/page-content';
import {Button, Table, DatePicker} from 'antd';
import {getStat3} from '../../lib/api_stat'
import Box from '../../components/box';
import moment from 'moment';
import excelUtil from '../../lib/excelUtil.js'
import JiaoAn2 from './jiaolian2'

const {Column} = Table;


// @hasAuth
class Staff extends Component {
    state = {
        date: moment(),
        list: [],
    };

    componentDidMount() {
        // console.log(this);
        // this.hasRole();
        this.loadData()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        let begin_time = this.state.date.format("YYYY-MM-01");
        let end_time = moment(this.state.date).endOf('month').format("YYYY-MM-DD")+" 23:59:59";
        getStat3(begin_time, end_time)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list: result.data
                    })
                    // this.props.setStaff(result.data);
                } else {

                }
            })
    };
    onChange = (date, dateString) => {
        console.log(date, dateString);
        this.setState({
            date: date
        }, () => {
            this.loadData()
        })
    };

    columns = [{
        title: '教练',
        dataIndex: 'nick_name',
        key: 'nick_name',
    }, {
        title: '次数',
        dataIndex: '次数',
        key: '次数',
    }, {
        title: '时长(min)',
        dataIndex: '时长(min)',
        key: '时长(min)',
    }];

    exportXlsx = () => {
        excelUtil.exportExcel(this.columns, this.state.list, `${this.state.date.format("M")}月赠课统计.xlsx`)
    };


    render() {
        let {list} = this.state;
        return (
            <Box>
                <DatePicker
                    allowClear={false}
                    onChange={this.onChange}
                    value={this.state.date}
                    picker="month"/>
                <Button onClick={this.exportXlsx}>导出</Button>
                <Table
                    dataSource={list}
                    rowKey={"nick_name"}
                    pagination={false}
                    size={"small"}
                >
                    <Column
                        title="教练"
                        dataIndex="nick_name"
                        key="nick_name"
                    />
                    <Column
                        title="次数"
                        dataIndex="次数"
                        key="次数"
                    />
                    <Column
                        title="时长(min)"
                        dataIndex="时长(min)"
                        key="时长(min)"
                    />

                </Table>

            </Box>


        );
    }
}


export default withRouter(Staff);



