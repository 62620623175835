import axios from './rest-axios';

const getcwcoaAll = () => axios.get('/manage/cw_coa/');

const getcwcoa = (id) => axios.get(`/manage/cw_coa/${id}/`);

const createcwcoa = (data) => axios.post('/manage/cw_coa/', data);

const updatecwcoa = (id, data) => axios.put(`/manage/cw_coa/${id}/`, data);

const deletecwcoa = (id) => axios.delete(`/manage/cw_coa/${id}/`);

export {
    getcwcoaAll,
    getcwcoa,
    createcwcoa,
    updatecwcoa,
    deletecwcoa,
};
