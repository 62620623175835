import React, {Component, useEffect, useState} from 'react';
import PageContent from "../../../components/page-content/page-content";
import Box from "../../../components/box/box";
import {Link, withRouter} from "react-router-dom";
import {Table} from "antd";
import {getStaff} from "../../../lib/api_staff";
import {connect} from "react-redux";


const {Column} = Table;

const Huamingce = ({menus}) => {
    const [list, setList] = useState([]);

    const fetchData = () => {
        getStaff()
            .then((result) => {
                if (result.code === 0) {
                    setList(result.data.filter(_ => !!_.userid && _.enable === 1));
                } else {

                }
            })
    }

    useEffect(fetchData, []);

    return (<PageContent>
        <Box title={"员工花名册"}>
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size={"small"}
                // rowClassName={this.rowClassName}
            >
                {menus.some(menu => menu.resource_key === "hmc_gh") && <Column
                    title="工号"
                    dataIndex="gh"
                    key="工号"
                />}
                {menus.some(menu => menu.resource_key === "hmc_name") && <Column
                    title="姓名"
                    dataIndex="name"
                    key="姓名"
                />}
                <Column
                    title="别名"
                    dataIndex="alias"
                    key="别名"
                />
                {menus.some(menu => menu.resource_key === "hmc_position") && <Column
                    title="职务"
                    dataIndex="position"
                    key="职务"
                    filters={[
                        {
                            text: 'Coach',
                            value: 'Coach',
                        },
                        {
                            text: 'Manager',
                            value: 'Manager',
                        },
                        {
                            text: 'Intern',
                            value: 'Intern',
                        },
                    ]}
                    onFilter={(value, record) => record.position.indexOf(value) !== -1}
                />}
                {menus.some(menu => menu.resource_key === "hmc_main_department") && <Column
                    title="部门"
                    key="部门"
                    dataIndex="main_department"
                    filters={[
                        {
                            text: '教练',
                            value: '教练',
                        },
                        {
                            text: '丹枫马会',
                            value: '丹枫马会',
                        },
                        {
                            text: '车场',
                            value: '车场',
                        },
                        {
                            text: '前厅',
                            value: '前厅',
                        },
                    ]}
                    onFilter={(value, record) => record.main_department.indexOf(value) !== -1}
                />}
                {menus.some(menu => menu.resource_key === "hmc_mobile") && <Column
                    title="手机"
                    dataIndex="mobile"
                    key="手机"
                />}
                {menus.some(menu => menu.resource_key === "hmc_email") && <Column
                    title="邮箱"
                    dataIndex="email"
                    key="邮箱"
                />}

                {menus.some(menu => menu.resource_key === "hmc_gender") && <Column
                    title="性别"
                    dataIndex="gender"
                    key="性别"
                    render={(gender) => gender === 1 ? "男" : "女"}
                />}
                {menus.some(menu => menu.resource_key === "hmc_login_name") && <Column
                    title="登录账号"
                    dataIndex="login_name"
                    key="登录账号"
                />}

            </Table>
        </Box>
    </PageContent>);
}


const mapStateToProps = state => ({
    menus: state.menus
});
const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Huamingce));

