import axios from './rest-axios';

const getPaiBan = (begin_time, end_time) => axios.get(`/manage/paiban`, {begin_time, end_time});
const postPaiBan = (model) => axios.post(`/manage/paiban`, model);
const deletePaiBan = (model) => axios.delete(`/manage/paiban`, model);
const postPaiBanYuyue = (model) => axios.post(`/manage/paiban/cantyuyue`, model);


export {
    getPaiBan,
    postPaiBan,
    deletePaiBan,
    postPaiBanYuyue,
};
