import React, {useState, useEffect} from 'react';
import {Card, Table, Input} from 'antd';
import {getCoursesFee} from "../../../lib/api_courses_fee";
import DictName from '../../../components/dict/dict-name';


const CourseFeeConfig = ({value, onChange}) => {
    const [list, setList] = useState([]);
    // const [selectedRowKeys, setselectedRowKeys] = useState([]);


    const loadData = () => {
        getCoursesFee()
            .then(res => {
                if (res.code === 0) {
                    let ls  = res.data.filter(_ => _.teaching_method === 1);
                    setList(ls);
                }
            });
        // setselectedRowKeys(value.map(_=>_.id))
    };
    useEffect(loadData, []);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRowKeys);
            // setselectedRowKeys(selectedRowKeys);
            onChange && onChange(selectedRowKeys);
        },
        defaultSelectedRowKeys: value
    };

    return (
        <Card bordered={false} title={"可预约课程（仅用于设置1v1课程）"}>
            <Table
                // title="课程设置"
                size="small"
                pagination={false}
                dataSource={list}
                rowKey={"id"}
                rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                }}
                columns={[{
                    title: '显示名称',
                    dataIndex: 'display_name',
                    width: 100,
                    // render: (value, record) => <Input defaultValue={value}
                    //                                   onChange={(e) => handleDisplayNameChange(e, record)}/>
                }, {
                    title: '课程类型',
                    dataIndex: 'course_type',
                    sorter: (a, b) => a.course_type - b.course_type,
                    width: 100,
                    render: (value) => <DictName type_value={"course_type"} value={value}/>
                }, {
                    title: '教师级别',
                    dataIndex: 'coach_level',
                    sorter: (a, b) => a.coach_level - b.coach_level,
                    width: 100,
                    render: (value) => <DictName type_value={"coach_level"} value={value}/>
                }, {
                    title: '授课方式',
                    dataIndex: 'teaching_method',
                    width: 100,
                    render: (value) => <DictName type_value={"teaching_method"} value={value}/>
                }, {
                    title: '教练费',
                    dataIndex: 'fee',
                    align: "right",
                    width: 100,
                    render: (value) => parseFloat(value).toFixed(2)
                }]}/>
        </Card>
    )
};


export default CourseFeeConfig;


