import React, {Component, useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Button, Card, Col, Row, Space, Table, Typography, Descriptions} from "antd";
import moment from "moment";
import {Link} from "react-router-dom";
import {getAllLuoMas, getLuoma} from "../../../lib/api_luoma";


const {Column} = Table;
const LuomaDetail = ({id}) => {
    const [data, setdata] = useState({});

    const fetchData = () => {
        getLuoma(id)
            .then((res) => {
                if (res.code === 0) {
                    setdata(res.data);
                }
            })
    }

    useEffect(fetchData, []);


    return (
        <PageContent>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                <Card title="处理状态">
                    {data.status === 0 && <Descriptions>
                        <Descriptions.Item label="处理状态">待责任教练反馈</Descriptions.Item>
                        <Descriptions.Item label="当前处理人">{data.staff_name}</Descriptions.Item>
                        {data.zjl_status === 1 &&
                            <Descriptions.Item label="驳回理由">{data.zjl_bhyj}</Descriptions.Item>}
                        <Descriptions.Item label="发起人">{data.faqiren}</Descriptions.Item>
                        <Descriptions.Item label="来源">{data.laiyuan}</Descriptions.Item>

                    </Descriptions>}
                    {data.status === 1 && <Descriptions>
                        <Descriptions.Item label="处理状态">待总教练反馈</Descriptions.Item>
                        <Descriptions.Item label="当前处理人">{data.staff_name}</Descriptions.Item>
                        {data.khjl_status === 1 &&
                            <Descriptions.Item label="驳回理由">{data.khjl_bhyj}</Descriptions.Item>}
                    </Descriptions>}
                    {data.status === 2 && <Descriptions>
                        <Descriptions.Item label="处理状态">待客户经理反馈</Descriptions.Item>
                        <Descriptions.Item label="当前处理人">{data.staff_name}</Descriptions.Item>
                        {data.glc_status === 1 &&
                            <Descriptions.Item label="驳回理由">{data.glc_bhyj}</Descriptions.Item>}
                    </Descriptions>}
                    {data.status === 3 && <Descriptions>
                        <Descriptions.Item label="处理状态">待管理员反馈</Descriptions.Item>
                        <Descriptions.Item label="当前处理人">{data.staff_name}</Descriptions.Item>
                    </Descriptions>}
                    {data.status === 4 && <Descriptions>
                        <Descriptions.Item label="处理状态">办结</Descriptions.Item>
                    </Descriptions>}
                </Card>
                <Card title="基本情况">
                    <Descriptions>
                        <Descriptions.Item
                            label="反馈时间">{moment(data.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Descriptions.Item>
                        <Descriptions.Item label="落马时间">{data.lmsj}</Descriptions.Item>
                        <Descriptions.Item label="骑乘类型">{data.qclx}</Descriptions.Item>
                        <Descriptions.Item label="教练">{data.teacher}</Descriptions.Item>
                        <Descriptions.Item label="学员">{data.user_name}</Descriptions.Item>
                        <Descriptions.Item label="马匹">{data.horse}</Descriptions.Item>
                        <Descriptions.Item label="落马地点">{data.lmdd}</Descriptions.Item>
                        <Descriptions.Item label="教练角色">{data.jljs}</Descriptions.Item>
                        <Descriptions.Item label="事件经过">
                            <pre style={{wordWrap: "break-word", whiteSpace: "pre-wrap"}}>{data.sjjg}</pre>
                        </Descriptions.Item>
                        <Descriptions.Item label="原因分析和改进措施">
                            <pre style={{wordWrap: "break-word", whiteSpace: "pre-wrap"}}>{data.sjyy}</pre>
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
                {data.jl_status === 1 &&
                    <Card title="教练否认落马">
                        <Descriptions column={1}>
                            <Descriptions.Item label="否认理由">{data.jl_bhyj}</Descriptions.Item>
                            <Descriptions.Item label="教练">{data.teacher}</Descriptions.Item>
                            <Descriptions.Item
                                label="提交时间">{moment(data.jl_clsj).format("YYYY-MM-DD HH:mm:ss")}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                }
                {data.zjl_status === 0 &&
                    <Card title="总教练意见">
                        <Descriptions column={1}>
                            <Descriptions.Item label="责任">{data.zjl_rd}</Descriptions.Item>
                            <Descriptions.Item label="补充意见">{data.zjl_yj}</Descriptions.Item>
                            <Descriptions.Item label="总教练">{data.zjl}</Descriptions.Item>
                            <Descriptions.Item label="提交时间">{data.zjl_clsj}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                }
                {data.zjl_status === 1 &&
                    <Card title="总教练驳回">
                        <Descriptions column={1}>
                            <Descriptions.Item label="驳回理由">{data.zjl_bhyj}</Descriptions.Item>
                            <Descriptions.Item label="总教练">{data.zjl}</Descriptions.Item>
                            <Descriptions.Item label="提交时间">{data.zjl_clsj}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                }
                {data.khjl_status === 0 &&
                    <Card title="客户经理意见">
                        <Descriptions column={1}>
                            <Descriptions.Item label="上交时间">{data.khjl_sjsj}</Descriptions.Item>
                            <Descriptions.Item label="补充意见">{data.khjl_yj}</Descriptions.Item>
                            <Descriptions.Item label="客户经理">{data.khjl}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                }
                {data.khjl_status === 1 &&
                    <Card title="客户经理驳回">
                        <Descriptions column={1}>
                            <Descriptions.Item label="驳回理由">{data.khjl_bhyj}</Descriptions.Item>
                            <Descriptions.Item label="客户经理">{data.khjl}</Descriptions.Item>
                            <Descriptions.Item label="提交时间">{data.khjl_clsj}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                }
                {data.glc_status === 0 &&
                    <Card title="管理层意见">
                        <Descriptions column={1}>
                            <Descriptions.Item label="责任">{data.glc_rd}</Descriptions.Item>
                            <Descriptions.Item label="补充意见">{data.glc_yj}</Descriptions.Item>
                            <Descriptions.Item label="管理层">{data.glc}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                }
                {data.glc_status === 1 &&
                    <Card title="管理层驳回">
                        <Descriptions column={1}>
                            <Descriptions.Item label="驳回理由">{data.glc_bhyj}</Descriptions.Item>
                            <Descriptions.Item label="管理层">{data.glc}</Descriptions.Item>
                            <Descriptions.Item label="提交时间">{data.glc_clsj}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                }
            </Space>
        </PageContent>
    );
}

export default LuomaDetail;