import React, {useState, useEffect} from 'react';
import {Modal, DatePicker, Form, InputNumber, message, Select, Col, Input, Radio, Row} from 'antd';
import moment from 'moment';
import {getMembership, postCard} from "../../../lib/api_membership";
import {getCards} from "../../../lib/api_cards";
import TeacherSelect from "../teacher-select";
import DictRadioGroup from "../../../components/dict/dict-radio-group";
import {postMembers, putMemberMembershipId} from "../../../lib/api_members";
import styles from "../memberships/memberships_type_select.module.css";

// const layout = {
//     labelCol: {span: 8},
//     wrapperCol: {span: 16},
// };

const MemberMoveModal = ({visible, id, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [list, setList] = useState([]);

    // 获取会籍列表
    const fetchData = () => {
        getMembership()
            .then((result) => {
                if (result.code === 0) {
                    setList(result.data);
                }
            })
    };
    useEffect(fetchData, []);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log(values);
                putMemberMembershipId(id, values.membership_id)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            message.success("转移成功").then(() => {
                            })
                            onOk && onOk()
                        }
                    })
            })
    };

    return (
        <Modal
            title={`转移会员`}
            visible={visible}
            onOk={handleSubmit}
            onCancel={onCancel}
            destroyOnClose={true}
            width={800}
        >
            <Form
                form={form}
                initialValues={{
                    sex: 1,
                    tag1: "",
                    tag2: "",
                }}
            >
                <Form.Item
                    label="接收会籍"
                    name="membership_id"
                    labelCol={{flex:"85px"}}
                    rules={[
                        {
                            required: true,
                            message: '请选择会籍',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        style={{
                            width: '100%',
                        }}
                        placeholder="选择会籍类型"
                        optionLabelProp="label"
                        filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {list.map(m => (
                            <Select.Option value={m.id} label={`${m.member_number} / ${m.name} / ${m.mobile_number} / ${m.license_plate} / ${m.member_count}人`}
                                           key={m.id}>
                                <div className={styles.option}>
                                    <span style={{width: 150}}>{m.member_number}</span>
                                    <span style={{flex: 1}}>{m.name}</span>
                                    <span style={{width: 100}}>{m.mobile_number}</span>
                                    <span style={{width: 100}}>{m.license_plate}</span>
                                    <span style={{width: 50}}>{m.member_count}人</span>
                                </div>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
};

export default MemberMoveModal;
