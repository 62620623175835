
const SHOW = 'password-modal/SHOW';
const HIDE = 'password-modal/HIDE';

const initialState = false;

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SHOW:
            return true;
        case HIDE:
            return false;
        default:
            return state;
    }
};

const showPasswordModal = function (account) {
    return {
        type: SHOW
    }
};

const hidePasswordModal = function () {
    return {
        type: HIDE
    }
};

export {
    reducer as default,
    initialState as initialPasswordModalState,
    showPasswordModal,
    hidePasswordModal
}