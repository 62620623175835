import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getMembershipFees} from "../../../lib/api_membership";
import {Table, Popover, notification, Button, Modal} from 'antd';
import moment from 'moment';
import Box from '../../../components/box/index';
import {FullscreenOutlined} from '@ant-design/icons';


const {Column} = Table;

const DataTable = ({list, students, scroll}) => {
    return (
        <Table
            size={"small"}
            dataSource={list}
            rowKey={"id"}
            pagination={false}
        >
            <Column
                title="时间"
                dataIndex="createdAt"
                key="时间"
                width={180}
                render={(time) => moment(time).format("YYYY-MM-DD ddd HH:mm")}
            />
            <Column
                title="金额"
                dataIndex="fee"
                key="金额"
                align="right"
            />
            <Column
                title="赠送"
                dataIndex="is_gift"
                key="赠送"
                width={60}
                render={(is_gift) => is_gift === 0 ? "否" : "是"}
            />
            <Column
                title="备注"
                dataIndex="remark"
                key="备注"
            />
            <Column
                title="会员"
                dataIndex="user_id"
                key="会员"
                render={(user_id) => {
                    let student = students.find(_ => _.id === user_id);
                    if (student) {
                        return <Link to={`/member/members/${student.id}`}>{student.nick_name}</Link>;
                    } else {
                        return null;
                    }
                }}
            />
        </Table>
    )
}

const MembershipFee = ({id, students}) => {
    const [list, setList] = useState([]);
    const [more, setmore] = useState(false);

    const fetchData = () => {
        getMembershipFees(id)
            .then(res => {
                if (res.code === 0) {
                    setList(res.data);
                }
            })
    };

    useEffect(fetchData, []);

    return (
        <Box title={`教练费记录(${list.length}条)`} style={{marginTop: 10}} extra={[
            <Button type="link" onClick={() => setmore(true)}>查看更多</Button>
        ]}>
            <DataTable list={list.slice(0, 5)} students={students}/>
            <Modal
                visible={more}
                title={`教练费记录(${list.length}条)`}
                footer={false}
                onCancel={() => setmore(false)}
                width={700}
            >
                <DataTable list={list} students={students} />
            </Modal>
        </Box>
    )
};


MembershipFee.propTypes = {
    id: PropTypes.number.isRequired,
};


const mapStateToProps = state => ({
    students: state.students
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MembershipFee));
