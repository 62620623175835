import React, {useState, useEffect} from 'react';
import {getAllLevels} from "../../lib/api_levels";


const LevelName = ({value}) => {
    const [name, setname] = useState("");
    const fetchData = () => {
        getAllLevels()
            .then(res => {
                if (res.code === 0) {
                    let le = res.data.find(m=>m.id===value);
                    if(le){
                        setname(le.name);
                    }
                }
            })
    };
    useEffect(fetchData, []);


    return (
        <span>{name}</span>
    )
};

export default LevelName;
