import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, DatePicker, Radio} from 'antd';
import {getHorseZtlasted, postHorseZt} from "../../../lib/api_horses";
import moment from "moment";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};


const HorsesZTAddModal = ({horse_id, onCancel, onOk}) => {
    const [form] = Form.useForm();
    const [values, setValues] = useState({});


    const fetchData = () => {
        getHorseZtlasted(horse_id)
            .then(res => {
                if (res.code === 0) {
                    delete res.data.time;
                    setValues(res.data);
                    form.setFieldsValue(res.data);
                }
            })
    }

    useEffect(fetchData, []);


    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                postHorseZt(horse_id, {...values, horse_id: horse_id})
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk();
                        }
                    })
            })
    };

    const handleCancel = () => {
        onCancel && onCancel();
    };

    const handleValueChange = (_, allValues) => {
        // setStatus(allValues.status);
        if (allValues.status === "正常") {
            form.setFieldsValue({
                ws: "否",
                jb: "否",
                qt: "否",
            })
        }
        setValues(allValues);
    }

    return (
        <Modal
            title="添加状态记录"
            open={true}
            onOk={handleSubmit}
            onCancel={handleCancel}
            destroyOnClose={true}
        >
            <Form
                form={form}
                {...formItemLayout}
                initialValues={{
                    time: moment()
                }}
                onValuesChange={handleValueChange}
            >
                <Form.Item
                    label="时间"
                    name="time"
                    rules={[
                        {
                            required: true,
                            message: '请选择时间',
                        },
                    ]}
                >
                    <DatePicker showTime format={"YYYY-MM-DD HH:mm"}/>
                </Form.Item>

                <Form.Item
                    label="状态"
                    name="status"
                    rules={[
                        {
                            required: true,
                            message: '请选择状态',
                        }
                    ]}
                >
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value="正常">正常</Radio.Button>
                        <Radio.Button value="需要关注">需要关注</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                {values.status === "需要关注" &&
                    <>
                        <Form.Item
                            label="是否外伤"
                            name="ws"
                        >
                            <Radio.Group buttonStyle="solid">
                                <Radio.Button value="是">是</Radio.Button>
                                <Radio.Button value="否">否</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        {values.ws === "是" &&
                            <Form.Item
                                label="外伤备注"
                                name="wsbz"
                            >
                                <Input.TextArea/>
                            </Form.Item>
                        }
                        <Form.Item
                            label="是否疾病"
                            name="jb"
                        >
                            <Radio.Group buttonStyle="solid">
                                <Radio.Button value="是">是</Radio.Button>
                                <Radio.Button value="否">否</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        {values.jb === "是" &&
                            <Form.Item
                                label="疾病备注"
                                name="jbbz"
                            >
                                <Input.TextArea/>
                            </Form.Item>
                        }
                        <Form.Item
                            label="是否其他"
                            name="qt"
                        >
                            <Radio.Group buttonStyle="solid">
                                <Radio.Button value="是">是</Radio.Button>
                                <Radio.Button value="否">否</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        {values.qt === "是" &&
                            <Form.Item
                                label="其他备注"
                                name="qtbz"
                            >
                                <Input.TextArea/>
                            </Form.Item>
                        }
                    </>
                }

            </Form>

        </Modal>
    )
}

export default HorsesZTAddModal;
