import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Modal, Form, InputNumber, Input, Spin} from 'antd';
import {posttuijiaolianfei} from "../../../lib/api_membership";
import {getApprovalsLast} from "../../../lib/api_approvals";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};

const MembershipTuiJiaoLianFeiModal = ({data, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [isApprovals, setIsApprovals] = useState(false);

    // 获取审核情况
    const fetchApprovals = () => {
        setLoading(true);
        getApprovalsLast("退还教练费")
            .then(res => {
                if (res.code === 0) {
                    if (res.data && res.data.sp_status === 1) {
                        // 存在审核记录并且未完成状态
                        setIsApprovals(true);
                    } else {
                        setIsApprovals(false);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(fetchApprovals, []);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                posttuijiaolianfei({
                    id: data.id,
                    fee: values.fee,
                    remark: values.remark,
                })
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk(res.data);
                        } else if (res.code === 1001) {
                            fetchApprovals();
                        }
                    })
            })
    };

    useEffect(() => {
        console.log(data);
        form.setFieldsValue({
            fee: data.fee_remaining
        })
    }, []);


    return (
        <Modal
            title="退教练费"
            visible={true}
            // onOk={form.submit}
            onOk={handleSubmit}
            onCancel={onCancel}
            destroyOnClose={true}
            width={800}
            confirmLoading={loading || isApprovals}
        >
            <Spin spinning={loading || isApprovals} size={"large"} tip={"正在等待审核，审核完成前无法提交新的申请！"}>
                <Form {...formItemLayout}
                      form={form}
                >
                    <Form.Item label="类型">
                        <span className="ant-form-text">{data.name}</span>
                    </Form.Item>
                    <Form.Item label="开始日期">
                        <span className="ant-form-text">{data.begin_time}</span>
                    </Form.Item>
                    <Form.Item label="结束日期">
                        <span className="ant-form-text">{data.end_time}</span>
                    </Form.Item>
                    <Form.Item label="剩余教练费">
                        <span className="ant-form-text">{data.fee_remaining}</span>
                    </Form.Item>
                    <Form.Item
                        label="退还教练费"
                        name="fee"
                        rules={[
                            {
                                required: true,
                                message: '请输入退款金额',
                            },
                        ]}
                    >
                        <InputNumber/>
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remark"
                        rules={[
                            {
                                required: true,
                                message: '请输入备注',
                            },
                        ]}
                    >
                        <Input.TextArea/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
};

MembershipTuiJiaoLianFeiModal.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
};

export default MembershipTuiJiaoLianFeiModal;
