import React, {} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Space, Table, Typography, Divider, Popconfirm} from 'antd';
import moment from 'moment';
import DictName from '../../../components/dict/dict-name';
import {VIP} from '../../../components/icons';
import {
    ManOutlined,
    WomanOutlined,
    EyeInvisibleOutlined,
    ExportOutlined,
    FileTextOutlined,
    EyeOutlined
} from '@ant-design/icons';
import UsersCardsStatus from "../users_cards/users_cards_status";
import LevelName from "../level-name";

const {Column} = Table;

const MembersList = ({list, onFile, onUnFile, onMove}) => {
    const history = useHistory()

    const handleFile = (id) => {
        onFile && onFile(id);
    }
    const handleUnFile = (id) => {
        onUnFile && onUnFile(id);
    }
    const handleMove = (id) => {
        onMove && onMove(id);
    }
    return (
        <Table
            size={"small"}
            dataSource={list}
            rowKey={"id"}
            pagination={false}
            scroll={{x: 1050}}
        >
            <Column
                title="状态"
                dataIndex="is_file"
                key="状态"
                width={50}
                render={(is_file) => <UsersCardsStatus status={is_file}/>}
                fixed={"left"}
            />
            <Column
                title="姓名"
                dataIndex="nick_name"
                key="nick_name"
                width={100}
                render={(nick_name, record) => (
                    <Link to={`/member/members/${record.id}`}>
                            <span style={{color: "#1890ff"}}>
                                    {nick_name}
                                </span>
                        {record.membership_id > 0 && <VIP/>}
                    </Link>
                )}
                fixed={"left"}
            />
            <Column
                title="昵称"
                dataIndex="other_name"
                key="other_name"
            />

            <Column
                title="性别"
                dataIndex="sex"
                key="sex"
                width={70}
                render={(sex) => (
                    <>
                        {sex === 1 && <ManOutlined style={{color: "blue"}}/>}
                        {sex === 2 && <WomanOutlined style={{color: "red"}}/>}
                    </>
                )}
            />

            <Column
                title="年龄"
                dataIndex="birthday"
                key="birthday"
                width={70}
                render={(birthday) => (
                    <span>
                        {birthday ? <>{moment().diff(moment(birthday), 'years') + 1}</> : ""}
                    </span>
                )}
            />

            <Column
                title="教练"
                dataIndex="teacher_name"
                key="教练"
                width={70}
            />
            <Column
                title="顾问"
                dataIndex="consultant_name"
                key="顾问"
                width={70}
            />
            <Column
                title="训练类型"
                dataIndex="train_type"
                key="训练类型"
                width={100}
                render={(train_type) => <DictName type_value={"train_type_s"} value={train_type}/>}
            />
            <Column
                title="标1"
                dataIndex="tag1"
                key="tag1"
                width={70}
            />
            <Column
                title="标2"
                dataIndex="tag2"
                key="tag2"
                width={70}
            />


            <Column
                title="级别"
                dataIndex="level"
                key="级别"
                width={70}
                render={(level)=><LevelName value={level}/>}
            />
            <Column
                title="操作"
                key="操作"
                fixed="right"
                width={200}
                align="right"
                render={(record) => {
                    return (
                        <Space split={<Divider type="vertical"/>}
                               size={0}>
                            <Typography.Link
                                onClick={() => history.push(`/member/members/${record.id}`)}
                            ><FileTextOutlined/> 详情</Typography.Link>
                            {/*{record.is_file === 0 && (*/}
                            {/*    <Popconfirm*/}
                            {/*        title="是否禁用该用户？"*/}
                            {/*        onConfirm={() => handleFile(record.id)}*/}
                            {/*    >*/}
                            {/*        <Typography.Link*/}
                            {/*            type="danger"><EyeInvisibleOutlined/> 失效</Typography.Link>*/}
                            {/*    </Popconfirm>*/}
                            {/*)}*/}
                            {/*{record.is_file === 1 && (*/}
                            {/*    <Popconfirm*/}
                            {/*        title="是否启用该用户？"*/}
                            {/*        onConfirm={() => handleUnFile(record.id)}*/}
                            {/*    >*/}
                            {/*        <Typography.Link*/}
                            {/*            type="success"><EyeOutlined/> 启用</Typography.Link>*/}
                            {/*    </Popconfirm>*/}
                            {/*)}*/}
                            {/*<Typography.Link*/}
                            {/*    type="danger"*/}
                            {/*    onClick={() => handleMove(record.id)}*/}
                            {/*><ExportOutlined/> 转移</Typography.Link>*/}
                        </Space>
                    )
                }}
            />
        </Table>
    )
}

export default MembersList;


