import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {Button, Popconfirm, Space, Table, Tabs, Typography} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import Box from '../../../components/box';
import {getcwcoaAll, deletecwcoa} from "../../../lib/api_cw_coa";
import CoaAddModal from "./coa_add_modal";
import CoaEditModal from "./coa_edit_modal";
import excelUtil from "../../../lib/excelUtil";
import moment from "moment/moment";
import ExcelJS from "exceljs";
import {saveAs} from 'file-saver';


const {Column} = Table;


const Coa = (props) => {
    const [data, setData] = useState([]);
    const [addmodal, setaddmodal] = useState(0);
    const [editmodal, seteditmodal] = useState(0);

    const fetchData = () => {
        getcwcoaAll()
            .then((res) => {
                if (res.code === 0) {
                    setData(listToTree(res.data));
                }
            })
    }

    useEffect(fetchData, []);

    // 递归将列表转成antd的树结构，父节点为pid，pid为0的为顶级节点
    const listToTree = (list, pid = 0) => {
        const tree = [];

        list.forEach(item => {
            if (item.pid === pid) {
                const children = listToTree(list, item.id);
                if (children.length) {
                    item.children = children;
                }
                tree.push(item);
            }
        });

        return tree;
    }

    const deleteCoa = (id) => {
        deletecwcoa(id).then((res) => {
            if (res.code === 0) {
                fetchData();
            }
        })
    }
    // 递归将树结构转成列表
    const treeToList = (worksheet, tree, indent) => {
        // 判断tree是否为空
        if (!tree) {
            return [];
        }
        const list = [];

        tree.forEach(item => {
            const rowCount = worksheet.rowCount;
            const row1 = worksheet.getRow(rowCount + 1);
            // row1.height = 20;
            row1.getCell(1).value = item.name;
            row1.getCell(2).value = item.department || "";
            row1.getCell(1).border = {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bot: {style: 'thin'},
                right: {style: 'thin'}
            }
            row1.getCell(2).border = {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bot: {style: 'thin'},
                right: {style: 'thin'}
            }
            // 缩进
            if (indent) {
                row1.getCell(1).alignment = {
                    horizontal: 'left',
                    indent: indent
                }
            }

            // 递归调用，将子节点转成列表
            if (item.children && item.children.length) {
                treeToList(worksheet, item.children, indent + 1);
            }
        });

        return list;
    }

    const exportXlsx = async () => {

        // let data1 = treeToList(data);

        // 导出excel
        const workbook = new ExcelJS.Workbook();
        for (let i = 0; i < data.length; i++) {
            const worksheet = workbook.addWorksheet(data[i].name);
            worksheet.columns = [ // 设置工作表的列信息
                {header: '名称', key: 'name', width: 32},
                {header: '部门', key: 'department', width: 20},
            ]
            // 设置标题行的边框样式
            const row1 = worksheet.getRow(1);
            row1.height = 20;
            worksheet.getRow(1).eachCell((cell) => {
                cell.border = {
                    top: {style: 'thin'},
                    left: {style: 'thin'},
                    bottom: {style: 'thin'},
                    right: {style: 'thin'}
                };
                cell.font = {
                    name: "黑体",
                    bold: true,
                    color: {
                        // 注意：在 exceljs 中所有的的颜色值均为 argb 格式，且不带 # 符号
                        // argb: "ffffff",
                    },
                }
                // cell.fill = {
                //     type: "pattern",
                //     pattern: "solid",
                //     fgColor: {
                //         argb: "808080",
                //     },
                // }
            });
            // 设置标题行的居中对齐
            worksheet.getRow(1).eachCell((cell) => {
                cell.alignment = {horizontal: 'center', vertical: 'middle'};
            });

            const data1 = treeToList(worksheet, data[i].children, 0);

            // 将数据添加到工作表中
            worksheet.addRows(data1);

        }
        //将data插入 工作表，并按照级别进行缩进
        const buffer = await workbook.xlsx.writeBuffer()
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        saveAs(blob, `财务科目表_${moment().format("YYYYMMDDHHmmss")}.xlsx`)


        // let columns = [
        //     {title: '名称', dataIndex: 'name', key: 'name',},
        //     {title: '部门', dataIndex: 'department', key: 'department',},
        // ]
        // excelUtil.exportExcel(columns, data1, `财务科目表_${moment().format("YYYYMMDDHHmmss")}.xlsx`)
    };

    return (
        <PageContent>
            <Box title={"财务科目表"}
                 extra={
                     <Button type='primary' onClick={exportXlsx}>导出</Button>
                 }
                 style={{marginBottom: 20}}
            >
                {data.length > 0 &&
                    <Tabs>
                        <Tabs.TabPane tab={"马术收入"} key={"1"}>
                            <Table
                                dataSource={data.filter(m => m.id == 1)}
                                rowKey={"id"}
                                pagination={false}
                                size={"small"}
                                expandable={{
                                    defaultExpandAllRows: true,
                                }}
                            >
                                <Column title="名称" dataIndex="name" key="名称"/>
                                <Column title="部门" dataIndex="department" key="部门"/>
                                <Column title="操作" dataIndex="id" key="操作"
                                        width={150}
                                        render={(text, record, index) => {
                                            if (record.name === '收入扣减 (填负数)') {
                                                return null;
                                            }
                                            return (
                                                <Space>
                                                    <Typography.Link
                                                        onClick={() => setaddmodal(record.id)}>添加子项</Typography.Link>
                                                    <Typography.Link
                                                        onClick={() => seteditmodal(record.id)}>修改</Typography.Link>
                                                    <Popconfirm
                                                        title="确定删除吗?"
                                                        onConfirm={() => {
                                                            deleteCoa(record.id);
                                                        }}
                                                        okText="确定"
                                                        cancelText="取消"
                                                    >
                                                        <Typography.Link>删除</Typography.Link>
                                                    </Popconfirm>
                                                </Space>
                                            )
                                        }}
                                />
                            </Table>

                        </Tabs.TabPane>

                        <Tabs.TabPane tab={"马术支出"} key={"2"}>
                            <Table
                                dataSource={data.filter(m => m.id == 2)}
                                rowKey={"id"}
                                pagination={false}
                                size={"small"}
                                expandable={{
                                    defaultExpandAllRows: true,
                                }}
                            >
                                <Column title="名称" dataIndex="name" key="名称"/>
                                <Column title="部门" dataIndex="department" key="部门"/>
                                <Column title="操作" dataIndex="id" key="操作"
                                        width={150}
                                        render={(text, record, index) => {
                                            if (record.name === '收入扣减 (填负数)') {
                                                return null;
                                            }
                                            return (
                                                <Space>
                                                    <Typography.Link
                                                        onClick={() => setaddmodal(record.id)}>添加子项</Typography.Link>
                                                    <Typography.Link
                                                        onClick={() => seteditmodal(record.id)}>修改</Typography.Link>
                                                    <Popconfirm
                                                        title="确定删除吗?"
                                                        onConfirm={() => {
                                                            deleteCoa(record.id);
                                                        }}
                                                        okText="确定"
                                                        cancelText="取消"
                                                    >
                                                        <Typography.Link>删除</Typography.Link>
                                                    </Popconfirm>
                                                </Space>
                                            )
                                        }}
                                />
                            </Table>

                        </Tabs.TabPane>
                    </Tabs>

                }
            </Box>

            {addmodal > 0 && <CoaAddModal
                pid={addmodal}
                onOk={() => {
                    setaddmodal(0);
                    fetchData();
                }}
                onCancel={() => {
                    setaddmodal(0);
                }}
            />}
            {editmodal > 0 && <CoaEditModal
                id={editmodal}
                onOk={() => {
                    seteditmodal(0);
                    fetchData();
                }}
                onCancel={() => {
                    seteditmodal(0);
                }}
            />}
        </PageContent>
    )
}

export default withRouter(Coa);