import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PageContent from '../../components/page-content/page-content';
import {Button, Table, DatePicker,Tabs,Space} from 'antd';
import {getStat7} from '../../lib/api_stat'
import Box from '../../components/box';
import moment from 'moment';
import excelUtil from '../../lib/excelUtil.js'
import JiaoAn2 from './jiaolian2'
import StoreSelect from '../members/store-select';
import {HotTable, HotColumn} from '@handsontable/react';
import  'handsontable';

const {Column} = Table;
const {TabPane} = Tabs;


// @hasAuth
class Staff extends Component {
    state = {
        store_id: 1,
        date: moment(),
        list1: [],
        list2: [],
        list3: [],
        list4: [],

        detailItem: null
    };

    componentDidMount() {
        // console.log(this);
        // this.hasRole();
        this.loadData()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        // let end_time = moment(this.state.date).endOf('month').format("YYYY-MM-DD");
        this.setState({
            list1:[],
            list2:[],
            list3:[],
            list4:[],
        })
        let year = moment(this.state.date).year();
        getStat7(year, this.state.store_id)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        list1: result.data.list1,
                        list2: result.data.list2,
                        list3: result.data.list3,
                        list4: result.data.list4,
                    })
                    // this.props.setStaff(result.data);
                } else {

                }
            })
    };
    onChange = (date, dateString) => {
        console.log(date, dateString);
        this.setState({
            date: date
        }, () => {
            this.loadData()
        })
    };

    onStoreChange = (id)=>{
        console.log(id)
        this.setState({
            store_id: id
        }, () => {
            this.loadData()
        })
    };

    columns = [{
        title: '教练',
        dataIndex: '教练',
        key: '教练',
    }, {
        title: '15分钟',
        dataIndex: '15分钟',
        key: '15分钟',
    }, {
        title: '30分钟',
        dataIndex: '30分钟',
        key: '30分钟',
    }, {
        title: '45分钟',
        dataIndex: '45分钟',
        key: '45分钟',
    }, {
        title: '合计',
        dataIndex: '合计',
        key: '合计',
    }, {
        title: '散客15分钟',
        dataIndex: '散客15分钟',
        key: '散客15分钟',
    }, {
        title: '散客30分钟',
        dataIndex: '散客30分钟',
        key: '散客30分钟',
    }, {
        title: '散客45分钟',
        dataIndex: '散客45分钟',
        key: '散客45分钟',
    }, {
        title: '会员',
        dataIndex: '会员',
        key: '会员',
    }, {
        title: '潜在',
        dataIndex: '潜在',
        key: '潜在',
    }, {
        title: '合计',
        dataIndex: '合计2',
        key: '合计2',
    }];

    getColumns = (list)=>{
        if(list.length > 0){
            return Object.keys(list[0]).map(name=>{
                return {
                    title: name,
                    dataIndex: name,
                    key: name,
                }
            })
        }
        return [];
    };

    exportXlsx = () => {
        excelUtil.exportExcels({
            "次卡":{
                headers: this.getColumns(this.state.list1),
                data: this.state.list1
            },
            "年卡教练费":{
                headers: this.getColumns(this.state.list2),
                data: this.state.list2
            },
            "年卡年费":{
                headers: this.getColumns(this.state.list3),
                data: this.state.list3
            },
            "合计":{
                headers: this.getColumns(this.state.list4),
                data: this.state.list4
            }
        }, `会员消费统计_${this.state.store_id}_${this.state.date.year()}年.xlsx`)
    };


    render() {
        let {list1,list2,list3,list4} = this.state;
        return (

                <Box>
                    <Space>
                    <StoreSelect
                        style={{width:150}}
                        onChange={this.onStoreChange}
                        value={this.state.store_id}
                    />

                    <DatePicker
                        allowClear={false}
                        onChange={this.onChange}
                        value={this.state.date}
                        picker="year"/>
                    <Button onClick={this.exportXlsx}>导出</Button>
                    </Space>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="次卡" key="1">
                            <HotTable
                                data={list1}
                                colHeaders={true}
                                rowHeaders={true}
                                width='auto'
                                height='auto'
                                // colWidths={100}
                                licenseKey="non-commercial-and-evaluation"
                                readOnly={true}
                            >
                                <HotColumn title="编号" data="编号"/>
                                <HotColumn title="门店" data="门店"/>
                                <HotColumn title="会员名" data="会员名"/>
                                <HotColumn title="销售" data="销售"/>
                                <HotColumn title="教练" data="教练"/>
                                <HotColumn title="有效/过期" data="有效/过期"/>
                                <HotColumn title="会籍数量" data="会籍数量"/>
                                <HotColumn title="首次办卡日期" data="首次办卡日期"/>
                                <HotColumn title="有效期至" data="有效期至"/>
                                <HotColumn title="1月" data="1月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="2月" data="2月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="3月" data="3月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="4月" data="4月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="5月" data="5月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="6月" data="6月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="7月" data="7月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="8月" data="8月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="9月" data="9月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="10月" data="10月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="11月" data="11月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="12月" data="12月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="剩余金额" data="剩余金额" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>

                            </HotTable>

                        </TabPane>
                        <TabPane tab="年卡教练费" key="2">
                            <HotTable
                                data={list2}
                                colHeaders={true}
                                rowHeaders={true}
                                width='auto'
                                height='auto'
                                // colWidths={100}
                                licenseKey="non-commercial-and-evaluation"
                                readOnly={true}
                            >
                                <HotColumn title="编号" data="编号"/>
                                <HotColumn title="门店" data="门店"/>
                                <HotColumn title="会员名" data="会员名"/>
                                <HotColumn title="销售" data="销售"/>
                                <HotColumn title="教练" data="教练"/>
                                <HotColumn title="有效/过期" data="有效/过期"/>
                                <HotColumn title="会籍数量" data="会籍数量"/>
                                <HotColumn title="首次办卡日期" data="首次办卡日期"/>
                                <HotColumn title="有效期至" data="有效期至"/>
                                <HotColumn title="1月" data="1月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="2月" data="2月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="3月" data="3月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="4月" data="4月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="5月" data="5月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="6月" data="6月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="7月" data="7月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="8月" data="8月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="9月" data="9月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="10月" data="10月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="11月" data="11月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="12月" data="12月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="剩余金额" data="剩余金额" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                            </HotTable>


                        </TabPane>
                        <TabPane tab="年卡年费" key="3">
                            <HotTable
                                data={list3}
                                colHeaders={true}
                                rowHeaders={true}
                                width='auto'
                                height='auto'
                                // colWidths={100}
                                licenseKey="non-commercial-and-evaluation"
                                readOnly={true}
                            >
                                <HotColumn title="编号" data="编号"/>
                                <HotColumn title="门店" data="门店"/>
                                <HotColumn title="会员名" data="会员名"/>
                                <HotColumn title="销售" data="销售"/>
                                <HotColumn title="教练" data="教练"/>
                                <HotColumn title="有效/过期" data="有效/过期"/>
                                <HotColumn title="会籍数量" data="会籍数量"/>
                                <HotColumn title="首次办卡日期" data="首次办卡日期"/>
                                <HotColumn title="有效期至" data="有效期至"/>
                                <HotColumn title="1月" data="1月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="2月" data="2月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="3月" data="3月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="4月" data="4月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="5月" data="5月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="6月" data="6月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="7月" data="7月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="8月" data="8月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="9月" data="9月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="10月" data="10月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="11月" data="11月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="12月" data="12月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="剩余金额" data="剩余金额" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>

                            </HotTable>


                        </TabPane>
                        <TabPane tab="合计" key="4">
                            <HotTable
                                data={list4}
                                colHeaders={true}
                                rowHeaders={true}
                                width='auto'
                                height='auto'
                                // colWidths={100}
                                licenseKey="non-commercial-and-evaluation"
                                readOnly={true}
                            >
                                <HotColumn title="编号" data="编号"/>
                                <HotColumn title="门店" data="门店"/>
                                <HotColumn title="会员名" data="会员名"/>
                                <HotColumn title="销售" data="销售"/>
                                <HotColumn title="教练" data="教练"/>
                                <HotColumn title="有效/过期" data="有效/过期"/>
                                <HotColumn title="会籍数量" data="会籍数量"/>
                                <HotColumn title="首次办卡日期" data="首次办卡日期"/>
                                <HotColumn title="有效期至" data="有效期至"/>
                                <HotColumn title="1月" data="1月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="2月" data="2月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="3月" data="3月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="4月" data="4月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="5月" data="5月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="6月" data="6月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="7月" data="7月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="8月" data="8月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="9月" data="9月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="10月" data="10月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="11月" data="11月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="12月" data="12月" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                                <HotColumn title="剩余金额" data="剩余金额" className="htRight" type='numeric' numericFormat={{pattern: '0.00'}}/>
                            </HotTable>
                        </TabPane>

                    </Tabs>
                    {/*{detailItem &&*/}
                    {/*<JiaoAn2*/}
                        {/*visible={true}*/}
                        {/*nick_name={detailItem["教练"]}*/}
                        {/*begin_time={begin_time}*/}
                        {/*end_time={end_time}*/}
                        {/*onClose={()=>{*/}
                            {/*this.setState({detailItem: null})*/}
                        {/*}*/}
                        {/*}*/}
                    {/*/>*/}
                    {/*}*/}
                </Box>


        );
    }
}


export default withRouter(Staff);



