import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getMembershipHistory} from "../../../lib/api_membership";
import {Table, Popover, notification, Button, Modal} from 'antd';
import moment from 'moment';
import Markdown from 'markdown-to-jsx';
import Box from '../../../components/box/index';
import {FullscreenOutlined} from '@ant-design/icons';


const {Column} = Table;

const linkreg = /\[([\s\S]*?)\]\(([\s\S]*?)\)/g;


const MyParagraph = ({children, href, ...props}) => {

    console.log(children, href);
    if (href.indexOf('@') >= 0) {
        let ls = href.split('@');
        switch (ls[0]) {
            case "staff":
                return <Link to={`/settings/staff/${ls[1]}`}>{children}</Link>;
            case "newmembership":
                return <Link to={`/member/member_ship/${ls[1]}`}>{children}</Link>;
            case "member":
                return <Link to={`/member/members/${ls[1]}`}>{children}</Link>;
            default:
                return children;
        }
    }


    return (
        <a {...props}>{children}</a>
    )
};


const dealContent = (content) => {
    // let str = content.replace(linkreg, (rs, rs1, rs2) => {
    //     console.log(rs, rs1, rs2);
    //     return `<Link to="${rs2}">${rs1}<\/Link>`;
    // })
    // return <div dangerouslySetInnerHTML={{__html: str}}/>
    return <Markdown options={{
        overrides: {
            a: {
                component: MyParagraph,
            },
        },
    }}>{content}</Markdown>;
}


const DataTable = ({list}) => {
    return (

        <Table
            size={"small"}
            dataSource={list}
            rowKey={"id"}
            pagination={false}
        >
            <Column
                title="时间"
                dataIndex="record_time"
                key="时间"
                width={180}
                render={(time) => moment(time).format("YYYY-MM-DD ddd HH:mm")}
            />
            <Column
                title="内容"
                dataIndex="content"
                key="内容"
                render={dealContent}
            />
            <Column
                title="操作人"
                dataIndex="createdByName"
                key="操作人"
            />
        </Table>
    )
}


const MembershipHistory = ({id}) => {
    const [list, setList] = useState([]);
    const [more, setmore] = useState(false);

    const fetchData = () => {
        getMembershipHistory(id)
            .then(res => {
                if (res.code === 0) {
                    setList(res.data);
                }
            })
    };

    useEffect(fetchData, []);

    return (
        <Box title={`操作记录(${list.length}条)`} style={{marginTop: 10}} extra={[
            <Button type="link" onClick={() => setmore(true)}>查看更多</Button>
        ]}>
            <DataTable list={list.slice(0, 5)}/>
            <Modal
                visible={more}
                title={`操作记录(${list.length}条)`}
                footer={false}
                onCancel={() => setmore(false)}
                width={800}
            >
                <DataTable list={list}/>
            </Modal>
        </Box>
    )
};


MembershipHistory.propTypes = {
    id: PropTypes.number.isRequired,
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MembershipHistory));
