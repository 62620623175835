import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {postStaff} from "../../lib/api_staff";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Radio, DatePicker } from 'antd';
import md5 from 'md5';
import TimeInput from './time-input';
import JiaoLianCheck from './jiaolian_check';
import {postPaiBanExec} from "../../lib/api_paiban_auto";
import moment from 'moment';

class PaiBanAddModal extends Component {

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                postPaiBanExec(this.props.id, values.date)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        this.handleOK();
                    })
                // postStaff({...values, password: md5(values.password)})
                //     .then(result => {
                //         console.log(result);
                //         // 刷新数据
                //         this.handleOK();
                //     })
            }
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };

        return (
            <Modal
                title="执行出勤计划"
                visible={!!this.props.id}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                destroyOnClose={true}
            >
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <Form.Item label="日期">
                        {getFieldDecorator("date", {
                            rules: [
                                {
                                    required: true,
                                    message: '请选择日期',
                                },
                            ],
                            initialValue: moment()
                        })(<DatePicker format={"YYYY-MM-DD"}/>)}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

PaiBanAddModal.propTypes = {
    id: PropTypes.number,
    onClose: PropTypes.func,
    onOK: PropTypes.func,
};

export default Form.create({name: 'staff-add'})(PaiBanAddModal);
