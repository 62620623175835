import React, {useState, useEffect} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Button, Descriptions, Space, Row, Col, Table, Typography, Popconfirm, Tag, Modal} from "antd";
import Box from "../../../components/box";
import {deleteHorseSYJH, getHorsesById, getHorseSYJH, getHorseTasks} from "../../../lib/api_horses";
import Horses_edit_modal from "./horses_edit_modal";
import {PlusOutlined, EditOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {deleteHorsesTrainingTasks, getHorsesTrainingTasks} from "../../../lib/api_horses_training_tasks";
import HorsesTrainingTasksAddModal from './horses_training_tasks_add_modal';
import HorsesTrainingTasksEditModal from './horses_training_tasks_edit_modal';
import moment from "moment";
import {Link} from "react-router-dom";
import HorsesSYJHAddModal from "./horses_siyangjihua_add_modal";
import HorsesTrainingList from './horses_training_list';
import HorsesZTAddModal from "./horses_zt_add_modal";
import HorsesZTList from "./horses_zt_list";


const {Column} = Table;
const {confirm} = Modal;

const Detail = ({id}) => {
    const [data, setData] = useState({});
    const [editId, setEditId] = useState(0);
    const [tasks, setTasks] = useState([]);
    const [showTasksAddModal, setShowTasksAddModal] = useState(false);
    const [editTaskId, seteditTaskId] = useState(0);
    const [showsyjhAddModal, setshowsyjhAddModal] = useState(false);
    const [syjh, setsyjh] = useState([]);
    const [showZTAddModal, setshowZTAddModal] = useState(false);
    const [updateid, setupdateid] = useState(0);


    const fetchData = () => {
        getHorsesById(id)
            .then(res => {
                if (res.code === 0) {
                    setData(res.data)
                }
            })
        // fetchTasks();
        fetchSYJH();
    }

    useEffect(fetchData, []);

    const fetchTasks = () => {
        getHorseTasks(id)
            .then(res => {
                if (res.code === 0) {
                    setTasks(res.data);
                }
            })
    }

    const fetchSYJH = () => {
        getHorseSYJH(id)
            .then(res => {
                if (res.code === 0) {
                    setsyjh(res.data);
                }
            })
    }

    const handleDeleteTask = (id) => {
        deleteHorsesTrainingTasks(id)
            .then(res => {
                if (res.code === 0) {
                    fetchTasks();
                }
            })
    }
    const handleDeleteSYJH = (syjhid) => {
        deleteHorseSYJH(id, syjhid)
            .then(res => {
                if (res.code === 0) {
                    fetchSYJH();
                }
            })
    }
    return (
        <PageContent title="马匹详情">
            <Box title={<span>
                {data.name}
                {data.tags ? JSON.parse(data.tags).map(tag => <Tag color="blue" key={tag}>{tag}</Tag>) : ""}
            </span>} extra={[<Space>
                <Button onClick={() => {
                    setEditId(id)
                }} icon={<EditOutlined/>}>编辑</Button>
            </Space>]}>
                <Descriptions bordered size={"small"} labelStyle={{width: 150}}>
                    <Descriptions.Item label="中文名">{data.name}</Descriptions.Item>
                    <Descriptions.Item label="英文名">{data.name_en}</Descriptions.Item>
                    <Descriptions.Item label="权属">{data.quanshu}</Descriptions.Item>
                    <Descriptions.Item label="照片">{data.photo}</Descriptions.Item>
                    <Descriptions.Item label="颜色">{data.color}</Descriptions.Item>
                    <Descriptions.Item label="性别">{data.sex}</Descriptions.Item>
                    <Descriptions.Item label="品种">{data.breed}</Descriptions.Item>
                    <Descriptions.Item label="出生地">{data.birthplace}</Descriptions.Item>
                    <Descriptions.Item label="年龄">{data.age}</Descriptions.Item>
                    <Descriptions.Item label="护照">{data.passport}</Descriptions.Item>
                    <Descriptions.Item label="芯片信息">{data.chip_information}</Descriptions.Item>
                    <Descriptions.Item label="备注">{data.remark}</Descriptions.Item>
                    <Descriptions.Item label="教练"><Link
                        to={`/manage/staff/${data.coach}`}>{data.coach_name}</Link></Descriptions.Item>
                    <Descriptions.Item label="马房"><Link
                        to={`/mafang/stables/${data.stable_id}`}>{data.stable_name}</Link></Descriptions.Item>
                    {data.mazhu === "马主"
                        ? <Descriptions.Item label="马主">马主({data.membership_name})</Descriptions.Item>
                        : <Descriptions.Item label="马主">{data.mazhu}</Descriptions.Item>
                    }
                </Descriptions>
                {/*<video src={}></video>*/}
            </Box>
            <Box title={"饲养计划"} style={{marginTop: 12}} extra={[<Space>
                <Button type={"primary"} icon={<PlusOutlined/>}
                        onClick={() => setshowsyjhAddModal(true)}>添加饲养计划</Button>
            </Space>]}>
                <Table
                    dataSource={syjh}
                    rowKey={"id"}
                    size={"small"}
                >
                    <Column
                        title={"提交时间"}
                        dataIndex={"createdAt"}
                        key={"提交时间"}
                        width={150}
                        render={(text, record, index) => moment(record.createdAt).format("YYYY-MM-DD HH:mm")}
                    />
                    <Column
                        title={"草料"}
                        dataIndex={"config"}
                        key={"草料"}
                        render={(text, record, index) => {
                            let i = 0;
                            if (record.config) {
                                let config = JSON.parse(record.config);
                                if (config.length > i) {
                                    return (<>
                                        <div>{config[i].name}</div>
                                        <div>{config[i].meiciliang}*{config[i].jicimeitian}</div>
                                    </>)
                                }
                            }
                        }}
                    />
                    <Column
                        title={"饲料"}
                        dataIndex={"config"}
                        key={"饲料"}
                        render={(text, record, index) => {
                            let i = 1;
                            if (record.config) {
                                let config = JSON.parse(record.config);
                                if (config.length > i) {
                                    return (<>
                                        <div>{config[i].name}</div>
                                        <div>{config[i].meiciliang}*{config[i].jicimeitian}</div>
                                    </>)
                                }
                            }
                        }}
                    />
                    <Column
                        title={"其他1"}
                        dataIndex={"config"}
                        key={"其他1"}
                        render={(text, record, index) => {
                            let i = 2;
                            if (record.config) {
                                let config = JSON.parse(record.config);
                                if (config.length > i) {
                                    return (<>
                                        <div>{config[i].name}</div>
                                        <div>{config[i].meiciliang}*{config[i].jicimeitian}</div>
                                    </>)
                                }
                            }
                        }}
                    />
                    <Column
                        title={"其他2"}
                        dataIndex={"config"}
                        key={"其他2"}
                        render={(text, record, index) => {
                            let i = 3;
                            if (record.config) {
                                let config = JSON.parse(record.config);
                                if (config.length > i) {
                                    return (<>
                                        <div>{config[i].name}</div>
                                        <div>{config[i].meiciliang}*{config[i].jicimeitian}</div>
                                    </>)
                                }
                            }
                        }}
                    />
                    <Column
                        title={"操作"}
                        key={"操作"}
                        render={(text, record, index) => {
                            return (
                                <Space>
                                    {/*<Button type="primary" onClick={() => {*/}
                                    {/*    setEditId(record.id);*/}
                                    {/*}}>编辑</Button>*/}
                                    <Typography.Link onClick={() => {
                                        confirm({
                                            title: '确定删除吗?',
                                            icon: <ExclamationCircleOutlined/>,
                                            content: '删除后无法恢复',
                                            okText: '确定',
                                            cancelText: '取消',
                                            onOk() {
                                                handleDeleteSYJH(record.id);
                                            },
                                            onCancel() {
                                            }
                                        });
                                    }}>删除</Typography.Link>
                                </Space>
                            )
                        }}
                    />

                </Table>
            </Box>
            <Row gutter={[12, 12]} style={{marginTop: 12}}>
                <Col span={12}>
                    <Box title={"状态记录"} extra={[<Space>
                        <Button type="primary" onClick={() => {
                            setshowZTAddModal(true);
                        }} icon={<PlusOutlined/>}>添加状态记录</Button>
                    </Space>]}>
                        <HorsesZTList id={id} key={updateid}/>
                    </Box>
                </Col>
                <Col span={12}>
                    <Box title={"训练记录"} extra={[<Space>

                    </Space>]}>
                        <HorsesTrainingList id={id}/>
                    </Box>
                </Col>
            </Row>

            {editId > 0 &&
                <Horses_edit_modal
                    id={editId}
                    onOk={() => {
                        setEditId(0);
                        fetchData();
                    }}
                    onCancel={() => {
                        setEditId(0);
                    }}
                />
            }
            {showTasksAddModal &&
                <HorsesTrainingTasksAddModal
                    horse_id={id}
                    onOk={() => {
                        setShowTasksAddModal(false);
                        fetchData();
                    }}
                    onCancel={() => {
                        setShowTasksAddModal(false);
                    }}
                />
            }
            {editTaskId > 0 &&
                <HorsesTrainingTasksEditModal
                    id={editTaskId}
                    onOk={() => {
                        seteditTaskId(false);
                        fetchData();
                    }}
                    onCancel={() => {
                        seteditTaskId(false);
                    }}
                />
            }
            {showsyjhAddModal &&
                <HorsesSYJHAddModal
                    horse_id={id}
                    onOk={() => {
                        setshowsyjhAddModal(false);
                        fetchData();
                    }}
                    onCancel={() => {
                        setshowsyjhAddModal(false);
                    }}
                />
            }
            {showZTAddModal &&
                <HorsesZTAddModal
                    horse_id={id}
                    onOk={() => {
                        setshowZTAddModal(false);
                        setupdateid(updateid + 1);
                        fetchData();
                    }}
                    onCancel={() => {
                        setshowZTAddModal(false);
                    }}
                />
            }

        </PageContent>
    )
}

export default Detail;