import React, {useEffect, useState} from 'react';
import {Card} from "antd";
import {getHorsesById} from "../../lib/api_horses";

const HorseDetail = ({id}) => {

    const [data, setData] = useState(null);

    // 获取马房数据
    const fetchData = () => {
        getHorsesById(id).then(res => {
            console.log(res)
            if (res.code === 0) {
                setData(res.data);
            }
        }).catch(err => {
            console.log(err);
        })

    }

    useEffect(fetchData, []);

    if (!data)
        return <div>loading...</div>;
    return (
        <div style={{backgroundColor: "#f5f5f5", padding: "20px"}}>
            <Card
                title={"马匹详情"}
            >
                <p>中文名：{data.name}</p>
                <p>英文名：{data.name}</p>
                <p>权属：{data.name}</p>
                <p>教练：{data.coach_name}</p>
                <p>年龄：{data.age}</p>
                <p>颜色：{data.color}</p>
            </Card>
            <a href={`/h5/mafang/${data.stable_id}`}>
                <Card
                    title={"马房编号"}
                    style={{marginTop: 12}}
                >
                    马房编号：{data.stable_name}
                </Card>
            </a>
        </div>
    );
}

export default HorseDetail;