import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {putStaff} from "../../lib/api_staff";
import '@ant-design/compatible/assets/index.css';
import {Modal, Input, Form, InputNumber, Divider, Radio, DatePicker} from 'antd';
import {putYuYueStat} from "../../lib/api_yuyue";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};

class QuerenEditModal extends Component {
    formRef = React.createRef();

    state = {
    };



    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    handleSubmit = e => {
        e.preventDefault();
        this.formRef.current.validateFields()
            .then(values => {
                console.log('Received values of form: ', values);
                // values.id = this.props.user_info.id;
                // values.birthday = values.birthday ? values.birthday.format("YYYY-MM-DD") : null;
                values=Object.assign({}, this.props.data, values);
                putYuYueStat(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            this.handleOK();
                        }
                    })
            });
    };

    render() {
        const {data} = this.props;


        if (data) {
            return (
                <Modal
                    title="修改统计信息"
                    visible={!!data}
                    onOk={this.handleSubmit}
                    onCancel={this.handleClose}
                    destroyOnClose={true}
                    width={600}
                >
                    <Form {...formItemLayout}
                          initialValues={data}
                          ref={this.formRef}
                    >
                        <Form.Item
                            label="教练"
                            name="nick_name"

                        >
                            {data.nick_name}
                        </Form.Item>
                        <Divider>1v1时长统计</Divider>

                        <Form.Item
                            label="15分钟"
                            name="d15"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item
                            label="30分钟"
                            name="d30"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item
                            label="45分钟"
                            name="d45"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item
                            label="合计"
                            name="dtotal"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>
                        <Divider>小组课时长统计</Divider>

                        <Form.Item
                            label="主教练"
                            name="xiaozu_zhu3"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>

                        <Form.Item
                            label="助教"
                            name="xiaozu_zhu4"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>
                        <Divider>人次统计</Divider>
                        <Form.Item
                            label="散客15分钟"
                            name="sanke15"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item
                            label="散客30分钟"
                            name="sanke30"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item
                            label="散客45分钟"
                            name="sanke45"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item
                            label="会员"
                            name="huiyuan"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item
                            label="潜在"
                            name="qianzai"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>
                        <Form.Item
                            label="合计"
                            name="ptotal"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber/>
                        </Form.Item>

                    </Form>
                </Modal>
            )
        } else {
            return null;
        }
    }
}

QuerenEditModal.propTypes = {
    onClose: PropTypes.func,
    onOK: PropTypes.func,
    data: PropTypes.object
};

export default QuerenEditModal;
