import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {putCard} from "../../lib/api_members.js";
import {Modal, Input, Row, Col, Radio, DatePicker, Form, Cascader, InputNumber,Typography} from 'antd';
import moment from 'moment';
import TeacherSelect from './teacher-select';
import MemberNumber from './member-number-edit';
import options from '../../lib/cascader-address-options';
import {postCard} from "../../lib/api_members";
import {postUserCardPayments} from "../../lib/api_users_cards";

const { Text, Link } = Typography;

class CardEditModal extends Component {
    formRef = React.createRef();

    state = {
        avg: 0
    };

    handleClose = () => {
        this.props.onClose && this.props.onClose();
    };

    handleOK = () => {
        this.props.onOK && this.props.onOK();
    };

    componentWillMount() {
    }

    componentDidMount() {
    }

    componentWillReceiveProps(props) {
        // this.Jisuan()
        if (props.data) {
            this.setState({
                avg: Math.ceil(props.data.payments / props.data.keshi_original)
            })
        }
    }


    Jisuan = () => {
        if (this.formRef.current) {
            let payments = this.formRef.current.getFieldValue("payments");
            let keshi_original = this.formRef.current.getFieldValue("keshi_original");
            this.setState({
                avg: Math.ceil(payments / keshi_original),
            })
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        let {data} = this.props;
        // if(this.state.error && data.card_type === 0) return;
        this.formRef.current.validateFields()
            .then(values => {
                console.log('Received values of form: ', values);
                // console.log(this.props.user_info.id)
                // values.begin_time = values.begin_time.format("YYYY-MM-DD");
                // values.end_time = values.end_time.format("YYYY-MM-DD");
                postUserCardPayments(data.id, {
                    payments: values.payments,
                })
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            this.handleOK();
                        } else {

                        }
                    })
            });
    };

    render() {

        const layout = {
            labelCol: {span: 8},
            wrapperCol: {span: 16},
        };

        const {data} = this.props;
        console.log(data);

        if (!this.props.data) {
            return null;
        }
        return (
            <Modal
                title={`设置支付金额`}
                visible={!!this.props.data}
                onOk={this.handleSubmit}
                onCancel={this.handleClose}
                // confirmLoading={this.state.editModalConfirmLoading}
                // okText="确认"
                // cancelText="取消"
                // footer={null}
                // width={800}
                destroyOnClose={true}
            >

                <Form
                    {...layout}
                    // className="ant-advanced-search-form"
                    // onSubmit={this.handleSubmit}
                    ref={this.formRef}
                    initialValues={{
                        keshi_original: data.keshi_original,
                        payments: data.payments,
                    }}
                >

                    <Form.Item
                        label="正课"
                        name="keshi_original"
                        rules={[
                            {
                                required: true,
                                message: '请输入正课课时',
                            },
                        ]}
                    >
                        <InputNumber disabled/>
                    </Form.Item>

                    <Form.Item
                        label="实际支付总金额"
                        rules={[
                            {
                                required: true,
                                message: '请输入支付金额',
                            },
                        ]}>
                        <Form.Item
                            // label="支付金额"
                            name="payments"
                            noStyle
                            rules={[
                                {
                                    required: true,
                                    message: '请输入支付金额',
                                },
                            ]}
                        >
                            <InputNumber onChange={this.Jisuan}/>

                        </Form.Item>
                        <div>
                            每课时：{this.state.avg}元<br />
                        </div>
                    </Form.Item>

                </Form>
            </Modal>

        )

    }

}


CardEditModal.propTypes = {
    onClose: PropTypes.func,
    onOK: PropTypes.func,
    data: PropTypes.object,
};

export default CardEditModal;
