import React, {useEffect, useState} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {getDrkb} from "../../../lib/api_drkb";
import {Statistic, Card, Row, Col, DatePicker, Tag, Progress, Space} from 'antd';
import moment from 'moment';
import Cards from "../cards/cards";

/**
 * 当日看板
 * @returns {JSX.Element}
 * @constructor
 */

const Drkb = () => {
    const [data, setdata] = useState({});
    const [data2, setdata2] = useState({});
    const [date, setdate] = useState(moment());
    const fetchData = () => {
        getDrkb(date.format("YYYY-MM-DD"))
            .then((res) => {
                if (res.code === 0) {
                    console.log(res.data);
                    // setlist(res.data);
                    setdata(res.data);
                    getDrkb(date.add(-1, "days").format("YYYY-MM-DD"))
                        .then((res) => {
                            if (res.code === 0) {
                                console.log(res.data);
                                // setlist(res.data);
                                setdata2(res.data);
                            }
                        })
                }
            })

    }

    useEffect(fetchData, [date]);

    return (
        <PageContent>
            <Row gutter={[12, 12]}>
                <Col span={12}>
                    {/*<DatePicker onChange={(date, dateString) => setdate(date)} defaultValue={date}/>*/}
                    <Card title="今日概览">
                        <Row gutter={[12, 12]}>
                            <Col span={24}>
                                <Card title={"出勤"}>
                                    {data.chuqin && data.chuqin.map(m => <div
                                        style={{fontSize: 14}}>{m.name}：{m.staffs.map(s => <Tag>{s.name}</Tag>)}</div>)}
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card>
                                    {/*<Statistic title="课勤比" value={data.keqingbi}/>*/}
                                    <div className="ant-statistic">
                                        <div className="ant-statistic-title">课勤比</div>
                                        <div className="ant-statistic-content"><span
                                            className="ant-statistic-content-value"
                                            style={{display: "block"}}>
                                        <Progress
                                            strokeColor={{
                                                '0%': '#108ee9',
                                                '100%': '#87d068',
                                            }}
                                            percent={data.keqingbi}
                                        />
                            </span>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card>
                                    <Statistic title="会员" value={data.huiyuan_anshi}/>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card>
                                    <Statistic title="潜在" value={data.qianzai_anshi}/>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card>
                                    <Statistic title="散客" value={data.sanke_anshi}/>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card title={"会员排课"}>
                                    <div>
                                        上午：
                                        {data.huiyuan1 && data.huiyuan1.map(m => <Tag>{m}</Tag>)}
                                    </div>
                                    <div>
                                        下午：
                                        {data.huiyuan2 && data.huiyuan2.map(m => <Tag>{m}</Tag>)}
                                    </div>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card title={"会籍有效期"}>
                                    <Row>
                                        <Col span={12}>
                                            <Statistic title="今日到期年卡" value={data.niankadaoqi}/>
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title="今日到期次卡" value={data.cikadaoqi}/>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>


                        </Row>

                    </Card>
                </Col>
                <Col span={12}>

                    <Card title="昨日汇总">
                        <Row gutter={[12, 12]}>
                            <Col span={24}>
                                <Card title={"课时确认"}>
                                    <div>
                                        未确认课时:
                                        {data2.weiqueren && data2.weiqueren.map(m =>
                                            <Tag>{m}</Tag>
                                        )}</div>
                                    <div>
                                        未写教学反馈: {data2.weitianxie && data2.weitianxie.map(m =>
                                        <Tag>{m}</Tag>
                                    )}
                                    </div>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card>
                                    {/*<Statistic title="课勤比" value={data.keqingbi}/>*/}
                                    <div className="ant-statistic">
                                        <div className="ant-statistic-title">课勤比</div>
                                        <div className="ant-statistic-content"><span
                                            className="ant-statistic-content-value"
                                            style={{display: "block"}}>
                                        <Progress
                                            strokeColor={{
                                                '0%': '#108ee9',
                                                '100%': '#87d068',
                                            }}
                                            percent={data2.keqingbi}
                                        />
                            </span>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card>
                                    <Statistic title="会员" value={data2.huiyuan_anshi}/>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card>
                                    <Statistic title="潜在" value={data2.qianzai_anshi}/>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card>
                                    <Statistic title="散客" value={data2.sanke_anshi}/>
                                </Card>
                            </Col>
                            {/*<Col span={24}>*/}
                            {/*    <Card title={"会员排课"}>*/}
                            {/*        <div>*/}
                            {/*            上午：*/}
                            {/*            {data2.huiyuan1 && data2.huiyuan1.map(m => <Tag>{m}</Tag>)}*/}
                            {/*        </div>*/}
                            {/*        <div>*/}
                            {/*            下午：*/}
                            {/*            {data2.huiyuan2 && data2.huiyuan2.map(m => <Tag>{m}</Tag>)}*/}
                            {/*        </div>*/}
                            {/*    </Card>*/}
                            {/*</Col>*/}
                            <Col span={24}>
                                <Card title={"收入"}>
                                    <Row>
                                        <Col span={8}>
                                            <Statistic title="年费" value={data2.shouru_nianfei || 0}/>
                                        </Col>
                                        <Col span={8}>
                                            <Statistic title="教练费" value={data2.shouru_jiaolianfei || 0}/>
                                        </Col>
                                        <Col span={8}>
                                            <Statistic title="赠送教练费"
                                                       value={data2.shouru_zengsongjiaolianfei || 0}/>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card title={"会籍有效期"}>
                                    <Row>
                                        <Col span={12}>
                                            <Statistic title="昨日新开年卡" value={data2.niankakai}/>
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title="昨日新开次卡" value={data2.cikakai}/>
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title="昨日到期年卡" value={data2.niankadaoqi}/>
                                        </Col>
                                        <Col span={12}>
                                            <Statistic title="昨日到期次卡" value={data2.cikadaoqi}/>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>

                    </Card>
                </Col>
            </Row>
        </PageContent>
    );
}

export default Drkb;