import React, {useEffect} from 'react';
import {Modal} from 'antd';
import {putMessageRead} from "../../lib/api_messages";
import {Switch, Route, withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import {flushMessageCount} from "../../reducers/message_count";


const md = require('markdown-it')({
    html: true,
});

const MessageDetailModal = ({data, onOk, onCancel, flushMessageCount}) => {

    const read = () => {
        putMessageRead(data.id)
            .then(res => {
                if (res.code === 0) {
                    flushMessageCount()
                }
            })
    };
    useEffect(read, []);


    return (
        <Modal
            title="消息详情"
            visible={true}
            onOk={onOk}
            onCancel={onCancel}
            destroyOnClose={true}
            footer={false}
        >
            <div dangerouslySetInnerHTML={{__html: md.render(data.content || '')}}/>
        </Modal>
    )

};


const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    flushMessageCount: () => {
        (async () => {
            dispatch(await flushMessageCount())
        })().catch()
    },

});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MessageDetailModal));

