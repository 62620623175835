/**
 * @name: 二级菜单
 * @description:
 * @author: Wood
 * @date: 2021/4/25
 * @update:
 */
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from "react-router-dom";
import {Menu} from 'antd';
import styles from './second-sider.module.css';

/**
 * 菜单项  递归
 * @param menu 当前菜单项
 * @return {XML} 组件
 * @constructor
 */
const MenuItem = (menu) => {
    if (!menu.children || menu.children.length === 0) {
        return (
            <Menu.Item key={menu.path} icon={menu.icon} className={styles.menuItem}>
                <Link to={menu.path}>
                    {menu.title}
                </Link>
            </Menu.Item>
        )
    } else {
        return (
            <Menu.SubMenu
                key={menu.path}
                icon={menu.icon}
                className={styles.menuItem}
                title={menu.title}
            >
                {menu.children && menu.children.map(item => MenuItem(item))}
            </Menu.SubMenu>
        )
    }
};

/**
 * 二级菜单
 * @param menu 一级菜单
 * @param path 当前页面路径
 * @return {XML} 组件
 * @constructor
 */
const SecondSider = ({menu, path}) => {
    // console.log(path)
    let keys = [];
    if (path && menu.children) {
        keys = menu.children.filter(_ => path.startsWith(_.path)).map(_ => _.path);
    }

    return (
        <div className={styles.secondSider}>
            <div className={styles.title}>
                丹枫马会
            </div>
            <Menu
                mode="inline"
                theme="light"
                defaultSelectedKeys={keys}
            >
                {menu.children && menu.children.map(menu => MenuItem(menu))}
            </Menu>
        </div>
    )
};

/**
 * 属性
 * @type {{menu: object, path: string}}
 */
SecondSider.propTypes = {
    menu: PropTypes.object,
    path: PropTypes.string,
};

export default SecondSider;