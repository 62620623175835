import PropTypes from 'prop-types';

const ConfigName = ({config, value}) => {
    let model = config.find(item => item.id === parseInt(value));
    return model ? model.name : "";
};
ConfigName.propTypes = {
    value: PropTypes.number,
    config: PropTypes.array,
};
export default ConfigName;

