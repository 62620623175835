import React, {Component, useRef, useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Form, Radio, DatePicker, TimePicker, Input, Button, message, InputNumber, Switch} from 'antd';
import DictRadioGroup from '../../components/dict/dict-radio-group'
import moment from 'moment';
import StaffSelect from './staff_select';
import {postYuYue, postYuYueShangma, postYuYueWancheng, postYuYueQuxiao} from "../../lib/api_yuyue";
import MemberCardSelecter from './member-card-selecter2';
import StaffCheck from './staff_check';

const formItemLayout = {
    labelCol: {
        span: 3
    },
    wrapperCol: {
        span: 21
    },
};


const FormHuiYuan = ({paiban, staff, yuyue, data, onOk}) => {
    const [form] = Form.useForm();
    const [course_type, setcourse_type] = useState(1);
    const [begin_time, setbegin_time] = useState(moment());
    const [end_time, setend_time] = useState(moment().add(15, 'm'));
    // const [group, setgroup] = useState(false);


    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log(values);

                postYuYue({
                    ...values,
                    id: data.id,
                    yuyue_type: 3,
                    begin_time: values.begin_time.format("YYYY-MM-DD HH:mm"),
                    end_time: values.end_time.format("YYYY-MM-DD HH:mm"),
                    // members: values.members,
                })
                // postMembers(values)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            message.success("预约成功");
                            onOk && onOk();
                        }
                        // this.handleOK();
                    })
            })
    };

    useEffect(() => {
        console.log(data);
        if (data && data.begin_time) {
            data.begin_time = moment(data.begin_time);
            data.end_time = moment(data.begin_time).add(data.anshi * 45, "m");
            setbegin_time(data.begin_time);
            setend_time(data.end_time);
            form.setFieldsValue({
                begin_time: data.begin_time,
                end_time: data.end_time
            })
        }
        if (data && data.teacher) {
            form.setFieldsValue({
                teacher: data.teacher,
            })
        }


        if (data && data.teachers) {
            form.setFieldsValue({
                teachers: data.teachers,
            })
        }


        if (data && data.members) {
            form.setFieldsValue({
                members: data.members,
            })
        }
        if (data && data.anshi) {
            form.setFieldsValue({
                anshi: data.anshi,
            })
        }

    }, []);


    const handleValuesChange = (changedValues, {course_type, anshi, begin_time, end_time, members}) => {
        console.log({course_type, anshi, begin_time, end_time, members});
        setcourse_type(course_type);
        setbegin_time(begin_time);
        end_time = moment(begin_time).add(anshi * 45, 'm');
        setend_time(end_time);
        form.setFieldsValue({
            end_time: end_time
        })

        // 会员信息处理
        // if (!members) members = "[]";
        // members = JSON.parse(members);
        // if (members.length > 1) {
        //     setgroup(true);
        // } else {
        //     setgroup(false);
        // }
    };

    return (
        <Form {...formItemLayout}
              onFinish={handleSubmit}
              form={form}
              style={{display: "flex", flexDirection: "column", height: "100%"}}
              initialValues={{
                  course_type: course_type,
                  begin_time: begin_time,
                  end_time: end_time,
                  anshi: 1,
                  user_type: '一般',
                  can_yuyue: true,
                  max_user: 5
              }}
              onValuesChange={handleValuesChange}
        >
            <div style={{flex: 1, overflow: "auto"}}>
                <Form.Item label="学员" name="members">
                    <MemberCardSelecter/>
                </Form.Item>

                <Form.Item label="类型">
                    小组预排课
                </Form.Item>

                <Form.Item label="课程" name={"course_type"} rules={[
                    {
                        required: true,
                        message: '请选择课程',
                    },
                ]}>
                    <DictRadioGroup type_value={"course_type"} optionType={"button"}/>
                </Form.Item>
                <Form.Item label="鞍时" name="anshi" rules={[
                    {
                        required: true,
                        message: '请选择按时',
                    },
                ]}>
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value={1}>1</Radio.Button>
                        <Radio.Button value={2}>2</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="日期" name={"begin_time"} rules={[
                    {
                        required: true,
                        message: '请选择日期',
                    },
                ]}>
                    <DatePicker format={"YYYY-MM-DD"}
                                minuteStep={5}
                                placeholder={"请选择日期"}
                    />
                </Form.Item>
                <Form.Item label="开始" required>
                    <Form.Item name="begin_time" noStyle rules={[
                        {
                            required: true,
                            message: '请选择开始时间',
                        },
                    ]}>
                        <TimePicker format={"HH:mm"}
                                    minuteStep={5}
                                    placeholder={"请选择时间"}
                        />
                    </Form.Item>
                    <span style={{marginLeft: 5}}>结束：</span>
                    <Form.Item name="end_time" noStyle>
                        <TimePicker format={"HH:mm"}
                                    disabled
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item label={"主教"} name="teacher" rules={[
                    {
                        required: true,
                        message: '请选择教练',
                    },
                ]}>
                    <StaffSelect
                        paiban={paiban}
                        // staffs={staff}
                        yuyue={yuyue}
                        type={course_type}
                        begin_time={begin_time}
                        end_time={end_time}
                    />
                </Form.Item>
                <Form.Item label="助教" name="teachers">
                    <StaffCheck
                        paiban={paiban}
                        begin_time={begin_time}
                        end_time={end_time}
                    />
                </Form.Item>
                <Form.Item label="备注" name="remark">
                    <Input.TextArea/>
                </Form.Item>
                <Form.Item label="最大人数" name="max_user">
                    <InputNumber/>
                </Form.Item>
                <Form.Item label="开放预约" name="can_yuyue" valuePropName={"checked"}>
                    <Switch/>
                </Form.Item>
            </div>
            <div style={{textAlign: "center"}}>
                {(data.status === 0 || data.status === 1 || !data.id) &&
                <Button htmlType={"submit"} type={"primary"} size={"large"} style={{width: 250}}>保存</Button>
                }

            </div>
        </Form>
    )
};


const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FormHuiYuan));

