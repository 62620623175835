import React, {useState, useEffect} from 'react';
import {getGrowup} from "../../lib/api_growup";
import {Tabs} from 'antd';
import TeachingDetail from "./teaching-detail";

const TeachingList = ({yuyue_id, menberstr}) => {
    const [users, setusers] = useState([]);

    const fetchData = () => {
        if (menberstr) {

            let members = JSON.parse(menberstr);
            if (members && members.length > 0) {
                setusers(members);
            }else{
                setusers([]);
            }
        }


    };

    useEffect(fetchData, []);

    if(users.length > 0){
        return (<Tabs defaultActiveKey={users[0].user_id}>
            {users.map(user => <Tabs.TabPane tab={user.nick_name} key={user.user_id}>
                <TeachingDetail yuyue_id={yuyue_id} user_id={user.user_id} />
            </Tabs.TabPane>)}
        </Tabs>)
    }else{
        return (<div>无会员</div>)
    }
}

export default TeachingList;