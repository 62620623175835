import PropTypes from 'prop-types';
import React, {Component, useEffect} from 'react';
import {postCard} from "../../../lib/api_cards";
import {Modal, Form, Input, DatePicker} from 'antd';
import TeacherSelect from "../../members/teacher-select";
import {addHorse} from "../../../lib/api_horses";
import {
    addHorsesTrainingTasks,
    getHorsesTrainingTasksById,
    updateHorsesTrainingTasks
} from "../../../lib/api_horses_training_tasks";
import moment from "moment";

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
};


const HorsesTrainingTasksAddModal = ({id, onCancel, onOk}) => {
    const [form] = Form.useForm();

    const fetchData = () => {
        getHorsesTrainingTasksById(id)
            .then(res => {
                if (res.code === 0) {
                    form.setFieldsValue({
                        time: moment(res.data.time),
                        name: res.data.name,
                    });
                }
            })
    }

    useEffect(fetchData, []);

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                updateHorsesTrainingTasks({...values, id: id})
                    .then(res => {
                        if (res.code === 0) {
                            onOk && onOk();
                        }
                    })
            })
    };

    const handleCancel = () => {
        onCancel && onCancel();
    };
    return (
        <Modal
            title="修改训练任务"
            visible={true}
            onOk={handleSubmit}
            onCancel={handleCancel}
            destroyOnClose={true}
        >
            <Form
                form={form}
                {...formItemLayout}
                initialValues={{}}
            >
                <Form.Item
                    label="时间"
                    name="time"
                    rules={[
                        {
                            required: true,
                            message: '请选择时间',
                        },
                    ]}
                >
                    <DatePicker showTime format={"YYYY-MM-DD HH:mm"}/>
                </Form.Item>

                <Form.Item
                    label="任务"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: '请输入任务',
                        }
                    ]}
                >
                    <Input.TextArea/>
                </Form.Item>
            </Form>

        </Modal>
    )
}

export default HorsesTrainingTasksAddModal;
