import PropTypes from 'prop-types';
import React, {Component, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Input, Select, InputNumber} from 'antd';
import moment from 'moment';
// import {school_numbers} from "../../config";
import {getAccountMemberNumber} from "../../lib/api_account";


const MemberNumber = ({value, onChange}) => {
    const [member_number, setmember_number] = useState(`01${moment().format("YYYY")}0001`);
    const [member_school_number, setmember_school_number] = useState("01");
    const [member_year, setmember_year] = useState(moment().format("YYYY"));
    const [member_index, setmember_index] = useState("0001");
    const [stores, setstores] = useState([]);
    const [students, setstudents] = useState([]);

    /**
     * 获取数据
     */
    const fetchData = () => {
        getAccountMemberNumber()
            .then(res => {
                if (res.code === 0) {
                    setstudents(res.data.students);
                    setstores(res.data.stores);
                    setmember_school_number(res.data.stores[0].number);
                    setmember_year(moment().format("YYYY"));
                    // setmember_index("0001")
                }
            })
    }

    useEffect(fetchData, []);

    useEffect(()=>{
        handleYearChange(member_year);
    },[students])

    /**
     * 返回会员编号结果
     */
    const handleNumberChange = () => {
        setmember_number( member_school_number + member_year + member_index);
        onChange && onChange({
            member_number: member_school_number + member_year + member_index,
            member_school_number,
            member_year,
            member_index
        })
    };
    useEffect(handleNumberChange, [member_school_number, member_year, member_index])

    const handleSchoolChange = (value) => {
        console.log(value);
        setmember_school_number(value);
    };

    /**
     * 年份变化
     * @param value
     */
    const handleYearChange = (value) => {
        console.log(value);
        // 计算年份最大值
        let maxindex = students.filter(item => item.member_year === value).reduce((a, b) => {
            return a > parseInt(b.member_index) ? a : parseInt(b.member_index);
        }, 0);
        console.log(students);
        setmember_year(value);
        setmember_index((maxindex + 1).toString().padStart(4, "0"));
    };

    /**
     * 序号变化
     * @param value
     */
    const handleIndexChange = (value) => {
        setmember_index(value.toString().padStart(4, "0"));
    };


    let years = [];
    for (let i = 2020; i < 2030; i++) {
        years.push(i.toString());
    }
    return (
        <Input.Group compact>
            <Select value={member_school_number}
                    onChange={handleSchoolChange}
            >
                {stores.map(item => <Select.Option value={item.number} key={item.number}>{item.number}</Select.Option>)}
            </Select>
            <Select value={member_year}
                    onChange={handleYearChange}
            >
                {years.map(year => <Select.Option value={year} key={year}>{year}</Select.Option>)}
            </Select>
            <InputNumber
                value={member_index}
                formatter={value => value.padStart(4, "0")}
                parser={value => parseInt(value).toString()}
                min={0}
                onChange={handleIndexChange}
            />
            <div style={{margin: "5px 8px"}}>编号：{member_number}</div>
        </Input.Group>
    )
}


MemberNumber.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
};

// export default MemberNumber;

const mapStateToProps = state => ({
    students: state.students,
    stores: state.stores
});

const mapDispatchToProps = dispatch => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberNumber);


