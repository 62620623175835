import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {message, Card, Descriptions, Avatar, Button, Space, Table, Popconfirm} from 'antd';
import PageContent from '../../../components/page-content/page-content';
import {getStaffDetail, postStaffCoursesFee, deleteStaffCoursesFee} from "../../../lib/api_staff";
import {setStaff} from "../../../reducers/staff";
import AuthComponent from '../../../components/auth_component';
import StaffResetModal from './staff2-reset-modal';
import StaffEditModal from './staff2-edit-modal';
import CoursesFeeSelectModal from '../courses-fee/courses-fee-select-modal';
import DictName from '../../../components/dict/dict-name';

// @hasAuth
@AuthComponent
class StaffDetail extends Component {
    state = {
        data: null,

        showAddModal: false,
        editUser: null,
        resetUser: null
    };

    componentDidMount() {
        // console.log(this);
        // this.hasRole();
        this.loadData()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    loadData = () => {
        getStaffDetail(this.props.id)
            .then((result) => {
                if (result.code === 0) {
                    this.setState({
                        data: result.data
                    });
                    // this.props.setStaff(result.data);
                } else {

                }
            })
    };

    showAddModal = () => {
        this.setState({
            showAddModal: true
        })
    };
    hideAddModal = () => {
        this.setState({
            showAddModal: false
        })
    };

    postCousesFee = (ids) => {
        postStaffCoursesFee(this.props.id, ids)
            .then(res => {
                if (res.code === 0) {
                    this.loadData();
                    message.success("保存成功!")
                }
            })
    };

    handleDeleteCoursesFee = (id) => {
        deleteStaffCoursesFee(this.props.id, id)
            .then(res => {
                if (res.code === 0) {
                    this.loadData();
                    message.success("移除成功!")
                }
            })
    };


    render() {
        let {data} = this.state;

        if (!data) {
            return null;
        }

        return (
            <PageContent title={"员工详情"}
                         routes={[
                             {
                                 path: '',
                                 breadcrumbName: <><span>首页</span></>,
                             },
                             {
                                 path: '//staff',
                                 breadcrumbName: '员工管理',
                             },
                         ]}>

                <Card>

                    <Descriptions title={data.name}
                                  extra={
                                      <Space>
                                          <Button onClick={() => {
                                              this.setState({editUser: data})
                                          }}>修改</Button>
                                          <Button onClick={() => {
                                              this.setState({resetUser: data})
                                          }}>重置密码</Button>
                                      </Space>
                                  }
                    >

                        <Descriptions.Item label="工号">{data.gh}</Descriptions.Item>
                        <Descriptions.Item label="别名">{data.alias}</Descriptions.Item>
                        <Descriptions.Item label="企业微信账号">{data.userid}</Descriptions.Item>
                        <Descriptions.Item label="手机">{data.mobile}</Descriptions.Item>
                        <Descriptions.Item label="性别">{data.gender}</Descriptions.Item>
                        <Descriptions.Item label="教练级别">{data.staff_order_by}</Descriptions.Item>
                        <Descriptions.Item label="邮箱">{data.email}</Descriptions.Item>
                        <Descriptions.Item label="状态">{data.enable === 1 ? "启用" : "已禁用"}</Descriptions.Item>
                        <Descriptions.Item label="职位">{data.position}</Descriptions.Item>
                        <Descriptions.Item label="头像"><Avatar size={64} src={data.avatar}/></Descriptions.Item>
                        <Descriptions.Item label="状态">{data.status}</Descriptions.Item>
                        <Descriptions.Item label="部门">{data.main_department}</Descriptions.Item>
                    </Descriptions>

                    <Descriptions title={"其他信息"}>
                        {data.extattr && JSON.parse(data.extattr).attrs.map(_ => {
                            if (_.type === 0) {
                                return <Descriptions.Item label={_.name}>{_.text.value}</Descriptions.Item>
                            } else if (_.type === 1) {
                                return <Descriptions.Item label={_.name}><a
                                    href={_.web.url}>{_.web.title}</a></Descriptions.Item>
                            } else {
                                return null;
                            }
                        })}
                        {/*<Descriptions.Item label="其他信息">{data.extattr}</Descriptions.Item>*/}
                    </Descriptions>


                </Card>
                <Card title="课程" style={{marginTop: 10}}
                      extra={<Button type={"primary"} onClick={this.showAddModal}>添加</Button>}>
                    <Table
                        dataSource={data.courses_fees}
                        pagination={false}
                        size={"small"}
                        columns={[
                            {
                                title: "课程",
                                dataIndex: "course_type",
                                key: "课程",
                                render: (value) => <DictName type_value={"course_type"} value={value}/>
                            },
                            {
                                title: "教师级别",
                                dataIndex: "coach_level",
                                key: "教师级别",
                                render: (value) => <DictName type_value={"coach_level"} value={value}/>
                            },
                            {
                                title: "授课方式",
                                dataIndex: "teaching_method",
                                key: "授课方式",
                                render: (value) => <DictName type_value={"teaching_method"} value={value}/>
                            },
                            {
                                title: "教练费",
                                dataIndex: "fee",
                                key: "教练费",
                                render: (value) => parseFloat(value).toFixed(2)
                            },
                            {
                                title: "操作",
                                key: "操作",
                                render: (record) => {
                                    return (
                                        <Popconfirm
                                            title="确定要删除这个设置吗？"
                                            onConfirm={() => this.handleDeleteCoursesFee(record.id)}
                                            // onCancel={cancel}
                                            // okText="Yes"
                                            // cancelText="No"
                                        >
                                            <a>移除</a>
                                        </Popconfirm>
                                    )
                                }
                            },
                        ]}
                    >


                    </Table>
                </Card>
                <StaffResetModal
                    onOK={() => this.setState({resetUser: null})}
                    onClose={() => this.setState({resetUser: null})}
                    user_info={this.state.resetUser}
                />
                <StaffEditModal
                    onOK={() => {
                        this.loadData();
                        this.setState({editUser: null})
                    }}
                    onClose={() => {
                        this.setState({editUser: null})
                    }}
                    user_info={this.state.editUser}
                />
                <CoursesFeeSelectModal
                    visible={this.state.showAddModal}
                    onOk={(ids) => {
                        this.setState({showAddModal: false});
                        // 保存数据
                        this.postCousesFee(ids)
                    }}
                    onCancel={() => {
                        this.setState({showAddModal: false})
                    }}
                />
            </PageContent>
        );
    }
}

StaffDetail.propTypes = {};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    setStaff: (staff) => dispatch(setStaff(staff)),
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(StaffDetail));



