import React, {useEffect, useState} from 'react';
import {Modal, Input, Form, message, Spin} from 'antd';
import {postMembershipShixiao} from "../../../lib/api_membership";
import {getApprovalsLast} from "../../../lib/api_approvals";

const UsersCardsShiXiaoModal = ({data, onOk, onCancel}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [isApprovals, setIsApprovals] = useState(false);


    // 获取审核情况
    const fetchApprovals = () => {
        setLoading(true);
        getApprovalsLast("年卡失效")
            .then(res => {
                if (res.code === 0) {
                    if (res.data && res.data.sp_status === 1) {
                        // 存在审核记录并且未完成状态
                        setIsApprovals(true);
                    } else {
                        setIsApprovals(false);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(fetchApprovals, []);

    const handleOk = () => {
        form.validateFields()
            .then(values => {
                postMembershipShixiao(data.id, values.remark)
                    .then(result => {
                        console.log(result);
                        // 刷新数据
                        if (result.code === 0) {
                            onOk && onOk()
                            message.success("已失效")
                        } else if (result.code === 1001) {
                            fetchApprovals();
                        }
                    })
            })
    };

    const handleClose = () => {
        onCancel && onCancel();
    };
    return (
        <Modal
            title="失效年卡操作"
            visible={!!data}
            onOk={handleOk}
            onCancel={handleClose}
            destroyOnClose={true}
            confirmLoading={loading || isApprovals}
        >
            {data &&
                <Spin spinning={loading || isApprovals} size={"large"} tip={"正在等待审核，审核完成前无法提交新的申请！"}>
                    <Form form={form}>
                        <Form.Item label="卡片名称">
                            {data.name}
                        </Form.Item>
                        <Form.Item label="扣除剩余年费">
                            {data.payments_remaining}
                        </Form.Item>
                        {/*<Form.Item label="手机号">*/}
                        {/*{user_info && user_info.mobile_number}*/}
                        {/*</Form.Item>*/}
                        {/*<Form.Item label="说明" name="remark"*/}
                        {/*rules={[*/}
                        {/*{*/}
                        {/*required: true,*/}
                        {/*message: '请输入说明',*/}
                        {/*},*/}
                        {/*]}*/}
                        {/*>*/}
                        {/*<Input.TextArea/>*/}
                        {/*</Form.Item>*/}
                    </Form>
                </Spin>
            }

        </Modal>
    )
};


export default UsersCardsShiXiaoModal;
