import React, {useState} from 'react';
import {Modal, Input, Table, InputNumber} from 'antd';
import {postHorseSYJH} from "../../../lib/api_horses";

const {Column} = Table;

const HorsesSYJHAddModal = ({horse_id, onCancel, onOk}) => {
    const [remark, setRemark] = useState("");
    const [list, setList] = useState([{
        id: 1,
        type: '草料',
        name: "",
        meiciliang: "",
        jicimeitian: "",
    }, {
        id: 2,
        type: '饲料',
        name: "",
        meiciliang: "",
        jicimeitian: "",
    }, {
        id: 3,
        type: '其他1',
        name: "",
        meiciliang: "",
        jicimeitian: "",
    }, {
        id: 4,
        type: '其他2',
        name: "",
        meiciliang: "",
        jicimeitian: "",
    }]);

    const handleSubmit = () => {
        console.log(JSON.stringify(list));
        postHorseSYJH({
            horse_id: horse_id,
            remark: remark,
            config: JSON.stringify(list),
        }).then(res => {
            if (res.code === 0) {
                onOk && onOk();
            }
        })
    };

    const handleCancel = () => {
        onCancel && onCancel();
    };

    const handleValueChange = (index, name, value) => {
        let newList = [...list];
        newList[index][name] = value;
        setList(newList);
    }
    return (
        <Modal
            title="添加饲养计划"
            open={true}
            onOk={handleSubmit}
            onCancel={handleCancel}
            destroyOnClose={true}
            width={800}
        >
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
            >
                <Column
                    title={"类型"}
                    dataIndex={"type"}
                    key={"类型"}
                />

                <Column
                    title={"名称"}
                    key={"名称"}
                    render={(text, record, index) =>
                        <Input value={record.name} onChange={(e) => handleValueChange(index, 'name', e.target.value)}/>
                    }
                />

                <Column
                    title={"每次量（克）"}
                    key={"每次量"}
                    render={(text, record, index) =>
                        <InputNumber value={record.meiciliang}
                               onChange={(e) => handleValueChange(index, 'meiciliang', e)}/>
                    }
                />
                <Column
                    title={"几次每天"}
                    key={"几次每天"}
                    render={(text, record, index) =>
                        <InputNumber value={record.jicimeitian}
                               onChange={(e) => handleValueChange(index, 'jicimeitian', e)}/>
                    }
                />
            </Table>

            <div>备注</div>
            <Input.TextArea value={remark} onChange={(e) => setRemark(e.target.value)}/>

        </Modal>
    )
}

export default HorsesSYJHAddModal;
