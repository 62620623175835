import React, {Component, useEffect, useState} from 'react';
import PageContent from "../../../components/page-content/page-content";
import {Button, Space, Table, Typography} from "antd";
import Box from "../../../components/box";
import {getStaff} from "../../../lib/api_staff";
import {getMenus} from "../../../lib/api_menu";
import ResourceAddModal from "./resource_add_modal";
import ResourceEditModal from "./resource_edit_modal";

const {Column} = Table;


const Resource = () => {
    const [list, setList] = useState([]);
    const [showAddModal, setShowAddModal] = useState(-1);
    const [editId, setEditId] = useState(0);

    const fetchData = () => {
        getMenus()
            .then((result) => {
                if (result.code === 0) {
                    setList(buildTree(result.data, 0));
                }
            })
    }

    useEffect(fetchData, []);

    // 组装成树结构
    const buildTree = (data, pid) => {
        let list = [];
        let fdata = data.filter(item => item.pid === pid);
        for (let i = 0; i < fdata.length; i++) {
            let item = fdata[i];
            item.children = buildTree(data, item.id);
            list.push(item);
        }
        if (list.length > 0)
            return list;
        else
            return null;
    }

    return (<PageContent>
        <Box title={"资源管理"} extra={[<Button onClick={() => setShowAddModal(0)}>添加</Button>]}>
            <Table
                dataSource={list}
                rowKey={"id"}
                pagination={false}
                size={"small"}
                // rowClassName={this.rowClassName}
                // rowSelection={rowSelection}
            >
                <Column
                    title="名称"
                    dataIndex="title"
                    key="名称"
                />
                <Column
                    title="简称"
                    dataIndex="minititle"
                    key="简称"
                />
                <Column
                    title="资源标识"
                    dataIndex="resource_key"
                    key="资源标识"
                />


                <Column
                    title="类型"
                    dataIndex="type"
                    key="类型"
                    render={(type) => type === 0 ? "菜单" : "模块"}
                />
                <Column
                    title="图标"
                    dataIndex="icon"
                    key="图标"
                />
                <Column
                    title="路径"
                    dataIndex="path"
                    key="路径"
                />
                <Column
                    title="排序"
                    key="排序"
                    dataIndex="order_index"
                />


                <Column
                    title="操作"
                    key="操作"
                    width={100}
                    render={(value, record, index) => <Space>
                        <Typography.Link onClick={() => setEditId(record.id)}>修改</Typography.Link>
                        <Typography.Link onClick={() => setShowAddModal(record.id)}>添加</Typography.Link>
                        {/*<Typography.Link>删除</Typography.Link>*/}
                    </Space>}
                />
            </Table>
        </Box>
        {showAddModal !== -1 && <ResourceAddModal
            id={showAddModal}
            onOk={() => {
                fetchData();
                setShowAddModal(-1);
            }}
            onCancel={() => {
                setShowAddModal(-1);
            }}
        />}

        {editId > 0 &&
            <ResourceEditModal
                id={editId}
                onOk={() => {
                    fetchData();
                    setEditId(0);
                }}
                onCancel={() => {
                    setEditId(0);
                }}
            />
        }


    </PageContent>)
}

export default Resource;