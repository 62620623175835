import React, {useState, useEffect} from 'react';
import {Card, Table, Input, Select, TimePicker, Space} from 'antd';
import {getCoursesFee} from "../../../lib/api_courses_fee";
import DictName from '../../../components/dict/dict-name';
import moment from 'moment';

const format = 'HH:mm';

const {Option} = Select;

const CourseFeeConfig = ({value, onChange}) => {
    const [list, setList] = useState([]);


    const loadData = () => {

    };
    useEffect(loadData, []);


    const handleChange = () => {
        onChange && onChange(value);
    };


    return (
        <Card bordered={false} title={"预约周期设置"}>
            <Space>
                每
                <Select defaultValue={(value && value.week) || 1} onChange={(e) => {
                    // console.log(e)
                    if (!value) value = {};
                    value.week = e;
                    handleChange()
                }}>
                    <Option value={1}>周一</Option>
                    <Option value={2}>周二</Option>
                    <Option value={3}>周三</Option>
                    <Option value={4}>周四</Option>
                    <Option value={5}>周五</Option>
                    <Option value={6}>周六</Option>
                    <Option value={7}>周日</Option>
                </Select>
                <TimePicker defaultValue={(value && value.time && moment(value.time, format)) || moment()} format={format} allowClear={false} onChange={(e) => {
                    if (!value) value = {};
                    value.time = e.format(format);
                    handleChange()
                }}/>
                开放下
                <Select defaultValue={(value && value.nextWeek) || 1} onChange={(e) => {
                    // console.log(e)
                    if (!value) value = {};
                    value.nextWeek = e;
                    handleChange()
                }}>
                    <Option value={1}>1</Option>
                    <Option value={2}>2</Option>
                    <Option value={3}>3</Option>
                    <Option value={4}>4</Option>
                    <Option value={5}>5</Option>
                </Select>
                周的约课(约课周期每周二到下周一)
            </Space>
        </Card>
    )
};


export default CourseFeeConfig;


